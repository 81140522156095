import { useEffect, useRef } from "react";
import customToast from "../Toast";

type UploadType = {
  fileRef: React.RefObject<HTMLInputElement>;
  handleClick: () => void;
  files: File[];
  handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (e: any, position: number) => void;
};

export function useUpload(
  managefiles: [File[], React.Dispatch<React.SetStateAction<File[]>>]
): UploadType {
  const fileRef: any = useRef(null);
  const [files, setFiles] = managefiles;

  useEffect(() => {
    /*
    const types = [
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    */
    // if (files) {
    //   if (types.includes(file.type)) {
    //     setErrorUpload(false);
    //     setButtonDelete(true);
    //   } else {
    //     setFile(null);
    //     setErrorUpload(true);
    //     setButtonDelete(false);
    //   }
    // }
  }, [files]);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allItems = event.target.files;
    const newUploads: File[] = [];
    for (let i = 0; i < (allItems?.length ?? 0); i++) {
      // const isAlreadyAdded = files.find(
      //   (file) => file.name === allItems?.[i].name
      // );
      if ((allItems?.[i]?.size ?? 0) < 2 * 1024 * 1024) {
        if (allItems?.[i]) newUploads.push(allItems?.[i]);
      }else{
        customToast.warning(`Permitidos arquivos de até 2MB`)
      }
    }
    if (newUploads) {
      setFiles([...files, ...newUploads]);
    }

    fileRef.current.value = null
  };

  const handleClick = () => {
    // event.preventDefault();
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleDelete = (e: any, position: number) => {
    e.preventDefault();
    setFiles(files.filter((_, i) => i !== position));
  };

  //   const fileDelete = () => {
  //     if (fileRef.current) {
  //       fileRef.current.value = "";
  //     }
  //   };

  return { fileRef, handleClick, files, handleUpload, handleDelete };
}
