import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

import {
  
  SelectionMode,
  Selection,
  
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";

import {
  CommandBarButton,
  DefaultButton,
  ICommandBarStyles,
  IDialogContentProps,
  
} from "office-ui-fabric-react";

import NoItems from "~/components/layout/NoItems";
import { Stack } from "office-ui-fabric-react";
import Panel from "~/components/layout/Panel";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import {
  InputAutocomplete,
  InputDate,
  InputNumber,
  InputText,
} from "~/components/Forms";
import CustomDialog from "~/components/CustomDialogFluentUI";


import { Creators as loadEmployeeSalarios } from "~/store/ducks/employees";
import { Creators as addEmployeeSalario } from "~/store/ducks/employees";
import { Creators as editEmployeeSalario } from "~/store/ducks/employees";
import { Creators as delEmployeeSalario } from "~/store/ducks/employees";
import {
  EmployeeCargoType,
  EmployeeSalaryType,
  EmployeeSelected,
} from "~/store/ducks/employees/types";
import {
  TipoMovType,
  DataTypes as DataTypesMotivo,
} from "~/store/ducks/admin/tipomov/types";
import { Creators as getTiposMov } from "~/store/ducks/admin/tipomov";
import { RootState } from "~/store/ducks";
import { InputAdornment } from "@material-ui/core";
import GridSalario from "./GridSalario";

interface IEmployeeSalariosState {
  items: EmployeeCargoType[];
  selectionDetails: string;
  selectionCount: number;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  motivoSelected: TipoMovType | null;
  initialValues: EmployeeSalaryType;
}

interface IEmployeeSalariosProps {
  employee: EmployeeSelected;
  idFuncionario: number;
  motivoState: DataTypesMotivo;
  loadEmployeeSalarios: (idFuncionario: number) => void;
  addEmployeeSalario: (
    idFuncionario: number,
    salario: EmployeeSalaryType
  ) => void;
  editEmployeeSalario: (
    idFuncionario: number,
    salario: EmployeeSalaryType
  ) => void;
  delEmployeeSalario: (idFuncionario: number, idHistSalario: number) => void;
  getTiposMov: (search?: string) => void;
}

class EmployeeSalarios extends Component<
  IEmployeeSalariosProps,
  IEmployeeSalariosState
> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number;

  constructor(props: IEmployeeSalariosProps) {
    super(props);

    this.state = {
      items: [],
      selectionCount: 0,
      selectionDetails: "",
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      motivoSelected: null,
      initialValues: initialValuesSalario,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { loadEmployeeSalarios, idFuncionario, employee } = this.props;

    if (employee.salarios?.length === 0 || employee.salarios === null) {
      loadEmployeeSalarios(idFuncionario);
    }
  }

  componentDidUpdate(prevProps: IEmployeeSalariosProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (item: EmployeeSalaryType): void => {
    this.openEdit();
  };

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as EmployeeSalaryType;

    this.setState({
      isPanelOpen: true,
      initialValues: selected,
      motivoSelected: selected.tipomov,
    });
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getTiposMov(text);
      }, 700);
    }
  };

  excludeSalario = () => {
    const { idFuncionario } = this.props;
    const idHistSalario = (this._selection.getSelection()[0] as EmployeeSalaryType)
      .idHistSalario!;
    this.props.delEmployeeSalario(idFuncionario, idHistSalario);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      motivoSelected,
      initialValues,
    } = this.state;
    const {
      employee,
      loadEmployeeSalarios,
      idFuncionario,
      addEmployeeSalario,
      editEmployeeSalario,
      motivoState,
    } = this.props;
    const { salarios,  loadingAction } = employee;
    return (
      <>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal verticalAlign="center">
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Add" }}
              text="Adicionar Salário"
              onClick={() =>
                this.setState({
                  isPanelOpen: true,
                  motivoSelected: null,
                  initialValues: initialValuesSalario,
                })
              }
            />
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Edit" }}
                text="Editar"
                onClick={this.openEdit}
              />
            )}
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Delete" }}
                text="Excluir"
                onClick={() => this.setState({ isDialogExcludeOpen: true })}
              />
            )}
          </Stack>
          <Stack>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              onClick={() => loadEmployeeSalarios(idFuncionario)}
            />
          </Stack>
        </Stack>
        {salarios?.length === 0 ? (
          <NoItems text="Não há histórico de salário" icon="Money" />
        ) : (
          <GridSalario 
            idFuncionario={idFuncionario}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={this._onItemInvoked}
          />
        )}

        <Panel
          title={
            !initialValues.idHistSalario ? "Novo Salário" : "Editar Salário"
          }
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              values.tipomov.descTipoMov = motivoSelected?.descTipoMov ?? "";
              console.log(values.idHistSalario);
              if (!values.idHistSalario) {
                addEmployeeSalario(idFuncionario, values);
              } else {
                editEmployeeSalario(idFuncionario, values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data"
                  name="dataHist"
                  value={values.dataHist ? moment(values.dataHist) : null}
                  onChange={(value) => {
                    setFieldValue("dataHist", value);
                    setFieldError("dataHist", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataHist}
                  helperText={errors.dataHist}
                  autoFocus
                />
                <InputText
                  label="Sequência"
                  name="sequencia"
                  value={values.sequencia ?? ""}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError("sequencia", "");
                  }}
                  error={!!errors.sequencia}
                  helperText={errors.sequencia}
                />
                <InputAutocomplete
                  value={motivoSelected as TipoMovType}
                  onChange={(_, newValue) => {
                    this.setState({ motivoSelected: newValue });
                    setFieldValue("idTipoMov", newValue?.idTipoMov);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("idTipoMov", "");
                    this.search(newInputValue);
                  }}
                  getOptionLabel={(motivo: TipoMovType) => motivo.descTipoMov}
                  getOptionSelected={(option, value) =>
                    option.idTipoMov === value.idTipoMov
                  }
                  options={motivoState.data}
                  input={{
                    idInput: "idTipoMov",
                    labelInput: "Motivo",
                    helperTextInput: errors.idTipoMov,
                    errorInput: !!errors.idTipoMov,
                  }}
                />
                <InputNumber
                  label="Percentual"
                  value={values.perc ? Number(values.perc) : null}
                  onValueChange={(val) => {
                    setFieldValue("perc", val.floatValue);
                    setFieldError("perc", "");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ paddingRight: 20 }}
                      >
                        %
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                  error={!!errors.perc}
                  helperText={errors.perc}
                />
                <InputNumber
                  label="Salário"
                  value={values.salario ? Number(values.salario) : null}
                  onValueChange={(val) => {
                    setFieldValue("salario", val.floatValue);
                    setFieldError("salario", "");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                  error={!!errors.salario}
                  helperText={errors.salario}
                />
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeSalario()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.fields = {
          alignItems: "center",
        };
        customStyles.cell = {
          selectors: {
            "&:nth-child(2), &:nth-child(4), &:nth-child(5)": {
              textAlign: "right",
            },
          },
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const initialValuesSalario: EmployeeSalaryType = {
  dataHist: null,
  idTipoMov: null,
  perc: "",
  salario: "",
  sequencia: null,
  tipomov: {
    idTipoMov: null,
    descTipoMov: "",
  },
};

const validationSchema = yup.object().shape({
  dataHist: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  idTipoMov: yup.number().nullable().required("Campo obrigatório"),
  perc: yup.number().required("Campo obrigatório"),
  salario: yup.number().required("Campo obrigatório"),
  sequencia: yup.number().nullable().required("Campo obrigatório"),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  motivoState: state.tipoMovReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeSalarios,
      ...addEmployeeSalario,
      ...editEmployeeSalario,
      ...delEmployeeSalario,
      ...getTiposMov,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSalarios);
