import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "~/components/CustomDialog";
import SelfEvaluationRender from "~/pages/Tasks/SelfEvaluation/selfEvaluationRender";
import SelfEvaluationCollectiveRender from "~/pages/SelfEvaluationCollective/selfEvaluationCollectiveRender";
import SelfEvaluationCollectiveResume from "~/pages/SelfEvaluationCollectiveResume/selfEvaluationCollectiveResume";
import { DataTypes as DataTypesAvaliation } from "~/store/ducks/avaliation/types";
import { Creators as CreatorsAvaliation } from "~/store/ducks/avaliation";
import { RootState } from "~/store/ducks";


interface IDialogPerformanceProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  idCicloAvaliacao: number | null;
}

export function DialogPerformance({
  isOpen,
  title,
  idCicloAvaliacao,
  onClose,
}: IDialogPerformanceProps) {
  const dispatch = useDispatch();
  const avaliation = useSelector<RootState, DataTypesAvaliation>(
    (state) => state.cycleAvaliationReducer
  );

  useEffect(() => {
    if (idCicloAvaliacao) {
      dispatch(CreatorsAvaliation.getAvaliationById(idCicloAvaliacao));
    }
  }, [idCicloAvaliacao]);

  return (
    <CustomDialog
      open={isOpen}
      title={title}
      btnConfirmText="Fechar"
      onClickConfirm={onClose}
      width={1120}
      height={720}
    >
      {avaliation.data?.tipoAvaliacao === "Autoavaliação" && (
        <SelfEvaluationRender
          error={avaliation.error}
          loading={avaliation.loading}
          avaliation={avaliation.data!}
          onSave={() => false}
          modalView={true}
        />
      )}
      {(avaliation.data?.tipoAvaliacao === "Avaliação gestor" ||
        avaliation.data?.tipoAvaliacao === "Desligado" ||
        avaliation.data?.tipoAvaliacao === "Afastado") && (
          <SelfEvaluationCollectiveRender
            isDesligado={avaliation.data?.tipoAvaliacao === "Desligado"}
            error={avaliation.error}
            loading={avaliation.loading}
            avaliation={avaliation.data!}
            onSave={() => false}
            modalView={true}
          />
        )}
      {avaliation.data?.tipoAvaliacao === "Avaliação coletiva" &&
        (
          <SelfEvaluationCollectiveResume
            onSave={() => false}
            avaliation={avaliation.data!}
            error={avaliation.error}
            loading={avaliation.loading}
            loadingSave={false}
            isDesligado={false}
            flagFinalizada={true}
            modalView={true}
          />
        )
      }
    </CustomDialog>
  );
}
