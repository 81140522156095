// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { PrimaryButton, DetailsHeader,  Text, Spinner, FontIcon } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky } from 'office-ui-fabric-react/lib/Sticky';
import CustomDialog from "~/components/CustomDialog";
import { InputText } from '~/components/Forms';
//Estilos

import { DataTypes as DataTypesTiposPDI } from "~/store/ducks/typesPDI/types";
import { Creators as getPdiTypes } from "~/store/ducks/typesPDI";

import { DataTypes as DataTypesPDI, PDIType } from '~/store/ducks/admin/pdi/types'
import { Creators as getPdi } from '~/store/ducks/admin/pdi'
import { Creators as resetPDI } from '~/store/ducks/admin/pdi'


import { DataTypes as DataTypesCompetence } from '~/store/ducks/competences/types'
import { Creators as getPdiCompetences } from '~/store/ducks/competences'

import { RootState } from "~/store/ducks";
import Dropdown from "~/components/layout/Dropdown";
import { MenuItem } from "@material-ui/core";
//#endregion

interface IFormSearchPDIState {
  columns: IColumn[];
  items: any[];
  selectionDetails: string;
  selectionCount: number;
  descricaoSearch: string;
  tipoPdiSelected: string;
  isOpenTipoPDI: boolean;
  isLoading: boolean;
  competenciaSelected: string;
  isOpenCompetence: boolean;
};

interface IPropsFormSearchPDI {
  pdis: DataTypesPDI;
  tiposPDI: DataTypesTiposPDI;
  isOpen: boolean;
  competencias: DataTypesCompetence;
  onClose: () => void;
  onAdd: (values: any[]) => void;
  resetPDI: () => void;
  getPdi: (SearchText?: string, TipoPDI?: number | string, Competencia?: number | string) => void;
  getPdiTypes: () => void;
  getPdiCompetences: () => void;
}


class FormSearch extends Component<IPropsFormSearchPDI, IFormSearchPDIState> {
  private _selection: Selection;

  constructor(props: IPropsFormSearchPDI) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Tipo',
        fieldName: 'tipoPDI.descTipoPDI',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (value: PDIType) => (
          <Stack horizontal verticalAlign="center">
            <FontIcon iconName="CircleFill" style={{ color: value.tipoPDI.cor, marginRight: 10 }} />
            <Text>{value.tipoPDI.descTipoPDI}</Text>
          </Stack>
        )
      },
      // {
      //   key: 'column2',
      //   name: 'Competência',
      //   fieldName: 'competencia.descCompetencia',
      //   minWidth: 200,
      //   maxWidth: 250,
      //   isRowHeader: true,
      //   isResizable: true,
      //   isSortedDescending: true,
      //   sortAscendingAriaLabel: 'Sorted A to Z',
      //   sortDescendingAriaLabel: 'Sorted Z to A',
      //   onColumnClick: this._onColumnClick,
      //   data: 'string',
      //   isPadded: true,
      //   onRender: (value: PDIType) => value.competencia.descCompetencia
      // },
      {
        key: 'column3',
        name: 'Descrição',
        fieldName: 'nomeAcaoPDI',
        minWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      descricaoSearch: "",
      tipoPdiSelected: '',
      isOpenTipoPDI: false,
      isLoading: false,
      competenciaSelected: '',
      isOpenCompetence: false,
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

  };

  componentDidUpdate(prevProps: IPropsFormSearchPDI, _: IFormSearchPDIState) {
    const { isOpen: prevIsOpen } = prevProps;
    const { isOpen: nextIsOpen } = this.props;

    if (prevIsOpen !== nextIsOpen) {
      if (nextIsOpen) {
        this.setState({ descricaoSearch: '', tipoPdiSelected: '', competenciaSelected: '' })
        this.props.resetPDI();
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  //#endregion

  handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const enterClick = event.key === 'Enter';
    if (enterClick) {
      const { descricaoSearch, tipoPdiSelected, competenciaSelected } = this.state;
      this._selection.setAllSelected(false);

      this.props.getPdi(descricaoSearch, tipoPdiSelected, competenciaSelected)
    }
  }

  render() {
    const { columns, descricaoSearch, tipoPdiSelected, competenciaSelected } = this.state;
    const { isOpen, onClose, onAdd, tiposPDI, pdis } = this.props;

    return (
      <>
        <CustomDialog
          open={isOpen}
          title="Pesquisa de Ações de Desenvolvimento"
          btnConfirmText="Selecionar"
          onClickCancel={onClose}
          onClickConfirm={() => {
            onAdd(this._selection.getSelection());
            // ! Tirar close()
            onClose();
          }}
          disabledBtnConfirm={this._selection.getSelectedCount() === 0}
          height={580}
          width={850}
        >
          <Stack horizontalAlign="end" styles={{ root: { width: 802, position: 'fixed', zIndex: 9, backgroundColor: '#FFFFFF' } }}>
            <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }} >
              <Stack>
                <InputText
                  label="Descrição"
                  value={descricaoSearch}
                  onChange={(e) => this.setState({ descricaoSearch: e.target.value })}
                  onKeyUp={this.handleEnter}
                  variant="outlined"
                  height_container={40}
                  fullWidth
                  size="small"
                  smaller="small"
                  styles={{ marginTop: 10, width: 360 }}
                  autoFocus
                />
                {/* <Dropdown
                  label="Competências"
                  name="worklevel"
                  variant="outlined"
                  size="small"
                  values={competenciaSelected}
                  styles={{ height: 40, marginTop: 10 }}
                  handleChange={(e: any) => this.setState({ competenciaSelected: e.target.value })}
                >
                  <MenuItem value="">Todos</MenuItem>
                  {
                    competencias.data.map((item, i) => (
                      <MenuItem key={i} id={`${item.idCompetencia}`} value={`${item.idCompetencia}`}>{item.descCompetencia}</MenuItem>
                    ))
                  }
                </Dropdown> */}
              </Stack>
              <Stack>
                <Stack styles={{ root: { width: 360 } }}>
                  <Dropdown
                    label="Tipo"
                    name="tipo"
                    variant="outlined"
                    size="small"
                    values={tipoPdiSelected}
                    styles={{ height: 40, marginTop: 10 }}
                    handleChange={(e: any) => this.setState({ tipoPdiSelected: e.target.value })}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {
                      tiposPDI.data.map((item, i) => (
                        <MenuItem key={i} id={`${item.idTipoPDI}`} value={`${item.idTipoPDI}`}>{item.descTipoPDI}</MenuItem>
                      ))
                    }
                  </Dropdown>
                </Stack>
              </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="end" verticalAlign="end" styles={{ root: { width: '100%' } }}>
              <PrimaryButton
                disabled={pdis.loadingData}
                text="Pesquisar"
                styles={{ root: { width: 110, marginTop: '15px !important' } }}
                onClick={() => {
                  this._selection.setAllSelected(false);

                  this.props.getPdi(descricaoSearch, tipoPdiSelected, competenciaSelected)
                }}
              />
            </Stack>
          </Stack>
          {
            pdis.loadingData ?
              <Spinner label="Pesquisando" styles={{
                root: {
                  position: 'absolute',
                  top: 325,
                  left: 0,
                  right: 0,
                }
              }}
              />
              :
              <ScrollablePane styles={{
                root: {
                  top: 180,
                  height: 325,
                  margin: '0 20px'
                }
              }}>
                <ShimmeredDetailsList
                  items={pdis.data}
                  enableShimmer={pdis.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  styles={{ root: { overflowX: 'hidden' } }}
                  onRenderDetailsHeader={
                    detailsHeaderProps => {
                      return (
                        <Sticky>
                          <DetailsHeader
                            {...detailsHeaderProps}
                            styles={{ root: { paddingTop: 0 } }}
                            layoutMode={DetailsListLayoutMode.justified}
                          />
                        </Sticky>
                      );
                    }
                  }
                />
              </ScrollablePane>
          }
        </CustomDialog>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: PDIType[] = [];
    items = this.props.pdis.data ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

/*
const WrapperAutoComplete = styled.div`
  position: relative;
  width: 100%;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment{
    right: 35px;
  }
`
*/

/*
const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    top: 18,
    right: 8,
    width: 24,
    height: 24,
    padding: 8
  },
  icon: {
    fontSize: 12,
  }
};
*/
const mapStateToProps = (state: RootState) => ({
  pdis: state.pdiReducer,
  competencias: state.competenceReducer,
  tiposPDI: state.typesPDIReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getPdi,
  ...getPdiTypes,
  ...getPdiCompetences,
  ...resetPDI
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
