import styled from 'styled-components';


export const Container = styled.div`
    padding: 20px 0;
`

export const WrapperBox = styled.div<{ color?: string }>`
    min-width: 110px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${props => props.color ? props.color : '#FFFFFF'} ;
    
`;

export const WrapperBoxCheck = styled.div`
    min-width: 110px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #FFFFFF;
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
export const Tr = styled.tr`
    min-height: 120px;
    td {
        width: 110px;
    }
`

export const TdFull = styled.td`
    width: 100% !important;
    background-color: #FBFBFB;
    border-radius: 2px;
    min-height: 120px;
    display: flex;
    align-items: flex-start;
`

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 10px;
    width: calc(100% + 20px);
    display: block;
    margin: 0 -10px;
    box-sizing: border-box;
`

export const TrFooter = styled.tr`
    min-height: 40px;
`

export const TdFooter = styled.td<{ bgColor?: string }>`
    height: 40px;
    background-color: ${props => props.bgColor ? props.bgColor : '#201F1E'};
    border: 1px solid #201F1E;
    color: #201F1E;
`

export const WrapperPersona = styled.div`
    display: flex;
    padding: 6px;
    box-sizing: border-box;
    flex-wrap: wrap;
`

export const WrapperPerson = styled.div<{ showIdentification: boolean }>`
    position: relative;
    /* cursor: ${({ showIdentification }) => showIdentification ? 'pointer' : 'normal'}; */

    &:hover{
        opacity: 0.7;
    }
`

export const TdBox = styled.td<{ bgColor: string, borderColor?: string }>`
    border-radius: 2px;
    border: 1px solid ${props => props.borderColor ? props.borderColor : props.bgColor};
    background-color: ${props => props.bgColor};
`

export const WrapperPersonaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 50px;
  height: auto;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  padding: 10px;
`
