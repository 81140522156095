import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import PainelHeader from "~/components/layout/PainelHeader";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";

import {
  Wrapper,
  Div,
  Table,
  Th,
  TrHead,
  Tr,
  TdBox,
  TdLegend,
  TitleBox,
  PercentBox,
  ValueBox,
} from "./styles";

import {
  FontIcon,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "office-ui-fabric-react";
import Dropdown from "~/components/layout/Dropdown";
import { Checkbox, ListItemText, MenuItem, Select } from "@material-ui/core";
import { PrimaryButton } from "~/components/Buttons";
import { makeStyles } from "@material-ui/core/styles";

import { Creators as CreatorsHome } from "~/store/ducks/home";
import { Creators as CreatorsReport } from "~/store/ducks/reports";
import { DataTypes as DataTypesReport } from "~/store/ducks/reports/types";

import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

import { generateColor } from "~/utils/colors";

const useStyles = makeStyles({
  root: {
    height: 32,
    width: 360,
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "6px 14.5px",
    },
    "& legend span": {
      display: "none",
    },
  },
});

const ReportPerformanceCurve: React.FC = () => {

  const [ano, setAno] = useState("");
  const [anoChartColor, setAnoChartColor] = useState<string[]>([])

  const [anosAnteriores, setAnosAnteriores] = useState<string[]>([]);



  const [language, setLanguage] = useState<"EN" | "PT">("EN");
  const [grupos, setGrupos] = useState<number[]>([]);
  const reportState = useSelector<RootState, DataTypesReport>(
    (state) => state.reportsReducer
  );
  const { data: report } = reportState;
  const chartLanguage = language === "EN" ? chartEN : chartPT;
  const [dataset, setDataset] = useState<Chart.ChartDataSets[]>([]);
  // Register the plugin to all charts:
  Chart.plugins.register(ChartDataLabels);


  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(CreatorsHome.setCurrentPage(page));
    dispatch(CreatorsReport.resetReports());
    dispatch(CreatorsReport.getReport());
  }, []);

  useEffect(() => {
    if (report.temporadas.length > 0 && ano === "") {
      const temporada = report.temporadas[0];
      setAno(String(temporada.ano) ?? "");
    }
  }, [report.temporadas, ano]);

  useEffect(() => {
    if (report.anteriores.length > 0 && anosAnteriores.length === 0) {
      //const antTemporada = report.anteriores[0];
      //setAnosAnteriores([String(antTemporada.ano)]);
    }
  }, [report.anteriores]);

  useEffect(() => {
    const arr = reportState.dataCurve?.data ?? [];
    let dataSetColors: string[] = arr.map( _ => generateColor())
    setAnoChartColor(dataSetColors)

    generateDataSet(dataSetColors)
  }, [reportState.dataCurve?.data])

  useEffect(() => {
    generateDataSet(anoChartColor)
  }, [chartLanguage]);

  const generateDataSet = (colors: string[]) => {
    const arr = reportState.dataCurve?.data ?? [];
    let dataSet: Chart.ChartDataSets[] = [];

    if (arr.filter((item) => item.anosAnteriores.length > 0).length > 0) {
      anosAnteriores
        .sort((a, b) => Number(a) - Number(b))
        .forEach((antAno, i) => {
            dataSet.push({
            label: antAno,
            data: arr.map((item) => item.anosAnteriores[i].percAnterior ?? 0),
            backgroundColor: colors[i],
            datalabels: {
              anchor: "end",
              align: "top",
              color: "#000000",
              formatter: (value) => value + "%",
            },
          });
        });
    }

    dataSet.push(
      {
        label: chartLanguage.legendChartRequired,
        data: arr.map((item) => item.percentualRequerido),
        backgroundColor: "#FFFFFF",
      
        borderColor: arr.map((item) => item.cor),
        borderWidth: 1,
        datalabels: {
          anchor: "end",
          align: "top",
          color: "#000000",
          formatter: (value) => value + "%",
        },
      },
      {
        label: chartLanguage.legendChartCalibrated,
        data: arr.map((item) => item.percentualAtual),
        backgroundColor: arr.map((item) => item.cor),
        datalabels: {
          anchor: "end",
          align: "top",
          color: "#000000",

          formatter: (value) => value + "%",
        },
      }
    );

    setDataset(dataSet);
  }

 

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const ano: string = event.target.value;
    setAno(ano);
    setGrupos([]);
    dispatch(CreatorsReport.getReport(ano));
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setGrupos(event.target.value as number[]);

    if((event.target.value as number[]).length > 0){

      setAnosAnteriores([]);
    }
  };

  const handleChangeMultipleAnos = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setAnosAnteriores(event.target.value as string[]);
  };

  const handleGenerateReport = () => {
    dispatch(CreatorsReport.getReportCurve(ano, grupos, anosAnteriores));
  };

  return (
    <Div>
      <PainelHeader
        title="Curva de Performance"
        icon={{ icon: "ReportDocument" }}
        notHasMarginLabelGroups={true}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          styles={{ root: { marginTop: 30, width: "100%" } }}
        >
          <Stack styles={{ root: { width: "100%" } }}>
            <Stack horizontal verticalAlign="center" style={{ height: 32 }}>
              <Stack styles={{ root: { minWidth: 120 } }}>
                <Text variant="medium">Ano</Text>
              </Stack>
              <Dropdown
                errors=""
                variant="outlined"
                label=""
                name="season"
                values={ano}
                handleChange={handleChange}
                fieldHeight={32}
                styles={{
                  boxSizing: "border-box",
                  height: 32,
                  backgroundColor: "white",
                  width: 170,
                }}
                autofocus
                customPadding="6.5px 14px"
              >
                {report.temporadas.map((temp) => (
                  <MenuItem key={temp.ano} value={temp.ano}>
                    {temp.ano}
                  </MenuItem>
                ))}
              </Dropdown>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              styles={{
                root: {
                  marginTop: "10px !important",
                  marginBottom: "10px !important",
                },
              }}
            >
              <Stack styles={{ root: { minWidth: 120 } }}>
                <Text variant="medium">Grupo</Text>
              </Stack>
              <Select
                className={classes.root}
                variant="outlined"
                label=""
                name="grupos"
                value={grupos}
                multiple
                onChange={handleChangeMultiple}
                disabled={reportState.loading}
                renderValue={(selected) => {
                  const nomeGrupos = report.reunioes
                    .filter((item) => {
                      return (selected as any[]).some(
                        (idCicloReuniao) =>
                          idCicloReuniao === item.idCicloReuniao
                      );
                    })
                    .map((item) => item.grupo.nomeGrupo);
                  return (nomeGrupos as string[]).join(", ");
                }}
              >
                {report.reunioes.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.idCicloReuniao}
                    style={{ height: 40 }}
                  >
                    <Checkbox
                      checked={grupos.indexOf(item.idCicloReuniao) > -1}
                      style={{ color: Colors.primary }}
                    />
                    <ListItemText primary={item.grupo.nomeGrupo} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack horizontal verticalAlign="center" style={{ height: 32 }}>
              <Stack styles={{ root: { minWidth: 120 } }}>
                <Text variant="medium">Comparar com</Text>
              </Stack>
              <Select
                className={classes.root}
                variant="outlined"
                label=""
                name="anosAnteriores"
                value={anosAnteriores}
                multiple
                onChange={handleChangeMultipleAnos}
                disabled={reportState.loading || grupos.length > 0}
                renderValue={(selected) => {

                  const anosAnt = report.anteriores
                    .filter((item) => {
                      return (selected as any[]).some(
                        (ano) => ano === String(item.ano)
                      );
                    })
                    .map((item) => String(item.ano));
                  return anosAnt.join(", ");
                }}
              >
                {report.anteriores.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={String(item.ano)}
                    style={{ height: 40 }}
                  >
                    <Checkbox
                      checked={
                        !!anosAnteriores.find((ant) => ant === String(item.ano))
                      }
                      style={{ color: Colors.primary }}
                    />
                    <ListItemText primary={item.ano} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
          <PrimaryButton text="Gerar" onClick={handleGenerateReport} />
        </Stack>
      </PainelHeader>
      {reportState.loadingAction ? (
        <Spinner
          size={SpinnerSize.medium}
          label="Carregando Curva"
          styles={{ root: { height: "100%" } }}
        />
      ) : (
        reportState.dataCurve && (
          <Wrapper>
            <Stack
              horizontal
              horizontalAlign="end"
              styles={{ root: { width: "100%", marginBottom: 10 } }}
            >
              <Stack styles={{ root: { width: 100 } }}>
                <Dropdown
                  label=""
                  name="language"
                  values={language}
                  variant="outlined"
                  handleChange={(e: any) => setLanguage(e.target.value)}
                  autofocus
                  fieldHeight={35}
                  noHelperText
                  styles={{ height: 35 }}
                  paddingSelected="5px 18px"
                >
                  <MenuItem value="EN">EN</MenuItem>
                  <MenuItem value="PT">PT</MenuItem>
                </Dropdown>
              </Stack>
            </Stack>
            <Table>
              <thead>
                <TrHead>
                  <Th colSpan={4}>
                    {chartLanguage.titlePartA} {reportState.dataCurve.qtdeTotal}{" "}
                    {chartLanguage.titlePartB}
                  </Th>
                </TrHead>
              </thead>
              <tbody>
                {reportState.dataCurve?.data.map((item, i, arr) => {
                  const label =
                    language === "EN" ? item.descIngles : item.descNivel;
                  return (
                    <Tr key={i}>
                      <TdBox color={item.cor}>
                        <Stack horizontalAlign="center" verticalAlign="center">
                          <TitleBox>{label}</TitleBox>
                          <ValueBox>{item.requerido}</ValueBox>
                          <PercentBox>{item.percentualRequerido}%</PercentBox>
                        </Stack>
                      </TdBox>
                      <TdBox color={item.cor} bg={item.cor}>
                        <Stack horizontalAlign="center" verticalAlign="center">
                          <TitleBox>{label}</TitleBox>
                          <ValueBox>{item.atual}</ValueBox>
                          <PercentBox>{item.percentualAtual}%</PercentBox>
                        </Stack>
                      </TdBox>
                      {i === 0 && (
                        <td rowSpan={arr.length + 1 ?? 1}>
                          <Bar
                            data={{
                              labels: arr.map((item) =>
                                language === "EN"
                                  ? item.descIngles
                                  : item.descNivel
                              ),
                              datasets: dataset,
                            }}
                            options={{
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: [ChartDataLabels],
                              legend: {
                                position: "bottom",
                              },
                              layout: {
                                padding: { top: 20 },
                              },
                              scales: {
                                yAxes: [
                                  {
                                    display: true,
                                    ticks: {
                                      suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                                      beginAtZero: true, // minimum value will be 0.,
                                      suggestedMax: 100,
                                    },
                                  },
                                ],
                              },
                              tooltips: {
                                callbacks: {
                                  label: (tooltipItem, data) => {
                                    let label =
                                      data?.datasets?.[
                                        tooltipItem.datasetIndex ?? 0
                                      ]?.label ?? "";
                                    let value: number = 0;
                                    
                                    data.datasets?.forEach((item, i) => {
                                      if (
                                        Number.isFinite(Number(item.label)) &&
                                        item.label === label
                                      ) {
                                        value =
                                          arr[tooltipItem.index ?? 0]
                                            .anosAnteriores[i].qtde;
                                      }
                                    });

                                    switch (label) {
                                      case "Last year":
                                      case "Ano anterior":
                                        value =
                                          arr[tooltipItem.index ?? 0]
                                            .qtdeAnterior;
                                        break;
                                      case "Required curve":
                                      case "Curva requerida":
                                        value =
                                          arr[tooltipItem.index ?? 0].requerido;
                                        break;
                                      case "Curva calibrada":
                                      case "Calibrated curve":
                                        value =
                                          arr[tooltipItem.index ?? 0].atual;
                                        break;
                                    }

                                    return `${label}: ${value}`;
                                  },
                                },
                                cornerRadius: 2,
                                bodyFontFamily: "Segoe UI",
                              },
                            }}
                          />
                        </td>
                      )}
                      <TdBox color={item.cor}>
                        <Stack
                          horizontalAlign="center"
                          verticalAlign="start"
                          styles={{ root: { height: 75 } }}
                        >
                          <TitleBox>{label}</TitleBox>
                          {item.diferenca === 0 ? (
                            <FontIcon
                              iconName="VerifiedBrandSolid"
                              style={{
                                fontSize: 50,
                                color: Colors.primary,
                                margin: "10px 0",
                              }}
                            />
                          ) : (
                            <ValueBox style={{ marginTop: 3 }}>
                              {item.diferenca > 0 && "+"}
                              {item.diferenca}
                            </ValueBox>
                          )}
                        </Stack>
                      </TdBox>
                    </Tr>
                  );
                })}
                <Tr>
                  <TdLegend>{chartLanguage.requiredCurve}</TdLegend>
                  <TdLegend>{chartLanguage.calibratedCurve}</TdLegend>
                  <TdLegend>{chartLanguage.requeredXcalibrated}</TdLegend>
                </Tr>
              </tbody>
            </Table>
          </Wrapper>
        )
      )}
    </Div>
  );
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Relatório Curva de Performance ", isCurrentItem: true },
];

const page: Page = {
  key: "relatorioCurvaPerformance",
  pages: itemsBreadCrumb,
};

interface IChartLanguage {
  titlePartA: string;
  titlePartB: string;
  requiredCurve: string;
  calibratedCurve: string;
  requeredXcalibrated: string;
  legendChartRequired: string;
  legendChartCalibrated: string;
  lastYear: string;
}

const chartPT: IChartLanguage = {
  titlePartA: "DESEMPENHO GERAL PARA",
  titlePartB: "PESSOAS CALIBRADAS (REQUERIDO VS CALIBRADO)",
  requiredCurve: "CURVA REQUERIDA",
  calibratedCurve: "CURVA CALIBRADA",
  requeredXcalibrated: "REQUERIDA VS CALIBRADA",
  legendChartRequired: "Curva requerida",
  legendChartCalibrated: "Curva calibrada",
  lastYear: "Ano anterior",
};

const chartEN: IChartLanguage = {
  titlePartA: "OVERALL PERFORMANCE FOR",
  titlePartB: "PEOPLE CALIBRATED (REQUIRED VS CALIBRATED)",
  requiredCurve: "REQUIRED CURVE",
  calibratedCurve: "CALIBRATED CURVE",
  requeredXcalibrated: "REQUIRED VS CALIBRATED",
  legendChartRequired: "Required curve",
  legendChartCalibrated: "Calibrated curve",
  lastYear: "Last year",
};

export default ReportPerformanceCurve;
