import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useFormik, FormikProps } from "formik";
import * as yup from "yup";

import {
  FontIcon,
  Stack,
  DefaultButton,
  PrimaryButton,
  Spinner,
  Label,
} from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import {
  Dropdown as DropdownUI,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { useId } from "@uifabric/react-hooks";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";

import { CommandBarButton } from "~/components/Buttons";
import { LabelGroup } from "~/components/label";
import { InputText } from "~/components/Forms";
import { DialogZ as CustomDialog } from "~/components/CustomDialogFluentUI";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  ContainerTitle,
  WrapperResults,
  WrapperForm,
  Table,
  Tr,
  TdNumber,
  TdPillar,
  TdGoal,
  TdPeso,
  TdCorpGoal,
  Th,
  ContainerCorpoGoal,
  Gap,
  Tbody,
  LeftItemMeta,
  RightItemMeta,
  BlankTd,
  TdReal,
  TdResult,
  TdButton,
  TdAtitude,
  TdDesc,
  TrAtitude,
  TrComentario,
  TextComentario,
  TextAtitude,
  TdResultAtitudes,
  Divider,
  TdAvaliacao,
  ThMeta,
  ThReal,
  ThResultado,
  ThAvaliacao,
  ThComentarios,
  TdIniciativa,
  TdValidacao,
  WrapperIniciativa,
  TableIniciativa,
  StatusTeam,
  WrapperLabelGroup,
} from "./styles";

import { formattedMeta } from "~/pages/Cycle/Strategic";

import Colors from "~/assets/js/colors";
import { Creators as CreatorsTasks } from "~/store/ducks/tasks";
import { Creators as CreatorsAvaliation } from "~/store/ducks/avaliation";
import { AvaliationType } from "~/store/ducks/tasks/types";
import NoItems from "~/components/layout/NoItems";

import { convertStatusTarefa } from "~/utils/tasks";

interface SelfEvaluationProps {
  onSave: (values?: any) => void;
  onSend?: () => void;
  avaliation: AvaliationType;
  loading: boolean;
  error: boolean;
  statusTarefa?: string;
  flagFinalizada?: boolean;
  loadingSave?: boolean;
  loadingAction?: boolean;
  modalView?: boolean;
}

interface IDialogIniciativa {
  isOpen: boolean;
  idCicloAvaliacaoArea?: number | null;
  index: number | null;
}

const SelfEvaluationRender: React.FC<SelfEvaluationProps> = (props) => {
  const dispatch = useDispatch();
  const [errorFormGestor, setErrorFormGestor] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [errorIniciativa, setErrorIniciativa] = useState(false);

  const { statusTarefa } = props;
  const avaliacao = props.avaliation;
  const isColetiva = avaliacao?.tipoAvaliacao === "Avaliação gestor";
  const tooltipId = useId("tooltip");
  const { modalView } = props;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    validateOnBlur: true,
    validateOnChange: false,
    validate(values) {
      const error: any = {};
      const hasIncitiavas = avaliacao?.areas?.some(
        (area) => area.iniciativas.length > 0
      );
      if (hasIncitiavas) {
        const hasComentarios = avaliacao?.areas?.every(
          (area) => area.comentariosIniciativas
        );
        const hasResultados = avaliacao?.areas?.every(
          (area) => area.resultados[area.resultados.length - 1].resultado
        );

        if (!hasComentarios || !hasResultados) {
          error.iniciativas = "Campo obrigatório";
          setErrorIniciativa(true);
        } else {
          setErrorIniciativa(false);
        }
      }

      return error;
    },
    onSubmit(values) {
      props.onSend?.();
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    errors,
    values,
    resetForm,
    isValid,
    isValidating,
    isSubmitting,
  } = formik;

  const {
    handleSubmit: handleSubmitGestor,
    setFieldValue: setFieldValueGestor,
    setFieldError: setFieldErrorGestor,
    errors: errorsGestor,
    values: valuesGestor,
    isValid: isValidGestor,
    isValidating: isValidatingGestor,
    isSubmitting: isSubmittingGestor,
    resetForm: resetFormGestor,
  } = useFormik({
    initialValues: initialValuesGestor,
    validationSchema: validationGestor,
    validateOnBlur: true,
    validateOnChange: false,
    validate(values) {
      const errors: any = {};
      if (
        (values.potencial === "Alto potencial para crescer" ||
          values.potencial === "Potencial para crescer") &&
        (values.sucessao === "Selecione uma opção" ||
          values.sucessao === "Null")
      ) {
        errors.sucessao = "Campo obrigatório";
      }

      return errors;
    },
    onSubmit(values) {
      props.onSave(values);
    },
  });

  const {
    

    values: valuesColetiva,
    errors: errorsColetiva,

  } = useFormik({
    initialValues: intialValuesGestorColetiva,
    validationSchema: validationGestorColetiva,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      console.log(values);
      // const iniciativasMetas = avaliacao?.areas.find(item => item.idCicloAvaliacaoArea === values.idCicloAvaliacaoArea)?.resultados?.find(item => item.colaboradormeta.flagIniciativas);

      // dispatch(CreatorsTasks.saveIniciativasAutoavaliacao(values.idCicloAvaliacaoArea!, values.avaliacao, values.comentarios, iniciativasMetas!.idCicloAvaliacaoAreaResultado))
      // setErrorIniciativa(false);
      // setIsDialogIniciativaOpen({ isOpen: false, idCicloAvaliacaoArea: null, index: null });
      // setFieldValue("iniciativas", true)
    },
  });

  useEffect(() => {
    if (!isValidatingGestor && !isSubmittingGestor && !isValidGestor) {
      setErrorFormGestor(true);
    } else {
      setErrorFormGestor(false);
    }
    if (!isValidating && !isSubmitting && !isValid) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [isValidatingGestor, isSubmittingGestor, isValidating, isSubmitting]);

  useEffect(() => {
    const atitudesMap = avaliacao?.atitudes.map((item) => {
      return {
        resultado: item.resultado,
        comentarios: item.comentarios,
        idCicloAvaliacaoAtitude: item.idCicloAvaliacaoAtitude,
      };
    });

    resetForm({
      values: {
        ...initialValues,
        atitudes: atitudesMap!,
        carreira: avaliacao?.aspiracoesCarreira ?? "",
        comentariosGerais: avaliacao?.comentariosGerais ?? "",
      },
    });

    resetFormGestor({
      values: {
        comentariosGestor: avaliacao?.comentariosGestor ?? "",
        idTabPerformanceGestor: avaliacao?.idTabPerformanceGestor,
        potencial: avaliacao?.potencial ?? "",
        sucessao: avaliacao?.sucessao
          ? avaliacao?.sucessao
          : avaliacao?.potencial === "Potencial lateral" ||
            avaliacao?.potencial === "Cedo demais para avaliar"
          ? "Null"
          : null,
      },
    });
  }, [dispatch, resetForm, avaliacao]);

  // const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
  //   const option = options[0];

  //   return (
  //     <Stack horizontal verticalAlign="center">
  //       {option.data && option.data.icon && (
  //         <Icon
  //           iconName={option.data.icon}
  //           aria-hidden="true"
  //           title={option.data.icon}
  //           styles={{ root: { ...option.data.style, marginRight: 10 } }}
  //         />
  //       )}
  //       <Stack>
  //         <span
  //           style={{
  //             fontSize: 12,
  //             whiteSpace: "break-spaces",
  //             lineHeight: 1.5,
  //             textAlign: "left",
  //             ...option.data.styleOption,
  //           }}
  //         >
  //           {option.text}
  //         </span>
  //       </Stack>
  //     </Stack>
  //   );
  // };

  const onRenderTitltePerformance = (
    options: IDropdownOption[]
  ): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        {option.data && !option?.data?.styleOption && (
          <Icon
            iconName="CircleShapeSolid"
            aria-hidden="true"
            styles={{
              root: { ...option.data.style, marginRight: 10, fontSize: 16 },
            }}
          />
        )}
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  const onRenderTitlePotencial = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container modalView={modalView}>
      {!modalView && (
        <PainelHeader
          title={`Avaliação ${avaliacao?.ciclo.ano ?? ""}`}
          icon={{ icon: "Equalizer" }}
          labelGroups={
            avaliacao
              ? [
                  {
                    label: "Funcionário",
                    value: avaliacao?.funcionario.nomeCompleto ?? "",
                    icon: "HomeGroup",
                    size: "full",
                  },
                  {
                    label: "Status",
                    value: convertStatusTarefa(statusTarefa ?? ""),
                    icon: "Flag",
                    size: "full",
                  },
                ]
              : []
          }
        />
      )}
      <Content modalView={modalView}>
        {props.loading ? (
          <Spinner
            label="Carregando avaliação"
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {props.error ? (
              <NoItems text="Tarefa não encontrada" error={true} />
            ) : (
              <>
                {avaliacao?.flagAutoAvaliacao && (
                  <Stack horizontal>
                    <CommandBarButton
                      iconProps={{ iconName: "Save" }}
                      isLoading={props.loadingSave}
                      onClick={() => {
                        setErrorIniciativa(false);
                        setErrorForm(false);
                        setErrorFormGestor(false);
                        props.onSave();
                      }}
                      styles={commandButtonStyle}
                    >
                      Salvar
                    </CommandBarButton>
                    <CommandBarButton
                      iconProps={{ iconName: "Send" }}
                      isLoading={props.loadingAction}
                      onClick={() => handleSubmit()}
                      styles={commandButtonStyle}
                    >
                      Enviar
                    </CommandBarButton>
                  </Stack>
                )}
                {avaliacao?.habilitaAvaliacaoGestor && (
                  <>
                    <CommandBarButton
                      iconProps={{ iconName: "Save" }}
                      isLoading={props.loadingSave}
                      onClick={() => {
                        if (avaliacao?.tipoAvaliacao !== "Avaliação gestor") {
                          handleSubmitGestor();
                        }
                      }}
                      styles={commandButtonStyle}
                    >
                      Salvar
                    </CommandBarButton>
                  </>
                )}
                {(errorForm || errorFormGestor) && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => {
                      setErrorForm(false);
                      setErrorFormGestor(false);
                    }}
                  >
                    Todos os campos são obrigatórios!{" "}
                    {errorIniciativa &&
                      "Necessário avaliar as iniciativas chave!"}
                  </MessageBar>
                )}

                <RenderResultados
                  areas={
                    avaliacao?.areas?.map(
                      (item) =>
                        ({
                          ...item,
                          iniciativas: item.iniciativas.map((init) => ({
                            descProjeto: init.colaboradoriniciativa.descProjeto,
                            nomeProjeto: init.colaboradoriniciativa.nomeProjeto,
                            peso: init.colaboradoriniciativa.peso,
                            prazoEntrega:
                              init.colaboradoriniciativa.prazoEntrega,
                            resultado: init.resultado,
                            idCicloAvaliacaoAreaIniciativa:
                              init.idCicloAvaliacaoAreaIniciativa,
                          })),
                        } as AreaAvaliationMap)
                    ) ?? []
                  }
                  comentariosGerais={avaliacao?.comentariosGerais ?? ""}
                  flagAutoAvaliacao={avaliacao?.flagAutoAvaliacao}
                  habilitaAvaliacaoGestor={avaliacao?.habilitaAvaliacaoGestor}
                  tipoAvaliacao={avaliacao?.tipoAvaliacao}
                  errorIniciativa={errorIniciativa}
                  setErrorIniciativa={(error) => setErrorIniciativa(error)}
                  validation={formik}
                />

                <WrapperResults>
                  <form onSubmit={handleSubmit}>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Atitudes Vencedoras
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        {props.loading ? (
                          <Spinner
                            label="Carregando atitudes"
                            styles={{ root: { height: 200 } }}
                          />
                        ) : (avaliacao?.atitudes?.length ?? []) <= 0 ? (
                          <NoItems
                            text="Não há atitudes"
                            error={false}
                            icon="Feedback"
                          />
                        ) : (
                          <Table>
                            <thead>
                              <tr>
                                <th colSpan={3}></th>
                                <ThAvaliacao>AVALIAÇÃO</ThAvaliacao>
                                <Gap />
                                <ThComentarios>COMENTÁRIOS</ThComentarios>
                              </tr>
                            </thead>
                            <Tbody>
                              {avaliacao?.atitudes.map((item, i) => {
                                const {
                                  atitude,
                                  idCicloAvaliacaoAtitude,
                                } = item;
                                const { nomeAtitude, descAtitude } = atitude;
                                const errorResultado = errors?.atitudes
                                  ? (errors?.atitudes[i] as any)?.resultado
                                  : "";
                                const errorComentario = errors?.atitudes
                                  ? (errors?.atitudes[i] as any)?.comentarios
                                  : "";

                                if (
                                  values.atitudes &&
                                  values?.atitudes.length > 0
                                ) {
                                  return (
                                    <TrAtitude key={i}>
                                      <TdAtitude>
                                        <p>{nomeAtitude}</p>
                                      </TdAtitude>
                                      <TdDesc>
                                        <TooltipHost
                                          content={descAtitude}
                                          id={tooltipId}
                                          styles={{
                                            root: { position: "relative" },
                                          }}
                                        >
                                          <TextAtitude>
                                            {descAtitude}
                                          </TextAtitude>
                                        </TooltipHost>
                                      </TdDesc>
                                      <Gap />
                                      <TdAvaliacao>
                                        <DropdownUI
                                          placeholder="Selecione uma opção"
                                          selectedKey={
                                            values?.atitudes[i]?.resultado ??
                                            "Selecione uma opção"
                                          }
                                          options={options ?? []}
                                          onRenderTitle={onRenderTitle as any}
                                          onChange={(e, item) => {
                                            setFieldValue(
                                              `atitudes.${i}.resultado`,
                                              item?.key
                                            );
                                            setFieldError(
                                              `atitudes.${i}.resultado`,
                                              ""
                                            );
                                            if (!isColetiva) {
                                              dispatch(
                                                CreatorsTasks.saveAtitudeAvaliacao(
                                                  idCicloAvaliacaoAtitude,
                                                  String(item?.key) ?? ""
                                                )
                                              );
                                            } else {
                                              dispatch(
                                                CreatorsAvaliation.saveAtitudeAvaliacao(
                                                  idCicloAvaliacaoAtitude,
                                                  String(item?.key) ?? ""
                                                )
                                              );
                                            }
                                          }}
                                          disabled={
                                            isColetiva
                                              ? !avaliacao?.habilitaAvaliacaoGestor
                                              : !avaliacao?.flagAutoAvaliacao
                                          }
                                          styles={{
                                            title: {
                                              height: 60,
                                              display: "flex",
                                              alignItems: "center",
                                              borderColor: errorResultado
                                                ? Colors.error
                                                : "#CCCCCC",
                                              paddingLeft: 10,
                                            },
                                            caretDownWrapper: { top: 16 },
                                          }}
                                        />
                                      </TdAvaliacao>
                                      <Gap />
                                      <TrComentario>
                                        <TextComentario
                                          rows={2}
                                          value={
                                            values?.atitudes[i]?.comentarios ??
                                            ""
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `atitudes.${i}.comentarios`,
                                              e.target.value
                                            );
                                            setFieldError(
                                              `atitudes.${i}.comentarios`,
                                              ""
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (
                                              avaliacao?.tipoAvaliacao !==
                                              "Avaliação gestor"
                                            ) {
                                              dispatch(
                                                CreatorsTasks.saveAtitudeComentario(
                                                  idCicloAvaliacaoAtitude,
                                                  e.target.value
                                                )
                                              );
                                            } else {
                                              dispatch(
                                                CreatorsAvaliation.saveAtitudeComentario(
                                                  idCicloAvaliacaoAtitude,
                                                  e.target.value
                                                )
                                              );
                                            }
                                          }}
                                          style={{
                                            borderColor: errorComentario
                                              ? Colors.error
                                              : "#CCCCCC",
                                          }}
                                          disabled={
                                            isColetiva
                                              ? !avaliacao?.habilitaAvaliacaoGestor
                                              : !avaliacao?.flagAutoAvaliacao
                                          }
                                        />
                                      </TrComentario>
                                    </TrAtitude>
                                  );
                                }
                              })}
                              <tr>
                                <td colSpan={3} />
                                <TdResultAtitudes colSpan={3}>
                                  <Stack
                                    horizontal
                                    verticalAlign="center"
                                    horizontalAlign="center"
                                  >
                                    {renderAvaliacao(
                                      convertAvaliacao(values.atitudes)
                                    )}
                                  </Stack>
                                </TdResultAtitudes>
                              </tr>
                            </Tbody>
                          </Table>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={false}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Revisão de Meio de Ano
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          styles={{ root: { width: "100%", marginBottom: 20 } }}
                        >
                          <Label
                            styles={{ root: { fontSize: 11, marginTop: -5 } }}
                          >
                            Momento de avaliar como está seu desempenho e se
                            necessário, realizar correções de curso para o
                            alcance das suas metas e dos objetivos estratégicos
                            do Marche. Lembre-se que você é o protagonista da
                            sua carreira por isso, após essa avaliação, agende
                            seu feedback com seu gestor para que juntos possam
                            traçar as rotas necessárias.
                          </Label>
                          <InputText
                            label="Comentários do funcionário"
                            multiline
                            rows={3}
                            value={avaliacao?.comentariosMeioAno ?? ""}
                            disabled={true}
                          />

                          <InputText
                            label="Comentários do líder"
                            multiline
                            rows={3}
                            value={avaliacao?.comentariosMeioAnoGestor ?? ""}
                            disabled={true}
                            styles={{ marginTop: 20 }}
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Revisão de Final de Ano
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          styles={{ root: { width: "100%", marginBottom: 20 } }}
                        >
                          <Label
                            styles={{ root: { fontSize: 11, marginTop: -5 } }}
                          >
                            Aqui você fará o balanço da sua performance no ano
                            que passou, ressaltando quais foram os pontos fortes
                            e principais oportunidades de melhoria. Esse balanço
                            também ajudará o seu gestor no embasamento da
                            avaliação de desempenho que ele fará, então, seja
                            objetivo e complemente a avaliação já feita com
                            fatos e dados.
                          </Label>
                          <WrapperForm>
                            <InputText
                              label="Comentários gerais"
                              multiline
                              rows={3}
                              value={values.comentariosGerais}
                              onChange={(e) => {
                                setFieldValue(
                                  "comentariosGerais",
                                  e.target.value
                                );
                                setFieldError("comentariosGerais", "");
                              }}
                              onBlur={(e) =>
                                dispatch(
                                  CreatorsTasks.saveComentariosGerais(
                                    e.target.value
                                  )
                                )
                              }
                              error={errors.comentariosGerais ? true : false}
                              helperText={errors.comentariosGerais}
                              disabled={
                                isColetiva
                                  ? !avaliacao?.habilitaAvaliacaoGestor
                                  : !avaliacao?.flagAutoAvaliacao
                              }
                            />
                          </WrapperForm>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <FontIcon
                            iconName="ChevronDown"
                            style={{ fontSize: 16 }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Text variant="mediumPlus" styles={textStyles}>
                          Carreira
                        </Text>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack styles={{ root: { width: "100%" } }}>
                          <Label
                            styles={{ root: { fontSize: 11, marginTop: -5 } }}
                          >
                            Não há ninguém na companhia que saiba mais do que
                            você mesmo sobre os seus objetivos, expectativas e
                            sonhos e por isso você precisa ser o protagonista da
                            sua carreira e para conseguirmos te apoiar em seu
                            desenvolvimento, queremos conhecer suas aspirações
                            de desenvolvimento. Descreva abaixo:
                          </Label>
                          <WrapperForm>
                            <InputText
                              label="Aspirações de desenvolvimento da carreira"
                              multiline
                              rows={3}
                              value={values.carreira}
                              onChange={(e) => {
                                setFieldValue("carreira", e.target.value);
                                setFieldError("carreira", "");
                              }}
                              onBlur={(e) => {
                                if (!isColetiva) {
                                  dispatch(
                                    CreatorsTasks.saveAspiracaoCarreira(
                                      e.target.value
                                    )
                                  );
                                } else {
                                  dispatch(
                                    CreatorsAvaliation.saveAspiracaoCarreira(
                                      e.target.value
                                    )
                                  );
                                }
                              }}
                              error={errors.carreira ? true : false}
                              helperText={errors.carreira}
                              disabled={
                                isColetiva
                                  ? !avaliacao?.habilitaAvaliacaoGestor
                                  : !avaliacao?.flagAutoAvaliacao
                              }
                            />
                          </WrapperForm>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    {isColetiva && (
                      <>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              O que está fazendo bem?
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              {/* <Label styles={{ root: { fontSize: 11, marginTop: -5 } }}>
                                Não há ninguém na companhia que saiba mais do que você mesmo sobre os seus objetivos, expectativas e sonhos e por isso você precisa
                                ser o protagonista da sua carreira e para conseguirmos te apoiar em seu desenvolvimento, queremos conhecer suas aspirações de
                                desenvolvimento. Descreva abaixo:
                              </Label> */}
                              <WrapperForm>
                                <InputText
                                  label="O que está fazendo bem?"
                                  multiline
                                  rows={3}
                                  value={
                                    valuesColetiva.comentariosPositivo ?? ""
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "comentariosPositivo",
                                      e.target.value
                                    );
                                    setFieldError("comentariosPositivo", "");
                                  }}
                                  onBlur={(e) =>
                                    dispatch(
                                      CreatorsAvaliation.saveComentariosPositivo(
                                        e.target.value
                                      )
                                    )
                                  }
                                  error={
                                    errorsColetiva.comentariosPositivo
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorsColetiva.comentariosPositivo
                                  }
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              O que precisa melhorar? Como posso te ajudar?
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              {/* <Label styles={{ root: { fontSize: 11, marginTop: -5 } }}>
                                Não há ninguém na companhia que saiba mais do que você mesmo sobre os seus objetivos, expectativas e sonhos e por isso você precisa
                                ser o protagonista da sua carreira e para conseguirmos te apoiar em seu desenvolvimento, queremos conhecer suas aspirações de
                                desenvolvimento. Descreva abaixo:
                              </Label> */}
                              <WrapperForm>
                                <InputText
                                  label="O que precisa melhorar?"
                                  multiline
                                  rows={3}
                                  value={
                                    valuesColetiva.comentariosMelhorar ?? ""
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "comentariosMelhorar",
                                      e.target.value
                                    );
                                    setFieldError("comentariosMelhorar", "");
                                  }}
                                  onBlur={(e) =>
                                    dispatch(
                                      CreatorsAvaliation.saveComentariosMelhorar(
                                        e.target.value
                                      )
                                    )
                                  }
                                  error={
                                    errorsColetiva.comentariosMelhorar
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorsColetiva.comentariosMelhorar
                                  }
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  </form>
                  {avaliacao?.habilitaVisualizacaoGestor && (
                    <form onSubmit={handleSubmitGestor}>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={
                            <FontIcon
                              iconName="ChevronDown"
                              style={{ fontSize: 16 }}
                            />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Text variant="mediumPlus" styles={textStyles}>
                            Resultado da Autoavaliação
                          </Text>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table style={{ width: "100%" }}>
                            <Tbody>
                              <tr>
                                <td style={{ width: 530, minWidth: 530 }} />
                                <TdResultAtitudes
                                  style={{ width: 420, minWidth: 420 }}
                                >
                                  <Stack
                                    horizontal
                                    verticalAlign="center"
                                    horizontalAlign="center"
                                  >
                                    <Stack horizontal verticalAlign="center">
                                      <StatusTeam
                                        bgColor={avaliacao?.performance?.cor}
                                      />
                                      <Text variant="medium">
                                        {avaliacao?.performance?.descNivel}
                                      </Text>
                                    </Stack>
                                  </Stack>
                                </TdResultAtitudes>
                              </tr>
                            </Tbody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                      {avaliacao.isGestor && (
                        <Accordion defaultExpanded={true}>
                          <AccordionSummary
                            expandIcon={
                              <FontIcon
                                iconName="ChevronDown"
                                style={{ fontSize: 16 }}
                              />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Text variant="mediumPlus" styles={textStyles}>
                              Avaliação do Gestor
                            </Text>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack styles={{ root: { width: "100%" } }}>
                              <Table>
                                <Tbody>
                                  <tr>
                                    <Gap
                                      style={{ minWidth: 370, maxWidth: 370 }}
                                    />
                                    <td style={{ width: 160, minWidth: 160 }}>
                                      <Text variant="small">PERFORMANCE 789</Text>
                                    </td>
                                    <TdResultAtitudes
                                      style={{
                                        width: 420,
                                        minWidth: 420,
                                        borderWidth: 0,
                                      }}
                                    >
                                      <DropdownUI
                                        placeholder="Selecione uma opção"
                                        options={[
                                          {
                                            key: -1,
                                            text: "Selecione uma opção",
                                            disabled: true,
                                            hidden: true,
                                            data: {
                                              styleOption: { fontSize: 14 },
                                            },
                                          },
                                          ...avaliacao?.tabPerformance.map(
                                            (item) => ({
                                              key: item.idTabPerformance,
                                              text: item.descNivel.toUpperCase(),
                                              data: {
                                                style: { color: item.cor },
                                              },
                                              hidden:
                                                item.flagBloquearAvaliacao,
                                            })
                                          ),
                                        ]}
                                        // defaultSelectedKey={avaliacao?.idTabPerformanceGestor ?? -1}
                                        selectedKey={
                                          valuesGestor.idTabPerformanceGestor ??
                                          -1
                                        }
                                        onRenderTitle={
                                          onRenderTitltePerformance as any
                                        }
                                        disabled={
                                          !avaliacao?.habilitaAvaliacaoGestor
                                        }
                                        onChange={(e, item) => {
                                          setFieldValueGestor(
                                            `idTabPerformanceGestor`,
                                            item?.key
                                          );
                                          setFieldErrorGestor(
                                            `idTabPerformanceGestor`,
                                            ""
                                          );
                                        }}
                                        styles={{
                                          title: {
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            borderColor: errorsGestor.idTabPerformanceGestor
                                              ? Colors.error
                                              : "#CCCCCC",
                                          },
                                          caretDownWrapper: {
                                            top: 16,
                                          },
                                        }}
                                      />
                                    </TdResultAtitudes>
                                  </tr>
                                  <tr>
                                    <Gap
                                      style={{ minWidth: 370, maxWidth: 370 }}
                                    />
                                    <td style={{ width: 160, minWidth: 160 }}>
                                      <Text variant="small">POTENCIAL</Text>
                                    </td>
                                    <TdResultAtitudes
                                      style={{
                                        width: 420,
                                        minWidth: 420,
                                        borderWidth: 0,
                                      }}
                                    >
                                      <DropdownUI
                                        options={optionsPotencial}
                                        defaultSelectedKey={
                                          avaliacao?.potencial ??
                                          "Selecione uma opção"
                                        }
                                        selectedKey={
                                          valuesGestor.potencial ??
                                          "Selecione uma opção"
                                        }
                                        onRenderTitle={
                                          onRenderTitlePotencial as any
                                        }
                                        disabled={
                                          !avaliacao?.habilitaAvaliacaoGestor
                                        }
                                        onChange={(e, item) => {
                                          if (
                                            item?.key === "Potencial lateral" ||
                                            item?.key ===
                                              "Cedo demais para avaliar"
                                          ) {
                                            setFieldValueGestor(
                                              `sucessao`,
                                              "Null"
                                            );
                                          } else if (
                                            valuesGestor.sucessao === "Null"
                                          ) {
                                            setFieldValueGestor(
                                              "sucessao",
                                              "Selecione uma opção"
                                            );
                                          }
                                          setFieldValueGestor(
                                            `potencial`,
                                            item?.key
                                          );
                                          setFieldErrorGestor(`potencial`, "");
                                        }}
                                        styles={{
                                          title: {
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            borderColor: errorsGestor.potencial
                                              ? Colors.error
                                              : "#CCCCCC",
                                          },
                                          caretDownWrapper: {
                                            top: 16,
                                          },
                                        }}
                                      />
                                    </TdResultAtitudes>
                                  </tr>
                                  <tr>
                                    <Gap
                                      style={{ minWidth: 370, maxWidth: 370 }}
                                    />
                                    <td style={{ width: 160, minWidth: 160 }}>
                                      <Text variant="small">SUCESSÃO</Text>
                                    </td>
                                    <TdResultAtitudes
                                      style={{
                                        width: 420,
                                        minWidth: 420,
                                        borderWidth: 0,
                                      }}
                                    >
                                      <DropdownUI
                                        placeholder="Selecione uma opção"
                                        options={optionsSucessao}
                                        defaultSelectedKey={
                                          avaliacao?.sucessao ??
                                          "Selecione uma opção"
                                        }
                                        selectedKey={
                                          valuesGestor.sucessao ??
                                          "Selecione uma opção"
                                        }
                                        onRenderTitle={
                                          onRenderTitlePotencial as any
                                        }
                                        disabled={
                                          !avaliacao?.habilitaAvaliacaoGestor ||
                                          valuesGestor.potencial ===
                                            "Potencial lateral" ||
                                          valuesGestor.potencial ===
                                            "Cedo demais para avaliar"
                                        }
                                        onChange={(e, item) => {
                                          setFieldValueGestor(
                                            `sucessao`,
                                            item?.key
                                          );
                                          setFieldErrorGestor(`sucessao`, "");
                                        }}
                                        styles={{
                                          title: {
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            borderColor: errorsGestor.sucessao
                                              ? Colors.error
                                              : "#CCCCCC",
                                          },
                                          caretDownWrapper: {
                                            top: 16,
                                          },
                                        }}
                                      />
                                    </TdResultAtitudes>
                                  </tr>
                                </Tbody>
                              </Table>
                              <WrapperForm>
                                <InputText
                                  label="Faça aqui os comentários para a calibração"
                                  multiline
                                  rows={3}
                                  disabled={!avaliacao?.habilitaAvaliacaoGestor}
                                  value={valuesGestor.comentariosGestor}
                                  onChange={(e) => {
                                    setFieldValueGestor(
                                      "comentariosGestor",
                                      e.target.value
                                    );
                                    setFieldErrorGestor(
                                      "comentariosGestor",
                                      ""
                                    );
                                  }}
                                  error={
                                    errorsGestor.comentariosGestor
                                      ? true
                                      : false
                                  }
                                  helperText={errorsGestor.comentariosGestor}
                                />
                              </WrapperForm>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </form>
                  )}

                  {avaliacao?.flagAutoAvaliacao && (
                    <>
                      <Divider />
                      <Stack horizontal>
                        <CommandBarButton
                          isLoading={props.loadingSave}
                          iconProps={{ iconName: "Save" }}
                          onClick={() => {
                            setErrorIniciativa(false);
                            setErrorForm(false);
                            setErrorFormGestor(false);
                            props.onSave();
                          }}
                          styles={commandButtonStyle}
                        >
                          Salvar
                        </CommandBarButton>
                        <CommandBarButton
                          isLoading={props.loadingAction}
                          iconProps={{ iconName: "Send" }}
                          onClick={() => handleSubmit()}
                          styles={commandButtonStyle}
                        >
                          Enviar
                        </CommandBarButton>
                      </Stack>
                    </>
                  )}
                  {avaliacao?.habilitaAvaliacaoGestor && (
                    <>
                      <Divider />
                      <CommandBarButton
                        iconProps={{ iconName: "Save" }}
                        onClick={() => {
                          if (avaliacao?.tipoAvaliacao !== "Avaliação gestor") {
                            handleSubmitGestor();
                          }
                        }}
                        isLoading={props.loadingSave}
                        styles={commandButtonStyle}
                      >
                        Salvar
                      </CommandBarButton>
                    </>
                  )}
                  {(errorForm || errorFormGestor) && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => {
                        setErrorForm(false);
                        setErrorFormGestor(false);
                      }}
                    >
                      Todos os campos são obrigatórios!{" "}
                      {errorIniciativa &&
                        "Necessário avaliar as iniciativas chave!"}
                    </MessageBar>
                  )}
                </WrapperResults>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

interface IValuesFormik {
  atitudes: Istate[];
  carreira: string;
  comentariosGerais: string;
  iniciativas?: null | boolean;
}

export interface AreaAvaliationMap {
  idCicloAvaliacaoArea?: number;
  comentariosIniciativas?: string;
  resultadometas?: string | null;
  iniciativas: {
    idCicloAvaliacaoAreaIniciativa?: number;
    resultado: string | null;
    nomeProjeto: string;
    descProjeto: string;
    prazoEntrega: Date;
    peso: string | number;
  }[];
  ciclocolaborador: {
    unidade: {
      nomeUnidade: string;
    };
    cc: {
      nomeCC: string;
    };
    cargo: {
      titulo: string;
    };
    area: {
      nomeLocal: string;
    };
  };
  descPeriodo: string;
  meses: number;
  somaMetas?: number;
  resultados: {
    idCicloAvaliacaoAreaResultado?: number;
    colaboradormeta: {
      cicloPeso: {
        objetivo: {
          descObjetivo: string;
          unidade: string;
          descricaoMecanica: string;
          casasDecimais: number | null;
        };
      };
      flagIniciativas: boolean | null;
    };
    meta: {
      minimo: string | number;
      target: string | number;
      maximo: string | number;
    };
    pilar: {
      corPilar: string;
      nomePilar: string;
    };
    real: number;
    resultado: string;
    ating: string | number | null;
    peso: number;
    cicloPesoPai: {
      objetivo: {
        descObjetivo: string | null;
      };
    };
  }[];
}

interface IRenderResultadosAvaliationProps {
  areas: AreaAvaliationMap[];
  flagAutoAvaliacao: boolean;
  habilitaAvaliacaoGestor: boolean;
  tipoAvaliacao: string;
  comentariosGerais: string;
  errorIniciativa?: boolean;
  setErrorIniciativa?: (errorIniciativa: boolean) => void;
  validation?: FormikProps<IValuesFormik>;
  hiddenResults?: boolean;
}

export const RenderResultados: React.FC<IRenderResultadosAvaliationProps> = (
  props
) => {
  const {
    areas,
    habilitaAvaliacaoGestor,
    tipoAvaliacao,
    flagAutoAvaliacao,
    comentariosGerais,
    errorIniciativa,
    setErrorIniciativa,
    validation,
    hiddenResults,
  } = props;

  const { errors, setFieldValue, setFieldError } = validation ?? {};

  const [isDialogIniciativaOpen, setIsDialogIniciativaOpen] = useState<
    IDialogIniciativa
  >({
    idCicloAvaliacaoArea: null,
    index: null,
    isOpen: false,
  });
  const dispatch = useDispatch();

  const {
    handleSubmit: handleSubmitIniciativa,
    setFieldValue: setFieldValueIniciativa,
    setFieldError: setFieldErrorIniciativa,
    values: valuesIniciativa,
    errors: errorsIniciativa,
    resetForm: resetFormIniciativa,
  } = useFormik({
    initialValues: initialValuesIniciativas,
    validationSchema: validationIniciativa,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      const iniciativasMetas = areas
        ?.find(
          (item) => item.idCicloAvaliacaoArea === values.idCicloAvaliacaoArea
        )
        ?.resultados?.find((item) => item.colaboradormeta.flagIniciativas);

      dispatch(
        CreatorsTasks.saveIniciativasAutoavaliacao(
          values.idCicloAvaliacaoArea!,
          values.avaliacao,
          values.comentarios,
          iniciativasMetas!.idCicloAvaliacaoAreaResultado!
        )
      );
      setErrorIniciativa?.(false);
      setIsDialogIniciativaOpen({
        isOpen: false,
        idCicloAvaliacaoArea: null,
        index: null,
      });
      setFieldValue?.("iniciativas", true);
    },
  });

  useEffect(() => {
    if (isDialogIniciativaOpen.isOpen) {
      const areaFind = areas?.find(
        (item) =>
          item.idCicloAvaliacaoArea ===
          isDialogIniciativaOpen.idCicloAvaliacaoArea!
      );

      const iniciativasMap = areaFind?.iniciativas?.map((item) => ({
        idCicloAvaliacaoAreaIniciativa: item?.idCicloAvaliacaoAreaIniciativa!,
        resultado: item?.resultado,
      }));

      resetFormIniciativa({
        values: {
          comentarios: areaFind?.comentariosIniciativas ?? "",
          avaliacao: iniciativasMap ?? [],
          idCicloAvaliacaoArea: isDialogIniciativaOpen.idCicloAvaliacaoArea!,
        },
      });
    }
  }, [isDialogIniciativaOpen]);

  const convertIniciativaResult = (
    idCicloAvaliacaoArea: number,
    result: any[]
  ) => {
    const total = result
      ?.map((item) => item)
      .reduce(
        (total, item) =>
          convertIniciativaResultVal(item.avaliacao.resultado) * item.peso +
          total,
        0
      );
    const hasChanged = result?.some(
      (item) => item.avaliacao.resultado === null
    );

    const iniciativasMetas = areas
      ?.find((item) => item.idCicloAvaliacaoArea === idCicloAvaliacaoArea)
      ?.resultados?.find((item) => item.colaboradormeta.flagIniciativas);

    if (hasChanged) {
      return "";
    } else if (total < (iniciativasMetas?.meta?.minimo ?? 0)) {
      return AbaixoExpectativa("NÃO ATINGIDO");
    } else if (total < (iniciativasMetas?.meta?.target ?? 0)) {
      return Parcialmente();
    } else if (total < (iniciativasMetas?.meta?.maximo ?? 0)) {
      return DentroExpectativa("ATINGIDO");
    } else {
      return AcimaExpectativa("SUPERADO");
    }
  };

  const renderMetas = (
    index: number,
    resultados: AreaAvaliationMap["resultados"],
    idCicloAvaliacaoArea?: number
  ) => {
    return resultados?.map((item, i) => {
      const {
        colaboradormeta,
        pilar,
        meta,
        cicloPesoPai,
        real,
        ating,
        resultado,
        peso,
      } = item;
      const { cicloPeso, flagIniciativas } = colaboradormeta;
      const { corPilar, nomePilar } = pilar;
      const { objetivo } = cicloPeso;
      const { objetivo: objPai } = cicloPesoPai ?? {};

      return (
        <Tr pillarColor={corPilar} key={i}>
          <TdNumber>{i + 1}</TdNumber>
          <Gap />
          {!flagIniciativas ? (
            <>
              <TdPillar>{nomePilar}</TdPillar>
              <TdGoal>
                <Stack>
                  {objPai && (
                    <Text
                      variant="tiny"
                      styles={{ root: { color: "#989898", marginBottom: 5 } }}
                    >
                      {objPai.descObjetivo ?? ""}
                    </Text>
                  )}
                  <Text
                    variant="small"
                    styles={{ root: { textTransform: "uppercase" } }}
                  >
                    {objetivo.descObjetivo}
                  </Text>
                  {objetivo.descricaoMecanica && (
                    <Text
                      variant="small"
                      styles={{
                        root: {
                          textTransform: "uppercase",
                          color: Colors.darkGray,
                          fontSize: 11,
                        },
                      }}
                    >
                      ({objetivo.descricaoMecanica})
                    </Text>
                  )}
                </Stack>
              </TdGoal>

              <Gap />
              {resultado !== "Não se aplica" ? (
                <>
                  <TdPeso>{Number(peso)}%</TdPeso>
                  <Gap />

                  {!flagIniciativas ? (
                    <>
                      <TdCorpGoal>
                        <ContainerCorpoGoal>
                          <LeftItemMeta>Min</LeftItemMeta>
                          <RightItemMeta>
                            {formattedMeta(
                              String(meta?.minimo),
                              objetivo.unidade,
                              objetivo.casasDecimais
                            )}
                          </RightItemMeta>
                        </ContainerCorpoGoal>
                        <ContainerCorpoGoal>
                          <LeftItemMeta>Target</LeftItemMeta>
                          <RightItemMeta>
                            {formattedMeta(
                              String(meta?.target),
                              objetivo.unidade,
                              objetivo.casasDecimais
                            )}
                          </RightItemMeta>
                        </ContainerCorpoGoal>
                        <ContainerCorpoGoal>
                          <LeftItemMeta>Max</LeftItemMeta>
                          <RightItemMeta>
                            {formattedMeta(
                              String(meta?.maximo),
                              objetivo.unidade,
                              objetivo.casasDecimais
                            )}
                          </RightItemMeta>
                        </ContainerCorpoGoal>
                      </TdCorpGoal>
                      <Gap />
                      <TdReal>
                        {Number(real) !== 0 && (
                          <Stack verticalAlign="space-between">
                            <Text
                              variant="medium"
                              styles={{ root: { fontWeight: "bold" } }}
                            >
                              {formattedMeta(
                                real.toString(),
                                objetivo.unidade,
                                objetivo.casasDecimais
                              )}
                            </Text>
                            {!hiddenResults && (
                              <Text variant="medium">
                                {(Number(ating) * 100).toFixed()}%
                              </Text>
                            )}
                          </Stack>
                        )}
                      </TdReal>
                    </>
                  ) : (
                    <>
                      <BlankTd />
                      <Gap />
                      <BlankTd />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Gap colSpan={5} style={{ width: 280, minWidth: 280 }} />
                </>
              )}

              <Gap />
              <TdResult>
                <Stack horizontal verticalAlign="center">
                  {convertResult(resultado)}
                </Stack>
              </TdResult>
            </>
          ) : (
            <>
              <TdPillar
                colSpan={2}
                style={{ borderRadius: 2, width: 460, minWidth: 460 }}
              >
                {objetivo.descObjetivo}
              </TdPillar>
              <Gap />
              <TdPeso>{Number(peso)}%</TdPeso>
              <Gap />
              <TdButton
                onClick={() =>
                  setIsDialogIniciativaOpen({
                    isOpen: true,
                    index,
                    idCicloAvaliacaoArea,
                  })
                }
                style={{
                  backgroundColor:
                    errorIniciativa && !resultado
                      ? Colors.error
                      : Colors.primary,
                }}
              >
                {flagAutoAvaliacao
                  ? "AVALIAR INICIATIVAS"
                  : "VISUALIZAR INICIATIVAS"}
              </TdButton>
              <Gap />
              <TdReal>
                {resultado !== null && resultado !== undefined && (
                  <Stack verticalAlign="space-between">
                    <Text
                      variant="medium"
                      styles={{ root: { fontWeight: "bold" } }}
                    >
                      {Number(real)}%
                    </Text>
                    <Text variant="medium">{Number(ating) * 100}%</Text>
                  </Stack>
                )}
              </TdReal>
              <Gap />
              <TdResult>
                <Stack horizontal verticalAlign="center">
                  {convertResult(resultado)}
                </Stack>
              </TdResult>
            </>
          )}
        </Tr>
      );
    });
  };

  let areasFiltered = areas;
  if (tipoAvaliacao === "Avaliação gestor") {
    areasFiltered =
      areasFiltered?.filter((item, i, arr) => arr.length - 1 === i) ?? [];
  }
  return (
    <>
      {areasFiltered?.map((area, i, arr) => {
        const {
          resultados,
          ciclocolaborador,
          idCicloAvaliacaoArea,
          descPeriodo,
          meses,
          resultadometas,
        } = area;
        const { unidade,  cargo, area: areaColab } = ciclocolaborador;
        const isColetiva = tipoAvaliacao === "Avaliação gestor";

        return (
          <Accordion defaultExpanded={true} key={i}>
            <AccordionSummary
              expandIcon={
                <FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Text variant="mediumPlus" styles={textStyles}>
                {isColetiva
                  ? "Avaliação de Performance"
                  : `Resultados ${i + 1}`}
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                <Stack styles={{ root: { width: "100%" } }}>
                  <WrapperLabelGroup>
                    <WrapperLabelGroup>
                      <LabelGroup
                        name="lg"
                        icon="EMI"
                        label="Unidade"
                        value={unidade.nomeUnidade}
                        tooltipMessage={unidade.nomeUnidade}
                      />
                      <LabelGroup
                        name="lg"
                        icon="Org"
                        label="Área"
                        value={areaColab.nomeLocal}
                        tooltipMessage={areaColab.nomeLocal}
                      />
                      <LabelGroup
                        name="lg"
                        icon="Work"
                        label="Cargo"
                        value={cargo.titulo}
                        tooltipMessage={cargo.titulo}
                      />
                      <LabelGroup
                        name="lg"
                        icon="Calendar"
                        label="Período"
                        value={descPeriodo}
                      />
                      <LabelGroup
                        name="lg"
                        styles={{ minWidth: "auto" }}
                        icon="Calendar"
                        label="Tempo na Função"
                        value={`${
                          meses !== 1 ? `${meses} meses` : `${meses} mês`
                        }`}
                      />
                    </WrapperLabelGroup>
                  </WrapperLabelGroup>
                </Stack>

                {isColetiva ? (
                  <Stack styles={{ root: { width: "100%" } }}>
                    <Table>
                      <Tbody>
                        <tr>
                          <Gap style={{ minWidth: 370, maxWidth: 370 }} />
                          <td style={{ width: 160, minWidth: 160 }}>
                            <Text variant="small">AVALIAÇÃO</Text>
                          </td>
                          <TdResultAtitudes
                            style={{
                              width: 420,
                              minWidth: 420,
                              borderWidth: 0,
                            }}
                          >
                            <DropdownUI
                              placeholder="Selecione uma opção"
                              options={optionsIniciativas}
                              defaultSelectedKey={
                                resultadometas ?? "Selecione uma opção"
                              }
                              onRenderTitle={onRenderTitle as any}
                              disabled={!habilitaAvaliacaoGestor}
                              onChange={(e, item: IDropdownOption | any) => {
                                setFieldValueIniciativa(
                                  `avaliacao.${i}.resultado`,
                                  item?.key
                                );
                                setFieldErrorIniciativa(
                                  `avaliacao.${i}.resultado`,
                                  ""
                                );
                              }}
                              styles={{
                                title: {
                                  height: 60,
                                  display: "flex",
                                  alignItems: "center",
                                  borderColor: "#CCCCCC",
                                },
                                caretDownWrapper: { top: 16 },
                              }}
                            />
                          </TdResultAtitudes>
                        </tr>
                      </Tbody>
                    </Table>
                    <InputText
                      label="Comentários gerais"
                      multiline
                      rows={3}
                      value={comentariosGerais ?? ""}
                      onChange={(e) => {
                        setFieldValue?.("comentariosGerais", e.target.value);
                        setFieldError?.("comentariosGerais", "");
                      }}
                      onBlur={(e) => {
                        if (!isColetiva) {
                          dispatch(
                            CreatorsTasks.saveComentariosGerais(e.target.value)
                          );
                        } else {
                          dispatch(
                            CreatorsAvaliation.saveComentariosGerais(
                              e.target.value
                            )
                          );
                        }
                      }}
                      error={errors?.carreira ? true : false}
                      helperText={errors?.carreira}
                      disabled={!habilitaAvaliacaoGestor}
                    />
                  </Stack>
                ) : (
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan={5}></th>
                        <Th>PESO</Th>
                        <Gap />
                        <ThMeta>META</ThMeta>
                        <Gap />
                        <ThReal>
                          {!hiddenResults ? "REAL / %" : "PARCIAL"}
                        </ThReal>
                        <Gap />
                        <ThResultado>RESULTADO</ThResultado>
                        <Gap />
                      </tr>
                    </thead>
                    <Tbody>
                      {renderMetas(i, resultados, idCicloAvaliacaoArea)}
                      {!hiddenResults && (
                        <tr>
                          <th colSpan={9}></th>
                          <TdReal isTotal={true}>
                            <Stack verticalAlign="space-between">
                              <Text
                                variant="smallPlus"
                                styles={{ root: { fontWeight: "bold" } }}
                              ></Text>
                              {Number(area?.somaMetas) !== 0 && (
                                <Text variant="smallPlus">
                                  {Number(area?.somaMetas).toFixed(1)}%
                                </Text>
                              )}
                            </Stack>
                          </TdReal>
                          <Gap />
                          <TdResult isTotal={true}>
                            {area?.resultadometas !== null && (
                              <Stack horizontal verticalAlign="center">
                                {convertResultMetas(area.resultadometas ?? "")}
                              </Stack>
                            )}
                          </TdResult>
                        </tr>
                      )}
                    </Tbody>
                  </Table>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}

      {isDialogIniciativaOpen.isOpen === true && (
        <CustomDialog
          hidden={!isDialogIniciativaOpen.isOpen}
          minWidth={850}
          dialogContentProps={dialogContentProps}
          zIndex={1000000}
          styles={{ main: { minHeight: 165, maxHeight: 800 } }}
          onRender={
            <WrapperIniciativa>
              <form onSubmit={handleSubmitIniciativa}>
                <TableIniciativa>
                  <thead>
                    <tr>
                      <th colSpan={2}></th>
                      <Th style={{ minWidth: 80 }}>VALIDADE</Th>
                      <Gap />
                      <Th style={{ minWidth: 40 }}>PESO</Th>
                      {!hiddenResults && (
                        <>
                          <Gap />
                          <ThAvaliacao>AVALIAÇÃO</ThAvaliacao>
                          <Gap />
                        </>
                      )}
                    </tr>
                  </thead>
                  <Tbody>
                    {areas?.[isDialogIniciativaOpen.index!]?.iniciativas?.map(
                      (item, i) => {
                        const errorAvaliacao = errorsIniciativa?.avaliacao
                          ? (errorsIniciativa?.avaliacao[i] as any)?.resultado
                          : "";
                        const {
                          nomeProjeto,
                          descProjeto,
                          prazoEntrega,
                          peso,
                        } = item;
                        return (
                          <tr key={i}>
                            <TdIniciativa>
                              <TooltipHost
                                content={descProjeto}
                                styles={{
                                  root: {
                                    height: 60,
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <Text variant="smallPlus">{nomeProjeto}</Text>
                              </TooltipHost>
                            </TdIniciativa>
                            <Gap />
                            <TdValidacao>
                              {moment(prazoEntrega).format("DD/MM/yyyy")}
                            </TdValidacao>
                            <Gap />
                            <TdPeso
                              style={{
                                borderColor: "#CCCCCC",
                                minWidth: 40,
                              }}
                            >
                              {Number(peso)}%
                            </TdPeso>
                            {!hiddenResults && (
                              <>
                                <Gap />
                                <TdAvaliacao>
                                  <DropdownUI
                                    placeholder="Selecione uma opção"
                                    options={optionsIniciativas}
                                    defaultSelectedKey={
                                      valuesIniciativa.avaliacao[i]
                                        ?.resultado ?? "Selecione uma opção"
                                    }
                                    onRenderTitle={onRenderTitle as any}
                                    disabled={!flagAutoAvaliacao}
                                    onChange={(
                                      e,
                                      item: IDropdownOption | any
                                    ) => {
                                      setFieldValueIniciativa(
                                        `avaliacao.${i}.resultado`,
                                        item?.key
                                      );
                                      setFieldErrorIniciativa(
                                        `avaliacao.${i}.resultado`,
                                        ""
                                      );
                                    }}
                                    styles={{
                                      title: {
                                        height: 60,
                                        display: "flex",
                                        alignItems: "center",
                                        borderColor: errorAvaliacao
                                          ? Colors.error
                                          : "#CCCCCC",
                                      },
                                      caretDownWrapper: { top: 16 },
                                    }}
                                  />
                                </TdAvaliacao>
                              </>
                            )}
                          </tr>
                        );
                      }
                    )}
                    {!hiddenResults && (
                      <tr>
                        <td colSpan={6} />
                        <TdResult style={{ backgroundColor: "#E1DEDE" }}>
                          <Stack horizontal horizontalAlign="center">
                            {convertIniciativaResult(
                              isDialogIniciativaOpen.idCicloAvaliacaoArea!,
                              valuesIniciativa.avaliacao.map((item, i) => ({
                                avaliacao: item,
                                peso: Number(
                                  areas?.[isDialogIniciativaOpen.index!]
                                    .iniciativas[i]?.peso
                                ),
                              }))
                            )}
                          </Stack>
                        </TdResult>
                      </tr>
                    )}
                  </Tbody>
                </TableIniciativa>
                {!hiddenResults && (
                  <>
                    <ContainerTitle>
                      <Text variant="mediumPlus" styles={textStyles}>
                        Comentários
                      </Text>
                    </ContainerTitle>
                    <WrapperForm>
                      <InputText
                        label="Insira comentários sobre as iniciativas"
                        multiline
                        rows={3}
                        value={valuesIniciativa.comentarios}
                        onChange={(e) => {
                          setFieldValueIniciativa(
                            "comentarios",
                            e.target.value
                          );
                          setFieldErrorIniciativa("comentarios", "");
                        }}
                        error={errorsIniciativa.comentarios ? true : false}
                        helperText={errorsIniciativa.comentarios}
                        disabled={!flagAutoAvaliacao}
                      />
                    </WrapperForm>
                  </>
                )}
              </form>
            </WrapperIniciativa>
          }
        >
          <DefaultButton
            onClick={() => {
              setIsDialogIniciativaOpen({
                isOpen: false,
                idCicloAvaliacaoArea: null,
                index: null,
              });
              resetFormIniciativa();
            }}
          >
            {!hiddenResults ? "Cancelar" : "Fechar"}
          </DefaultButton>
          {!hiddenResults && (
            <PrimaryButton
              onClick={() => handleSubmitIniciativa()}
              disabled={!flagAutoAvaliacao}
            >
              Confirmar
            </PrimaryButton>
          )}
        </CustomDialog>
      )}
    </>
  );
};

const AcimaExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.blue,
        transform: "rotate(90deg)",
      }}
    />
    {text ?? "ACIMA DA EXPECTATIVA"}
  </>
);

const AbaixoExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleArrow"
      style={{
        fontSize: 16,
        marginRight: 5,
        color: Colors.error,
        transform: "rotate(-90deg)",
      }}
    />
    {text ?? "ABAIXO DA EXPECTATIVA"}
  </>
);

const Parcialmente = (text?: string) => (
  <>
    <FontIcon
      iconName="CircleHalfFull"
      style={{ fontSize: 16, marginRight: 5, color: "#FB6B20" }}
    />
    {text ?? "PARCIALMENTE ATINGIDO"}
  </>
);

const DentroExpectativa = (text?: string) => (
  <>
    <FontIcon
      iconName="SkypeCircleCheck"
      style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
    />
    {text ?? "DENTRO DA EXPECTATIVA"}
  </>
);

const convertAvaliacao = (result: any[]) => {
  const total = result
    ?.map((item) => item.resultado)
    .reduce((total, item) => convertAtitudesVal(item) + total, 0);
  const length = result?.length;
  const hasChanged = result?.some((item) => item.resultado === null);
  const valorAcima = Math.round(length * 1.3333);

  if (hasChanged) {
    return "";
  } else if (total >= valorAcima) return "Acima da Expectativa";
  else if (total >= length) return "Dentro da Expectativa";
  else return "Abaixo da Expectativa";
};

const renderAvaliacao = (result: string) => {
  switch (result) {
    case "Abaixo da Expectativa":
      return AbaixoExpectativa();
    case "Dentro da Expectativa":
      return DentroExpectativa();
    case "Acima da Expectativa":
      return AcimaExpectativa();
    default:
      return result;
  }
};

const convertResultMetas = (result: string) => {
  switch (result) {
    case "Abaixo da Expectativa":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleArrow"
            style={{
              fontSize: 16,
              marginRight: 5,
              color: Colors.error,
              transform: "rotate(-90deg)",
            }}
          />
          ABAIXO DA EXPECTATIVA
        </>
      );
    case "Acima da Expectativa":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleArrow"
            style={{
              fontSize: 16,
              marginRight: 5,
              color: Colors.blue,
              transform: "rotate(90deg)",
            }}
          />
          ACIMA DA EXPECTACTIVA
        </>
      );
    case "Dentro da Expectativa":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleCheck"
            style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
          />
          DENTRO DA EXPECTATIVA
        </>
      );
    default:
      return result;
  }
};

const convertResult = (result: string) => {
  switch (result) {
    case "Não atingido":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleArrow"
            style={{
              fontSize: 16,
              marginRight: 5,
              color: Colors.error,
              transform: "rotate(-90deg)",
            }}
          />
          NÃO ATINGIDO
        </>
      );
    case "Superado":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleArrow"
            style={{
              fontSize: 16,
              marginRight: 5,
              color: Colors.blue,
              transform: "rotate(90deg)",
            }}
          />
          SUPERADO
        </>
      );
    case "Atingido":
      return (
        <>
          <FontIcon
            iconName="SkypeCircleCheck"
            style={{ fontSize: 16, marginRight: 5, color: Colors.primary }}
          />
          ATINGIDO
        </>
      );
    case "Não se aplica":
      return (
        <>
          <FontIcon
            iconName="StatusErrorFull"
            style={{ fontSize: 16, marginRight: 5, color: "#484644" }}
          />
          NÃO SE APLICA
        </>
      );
    case "Parcialmente atingido":
      return (
        <>
          <FontIcon
            iconName="CircleHalfFull"
            style={{ fontSize: 16, marginRight: 5, color: "#FB6B20" }}
          />
          PARCIALMENTE ATINGIDO
        </>
      );
    default:
      return result;
  }
};

const Accordion = withStyles({
  root: {
    padding: "0px !important",
    paddingBottom: 10,
    boxShadow: "none",
    display: "contents",

    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: "50px !important",
    "&:hover": {
      backgroundColor: "#F3F2F1",
    },
    expanded: {},
  },
})(MuiAccordionSummary);

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500,
    display: "block",
  },
};

const dialogContentProps = {
  title: "Avaliação das Iniciativas",
};

const options: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Acima da Expectativa",
    text: "ACIMA DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Dentro da Expectativa",
    text: "DENTRO DA EXPECTATIVA",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Abaixo da Expectativa",
    text: "ABAIXO DA EXPECTATIVA",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

const convertAtitudesVal = (result: string) => {
  switch (result) {
    case "Selecione uma opção":
      return 0;
    case "Acima da Expectativa":
      return 2;
    case "Dentro da Expectativa":
      return 1;
    case "Abaixo da Expectativa":
      return 0;
    default:
      return 0;
  }
};

const convertIniciativaResultVal = (result: string) => {
  switch (result) {
    case "Selecione uma opção":
      return 0;
    case "Superado":
      return 1.5;
    case "Atingido":
      return 1;
    case "Parcialmente atingido":
      return 0.5;
    case "Não atingido":
      return 0;
    case "Não se aplica":
      return 0;
    default:
      return 0;
  }
};

const optionsIniciativas: (IDropdownOption | any)[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Superado",
    text: "SUPERADO",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.blue, transform: "rotate(90deg)" },
    },
  },
  {
    key: "Atingido",
    text: "ATINGIDO",
    data: { icon: "SkypeCircleCheck", style: { color: Colors.primary } },
  },
  {
    key: "Parcialmente atingido",
    text: "PARCIALMENTE ATINGIDO",
    data: { icon: "CircleHalfFull", style: { color: "#FB6B20" } },
  },
  {
    key: "Não atingido",
    text: "NÃO ATINGIDO",
    data: {
      icon: "SkypeCircleArrow",
      style: { color: Colors.error, transform: "rotate(-90deg)" },
    },
  },
];

const optionsPotencial: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Alto potencial para crescer",
    text: "ALTO POTENCIAL PARA CRESCER",
    data: { style: { color: "#027636" } },
  },
  {
    key: "Potencial para crescer",
    text: "POTENCIAL PARA CRESCER",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Potencial lateral",
    text: "POTENCIAL LATERAL",
    data: { style: { color: "#D4A31D" } },
  },
  {
    key: "Cedo demais para avaliar",
    text: "CEDO DEMAIS PARA AVALIAR",
    data: { style: { color: "#73AF8E" } },
  },
];

const optionsSucessao: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Null",
    text: "",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  { key: "Imediato", text: "IMEDIATO", data: { style: { color: "#027636" } } },
  {
    key: "Curto Prazo - 1 a 2 anos",
    text: "CURTO PRAZO - 1 A 2 ANOS",
    data: { style: { color: "#73AF8E" } },
  },
  {
    key: "Médio Prazo - 3 a 5 anos",
    text: "MÉDIO PRAZO - 3 A 5 ANOS",
    data: { style: { color: "#88C8A5" } },
  },
  {
    key: "Longo Prazo - acima 5 anos",
    text: "LONGO PRAZO - ACIMA 5 ANOS",
    data: { style: { color: "#D4A31D" } },
  },
];

const validation = yup.object({
  idCicloAvaliacaoAtitude: yup.number().notRequired(),
  atitudes: yup.array().of(
    yup.object().shape({
      resultado: yup.string().required("Campo obrigatório"),
      comentarios: yup.string().required("Campo obrigatório"),
    })
  ),
  carreira: yup.string().required("Campo obrigatório"),
  comentariosGerais: yup.string().required("Campo obrigatório"),
  iniciativas: yup.boolean().nullable().notRequired(),
});

const validationIniciativa = yup.object({
  idCicloAvaliacao: yup.number().notRequired(),
  avaliacao: yup.array().of(
    yup.object().shape({
      resultado: yup.string().required("Campo obrigatório"),
    })
  ),
  comentarios: yup.string().required("Campo obrigatório"),
});

const validationGestor = yup.object({
  idTabPerformanceGestor: yup.number().nullable().required("Campo obrigatório"),
  potencial: yup.string().required("Campo obrigatório"),
  sucessao: yup.string().nullable().notRequired(),
  comentariosGestor: yup.string().required("Campo obrigatório"),
});

const validationGestorColetiva = yup.object({
  idCicloAvaliacaoAtitude: yup.number().notRequired(),
  atitudes: yup.array().of(
    yup.object().shape({
      resultado: yup.string().required("Campo obrigatório"),
      comentarios: yup.string().required("Campo obrigatório"),
    })
  ),
  carreira: yup.string().required("Campo obrigatório"),
  comentariosGerais: yup.string().required("Campo obrigatório"),
  idTabPerformanceGestor: yup.number().nullable().required("Campo obrigatório"),
  potencial: yup.string().required("Campo obrigatório"),
  sucessao: yup.string().nullable().notRequired(),
  comentariosGestor: yup.string().required("Campo obrigatório"),
  resultadometas: yup.string().required("Campo obrigatório"),
  comentarioPositivo: yup.string().required("Campo obrigatório"),
  comentariosMelhorar: yup.string().nullable().notRequired(),
});

interface Istate {
  idCicloAvaliacaoAtitude: number;
  resultado: string | null;
  comentarios: string | null;
}

interface IForm {
  atitudes: Istate[];
  carreira: string;
  comentariosGerais: string;
  iniciativas?: null | boolean;
}

const initialValues: IForm = {
  carreira: "",
  atitudes: [],
  comentariosGerais: "",
  iniciativas: null,
};

interface IFormIniciativa {
  comentarios: string;
  avaliacao: {
    idCicloAvaliacaoAreaIniciativa?: number;
    resultado: string | null;
  }[];
  idCicloAvaliacaoArea: number | null;
}

interface IFormGestor {
  comentariosGestor: string;
  idTabPerformanceGestor: number | null;
  potencial: string;
  sucessao: string | null;
}

interface IFormGestorColetiva {
  atitudes: Istate[];
  carreira: string;
  comentariosGerais: string;
  idTabPerformanceGestor: number | null;
  potencial: string;
  sucessao: string | null;
  comentariosGestor: string;
  resultadometas: string;
  comentariosPositivo: string;
  comentariosMelhorar: string;
}

const initialValuesIniciativas: IFormIniciativa = {
  comentarios: "",
  avaliacao: [],
  idCicloAvaliacaoArea: null,
};

const initialValuesGestor: IFormGestor = {
  comentariosGestor: "",
  idTabPerformanceGestor: null,
  potencial: "",
  sucessao: "",
};

const intialValuesGestorColetiva: IFormGestorColetiva = {
  atitudes: [],
  carreira: "",
  comentariosPositivo: "",
  comentariosGerais: "",
  comentariosGestor: "",
  comentariosMelhorar: "",
  idTabPerformanceGestor: null,
  potencial: "",
  resultadometas: "",
  sucessao: "",
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
  const option = options[0];

  return (
    <Stack horizontal verticalAlign="center">
      {option.data && option.data.icon && (
        <Icon
          iconName={option.data.icon}
          aria-hidden="true"
          title={option.data.icon}
          styles={{ root: { ...option.data.style, marginRight: 10 } }}
        />
      )}
      <Stack>
        <span
          style={{
            fontSize: 12,
            whiteSpace: "break-spaces",
            lineHeight: 1.5,
            textAlign: "left",
            ...option.data.styleOption,
          }}
        >
          {option.text}
        </span>
      </Stack>
    </Stack>
  );
};
export default SelfEvaluationRender;
