// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';

import Dialog from '~/components/CustomDialog';
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputAutocomplete, InputText } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import Accordion from '~/components/Accordion';
import NoItems from '~/components/layout/NoItems';


//Estilos
import {
    Wrapper,
    ListContainer,
    DefaultButton,
    ContainerContent,
} from "./styles";

import { DataTypes as DataTypesSteps, StepType } from "~/store/ducks/admin/steps/types";
import { Creators as getSteps } from "~/store/ducks/admin/steps";
import { Creators as addStep } from "~/store/ducks/admin/steps";
import { Creators as delStep } from "~/store/ducks/admin/steps";

import { DataTypes as DataTypesTipoMov, TipoMovType } from "~/store/ducks/admin/tipomov/types";
import { Creators as getTiposMov } from "~/store/ducks/admin/tipomov";

import { DataTypes as DataTypesAdmin } from "~/store/ducks/admin/admin/types";
import { Creators as loadConfigMotivos } from "~/store/ducks/admin/admin";
import { Creators as editConfigMotivos } from "~/store/ducks/admin/admin";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import { ConfigTipoMovType } from "~/store/ducks/admin/admin/types";

//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

interface ISalaryStruturesState {
    columns: IColumn[];
    items: StepType[];
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    isDialogAddOpen: boolean;
    tipoAdmissaoSelected: TipoMovType | null;
    tipoMeritoSelected: TipoMovType | null;
    tipoPromocaoSelected: TipoMovType | null;
    tipoEnquadramentoSelected: TipoMovType | null;
    tipoConfig: ConfigTipoMovType | null;
};

interface IPropsSalaryStrutures {
    steps: DataTypesSteps;
    tiposMov: DataTypesTipoMov;
    admin: DataTypesAdmin;
    setCurrentPage: (page: Page) => void;
    getSteps: () => void;
    getTiposMov: (SearchtText?: string) => void;
    addStep: (step: StepType) => void;
    delStep: (idStep: number) => void;
    loadConfigMotivos: () => void;
    editConfigMotivos: (tipoMovAdmissao: ConfigTipoMovType) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Configuração Estruturas Salariais", isCurrentItem: true },
];

class ConfigSalaryStructures extends Component<IPropsSalaryStrutures, ISalaryStruturesState> {
    private timeout: number;
    private formRefAddStep: any;
    private _selection: Selection;

    constructor(props: IPropsSalaryStrutures) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column2',
                name: 'Faixa',
                fieldName: 'percent',
                minWidth: 40,
                maxWidth: 40,
                isRowHeader: true,
                data: 'number',
                isPadded: true,
                styles: { cellTitle: { justifyContent: 'flex-end', marginRight: 24 } },
                onRender: (value: StepType) => (
                    <Stack horizontalAlign="end" styles={{ root: { width: '100%' } }}>
                        {`${Number(value.percent)}%`}
                    </Stack>
                )
            }
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            selectionDetails: "",
            selectionCount: 0,
            isDialogExcludeOpen: false,
            isDialogAddOpen: false,
            tipoAdmissaoSelected: null,
            tipoEnquadramentoSelected: null,
            tipoPromocaoSelected: null,
            tipoMeritoSelected: null,
            tipoConfig: null
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.timeout = 0;
        this.formRefAddStep = React.createRef();
    };

    componentDidMount() {
        const page: Page = {
            key: 'configEstruturaSalarial',
            pages: itemsBreadCrumb
        };
        this.props.setCurrentPage(page);
        this.props.getSteps();
        this.props.loadConfigMotivos()
    };

    componentDidUpdate(prevProps: IPropsSalaryStrutures) {
        const { tipoMovData } = this.props.admin;

        if (prevProps.admin.tipoMovData !== tipoMovData) {
            if (tipoMovData) {
                this.setState({
                    tipoAdmissaoSelected: tipoMovData.tipoMovAdm && {
                        idTipoMov: tipoMovData.idTipoMovAdmissao,
                        descTipoMov: tipoMovData.tipoMovAdm?.descTipoMov
                    },
                    tipoEnquadramentoSelected: tipoMovData.tipoMovEnq && {
                        idTipoMov: tipoMovData.idTipoMovEnquadramento,
                        descTipoMov: tipoMovData.tipoMovEnq?.descTipoMov
                    },
                    tipoMeritoSelected: tipoMovData.tipoMovMer && {
                        idTipoMov: tipoMovData.idTipoMovMerito,
                        descTipoMov: tipoMovData.tipoMovMer?.descTipoMov
                    },
                    tipoPromocaoSelected: tipoMovData.tipoMovPro && {
                        idTipoMov: tipoMovData.idTipoMovPromocao,
                        descTipoMov: tipoMovData.tipoMovPro?.descTipoMov
                    },
                    tipoConfig: this.props.admin.tipoMovData
                })
            }
        }
    }

    // Submit do formulário Formik
    handleSubmitAddStep = () => {
        if (this.formRefAddStep.current) {
            this.formRefAddStep.current.handleSubmit();
        };
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    handleDel = () => {
        this.props.delStep((this._selection.getSelection()[0] as StepType).idStep!);
        this.setState({ isDialogExcludeOpen: false });
        this._selection.setAllSelected(false);
    };

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
            );
        }
    };


    search = (text: string) => {
        if (text.trim()) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.props.getTiposMov(text);
            }, 700);
        }
    };

    handleConfigMotivo = () => {
        const { tipoConfig } = this.state;

        if (tipoConfig) {
            console.log(tipoConfig)
            this.props.editConfigMotivos(tipoConfig);
        }
    }


    render() {
        const {
            isDialogExcludeOpen,
            selectionCount,
            columns,
            isDialogAddOpen,
            tipoAdmissaoSelected,
            tipoMeritoSelected,
            tipoEnquadramentoSelected,
            tipoPromocaoSelected,
            tipoConfig
        } = this.state;
        const { steps, addStep, tiposMov } = this.props;

        const dialogContentProps: IDialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                <Wrapper>
                    <ContainerContent>
                        <HeaderPage title="Configurações Cargos & Salários" />
                        <Accordion
                            title="Motivos de Movimentações"
                            subtitle="Os motivos abaixo serão utilizadas nas movimentações realizadas automaticamente no Portal."
                        >

                            <Stack>
                                <Stack horizontal>
                                    <Stack styles={{ root: { width: 360, marginRight: 60 } }}>
                                        <InputAutocomplete
                                            value={(tipoAdmissaoSelected as TipoMovType)}
                                            onChange={(_, newValue) => {
                                                this.setState({
                                                    tipoAdmissaoSelected: newValue,
                                                    tipoConfig: {
                                                        ...tipoConfig!,
                                                        idTipoMovAdmissao: newValue?.idTipoMov ?? null,
                                                        tipoMovAdm: newValue && {
                                                            descTipoMov: newValue?.descTipoMov
                                                        }
                                                    }
                                                }, () => this.handleConfigMotivo())

                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                this.search(newInputValue);
                                            }}
                                            getOptionLabel={(tipoMov: TipoMovType) => tipoMov.descTipoMov}
                                            getOptionSelected={(option, value) => option.idTipoMov === value.idTipoMov}
                                            options={tiposMov.data}
                                            input={{
                                                idInput: "",
                                                labelInput: "Admissão",
                                            }}
                                        />
                                    </Stack>
                                    <Stack styles={{ root: { width: 360 } }}>
                                        <InputAutocomplete
                                            value={(tipoMeritoSelected as TipoMovType)}
                                            onChange={(_, newValue) => {
                                                this.setState({
                                                    tipoMeritoSelected: newValue,
                                                    tipoConfig: {
                                                        ...tipoConfig!,
                                                        idTipoMovMerito: newValue?.idTipoMov ?? null,
                                                        tipoMovMer: newValue && {
                                                            descTipoMov: newValue?.descTipoMov
                                                        }
                                                    }
                                                }, () => this.handleConfigMotivo())
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                this.search(newInputValue);
                                            }}
                                            getOptionLabel={(tipoMov: TipoMovType) => tipoMov.descTipoMov}
                                            getOptionSelected={(option, value) => option.idTipoMov === value.idTipoMov}
                                            options={tiposMov.data}
                                            input={{
                                                idInput: "",
                                                labelInput: "Mérito",
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack horizontal>
                                    <Stack styles={{ root: { width: 360, marginRight: 60 } }}>
                                        <InputAutocomplete
                                            value={(tipoEnquadramentoSelected as TipoMovType)}
                                            onChange={(_, newValue) => {
                                                this.setState({
                                                    tipoEnquadramentoSelected: newValue,
                                                    tipoConfig: {
                                                        ...tipoConfig!,
                                                        idTipoMovEnquadramento: newValue?.idTipoMov ?? null,
                                                        tipoMovEnq: newValue && {
                                                            descTipoMov: newValue?.descTipoMov
                                                        }
                                                    }
                                                }, () => this.handleConfigMotivo())
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                this.search(newInputValue);
                                            }}
                                            getOptionLabel={(tipoMov: TipoMovType) => tipoMov.descTipoMov}
                                            getOptionSelected={(option, value) => option.idTipoMov === value.idTipoMov}
                                            options={tiposMov.data}
                                            input={{
                                                idInput: "",
                                                labelInput: "Enquadramento",
                                            }}
                                        />
                                    </Stack>
                                    <Stack styles={{ root: { width: 360 } }}>
                                        <InputAutocomplete
                                            value={(tipoPromocaoSelected as TipoMovType)}
                                            onChange={(_, newValue) => {
                                                this.setState({
                                                    tipoPromocaoSelected: newValue,
                                                    tipoConfig: {
                                                        ...tipoConfig!,
                                                        idTipoMovPromocao: newValue?.idTipoMov ?? null,
                                                        tipoMovPro: newValue && {
                                                            descTipoMov: newValue?.descTipoMov
                                                        }
                                                    }
                                                }, () => this.handleConfigMotivo())
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                this.search(newInputValue);
                                            }}
                                            getOptionLabel={(tipoMov: TipoMovType) => tipoMov.descTipoMov}
                                            getOptionSelected={(option, value) => option.idTipoMov === value.idTipoMov}
                                            options={tiposMov.data}
                                            input={{
                                                idInput: "",
                                                labelInput: "Promoção",
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Accordion>

                        <Accordion
                            title="Faixas da Estrutura Salarial"
                            subtitle="Os steps serão utilizados para facilitar a visualização das estruturas salariais."
                        >
                            <Stack horizontal>
                                <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Add' }} text="Adicionar Faixa" onClick={() => this.setState({ isDialogAddOpen: true })} />
                                {this.commandBarRender()}
                            </Stack>

                            {!steps.loadingData && steps.data.length === 0 ?
                                <NoItems
                                    error={steps.error}
                                    text="Não há faixas cadastradas"
                                    icon="Step"
                                />
                                :
                                <ListContainer>
                                    <ShimmeredDetailsList
                                        items={steps.data}
                                        enableShimmer={steps.loadingData}
                                        columns={columns}
                                        selectionMode={SelectionMode.single}
                                        selection={this._selection}
                                        getKey={this._getKey}
                                        selectionPreservedOnEmptyClick={true}
                                        setKey="single"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                    />
                                </ListContainer>
                            }
                        </Accordion>

                    </ContainerContent>

                    <Dialog
                        title="Adicionar Faixa"
                        open={isDialogAddOpen}
                        onClickCancel={() => this.setState({ isDialogAddOpen: false })}
                        onClickConfirm={this.handleSubmitAddStep}
                        btnConfirmText="Adicionar"
                    >
                        <Formik
                            innerRef={this.formRefAddStep}
                            initialValues={{ idStep: null, percent: "" }}
                            validationSchema={validationStepSchema}
                            validateOnChange={false}
                            validateOnBlur={true}
                            onSubmit={(values) => {
                                addStep(values)
                                this.setState({ isDialogAddOpen: false })
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
                                <form onSubmit={handleSubmit}>
                                    <InputText
                                        value={values.percent ?? ""}
                                        onChange={(e) => { handleChange(e); setFieldError("percent", "") }}
                                        id="percent"
                                        error={errors.percent ? true : false}
                                        name="percent"
                                        type="number"
                                        label="Faixa"
                                        helperText={errors.percent}
                                        className="mt-2"
                                        autoFocus
                                    />
                                </form>
                            )}
                        </Formik>
                    </Dialog>

                    <CustomDialog
                        hidden={!isDialogExcludeOpen}
                        onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                        dialogContentProps={dialogContentProps}
                    >
                        <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                        <DeleteButton onClick={this.handleDel} text="Excluir" />
                    </CustomDialog>
                </Wrapper>
            </>
        );
    };
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: StepType[] = this.props.steps.data;

        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
        return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
    };

}


/**
 * Validação do formulário
 */
const validationStepSchema = yup.object().shape({
    percent: yup.number().required("Campo obrigatório"),
});

const mapStateToProps = (state: RootState) => ({
    steps: state.stepsReducer,
    tiposMov: state.tipoMovReducer,
    admin: state.adminReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getTiposMov,
    ...getSteps,
    ...addStep,
    ...delStep,
    ...loadConfigMotivos,
    ...editConfigMotivos,
    ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSalaryStructures);
