import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { Stack } from "office-ui-fabric-react";



import {
  CargoHistType,
} from "~/store/ducks/admin/positions/types";

import { RootState } from "~/store/ducks";
import { Text } from "@fluentui/react";

interface IEmployeeAreasState {
  columns: IColumn[];
}

interface IEmployeeCargosProps {
  cargos: CargoHistType[];
  isLoading: boolean;
}

class GridCargos extends Component<
  IEmployeeCargosProps,
  IEmployeeAreasState
> {
  constructor(props: IEmployeeCargosProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        fieldName: "dataHist",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "data",
        isPadded: true,
        onRender: (item: CargoHistType) =>
          moment(item.dataHist).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Código id hist Cargo",
        fieldName: "idCargo",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: "column3",
        name: "Cargo",
        fieldName: "cargo",
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CargoHistType) => (
          <Stack styles={{ root: { whiteSpace: "break-spaces" } }}>
            <Text>{item.cargo?.titulo}</Text>
          </Stack>
        ),
      },
      {
        key: "column4",
        name: "Motivo",
        fieldName: "tipomov.descTipoMov",
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: CargoHistType) => (
          <Stack styles={{ root: { whiteSpace: "break-spaces" } }}>
            <Text>{item.tipomov?.descTipoMov}</Text>
          </Stack>
        ),
      },
    ];

    this.state = {
      columns,
    };

  }

  render() {
    const {
      columns,
    } = this.state;
    const { cargos, isLoading } = this.props;
    return <ShimmeredDetailsList
      items={cargos ?? []}
      enableShimmer={isLoading}
      shimmerLines={4}
      columns={columns}
      selectionMode={SelectionMode.none}
      getKey={this._getKey}
      selectionPreservedOnEmptyClick={true}
      setKey="single"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      styles={{ root: { paddingTop: 0 } }}
    />
  }

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridCargos);
