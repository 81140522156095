// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import NoItems from "~/components/layout/NoItems";

//Estilos
import { ListContainer } from "./styles";

import { Creators as getPerformance } from "~/store/ducks/profile";
import {
  DataTypes as DataTypesPerfil,
  ProfilePerformanceType,
} from "~/store/ducks/profile/types";
import { RootState } from "~/store/ducks";
import { FontIcon, Text } from "@fluentui/react";


//#endregion

interface IAttitudesState {
  columns: IColumn[];
  items: ProfilePerformanceType[];
  selectionDetails: string;
  selectionCount: number;
  dialogAvaliacao: {
    isOpen: boolean;
    nomeCompleto: string;
  };
}

interface IPropsAttitudes {
  idFuncionario?: number;
  perfil: DataTypesPerfil;
  getPerformance: (idFuncionario?: number) => void;
}

class ProfilePerformance extends Component<IPropsAttitudes, IAttitudesState> {
  private _selection: Selection;

  constructor(props: IPropsAttitudes) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Ciclo",
        ariaLabel: "Ciclo",
        fieldName: "ciclo.ano",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: ProfilePerformanceType) => item.ciclo.ano,
      },
      {
        key: "column2",
        name: "Cargo",
        ariaLabel: "Cargo",
        fieldName: "cargo.titulo",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 500,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: ProfilePerformanceType) => item.cargo.titulo,
      },
      {
        key: "column3",
        name: "Autoavaliação",
        fieldName: "nomeAtitude",
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: ProfilePerformanceType) =>
          item.autoAvaliacao ? (
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
              styles={{ root: { width: "100%" } }}
            >
              <Stack horizontal>
                <FontIcon
                  iconName="CircleFill"
                  style={{
                    fontSize: 17,
                    color: item.autoAvaliacao?.cor,
                    marginRight: 12,
                  }}
                />
                <Text
                  variant="medium"
                  styles={{ root: { fontWeight: "inherit" } }}
                >
                  {item?.autoAvaliacao?.descNivel}
                </Text>
              </Stack>
            </Stack>
          ) : null,
      },
      {
        key: "column4",
        name: "Avaliação Final",
        fieldName: "nomeAtitude",
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: ProfilePerformanceType) => (
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
            styles={{ root: { width: "100%" } }}
          >
            <Stack horizontal>
              <FontIcon
                iconName="CircleFill"
                style={{
                  fontSize: 17,
                  color: item.performance.cor,
                  marginRight: 12,
                }}
              />
              <Text
                variant="medium"
                styles={{ root: { fontWeight: "inherit" } }}
              >
                {item.performance?.descNivel}
              </Text>
            </Stack>
          </Stack>
        ),
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      dialogAvaliacao: {
        isOpen: false,
        nomeCompleto: "",
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    this.props.getPerformance(this.props.idFuncionario);
  }

  _onItemInvoked = (item: ProfilePerformanceType): void => {
    // this.props.getAttitudeById(item.idAtitude!)
    // this.setState({
    //     isPanelOpen: true,
    // });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  render() {
    const {
    
      columns,
      
    } = this.state;
    const { perfil } = this.props;
    const { dataPerformance } = perfil;

    return (
      <>
        {!perfil.loadingData && dataPerformance.length === 0 ? (
          <NoItems
            error={perfil.error}
            text="Não há nenhum desempenho publicado"
            icon="Chart"
          />
        ) : (
          <ListContainer>
            <ShimmeredDetailsList
              items={dataPerformance}
              enableShimmer={perfil.loadingData}
              columns={columns}
              selectionMode={SelectionMode.none}
              selection={this._selection}
              getKey={this._getKey}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
            />
          </ListContainer>
        )}

        {/* <CustomDialog
          open={dialogAvaliacao.isOpen}
          title={dialogAvaliacao.nomeCompleto}
          btnConfirmText="Fechar"
          onClickConfirm={() =>
            this.setState({
              dialogAvaliacao: {
                isOpen: false,
                nomeCompleto: "",
              },
            })
          }
          width={1120}
          height={720}
        >
          {avaliation.data?.tipoAvaliacao === "Autoavaliação" && (
            <SelfEvaluationRender
              error={avaliation.error}
              loading={avaliation.loading}
              avaliation={avaliation.data!}
              onSave={() => false}
              modalView={true}
            />
          )}
          {(avaliation.data?.tipoAvaliacao === "Avaliação gestor" ||
            avaliation.data?.tipoAvaliacao === "Desligado" ||
            avaliation.data?.tipoAvaliacao === "Afastado") && (
            <SelfEvaluationCollectiveRender
              isDesligado={avaliation.data?.tipoAvaliacao === "Desligado"}
              error={avaliation.error}
              loading={avaliation.loading}
              avaliation={avaliation.data!}
              onSave={() => false}
              modalView={true}
            />
          )}
        </CustomDialog> */}
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: ProfilePerformanceType[] = this.props.perfil.dataPerformance;

    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  perfil: state.profileReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getPerformance,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePerformance);
