import { SuccesstionType } from "../calibration/types";

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_REPORT_LOAD = "report/REQUEST_REPORT_LOAD",
    SUCCESS_REPORT_LOAD = "report/SUCCESS_REPORT_LOAD",
    FAILURE_REPORT_LOAD = "report/FAILURE_REPORT_LOAD",
    //GET REPORT CURVE
    REQUEST_REPORT_CURVE_LOAD = "report/REQUEST_REPORT_CURVE_LOAD",
    SUCCESS_REPORT_CURVE_LOAD = "report/SUCCESS_REPORT_CURVE_LOAD",
    FAILURE_REPORT_CURVE_LOAD = "report/FAILURE_REPORT_CURVE_LOAD",
    RESET_REPORT = "report/RESET_REPORT",
    //GET REPORT SUCCESSION
    REQUEST_REPORT_SUCCESSION_LOAD = "report/REQUEST_REPORT_SUCCESSION_LOAD",
    SUCCESS_REPORT_SUCCESSION_LOAD = "report/SUCCESS_REPORT_SUCCESSION_LOAD",
    FAILURE_REPORT_SUCCESSION_LOAD = "report/FAILURE_REPORT_SUCCESSION_LOAD",
    //GET REPORT TALENT
    REQUEST_REPORT_TALENT_LOAD = "report/REQUEST_REPORT_TALENT_LOAD",
    SUCCESS_REPORT_TALENT_LOAD = "report/SUCCESS_REPORT_TALENT_LOAD",
    FAILURE_REPORT_TALENT_LOAD = "report/FAILURE_REPORT_TALENT_LOAD",
}

type ReportType = {
    temporadas: SeasonType[];
    anteriores: SeasonType[];
    reunioes: ReportMeetingType[]
}

type SeasonType = {
    ano: number;
}

type ReportMeetingType = {
    idCicloReuniao: number;
    idCicloGrupo: number;
    ciclo: {
        ano: number;
    };
    grupo: {
        nomeGrupo: string;
    }
}

type TalentType = {
    qtdeTotal: number;
    qtdeTalents: number;
    cel1: number;
    cel2: number;
    cel3: number;
    cel4: number;
    cel5: number;
    cel6: number;
    cel7: number;
    cel8: number;
    cel9: number;
}

type ReportCurvaType = {
    qtdeTotal: number;
    TotAnoAnterior: number;
    data: ReportCurveData[];
}

type ReportCurveData = {
    descNivel: string;
    cor: string;
    descIngles: string;
    requerido: number;
    atual: number;
    diferenca: number;
    percentualAtual: number;
    percentualRequerido: number;
    anosAnteriores: {
        qtde: number,
        percAnterior: null | number
    }[];
    qtdeAnterior: number;
}

interface IReportState {
    readonly data: ReportType;
    readonly dataCurve: ReportCurvaType | null;
    readonly dataSuccession: SuccesstionType[];
    readonly dataTalent: TalentType | null;
    readonly loading: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IReportState;


//GET
export interface IRequestReportLoadAction {
    type: typeof Types.REQUEST_REPORT_LOAD;
    payload?: {
        Ano?: string;
    }
};

export interface ISuccessReportLoadAction {
    type: typeof Types.SUCCESS_REPORT_LOAD;
    payload: ReportType;
};

interface IFailureReportLoadAction {
    type: typeof Types.FAILURE_REPORT_LOAD;
    message: string;
};

type ReportAction = IRequestReportLoadAction | ISuccessReportLoadAction | IFailureReportLoadAction;

//GET CURVE
export interface IRequestReportCurveLoadAction {
    type: typeof Types.REQUEST_REPORT_CURVE_LOAD;
    payload?: {
        Ano?: string;
        Reuniao?: number[];
        comparar?: string[];
    }
};

export interface ISuccessReportCurveLoadAction {
    type: typeof Types.SUCCESS_REPORT_CURVE_LOAD;
    payload: ReportCurvaType;
};

interface IFailureReportCurveLoadAction {
    type: typeof Types.FAILURE_REPORT_CURVE_LOAD;
    message: string;
};

interface IResetReportCurveAction {
    type: typeof Types.RESET_REPORT;
};

type ReportCurveAction = IRequestReportCurveLoadAction | ISuccessReportCurveLoadAction | IFailureReportCurveLoadAction | IResetReportCurveAction;

//GET SUCCESSION
export interface IRequestReportSuccessionLoadAction {
    type: typeof Types.REQUEST_REPORT_SUCCESSION_LOAD;
    payload?: {
        Ano?: string;
        Reuniao?: number[];
    }
};

export interface ISuccessReportSuccessionLoadAction {
    type: typeof Types.SUCCESS_REPORT_SUCCESSION_LOAD;
    payload: SuccesstionType[];
};

interface IFailureReportCurveSuccessionAction {
    type: typeof Types.FAILURE_REPORT_SUCCESSION_LOAD;
    message: string;
};

type ReportSuccessionAction = IRequestReportSuccessionLoadAction | ISuccessReportSuccessionLoadAction | IFailureReportCurveSuccessionAction;

//GET TALENT
export interface IRequestReportTalentLoadAction {
    type: typeof Types.REQUEST_REPORT_TALENT_LOAD;
    payload?: {
        Ano?: string;
        Reuniao?: number[];
        TipoCargo?: string;
    }
};

export interface ISuccessReportTalentLoadAction {
    type: typeof Types.SUCCESS_REPORT_TALENT_LOAD;
    payload: TalentType;
};

interface IFailureReportTalentAction {
    type: typeof Types.FAILURE_REPORT_TALENT_LOAD;
    message: string;
};

type ReportTalentAction = IRequestReportTalentLoadAction | ISuccessReportTalentLoadAction | IFailureReportTalentAction;

export type ReportActionTypes = ReportAction | ReportCurveAction | ReportSuccessionAction | ReportTalentAction;


