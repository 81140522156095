import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import {
  Wrapper,
  ContainerContent
} from "./styles";

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { CommandBarButton, ICommandBarStyles, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
//Componentes

import TreeView from "~/components/TreeView";

import { DataTypes, ValidationsType } from "~/store/ducks/cycle/validation/types";
import { Creators } from "~/store/ducks/cycle/validation";

import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

interface IValidationCycleProps {
  idCiclo: number;
}

const Validation: React.FC<IValidationCycleProps> = props => {
  // const [idSelected, setIdSelected] = useState<number | null>(null);
  const [itemSelected, setItemSelected] = useState<ValidationsType | null>(null);

  const validationsState = useSelector<RootState, DataTypes>(state => state.cycleValidationReducer);

  const dispatch = useDispatch();
  const idCiclo = props.idCiclo;

  useEffect(() => {
    dispatch(Creators.getValidationCycle(idCiclo))
  }, []);


  const handleValidationArea = () => {
    dispatch(Creators.getValidationArea(idCiclo, itemSelected!.idArea))
  }

  const convertArea = (area: any, values: any) => {
    const isLoading = itemSelected?.idCicloValidacao === values.idCicloValidacao && validationsState.loadingArea;

    const disponibilizarIcon = () => (
      <FontIcon iconName="View" style={{ color: Colors.primary, marginLeft: 10 }} title="Disponibilizar" />
    )

    if (values.flagError) {
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { color: Colors.error } }}>
          {area.nomeLocal}
          {
            isLoading ?
              <Spinner size={SpinnerSize.small} style={{ marginLeft: 10 }} />
              :
              <FontIcon iconName="StatusErrorFull" style={{ color: Colors.error, marginLeft: 10 }} />
          }
          {
            values?.flagDisponibilizado && !isLoading &&
            disponibilizarIcon()
          }
        </Stack>
      );
    } else if (values.flagPublicado) {
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { opacity: 0.5 } }}>
          {area.nomeLocal}
          {
            isLoading ?
              <Spinner size={SpinnerSize.small} style={{ marginLeft: 10 }} />
              :
              <FontIcon iconName="CompletedSolid" style={{ color: Colors.primary, marginLeft: 10 }} />
          }
          {
            values?.flagDisponibilizado && !isLoading &&
            disponibilizarIcon()
          }
        </Stack>
      );
    } else if (values.flagValidado) {
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { color: Colors.primary } }}>
          {area.nomeLocal}
          {
            isLoading ?
              <Spinner size={SpinnerSize.small} style={{ marginLeft: 10 }} />
              :
              <FontIcon iconName="CompletedSolid" style={{ color: Colors.primary, marginLeft: 10 }} />
          }
          {
            values?.flagDisponibilizado && !isLoading &&
            disponibilizarIcon()
          }
        </Stack>
      );
    } else {
      return (<>
        {area.nomeLocal}
        {
          values?.flagDisponibilizado && !isLoading &&
          disponibilizarIcon()
        }
      </>);
    }
  }

  return (
    <>
      <Wrapper >
        <ContainerContent>
          <Stack horizontal horizontalAlign="space-between" styles={{ root: { height: 44 } }}>
            {
              itemSelected !== null && itemSelected?.Area.tipoLocal === 'D' &&
              <Stack horizontal>
                <CommandBarButton
                  styles={{ root: { width: 82, height: 44 } }}
                  iconProps={{ iconName: 'Trackers' }}
                  text="Validar"
                  onClick={handleValidationArea}
                  disabled={itemSelected.flagPublicado || validationsState.loadingArea}
                />
                {
                  itemSelected.flagValidado && !itemSelected.flagError &&
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'PublishContent' }}
                    text="Publicar"
                    onClick={() => dispatch(Creators.getPublishingArea(idCiclo, itemSelected.idArea))}
                    disabled={itemSelected.flagPublicado || validationsState.loadingArea}
                  />
                }
                {
                  itemSelected.flagValidado && !itemSelected.flagError &&
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'View' }}
                    text="Disponibilizar"
                    onClick={() => dispatch(Creators.getPublishingVisualizationArea(idCiclo, itemSelected.idArea))}
                    disabled={itemSelected.flagPublicado || validationsState.loadingArea}
                  />
                }
              </Stack>
            }
          </Stack>
          <TreeView
            columns={[
              {
                name: 'Regra',
                fieldName: 'Area',
                onRender: convertArea
              },
              {
                name: 'Quantidade',
                fieldName: 'qtdePesos',
                onRender: convertQtde,
                width: 150,
                justifyHeader: 'center',
                justifyValue: 'center',
              },
              {
                name: 'Peso',
                fieldName: 'somaPesos',
                onRender: convertPeso,
                width: 150,
                justifyHeader: 'center',
                justifyValue: 'center',
              }
            ]}
            state={{ ...validationsState, data: validationsState.data.validacoes }}
            fieldId="idCicloValidacao"
            selectionMode="single"
            changeItemsSelected={item => {
              setItemSelected(item[0])
            }}
          />
        </ContainerContent>
      </Wrapper>
    </>
  );
};



const convertQtde = (qtde: string, values: any): string | JSX.Element => {
  switch (values.statusQtde) {
    case 'O':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end', opacity: values.flagPublicado ? 0.5 : 1 } }}>
          {String(qtde)}
          <FontIcon iconName="CheckMark" style={{ color: Colors.primary, marginLeft: 5 }} />
        </Stack>
      );
    case 'A':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end', color: Colors.error } }}>
          {String(qtde)}
          <FontIcon iconName="WarningSolid" style={{ color: Colors.error, marginLeft: 5 }} />
        </Stack>
      );
    default:
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end', paddingRight: 19 } }}>
          {String(qtde)}
        </Stack>
      )
  }
}

const convertPeso = (peso: string, values: any): string | JSX.Element => {
  switch (values.statusQtde) {
    case 'O':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 58, justifyContent: 'flex-end', opacity: values.flagPublicado ? 0.5 : 1 } }}>
          {String(peso)}%
          <FontIcon iconName="CheckMark" style={{ color: Colors.primary, marginLeft: 5 }} />
        </Stack>
      );
    case 'A':
      return (
        <Stack horizontal verticalAlign="center" style={{ color: Colors.error }} styles={{ root: { width: 58, justifyContent: 'flex-end' } }}>
          {String(peso)}%
          <FontIcon iconName="WarningSolid" style={{ color: Colors.error, marginLeft: 5 }} />
        </Stack>
      );
    default:
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 58, justifyContent: 'flex-end', paddingRight: 19 } }}>
          {`${peso}%`}
        </Stack>

      )
  }
}

export default Validation;
