// #region Imports
import React, { useState, useEffect } from "react";


//FluentUI



import CustomDialog from "~/components/CustomDialog";
import { InputText } from '~/components/Forms';
//Estilos


//#endregion

interface IDialogComentarioProps {
  comentario: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (comentario: string) => void;
}

const DialogComentario: React.FC<IDialogComentarioProps> = (props) => {
  const { isOpen, onClose, onConfirm } = props;
  const [comentario, setComentario] = useState(props.comentario)

  useEffect(() => {
    if (isOpen) {
      setComentario(props.comentario)
    }
  }, [isOpen])

  return (
    <CustomDialog
      open={isOpen}
      title="Comentários"
      btnConfirmText="Salvar"
      onClickCancel={onClose}
      onClickConfirm={() => {
        onConfirm(comentario);
      }}
      // disabledBtnConfirm={}
      height={260}
      width={850}
    >
      <InputText
        value={comentario}
        type="text"
        onChange={e => setComentario(e.target.value)}
        multiline
        rows={5}
        autoFocus
      />
    </CustomDialog>
  );
}

export default DialogComentario;