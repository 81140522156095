import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'

import {
  
  Persona,
  PersonaSize,
} from "office-ui-fabric-react";
import HeaderPage from "~/components/layout/HeaderPage";
import NoItems from "~/components/layout/NoItems";
import TreeView from "~/components/TreeView";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";

import { Wrapper, ContainerContent, WrapperTreeview } from "./styles";

import { Creators as CreatorTeam } from "~/store/ducks/team";
import { DataTypes as DataTypesTeam, TeamType } from "~/store/ducks/team/types";

import { Creators as CreatorsPage } from "~/store/ducks/home";
import { Page } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";

import { getAbbreviatedName } from "~/utils/abbreviatedName";
import Colors from "~/assets/js/colors";

import ListTeam from "./listTeam";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { getPresence } from "~/utils/statusFunc";

const baseURL = process.env.REACT_APP_BASE_URL;

const MyTeam: React.FC = () => {
  const [itemSelected, setItemSelected] = useState<TeamType | null>(null);

  const [search, setSearch] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);

  const teamState = useSelector<RootState, DataTypesTeam>(
    (state) => state.teamReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const page: Page = {
      key: "myTeam",
      pages: [
        {
          text: "Home",
          isCurrentItem: false,
          icon: "HomeSolid",
          onlyIcon: true,
          url: "/",
        },
        { text: "Meu Time", isCurrentItem: true },
      ],
    };

    dispatch(CreatorsPage.setCurrentPage(page));
    dispatch(CreatorTeam.loadTeam());
  }, [dispatch]);

  const searchFunc = (nomeFunc: string = "") => {
    setSearch(nomeFunc);
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const hasItems = search.length !== 0;
      setIsFiltering(hasItems);
      setItemSelected(null);
      dispatch(CreatorTeam.loadTeam(search));
    }
  };

  const handleClearSearch = () => {
    setIsFiltering(false);
    setItemSelected(null);
    dispatch(CreatorTeam.loadTeam());
  };

  return (
    <Wrapper>
      <ContainerContent>
        <HeaderPage
          title="Meu Time"
          leftItems={
            <>
              {/* <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Add" }}
                text="Solicitar Aumento de Quadro"
                onClick={() => {
                  console.log(itemSelected);
                }}
                // disabled={objectivesState.loadingData}
              /> */}
              {/* {commandBarRender()} */}
            </>
          }
          rightItems={
            <>
              <SearchBox
                placeholder="Pesquisar"
                value={search}
                // disabled={teamState.data.length === 0}
                onChange={(e) => searchFunc(e?.target.value)}
                onKeyUp={handleSearchKeyUp}
                onClear={handleClearSearch}
                styles={{
                  root: { border: "none", width: 200, marginRight: 1 },
                }}
              />
              {/* <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Filter" }}
                text="Filtrar"
                // onClick={() => setIsOpen(true)}
              /> */}
              {/* <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Refresh" }}
                text="Atualizar"
                onClick={() => {
                  setItemSelected(null);
                  setIsFiltering(false);
                  dispatch(CreatorTeam.loadTeam());
                }}
              /> */}
            </>
          }
        />
        <WrapperTreeview>
          {teamState.loadingData ? (
            <Spinner
              size={SpinnerSize.medium}
              label="Carregando Time"
              styles={{ root: { height: "100%" } }}
            />
          ) : teamState.data.length === 0 ? (
            <NoItems
              error={teamState.error}
              text="Não há funcionários no seu time"
              icon="Teamwork"
            />
          ) : !isFiltering ? (
            <TreeView
              columns={[
                {
                  name: "ID",
                  fieldName: "idFuncionario",
                  width: 50,
                },
                {
                  name: "Nome Completo",
                  fieldName: "nomeCompleto",
                  position: "left",
                },
                // {
                //   name: "ID Vaga",
                //   fieldName: "idVaga",
                //   width: 120,
                // },
                {
                  name: "Cargo",
                  fieldName: "idCargo",
                  onRender: convertCargo,
                  width: 500,
                },
              ]}
              state={teamState}
              fieldId="idFuncionario"
              changeItemsSelected={(items) => setItemSelected(items[0])}
              handleOnItemInvoked={(item: TeamType) => history.push(`/perfil/${item?.idFuncionario}`)}
              selectionMode="none"
              status={{
                hasStatus: true,
                fieldName: "flagAtivo",
                component: (item: TeamType) => (
                  <Persona
                    imageUrl={`${baseURL}uploads/${item.imagem}`}
                    imageInitials={getAbbreviatedName(item.nomeCompleto)}
                    initialsColor={Colors.primary}
                    size={PersonaSize.size32}
                    presence={getPresence(item.status ? "A" : "D")}
                    styles={{
                      root: {
                        width: 32,
                        marginRight: 10,
                        selectors: {
                          "& .ms-Persona-initials": {
                            filter: "grayscale(0)",
                            // filter: status === 'D' ? 'grayscale(1)' : 'grayscale(0)'
                          },
                        },
                      },
                    }}
                  />
                ),
              }}
            />
          ) : (
            <ListTeam
              teamState={teamState}
              handleSelectItem={(item: TeamType | null) =>
                setItemSelected(item)
              }
            />
          )}
        </WrapperTreeview>
      </ContainerContent>
    </Wrapper>
  );
};

const convertCargo = (text: string, values: TeamType) => {
  return `${values.idCargo} - ${values.titulo}`;
};
/*
const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};
*/
export default MyTeam;
