import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";



import { ListContainer } from "./styles";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { FontIcon, Spinner, SpinnerSize } from "office-ui-fabric-react";
//Componentes
import { RootState } from "~/store/ducks";
import NoItems from "~/components/layout/NoItems";
import Accordion from "~/components/Accordion";
import { toCurrency } from "~/utils/numbers";

import {
  
  MeritResume,
  MeritTableResume,
} from "~/store/ducks/merit/types";
import { Legenda } from "../styles";

interface ICycleState {
  selectionDetails: string;
  selectionCount: number;
}

interface ICyclesProps {
  merits: MeritResume | null;
  isLoading: boolean;
}

class GridResumeMerit extends Component<ICyclesProps, ICycleState> {
  private _selection: Selection;

  constructor(props: ICyclesProps) {
    super(props);

    this.state = {
      selectionDetails: "",
      selectionCount: 0,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  renderColumns = (data: MeritTableResume[], key: string): IColumn[] => {
    return [
      {
        key: key + "column1",
        name: "Rating",
        ariaLabel: "Rating",
        fieldName: "idSolicitacao",
        isRowHeader: true,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: MeritTableResume, i) => (
          <>
            {item.idTabPerformance ? (
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                styles={{ root: { width: "100%" } }}
              >
                <Stack horizontal>
                  <FontIcon
                    iconName="CircleFill"
                    style={{
                      fontSize: 17,
                      color: item.nivel?.cor,
                      marginRight: 12,
                    }}
                  />
                  <Text
                    variant="medium"
                    styles={{ root: { fontWeight: "inherit" } }}
                  >
                    {item.nivel?.descNivel}
                  </Text>
                </Stack>
              </Stack>
            ) : (data.length ?? 0) - 1 === i ? (
              <Text styles={{ root: { fontWeight: 600 } }}>Total</Text>
            ) : (
              <Text>N/A</Text>
            )}
          </>
        ),
      },
      {
        key: key + "column2",
        name: "Qtde",
        fieldName: "qtdeTotal",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        onRender: (item: MeritTableResume, i) => {
          const isLast = (data.length ?? 0) - 1 === i;
          return (
            <Text
              styles={{
                root: { fontWeight: isLast ? 600 : 400 },
              }}
            >
              {item.qtdeTotal}
            </Text>
          );
        },
      },
      {
        key: key + "column3",
        name: "LÍDER",
        fieldName: "percGestor",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: cellStyleGreen,
        onRender: (item: MeritTableResume, i) => {
          const isLast = (data.length ?? 0) - 1 === i;
          return (
            <Text
              styles={{
                root: { fontWeight: isLast ? 600 : 400 },
              }}
            >
              {item.qtdeGestor !== 0 || item.qtdeTotal !== 0
                ? item.percGestor.toFixed(1) + "%"
                : "-"}
            </Text>
          );
        },
      },
      {
        key: key + "column4",
        name: "PROFISSIONAL",
        fieldName: "percPro",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: cellStyleGreen,
        onRender: (item: MeritTableResume, i) => {
          const isLast = (data.length ?? 0) - 1 === i;
          return (
            <Text
              styles={{
                root: { fontWeight: isLast ? 600 : 400 },
              }}
            >
              {item.qtdePro !== 0 || item.qtdeTotal !== 0
                ? item.percPro.toFixed(1) + "%"
                : "-"}
            </Text>
          );
        },
      },
      {
        key: key + "column5",
        name: "TOTAL",
        fieldName: "percTotal",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: cellStyleGreen,
        onRender: (item: MeritTableResume) => (
          <Text
            styles={{
              root: { fontWeight: 600 },
            }}
          >
            {item.qtdeTotal !== 0 && item.percTotal !== null
              ? item.percTotal.toFixed(1) + "%"
              : "-"}
          </Text>
        ),
      },
      {
        key: key + "column6",
        name: "LÍDER",
        fieldName: "valorGestor",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: cellStyleGreenDark,
        onRender: (item: MeritTableResume, i) => {
          const isLast = (data.length ?? 0) - 1 === i;
          return (
            <Text
              styles={{
                root: { fontWeight: isLast ? 600 : 400 },
              }}
            >
              {item.qtdeGestor !== 0 || item.qtdeTotal !== 0
                ? toCurrency(item.valorGestor ?? 0, true)
                : "-"}
            </Text>
          );
        },
      },
      {
        key: key + "column7",
        name: "PROFISSIONAL",
        fieldName: "valorPro",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: cellStyleGreenDark,
        onRender: (item: MeritTableResume, i) => {
          const isLast = (data.length ?? 0) - 1 === i;
          return (
            <Text
              styles={{
                root: { fontWeight: isLast ? 600 : 400 },
              }}
            >
              {item.qtdePro !== 0 || item.qtdeTotal !== 0
                ? toCurrency(item.valorPro ?? 0, true)
                : "-"}
            </Text>
          );
        },
      },
      {
        key: key + "column8",
        name: "TOTAL",
        fieldName: "valorTotal",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "flex-end",
            paddingRight: 18,
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: MeritTableResume) => (
          <Text
            styles={{
              root: { fontWeight: 600, marginRight: 10 },
            }}
          >
            {item.qtdeTotal !== 0 && item.valorTotal !== null
              ? toCurrency(item.valorTotal, true)
              : "-"}
          </Text>
        ),
      },
    ];
  };

  render() {
    const { merits, isLoading } = this.props;
    return (
      <>
        {isLoading ? (
          <Spinner
            size={SpinnerSize.medium}
            label="Carregando Resumo"
            styles={{ root: { height: 250 } }}
          />
        ) : // {!merits.loadingData && funcData?.length === 0 ?
        false ? (
          <NoItems
            error={false}
            text="Não há méritos cadastrados"
            icon="Teamwork"
          />
        ) : (
          <>
            <Accordion title="Total" defaultExpanded={true}>
              <Legendas />
              <ListContainer>
                <ShimmeredDetailsList
                  items={merits?.total ?? []}
                  enableShimmer={false}
                  columns={this.renderColumns(merits?.total ?? [], "total")}
                  selectionMode={SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderRow={this._onRenderRow}
                />
              </ListContainer>
            </Accordion>
            <Accordion title="Méritos" defaultExpanded={false}>
              <Legendas />
              <ListContainer>
                <ShimmeredDetailsList
                  items={merits?.merito ?? []}
                  enableShimmer={false}
                  columns={this.renderColumns(merits?.merito ?? [], "merito")}
                  selectionMode={SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderRow={this._onRenderRow}
                />
              </ListContainer>
            </Accordion>
            <Accordion title="Enquadramento"  defaultExpanded={false}>
              <Legendas />
              <ListContainer>
                <ShimmeredDetailsList
                  items={merits?.enquadramento ?? []}
                  enableShimmer={false}
                  columns={this.renderColumns(
                    merits?.enquadramento ?? [],
                    "enq"
                  )}
                  selectionMode={SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderRow={this._onRenderRow}
                />
              </ListContainer>
            </Accordion>
            <Accordion title="Promoções" defaultExpanded={false}>
              <Legendas />
              <ListContainer>
                <ShimmeredDetailsList
                  items={merits?.promocoes ?? []}
                  enableShimmer={false}
                  columns={this.renderColumns(
                    merits?.promocoes ?? [],
                    "promocao"
                  )}
                  selectionMode={SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onRenderRow={this._onRenderRow}
                />
              </ListContainer>
            </Accordion>
          </>
        )}
      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.root = {
          selectors: {
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(3), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(4), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(5)": {
              backgroundColor: "#DFEBE5",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(6), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(7), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(8)": {
              backgroundColor: "#CAE0D6",
            },
          },
        };
        if (props?.columns[0].name === "Rating") {
          customStyles.cell = {
            display: "flex",
            alignItems: "center",
            whiteSpace: "break-spaces",
            position: "sticky",
            top: 50,
            selectors: {
              "&:nth-child(2)": {
                justifyContent: "center",
              },
              "&:nth-child(3), &:nth-child(4), &:nth-child(5)": {
                justifyContent: "center",
                backgroundColor: "#d3eadf",
              },
              "&:nth-child(6), &:nth-child(7), &:nth-child(8)": {
                justifyContent: "flex-end",
                backgroundColor: "#bedbca",
              },
            },
          };
        }
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const cellStyleGreen = {
  cellTitle: {
    backgroundColor: "#D3EADF",
    justifyContent: "center",
    selectors: {
      "&:hover": {
        backgroundColor: "#DFEBE5",
      },
    },
  },
};

const cellStyleGreenDark = {
  cellTitle: {
    backgroundColor: "#BEDBCA",
    justifyContent: "flex-end",
    selectors: {
      "&:hover": {
        backgroundColor: "#CAE0D6",
      },
    },
  },
};

const Legendas = () => (
  <Stack horizontal verticalAlign="center" styles={{ root: { height: 40 } }}>
    <Stack
      horizontal
      verticalAlign="center"
      styles={{ root: { marginRight: 16 } }}
    >
      <Legenda bgColor="#d3eadf" />
      <Text styles={{ root: { fontSize: 11 } }}>% Aumento Médio</Text>
    </Stack>
    <Stack
      horizontal
      verticalAlign="center"
      styles={{ root: { marginRight: 16 } }}
    >
      <Legenda bgColor="#bedbca" />
      <Text styles={{ root: { fontSize: 11 } }}>R$ Impacto Financeiro</Text>
    </Stack>
  </Stack>
);

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridResumeMerit);
