import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { FontIcon, Stack, Text, ITextStyles, SelectionMode, IconButton, DefaultButton, Label, Spinner, SpinnerSize } from 'office-ui-fabric-react';

import { Creators } from '~/store/ducks/calibration';
import { NivelType, DataTypes as DataTypesCalibration,  AvaliadoState, SuccesstionType } from '~/store/ducks/calibration/types';
import { RootState } from "~/store/ducks";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { useId } from '@uifabric/react-hooks';

import { Table, Th, Td, Tr, VagaContainer, LiderContainer, SuccessorCointainer, SuccessorWrapper, SuccessionTimeWrapper, WrapperTd, WrapperRemove } from './styles';

import FormSearch from '~/pages/Employees/formSearch';
import { DeleteButton, OutlinedButton } from '~/components/Buttons';
import Dropdown from '~/components/layout/Dropdown';
import { MenuItem } from '@material-ui/core';
import DialogCalibration from '../DialogCalibration';
import { TabPerfomanceType } from '~/store/ducks/tasks/types';

interface ISuccessionProps {
  idCicloReuniao: number;
  niveis: NivelType[] | undefined;
  state: {
    nivelSucessao: string;
    lockedNivelSucessao: boolean;
    performances: TabPerfomanceType[];
    avaliadoSelected: AvaliadoState | null;
    readOnly: boolean;
  }
  setNivelSucessao: (value: string) => void;
  setLockedNivelSucessao: (value: boolean) => void;
}

interface ICalibrationSucesssao {
  isOpen: boolean;
  idCicloReuniaoSucessaoGrupo: number | null;
  sucessao: 'Imediato' | 'Curto Prazo - 1 a 2 anos' | 'Médio Prazo - 3 a 5 anos' | 'Longo Prazo - acima 5 anos' | ''
}

interface IDelete {
  isOpen: boolean;
  idCicloReuniaoSucessaoGrupo: number | null;
  idCicloReuniaoSucessaoGrupoAvaliado: number | null;
  sucessao: 'Imediato' | 'Curto Prazo - 1 a 2 anos' | 'Médio Prazo - 3 a 5 anos' | 'Longo Prazo - acima 5 anos' | ''
}

interface IDialogCalibration {
  isOpen: boolean;
  idCicloReuniaoAvaliado: number | null;
}

const initialValueDelete: IDelete = {
  isOpen: false,
  idCicloReuniaoSucessaoGrupo: null,
  idCicloReuniaoSucessaoGrupoAvaliado: null,
  sucessao: ''
}


const Succession: React.FC<ISuccessionProps> = (props) => {
  const { avaliadoSelected, performances, readOnly } = props.state;
  const { avaliadoCalib } = avaliadoSelected ?? {};

  const calibrationState = useSelector<RootState, DataTypesCalibration>(state => state.calibrationReducer)
  const { calibration } = calibrationState;
  const { sucessao } = calibration ?? {};
  const dispatch = useDispatch();
  const [flagOnlyMeeting, setFlagOnlyMeeting] = useState(true);
  const [dialogCalibration, setDialogCalibration] = useState<IDialogCalibration>({
    isOpen: false,
    idCicloReuniaoAvaliado: null
  });

  const [dialogDelete, setDialogDelete] = useState<IDelete>(initialValueDelete)

  const [calibrationSucessao, setCalibrationSucessao] = useState<ICalibrationSucesssao>({
    isOpen: false,
    idCicloReuniaoSucessaoGrupo: null,
    sucessao: ''
  });

  useEffect(() => {
    if (avaliadoSelected?.success) {
      setDialogCalibration({
        isOpen: false,
        idCicloReuniaoAvaliado: null
      })
    }
  }, [avaliadoSelected?.success])

  useEffect(() => {
    if (calibrationState.successSucessao) {
      dispatch(Creators.clearCalibrationPesquisaFunc())
    }
  }, [calibrationState.successSucessao])

  useEffect(() => {
    if (calibrationState.successSucessao) {
      setDialogDelete(initialValueDelete)
    }
  }, [calibrationState.successSucessao])

  return (
    <Stack>
      <Stack horizontal verticalAlign="center" styles={{ root: { marginBottom: 10, marginTop: 20 } }}>
        <Stack>
          <Label styles={{ root: { marginBottom: 10 } }}>Nível Hierárquico</Label>
          <Stack horizontal>
            <Stack styles={{ root: { width: 200, marginRight: 10 } }}>
              <Dropdown
                label=""
                name="nivel"
                values={props.state.nivelSucessao}
                variant="outlined"
                handleChange={(e: any) => props.setNivelSucessao(String(e.target.value))}
                disabled={props.state.lockedNivelSucessao}
                autofocus
                fieldHeight={35}
                styles={{ height: 35 }}
                paddingSelected="5px 18px"
              >
                {
                  props.niveis?.map((item, i) => (
                    <MenuItem key={i} value={item.nivel}>Hierarquia {item.nivel}</MenuItem>
                  ))
                }
                <MenuItem value={'T'}>Todos</MenuItem>
              </Dropdown>
            </Stack>
            {
              !props.state.lockedNivelSucessao ?
                <OutlinedButton
                  text="Abrir Nível"
                  style={{ width: 125 }}
                  disabled={props.state.nivelSucessao === ''}
                  onClick={() => {
                    props.setLockedNivelSucessao(true)
                    dispatch(Creators.getSucessao(props.idCicloReuniao, props.state.nivelSucessao))
                    // this.props.setCalibrationActive({
                    //   canFinish: false,
                    //   idCicloReuniaoAvaliado: null,
                    //   isActive: false,
                    //   collapseInfo: false
                    // })
                    // getMeetingByIdNivel(idCicloReuniao, Number(nivel))
                  }}
                />
                :
                <OutlinedButton
                  text="Fechar Nível"
                  style={{ width: 125 }}
                  onClick={() => {
                    props.setLockedNivelSucessao(false)
                    dispatch(Creators.clearSucessao())
                  }}
                />
            }
          </Stack>
        </Stack>
      </Stack>
      {
        props.state.lockedNivelSucessao &&
        <>
          {
            calibrationState.loadingSuccession ?
              <Spinner size={SpinnerSize.medium} label="Carregando sucessão" styles={{ root: { height: 340 } }} />
              :
              <TableSuccession
                sucessao={sucessao ?? []}
                readOnly={readOnly}
                handleDelete={(idCicloReuniaoSucessaoGrupo, idCicloReuniaoSucessaoGrupoAvaliado, sucessao) => {
                  setDialogDelete({
                    isOpen: true,
                    idCicloReuniaoSucessaoGrupo,
                    idCicloReuniaoSucessaoGrupoAvaliado,
                    sucessao
                  })
                }}
                handleAddFuncionario={(idCicloReuniaoSucessaoGrupo, sucessao) => {
                  setCalibrationSucessao({ isOpen: true, idCicloReuniaoSucessaoGrupo, sucessao })
                }}
                handleClickFuncionario={(idCicloReuniaoAvaliado) => {
                  dispatch(Creators.loadAvaliado(props.idCicloReuniao, idCicloReuniaoAvaliado))
                  setDialogCalibration({
                    isOpen: true,
                    idCicloReuniaoAvaliado
                  })
                }}
              />
          }
        </>
      }
      <FormSearch
        isOpen={calibrationSucessao.isOpen}
        items={calibrationState.pesquisaFunc.avaliados ?? []}
        loading={calibrationState.pesquisaFunc.loading}
        selectionMode={SelectionMode.single}
        onAdd={(values) => dispatch(Creators.addSucessao(Number(props.idCicloReuniao), calibrationSucessao.idCicloReuniaoSucessaoGrupo!, calibrationSucessao.sucessao, values[0].nomeCompleto, values[0].idCicloReuniaoAvaliado, values[0].idFuncionario))}
        onClose={() => {
          dispatch(Creators.clearCalibrationPesquisaFunc())
          setCalibrationSucessao({
            isOpen: false,
            idCicloReuniaoSucessaoGrupo: null,
            sucessao: '',
          })
        }}
        checkbox={{
          value: flagOnlyMeeting,
          setValue: (value) => setFlagOnlyMeeting(value),
          text: "Listar apenas avaliados na reunião",
          disabled: props.state.nivelSucessao !== 'T',
          tooltip: props.state.nivelSucessao !== 'T' ? 'Não é possível esse tipo de busca na sucessão por nível' : ''
        }}
        searchAPI={(nomeSearh?: string, idArea?: number | null, idWorkLevel?: number | null, idChefe?: number | null) =>
          dispatch(Creators.calibrationPesquisaFunc(Number(props.idCicloReuniao), idArea, idChefe, nomeSearh, idWorkLevel, flagOnlyMeeting))}
      />

      <CustomDialog
        hidden={!dialogDelete.isOpen}
        dialogContentProps={dialogContentProps}
      >
        <DefaultButton
          onClick={() => setDialogDelete({
            isOpen: false,
            idCicloReuniaoSucessaoGrupo: null,
            idCicloReuniaoSucessaoGrupoAvaliado: null,
            sucessao: ''
          })}
          text="Cancelar"
        />
        <DeleteButton
          onClick={() => dispatch(Creators.delSucessao(Number(props.idCicloReuniao), dialogDelete.idCicloReuniaoSucessaoGrupo!, dialogDelete.idCicloReuniaoSucessaoGrupoAvaliado!, dialogDelete.sucessao))}
          text="Excluir"
        />
      </CustomDialog>

      <DialogCalibration
        isOpen={dialogCalibration.isOpen}
        loadingData={calibrationState.avaliadoSelected.loading}
        success={calibrationState.avaliadoSelected.successLoading}
        initialValues={{
          nomeCompleto: avaliadoCalib?.avaliado?.funcionario?.nomeCompleto ?? '',
          idTabPerformance: avaliadoCalib?.avaliado?.idTabPerformance ?? null,
          potencial: avaliadoCalib?.avaliado?.potencial ?? '',
          sucessao: avaliadoCalib?.avaliado?.sucessao ?? '',
          carreira: avaliadoCalib?.avaliado?.carreira ?? '',
          proximoCiclo: avaliadoCalib?.avaliado?.proximoCiclo ?? '',
          performance: {
            descNivel: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor?.descNivel ?? '',
            cor: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor?.cor ?? '',
            nivel: avaliadoCalib?.avaliado?.performance?.nivel ?? null,
          },
          avaliacaoGestor: {
            idTabPerformance: Number(avaliadoCalib?.avaliado?.avaliacaoGestor?.idTabPerformanceGestor) ?? null,
            performancegestor: avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor,
            potencial: avaliadoCalib?.avaliado?.avaliacaoGestor?.potencial ?? '',
            sucessao: avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? ''
          },
          anotacoes: avaliadoCalib?.comentarios ?? [],
          aspiracoesCarreira: avaliadoCalib?.avaliado?.avaliacaoGestor?.aspiracoesCarreira ?? ''
        }}
        performances={performances ?? []}
        readonly={readOnly}
        onClose={() => setDialogCalibration({
          isOpen: false,
          idCicloReuniaoAvaliado: null
        })}
        onSuccess={(values) => {
          const nivelPerformance = avaliadoCalib?.avaliado?.performance?.nivel;
          const nivelOriginal = (nivelPerformance !== null && nivelPerformance !== undefined) ? nivelPerformance : Number(avaliadoCalib?.avaliado?.avaliacaoGestor?.performancegestor.nivel) ?? null;
          const sucessaoOriginal = avaliadoCalib?.avaliado?.sucessao ? avaliadoCalib?.avaliado?.sucessao : avaliadoCalib?.avaliado?.avaliacaoGestor?.sucessao ?? "";

          dispatch(Creators.saveAvaliado(props.idCicloReuniao, dialogCalibration.idCicloReuniaoAvaliado!, values, nivelOriginal!, sucessaoOriginal))
        }}
      />
    </Stack >
  );
}

const funcStyles: Partial<ITextStyles> = {
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
}

const dialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este avaliado da sucessão?"
};

interface ITableSuccession {
  sucessao: SuccesstionType[];
  readOnly: boolean;
  handleClickFuncionario?: (idCicloReuniaoAvaliado: number) => void;
  handleDelete?: (idCicloReuniaoSucessaoGrupo: number, idCicloReuniaoSucessaoGrupoAvaliado: number, nivelSucessao: 'Imediato' | 'Curto Prazo - 1 a 2 anos' | 'Médio Prazo - 3 a 5 anos' | 'Longo Prazo - acima 5 anos' | '') => void;
  handleAddFuncionario?: (idCicloReuniaoSucessaoGrupo: number, nivelSucessao: 'Imediato' | 'Curto Prazo - 1 a 2 anos' | 'Médio Prazo - 3 a 5 anos' | 'Longo Prazo - acima 5 anos' | '') => void;
}

export const TableSuccession: React.FC<ITableSuccession> = (props) => {
  const { sucessao, readOnly, handleDelete, handleAddFuncionario, handleClickFuncionario } = props;
  const tooltipId = useId('tooltip');

  const renderVagas = () => {
    return sucessao?.map((grupo, i) => {
      return (
        <Th key={i}>
          <Stack>
            <VagaContainer cor={grupo.flagOutros ?? false}>
              <Text variant="smallPlus">{grupo.nomeGrupo}</Text>
            </VagaContainer>
            <LiderContainer>
              <FontIcon iconName="Contact" style={{ fontSize: 12, marginRight: 10 }} />
              <Text variant="tiny" styles={funcStyles}>{grupo.chefe?.nomeCompleto}</Text>
            </LiderContainer>
          </Stack>
        </Th>
      )
    })
  }


  const renderSucessao = () => {
    return (
      <tbody>
        <Tr>
          {
            sucessao?.map((suc, i) => {
              const { nivel1, idCicloReuniaoSucessaoGrupo } = suc;
              return (
                <Td color="#0C68AC" key={i}>
                  <WrapperTd>
                    <SuccessionTimeWrapper>
                      <Text variant="tiny">IMEDIATO (READY NOW)</Text>
                    </SuccessionTimeWrapper>
                    <SuccessorCointainer>
                      {
                        nivel1.map((avaliadoN1, j) => {
                          const { flagInicial, avaliado, idCicloReuniaoSucessaoGrupoAvaliado, idCicloReuniaoAvaliado } = avaliadoN1;
                          return (
                            <SuccessorWrapper key={j} flagInicial={flagInicial}>
                              {
                                !readOnly &&
                                <WrapperRemove>
                                  <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ icon: { fontSize: 14 }, root: { width: 24, height: 24 } }}
                                    onClick={() => handleDelete?.(idCicloReuniaoSucessaoGrupo, idCicloReuniaoSucessaoGrupoAvaliado, 'Imediato')}
                                  />
                                </WrapperRemove>
                              }
                              <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{ root: { cursor: flagInicial && handleClickFuncionario ? 'pointer' : 'normal', width: 165 } }}
                                onClick={() => {
                                  if (flagInicial) {
                                    handleClickFuncionario?.(idCicloReuniaoAvaliado)
                                  }
                                }}
                              >
                                <FontIcon iconName={flagInicial ? "PinnedSolid" : "Contact"} style={{ fontSize: 12, marginRight: 10, color: '#323130' }} />
                                <TooltipHost
                                  content={avaliado.funcionario.nomeCompleto}
                                  id={tooltipId}
                                  styles={funcStyles}
                                >
                                  <Text variant="smallPlus">{avaliado.funcionario.nomeCompleto}</Text>
                                </TooltipHost>
                              </Stack>
                            </SuccessorWrapper>
                          )
                        })}
                      {
                        !readOnly &&
                        <SuccessorWrapper>
                          <FontIcon iconName="AddFriend" style={{ fontSize: 12, marginRight: 10 }} />
                          <Text
                            variant="small"
                            className="linkAddFuncSuccessao"
                            styles={{ root: { display: 'none' } }}
                            onClick={() => handleAddFuncionario?.(idCicloReuniaoSucessaoGrupo, 'Imediato')}
                          >
                            Adicionar Funcionário
                          </Text>
                        </SuccessorWrapper>
                      }
                    </SuccessorCointainer>
                  </WrapperTd>
                </Td>
              )
            })
          }
        </Tr>
        <Tr>
          {
            sucessao?.map((suc, i) => {
              const { nivel2, idCicloReuniaoSucessaoGrupo } = suc;
              return (
                <Td color="#187636" key={i}>
                  <WrapperTd>
                    <SuccessionTimeWrapper>
                      <Text variant="tiny">Curto Prazo - 1 a 2 anos</Text>
                    </SuccessionTimeWrapper>
                    <SuccessorCointainer>
                      {
                        nivel2.map((avaliadoN2, j) => {
                          const { flagInicial, avaliado, idCicloReuniaoSucessaoGrupoAvaliado, idCicloReuniaoAvaliado } = avaliadoN2;
                          return (
                            <SuccessorWrapper key={j} flagInicial={flagInicial}>
                              {
                                !readOnly &&
                                <WrapperRemove>
                                  <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ icon: { fontSize: 14 }, root: { width: 24, height: 24 } }}
                                    onClick={() => handleDelete?.(idCicloReuniaoSucessaoGrupo, idCicloReuniaoSucessaoGrupoAvaliado, 'Curto Prazo - 1 a 2 anos')}
                                  />
                                </WrapperRemove>
                              }
                              <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{ root: { cursor: flagInicial && handleClickFuncionario ? 'pointer' : 'normal', width: 165 } }}
                                onClick={() => {
                                  if (flagInicial) {
                                    handleClickFuncionario?.(idCicloReuniaoAvaliado)
                                  }
                                }}
                              >
                                <FontIcon iconName={flagInicial ? "PinnedSolid" : "Contact"} style={{ fontSize: 12, marginRight: 10, color: '#323130' }} />
                                <TooltipHost
                                  content={avaliado.funcionario.nomeCompleto}
                                  id={tooltipId}
                                  styles={funcStyles}
                                >
                                  <Text variant="smallPlus">{avaliado.funcionario.nomeCompleto}</Text>
                                </TooltipHost>
                              </Stack>
                            </SuccessorWrapper>
                          )
                        })}
                      {
                        !readOnly &&
                        <SuccessorWrapper>
                          <FontIcon iconName="AddFriend" style={{ fontSize: 12, marginRight: 10 }} />
                          <Text
                            variant="small"
                            className="linkAddFuncSuccessao"
                            styles={{ root: { display: 'none' } }}
                            onClick={() => handleAddFuncionario?.(idCicloReuniaoSucessaoGrupo, 'Curto Prazo - 1 a 2 anos')}>
                            Adicionar Funcionário
                          </Text>
                        </SuccessorWrapper>
                      }
                    </SuccessorCointainer>
                  </WrapperTd>
                </Td>
              )
            })
          }
        </Tr>
        <Tr>
          {
            sucessao?.map((suc, i) => {
              const { nivel3, idCicloReuniaoSucessaoGrupo } = suc;
              return (
                <Td color="#489E25" key={i}>
                  <WrapperTd>
                    <SuccessionTimeWrapper>
                      <Text variant="tiny">Médio Prazo - 3 a 5 anos</Text>
                    </SuccessionTimeWrapper>
                    <SuccessorCointainer>
                      {
                        nivel3.map((avaliadoN3, j) => {
                          const { flagInicial, avaliado, idCicloReuniaoSucessaoGrupoAvaliado, idCicloReuniaoAvaliado } = avaliadoN3;
                          return (
                            <SuccessorWrapper key={j} flagInicial={flagInicial}>
                              {
                                !readOnly &&
                                <WrapperRemove>
                                  <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ icon: { fontSize: 14 }, root: { width: 24, height: 24 } }}
                                    onClick={() => handleDelete?.(idCicloReuniaoSucessaoGrupo, idCicloReuniaoSucessaoGrupoAvaliado, 'Médio Prazo - 3 a 5 anos')}
                                  />
                                </WrapperRemove>
                              }
                              <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{ root: { cursor: flagInicial && handleClickFuncionario ? 'pointer' : 'normal', width: 165 } }}
                                onClick={() => {
                                  if (flagInicial) {
                                    handleClickFuncionario?.(idCicloReuniaoAvaliado)
                                  }
                                }}
                              >
                                <FontIcon iconName={flagInicial ? "PinnedSolid" : "Contact"} style={{ fontSize: 12, marginRight: 10, color: '#323130' }} />
                                <TooltipHost
                                  content={avaliado.funcionario.nomeCompleto}
                                  id={tooltipId}
                                  styles={funcStyles}
                                >
                                  <Text styles={funcStyles} variant="smallPlus">{avaliado.funcionario.nomeCompleto}</Text>
                                </TooltipHost>
                              </Stack>
                            </SuccessorWrapper>
                          )
                        })}
                      {
                        !readOnly &&
                        <SuccessorWrapper>
                          <FontIcon iconName="AddFriend" style={{ fontSize: 12, marginRight: 10 }} />
                          <Text
                            variant="small"
                            className="linkAddFuncSuccessao"
                            styles={{ root: { display: 'none' } }}
                            onClick={() => handleAddFuncionario?.(idCicloReuniaoSucessaoGrupo, 'Médio Prazo - 3 a 5 anos')}>
                            Adicionar Funcionário
                          </Text>
                        </SuccessorWrapper>
                      }
                    </SuccessorCointainer>
                  </WrapperTd>
                </Td>
              )
            })
          }
        </Tr>
        <Tr>
          {
            sucessao?.map((suc, i) => {
              const { nivel4, idCicloReuniaoSucessaoGrupo } = suc;
              return (
                <Td color="#FB6B20" key={i}>
                  <WrapperTd>
                    <SuccessionTimeWrapper>
                      <Text variant="tiny">Longo Prazo - acima 5 anos</Text>
                    </SuccessionTimeWrapper>
                    <SuccessorCointainer>
                      {
                        nivel4.map((avaliadoN4, j) => {
                          const { flagInicial, avaliado, idCicloReuniaoSucessaoGrupoAvaliado, idCicloReuniaoAvaliado } = avaliadoN4;
                          return (
                            <SuccessorWrapper key={j} flagInicial={flagInicial}>
                              {
                                !readOnly &&
                                <WrapperRemove>
                                  <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ icon: { fontSize: 14 }, root: { width: 24, height: 24 } }}
                                    onClick={() => handleDelete?.(idCicloReuniaoSucessaoGrupo, idCicloReuniaoSucessaoGrupoAvaliado, 'Longo Prazo - acima 5 anos')}
                                  />
                                </WrapperRemove>
                              }
                              <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{ root: { cursor: flagInicial && handleClickFuncionario ? 'pointer' : 'normal', width: 165 } }}
                                onClick={() => {
                                  if (flagInicial) {
                                    handleClickFuncionario?.(idCicloReuniaoAvaliado)
                                  }
                                }}
                              >
                                <FontIcon iconName={flagInicial ? "PinnedSolid" : "Contact"} style={{ fontSize: 12, marginRight: 10, color: '#323130' }} />
                                <TooltipHost
                                  content={avaliado.funcionario.nomeCompleto}
                                  id={tooltipId}
                                  styles={funcStyles}
                                >
                                  <Text styles={funcStyles} variant="smallPlus">{avaliado.funcionario.nomeCompleto}</Text>
                                </TooltipHost>
                              </Stack>
                            </SuccessorWrapper>
                          )
                        })}
                      {
                        !readOnly &&
                        <SuccessorWrapper>
                          <FontIcon iconName="AddFriend" style={{ fontSize: 12, marginRight: 10 }} />
                          <Text
                            variant="small"
                            className="linkAddFuncSuccessao"
                            styles={{ root: { display: 'none' } }}
                            onClick={() => handleAddFuncionario?.(idCicloReuniaoSucessaoGrupo, 'Longo Prazo - acima 5 anos')}>
                            Adicionar Funcionário
                          </Text>
                        </SuccessorWrapper>
                      }
                    </SuccessorCointainer>
                  </WrapperTd>
                </Td>
              )
            })
          }
        </Tr>
      </tbody >
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          {renderVagas()}
        </tr>
      </thead>
      {renderSucessao()}
    </Table>
  )
}

export default Succession;
