// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';
import moment from "moment";

//FluentUI
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, ICommandBarStyles, Link, Pivot, Text } from 'office-ui-fabric-react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import Panel from "~/components/layout/Panel";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputCheckbox, InputDate, InputText } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { PrimaryButton } from '~/components/Buttons';
//Estilos
import {
    Wrapper,
    ListContainer,
    DefaultButton,
    ContainerContent,
} from "./styles";

import { Creators as getSalaryStructure } from "~/store/ducks/admin/salaryStructure";
import { Creators as getSalaryStructureByID } from "~/store/ducks/admin/salaryStructure";
import { Creators as addSalaryStructure } from "~/store/ducks/admin/salaryStructure";
import { Creators as editSalaryStructure } from "~/store/ducks/admin/salaryStructure";
import { Creators as delSalaryStructure } from "~/store/ducks/admin/salaryStructure";
import { SalaryStructureType, DataTypes as DataTypesSalary } from "~/store/ducks/admin/salaryStructure/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import Dropdown from "~/components/layout/Dropdown";
import { MenuItem } from "@material-ui/core";
import PanelDisplay from "~/components/PanelDisplay";
import { PivotItem } from "@fluentui/react";
import { GroupItemDoisTerco, GroupItems, GroupItemUmTerco, TitleField } from "~/components/FormGroup";

import TableSalary from './TableSalary';
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

interface ISalaryStruturesState {
    columns: IColumn[];
    items: SalaryStructureType[];
    isPanelOpen: boolean;
    isPanelDisplayOpen: boolean;
    isPanelEditOpen: boolean;
    inicialValues: SalaryStructureType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    search: string;
    dataTabSelected: number | null;
};

interface IPropsSalaryStrutures {
    salary: DataTypesSalary;
    setCurrentPage: (page: Page) => void;
    getSalaryStructure: (search?: string) => void;
    getSalaryStructureByID: (idES: number) => void;
    addSalaryStructure: (salary: SalaryStructureType) => void;
    editSalaryStructure: (salary: SalaryStructureType) => void;
    delSalaryStructure: (idES: number) => void;

}

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Estruturas Salariais", isCurrentItem: true },
];

const initialValues: SalaryStructureType = {
    idES: null,
    codFolha: '',
    nomeES: '',
    tipoES: '',
    horasTrab: null,
    flagAtiva: true,
    vigencia: null,
};

class SalaryStructures extends Component<IPropsSalaryStrutures, ISalaryStruturesState> {
    private formRef: any;
    private formEditRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: IPropsSalaryStrutures) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'ID',
                ariaLabel: 'ID da estrutura salarial',
                fieldName: 'idES',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column2',
                name: 'Nome',
                fieldName: 'nomeES',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            }
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            isPanelDisplayOpen: false,
            isPanelEditOpen: false,
            selectionDetails: "",
            inicialValues: initialValues,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            search: "",
            dataTabSelected: null
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.formRef = React.createRef();
        this.formEditRef = React.createRef();
        this.inputSearch = React.createRef();
    };

    componentDidMount() {
        const page: Page = {
            key: 'estruturasalarial',
            pages: itemsBreadCrumb
        };
        this.props.setCurrentPage(page);
        this.props.getSalaryStructure();
    };

    componentDidUpdate(prevProps: IPropsSalaryStrutures, _: any) {
        if (this.state.isPanelOpen) {
            if (prevProps.salary.success !== this.props.salary.success) {
                if (this.props.salary.success) {
                    this.setState({ isPanelOpen: false })
                }
            }
        }

        if (this.props.salary.itemSelected.success !== prevProps.salary.itemSelected.success) {
            if (this.props.salary.itemSelected.success) {
                this.setState({ inicialValues: this.props.salary.itemSelected.item!, isPanelEditOpen: false })
            }
        }

        if (this.props.salary.itemSelected.error !== prevProps.salary.itemSelected.error) {
            if (this.props.salary.itemSelected.error) {
                this.setState({ isPanelOpen: false, inicialValues: initialValues })
            }
        }

    }

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    handleSubmitEdit = () => {
        if (this.formEditRef.current) {
            this.formEditRef.current.handleSubmit();
        };
    };

    _onItemInvoked = (item: SalaryStructureType): void => {
        this.openEdit();
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        const selectItem: number = (this._selection.getSelection()[0] as SalaryStructureType).idES!;
        this.props.getSalaryStructureByID(selectItem)
        this.setState({ isPanelDisplayOpen: true });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    excludeSalary = () => {
        this.props.delSalaryStructure((this._selection.getSelection()[0] as SalaryStructureType).idES!);
        this.setState({ isDialogExcludeOpen: false });
        this._selection.setAllSelected(false);
    };

    //#region  Funções do filtro

    handleSearch = (e: any) => {
        const salaryStructures = this.props.salary.data;

        const text = e.target.value;

        let items = text
            ? salaryStructures.filter(
                item =>
                    item.nomeES!.toLowerCase().indexOf(text.toLowerCase()) > -1
            )
            : salaryStructures;

        this.setState({ items, isFiltering: true, search: text });
    };

    //#endregion

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                </>
            );
        }
    };

    render() {
        const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, search, isPanelEditOpen, isPanelDisplayOpen } = this.state;
        const { salary, getSalaryStructure, addSalaryStructure, editSalaryStructure } = this.props;
        const { item: salarySelected } = salary.itemSelected
        const dialogContentProps: IDialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                <Wrapper>
                    <ContainerContent>
                        <HeaderPage
                            title="Estruturas Salariais"
                            leftItems={
                                <>
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Add' }}
                                        disabled={salary.loadingData}
                                        text="Adicionar Estrutura"
                                        onClick={() => this.setState(
                                            {
                                                isPanelOpen: true,
                                                inicialValues: initialValues
                                            }
                                        )}
                                    />
                                    {this.commandBarRender()}
                                </>
                            }
                            rightItems={
                                <>
                                    <SearchBox
                                        placeholder="Pesquisar"
                                        value={search}
                                        onChange={(e) => this.handleSearch(e)}
                                        // onKeyUp={(e) => this.filter(e.key)}
                                        componentRef={this.inputSearch}
                                        styles={{
                                            root:
                                            {
                                                border: 'none',
                                                width: 200,
                                                marginRight: 1
                                            }
                                        }}
                                    />
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: 'Refresh' }}
                                        text="Atualizar"
                                        onClick={() => getSalaryStructure()}
                                    />
                                </>
                            }
                        />
                        {!salary.loadingData && salary.data.length === 0 ?
                            <NoItems
                                error={salary.error}
                                text="Não há estrururas salariais cadastradas"
                                icon="Money"
                            />
                            :
                            <ListContainer>
                                <ShimmeredDetailsList
                                    items={isFiltering ? items : salary.data}
                                    enableShimmer={salary.loadingData}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    getKey={this._getKey}
                                    selectionPreservedOnEmptyClick={true}
                                    setKey="single"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onItemInvoked={this._onItemInvoked}
                                />
                            </ListContainer>
                        }
                    </ContainerContent>
                </Wrapper>

                <Panel
                    title={"Nova Estrutura Salarial"}
                    open={isPanelOpen}
                    onClose={() => this.cancelPanel()}
                    footer={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                            <PrimaryButton onClick={this.handleSubmit} isLoading={salary.loadingAction} text="Salvar" />
                        </Stack>
                    }
                >
                    {
                        salary.itemSelected.loading ?
                            <Spinner size={SpinnerSize.medium} styles={{ root: { height: '100%' } }} />
                            :
                            <Formik
                                innerRef={this.formRef}
                                initialValues={inicialValues}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize
                                validationSchema={validationSalaryStructureSchema}
                                onSubmit={(values) => {
                                    addSalaryStructure(values);
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputText
                                            value={values.nomeES}
                                            onChange={(e) => { handleChange(e); setFieldError("nomeES", "") }}
                                            id="nomeES"
                                            error={errors.nomeES ? true : false}
                                            name="nomeES"
                                            type="text"
                                            label="Nome"
                                            helperText={errors.nomeES}
                                            className="mt-2"
                                            autoFocus
                                        />
                                        <Dropdown
                                            errors={errors.tipoES}
                                            label="Tipo de Pagamento"
                                            name="tipoES"
                                            values={values.tipoES}
                                            handleChange={(e: any) => { handleChange(e); setFieldError("tipoES", "") }}
                                            errorText={errors.tipoES}
                                        >
                                            <MenuItem value={'M'}>Mensalista</MenuItem>
                                            <MenuItem value={'H'}>Horista</MenuItem>
                                        </Dropdown>
                                        <InputText
                                            value={values.horasTrab ?? ""}
                                            onChange={(e) => { handleChange(e); setFieldError("horasTrab", "") }}
                                            id="horasTrab"
                                            error={errors.horasTrab ? true : false}
                                            name="horasTrab"
                                            type="number"
                                            label="Horas trabalhadas no mês"
                                            helperText={errors.horasTrab}
                                            inputProps={{ maxLength: 3 }}
                                            className="mt-2"
                                        />
                                        <InputText
                                            value={values.codFolha ?? ""}
                                            onChange={(e) => { handleChange(e); setFieldError("codFolha", "") }}
                                            id="codFolha"
                                            error={errors.codFolha ? true : false}
                                            name="codFolha"
                                            type="number"
                                            label="Código integração Folha"
                                            helperText={errors.codFolha}
                                            inputProps={{ maxLength: 4 }}
                                            className="mt-2"
                                        />
                                        <InputDate
                                            id="vigencia"
                                            error={errors.vigencia ? true : false}
                                            name="vigencia"
                                            label="Vigência"
                                            value={values?.vigencia ? moment(values.vigencia) : null}
                                            onChange={value => setFieldValue('vigencia', value)}
                                            className="mt-2"
                                            helperText={errors.vigencia}
                                        />
                                    </form>
                                )}
                            </Formik>
                    }
                </Panel>

                <PanelDisplay
                    open={isPanelDisplayOpen}
                    isLoading={false}
                    onClose={() => this.setState({ isPanelDisplayOpen: false })}
                    panelHeader={
                        <Text variant="xxLarge">{salarySelected?.nomeES}</Text>
                    }

                >
                    <Pivot aria-label="Basic Pivot Example" styles={{ root: { marginBottom: 32 } }}>
                        <PivotItem headerText="Dados Principais">
                            <GroupItems title="Identificação" hasMultiItems>
                                <GroupItems>
                                    <GroupItemUmTerco>
                                        <TitleField text="Id" />
                                        <TitleField text={String(salarySelected?.idES) ?? ''} isValue />
                                    </GroupItemUmTerco>
                                    <GroupItemDoisTerco>
                                        <TitleField text="Nome" />
                                        <TitleField text={salarySelected?.nomeES ?? ''} isValue />
                                    </GroupItemDoisTerco>
                                </GroupItems>
                                <GroupItems>
                                    <GroupItemUmTerco>
                                        <TitleField text="Tipo de Pagamento" />
                                        <TitleField text={convertTipoES(salarySelected?.tipoES)} isValue />
                                    </GroupItemUmTerco>
                                    <GroupItemDoisTerco>
                                        <TitleField text="Carga Horária Mensal" />
                                        <TitleField text={salarySelected?.horasTrab} isValue />
                                    </GroupItemDoisTerco>
                                </GroupItems>
                                <GroupItems>
                                    <GroupItemUmTerco>
                                        <TitleField text="Ativa" />
                                        <TitleField text={salarySelected?.flagAtiva ? 'SIM' : 'NÃO'} isValue />
                                        <Link
                                            styles={{ root: { fontSize: 14 } }}
                                            onClick={() => this.setState({ isPanelEditOpen: true })}
                                        >
                                            Gerenciar identificação
                                    </Link>
                                    </GroupItemUmTerco>
                                </GroupItems>
                            </GroupItems>
                        </PivotItem>
                        <PivotItem headerText="GS">
                            <TableSalary
                                idES={salarySelected?.idES!}
                                salaryState={salary}
                            />
                        </PivotItem>
                    </Pivot>
                </PanelDisplay>

                <PanelDisplay
                    open={isPanelEditOpen}
                    onClose={() => this.setState({ isPanelEditOpen: false })}
                    isLoading={false}
                    panelHeader={<Text variant="xxLarge">{salarySelected?.nomeES}</Text>}
                    footer={
                        <>
                            <DefaultButton styles={{ root: { marginRight: 10 } }} onClick={() => this.setState({ isPanelEditOpen: false })}>Cancelar</DefaultButton>
                            <PrimaryButton onClick={this.handleSubmitEdit} text="Salvar" isLoading={salary.loadingAction} />
                        </>
                    }
                >
                    <Formik
                        innerRef={this.formEditRef}
                        initialValues={inicialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize
                        validationSchema={validationSalaryStructureEditSchema}
                        onSubmit={(values) => {
                            editSalaryStructure(values);
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <InputText
                                    value={values.nomeES}
                                    onChange={(e) => { handleChange(e); setFieldError("nomeES", "") }}
                                    id="nomeES"
                                    error={errors.nomeES ? true : false}
                                    name="nomeES"
                                    type="text"
                                    label="Nome"
                                    helperText={errors.nomeES}
                                    className="mt-2"
                                    autoFocus
                                />
                                <Dropdown
                                    errors={errors.tipoES}
                                    label="Tipo de Pagamento"
                                    name="tipoES"
                                    values={values.tipoES}
                                    handleChange={(e: any) => { handleChange(e); setFieldError("tipoES", "") }}
                                    errorText={errors.tipoES}
                                >
                                    <MenuItem value={'M'}>Mensalista</MenuItem>
                                    <MenuItem value={'H'}>Horista</MenuItem>
                                </Dropdown>
                                <InputText
                                    value={values.horasTrab ?? ""}
                                    onChange={(e) => { handleChange(e); setFieldError("horasTrab", "") }}
                                    id="horasTrab"
                                    error={errors.horasTrab ? true : false}
                                    name="horasTrab"
                                    type="number"
                                    label="Horas trabalhadas no mês"
                                    helperText={errors.horasTrab}
                                    className="mt-2"
                                />
                                <InputText
                                    value={values.codFolha ?? ""}
                                    onChange={(e) => { handleChange(e); setFieldError("codFolha", "") }}
                                    id="codFolha"
                                    error={errors.codFolha ? true : false}
                                    name="codFolha"
                                    type="number"
                                    label="Código integração Folha"
                                    helperText={errors.codFolha}
                                    className="mt-2"
                                />
                                <InputCheckbox
                                    checked={values.flagAtiva}
                                    onChange={handleChange}
                                    name="flagAtiva"
                                    color="primary"
                                    label="Estrutura ativa"
                                />
                            </form>
                        )}
                    </Formik>
                </PanelDisplay>

                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                    <DeleteButton onClick={() => this.excludeSalary()} text="Excluir" />
                </CustomDialog>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: SalaryStructureType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.salary.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
        return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
    };

}

const convertTipoES = (tipo: string | undefined): string => {
    switch (tipo) {
        case 'M':
            return 'Mensalista';
        case 'H':
            return 'Horista';
        default:
            return '';
    }
}

/**
 * Validação do formulário
 */
const validationSalaryStructureSchema = yup.object().shape({
    nomeES: yup.string()
        .required("Campo obrigatório"),
    tipoES:
        yup.string()
            .required("Campo obrigatório"),
    horasTrab:
        yup.string()
            .nullable()
            .max(3, "As horas trabalhadas não podem ter mais do que 3 dígitos")
            .required("Campo obrigatório"),
    codFolha:
        yup.string()
            .max(4, "O código da folha não pode ter mais do que 4 dígitos")
            .required("Campo obrigatório"),
    vigencia:
        yup.date()
            .nullable()
            .typeError("Data inválida")
            .required("Campo obrigatório")
});

const validationSalaryStructureEditSchema = yup.object().shape({
    nomeES: yup.string()
        .required("Campo obrigatório"),
    tipoES:
        yup.string()
            .required("Campo obrigatório"),
    horasTrab:
        yup.string()
            .max(3, "As horas trabalhadas não podem ter mais do que 3 dígitos")
            .required("Campo obrigatório"),
    codFolha:
        yup.string()
            .max(4, "O código da folha não pode ter mais do que 4 dígitos")
            .required("Campo obrigatório"),
    flagAtiva:
        yup.bool()
            .notRequired()

});

const mapStateToProps = (state: RootState) => ({
    salary: state.salaryStructureReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getSalaryStructure,
    ...getSalaryStructureByID,
    ...addSalaryStructure,
    ...editSalaryStructure,
    ...delSalaryStructure,
    ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalaryStructures);
