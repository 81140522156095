// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";



//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";

import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";


import { Page } from "~/store/ducks/home/types";


import {  OutlinedButton } from "~/components/Buttons";
import HeaderPage from "~/components/layout/HeaderPage";

import { PrimaryButton } from "~/components/Buttons";

//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as solicitarCartasDevolutiva } from "~/store/ducks/tasks";
import { Creators as statusCartasDevolutiva } from "~/store/ducks/tasks";
import { Creators as downloadCartaDevolutiva } from "~/store/ducks/tasks";
import { Creators as realizarDevolutiva } from "~/store/ducks/tasks";
import { Creators as cancelarDevolutiva } from "~/store/ducks/tasks";
import { Creators as finishTask } from "~/store/ducks/tasks";
import {
  DataTypes as DataTypesTask,
  TaskDevolutivaMerito,
} from "~/store/ducks/tasks/types";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { RootState } from "~/store/ducks";
import PainelHeader from "~/components/layout/PainelHeader";
import { convertStatusTarefa } from "~/utils/tasks";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import { IconButton, Persona, PersonaSize, Text } from "@fluentui/react";
import Colors from "~/assets/js/colors";


import { RouteComponentProps, withRouter } from "react-router";
//#endregion

interface IDevolutivaMeritoState {
  columns: IColumn[];
  items: TaskDevolutivaMerito[];
  selectionDetails: string;
  selectionCount: number;
  isStatusUpdating: boolean;
}

interface IPropsDevolutivaMerito extends RouteComponentProps {
  taskState: DataTypesTask;
  solicitarCartasDevolutiva: (idTarefa: number, cartas: number[]) => void;
  statusCartasDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ) => void;
  downloadCartaDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ) => void;
  realizarDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ) => void;
  cancelarDevolutiva: (
    idTarefa: number,
    idTarefaDevMerito: number | string
  ) => void;
  finishTask: (idTarefa: number) => void;
  setCurrentPage: (page: Page) => void;
  history: RouteComponentProps["history"];
}

const baseURL = process.env.REACT_APP_BASE_URL;

class DevolutivaMerito extends Component<
  IPropsDevolutivaMerito,
  IDevolutivaMeritoState
> {
  private _selection: Selection;

  constructor(props: IPropsDevolutivaMerito) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "",
        fieldName: "",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: TaskDevolutivaMerito) => {
          const { nomeCompleto, imagem } = item.meritoFun.funcionario ?? {};
          // const { imagem, nomeCompleto, status, isVisible } = values.funcionario ?? {};
          // const imagePath = `${baseURL}uploads/${imagem}`;
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto ?? "");

          return (
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              styles={{ root: { whiteSpace: "break-spaces", width: "100%" } }}
            >
              <Stack horizontal verticalAlign="center">
                <Persona
                  imageUrl={imagePath}
                  imageInitials={initials}
                  initialsColor={Colors.primary}
                  size={PersonaSize.size32}
                  // presence={getPresence(item.statusFuncionario)}
                  styles={{
                    root: {
                      width: 32,
                      marginRight: 10,
                      selectors: {
                        "& .ms-Persona-initials": {
                          filter: "grayscale(0)",
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          );
        },
      },
      {
        key: "column2",
        name: "Matrícula",
        fieldName: "meritoFun.idFuncionario",
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: TaskDevolutivaMerito) => (
          <Text>{item.meritoFun.idFuncionario}</Text>
        ),
      },
      {
        key: "column3",
        name: "Nome",
        fieldName: "meritoFun.funcionario.nomeCompleto",
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: TaskDevolutivaMerito) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.meritoFun.funcionario.nomeCompleto}
          </Text>
        ),
      },
      {
        key: "column4",
        name: "Cargo",
        fieldName: "meritoFun.cargoOrigem.titulo",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: TaskDevolutivaMerito) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.meritoFun.cargoOrigem.titulo}
          </Text>
        ),
      },
      {
        key: "column5",
        name: "Carta",
        fieldName: "carta",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: TaskDevolutivaMerito) => {
          if (item.meritoFun.statusCarta === "P") {
            return (
              <Spinner
                size={SpinnerSize.small}
                styles={{
                  root: {
                    alignItems: "flex-start",
                    marginLeft: 7,
                    height: "100%",
                  },
                }}
              />
            );
          } else if (item.meritoFun.statusCarta === "G") {
            return (
              <IconButton
                iconProps={{ iconName: "DownloadDocument" }}
                onClick={() =>
                  this.handleBaixarCarta([item.idTarefaDevMerito as number])
                }
              />
            );
          }
        },
      },
      {
        key: "column6",
        name: "Status Devolutiva",
        fieldName: "status",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: TaskDevolutivaMerito, i = 0) => {
          const idTarefa = this.props.taskState.selected?.idTarefa;

          if (
            item.meritoFun.statusCarta !== null &&
            this.props.taskState.selected?.flagEdicao
          ) {
            if (item.meritoFun.statusCarta === "P") {
              return <Spinner size={SpinnerSize.small} />;
            }
            if (item.meritoFun.statusCarta === "G") {
              if (!item.flagBaixouCarta) {
                return (
                  <OutlinedButton
                    text="Baixar Carta"
                    iconProps={{ iconName: "DownloadDocument" }}
                    styles={{ root: { minWidth: 150 } }}
                    onClick={() =>
                      this.handleBaixarCarta([item.idTarefaDevMerito as number])
                    }
                  />
                );
              } else if (!item.flagRealizouDevolutiva) {
                return (
                  <PrimaryButton
                    text="Realizar Devolutiva"
                    onClick={() =>
                      this.props.realizarDevolutiva(
                        idTarefa!,
                        item.idTarefaDevMerito
                      )
                    }
                  />
                );
              } else {
                return (
                  <DefaultButton
                    text="Cancelar Devolutiva"
                    styles={{ root: { minWidth: 168 } }}
                    onClick={() =>
                      this.props.cancelarDevolutiva(
                        idTarefa!,
                        item.idTarefaDevMerito
                      )
                    }
                  />
                );
              }
            }
          }
        },
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      selectionCount: 0,
      isStatusUpdating: false,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidMount() {
    const idTarefa = this.props.taskState.selected?.idTarefa;
    const cartaIndisponivel = this.props.taskState.selected?.TarefaDevMerito?.filter(
      (item) =>
        item.meritoFun.statusCarta === null ||
        item.meritoFun.statusCarta === "P"
    );

    if (cartaIndisponivel !== undefined && cartaIndisponivel?.length > 0) {
      cartaIndisponivel?.forEach((item) => {
        this.props.statusCartasDevolutiva(idTarefa!, item.idTarefaDevMerito);
      });
    }
  }

  componentDidUpdate(prevProps: IPropsDevolutivaMerito, _: any) {
    const { redirectTo: prevRedirectTo } = prevProps.taskState;
    const { redirectTo: nextRedirectTo } = this.props.taskState;
    const idTarefa = this.props.taskState.selected?.idTarefa;

    if (prevRedirectTo !== nextRedirectTo) {
      if (nextRedirectTo) {
        this.props.history.push(nextRedirectTo);
      }
    }

    if (
      !this.state.isStatusUpdating &&
      !!idTarefa &&
      !this.props.taskState.selected?.flagFinalizada
    ) {
      this.setState({ isStatusUpdating: true });
      setTimeout(() => {
        if (
          prevProps.taskState.selected?.TarefaDevMerito !==
          this.props.taskState.selected?.TarefaDevMerito
        ) {
          const cartasSolicitadas =
            this.props.taskState.selected?.TarefaDevMerito?.filter(
              (item) =>
                item.meritoFun.statusCarta === null ||
                item.meritoFun.statusCarta === "P"
            ) ?? [];
          if (cartasSolicitadas.length > 0) {
            cartasSolicitadas.forEach((item) => {
              this.props.statusCartasDevolutiva(
                idTarefa,
                item.idTarefaDevMerito
              );
            });
          }
        }
        this.setState({ isStatusUpdating: false });
      }, 20000); // 20 SEC
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  handleBaixarCarta = (allIdsMeritoFuncionario: number[]) => {
    const idTarefa = this.props.taskState.selected?.idTarefa;
    if (idTarefa) {
      allIdsMeritoFuncionario.forEach((idMeritoFunc) => {
        this.props.downloadCartaDevolutiva(idTarefa, idMeritoFunc);
      });
    }
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;

    if (selectionCount > 0) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "ChromeClose" }}
            text="Limpar Seleção"
            onClick={() => this._selection.setAllSelected(false)}
          />
          {(this._selection.getSelection() as TaskDevolutivaMerito[]).some(
            (item) => item.meritoFun.statusCarta === "G"
          ) && (
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "DownloadDocument" }}
              text="Baixar Cartas"
              onClick={() =>
                this.handleBaixarCarta(
                  (this._selection.getSelection() as TaskDevolutivaMerito[])
                    .filter((item) => item.meritoFun.statusCarta === "G")
                    .map((item) => item.idTarefaDevMerito) as number[]
                )
              }
            />
          )}
        </>
      );
    }
  };

  render() {
    const { columns } = this.state;
    const { taskState, finishTask } = this.props;
    const { TarefaDevMerito } = taskState.selected ?? {};

    return (
      <>
        <Wrapper>
          <PainelHeader
            title={`Devolutiva do Mérito de 2022`}
            icon={{ svgIcon: "/static/icons/ciclo.svg" }}
            labelGroups={[
              {
                label: "Líder",
                value: taskState.selected?.funcionario.nomeCompleto ?? "",
                icon: "HomeGroup",
                size: "full",
              },
              {
                label: "Status",
                value: convertStatusTarefa(
                  taskState.selected?.statusTarefa ?? ""
                ),
                icon: "Flag",
                size: "full",
              },
            ]}
          />
          <ContainerContent>
            <Stack
              styles={{ root: { width: "fit-content", marginBottom: 16 } }}
            >
              {taskState.selected?.habilitaFinalizarTarefa &&
                taskState.selected?.flagEdicao && (
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Send" }}
                    // disabled={vinculos.loadingData}
                    onClick={() => finishTask(taskState.selected?.idTarefa!)}
                    text="Finalizar Tarefa"
                  />
                )}
            </Stack>
            <HeaderPage
              title="Funcionários"
              titleSize="14px"
              leftItems={
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "CheckList" }}
                    text="Selecionar Todos"
                    onClick={() => {
                      this._selection.setAllSelected(true);
                      this._getSelectionDetails();
                    }}
                  />

                  {this.commandBarRender()}
                </>
              }
            />
            {/* {!vinculos.loadingData && vinculos.data.length === 0 ? (
              <NoItems
                error={vinculos.error}
                text="Não há funcionarios"
                icon="Group"
              />
            ) : ( */}
            <ListContainer>
              <ShimmeredDetailsList
                items={TarefaDevMerito ?? []}
                enableShimmer={taskState.loadingData}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                selection={this._selection}
                getKey={this._getKey}
                selectionPreservedOnEmptyClick={true}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={this._onRenderRow}
              />
            </ListContainer>
            {/* )} */}
          </ContainerContent>
        </Wrapper>
      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.cell = {
          display: "flex",
          alignItems: "center",
          whiteSpace: "break-spaces",
        };
        customStyles.check = {
          height: "100% !important",
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TaskDevolutivaMerito[] = [];
    items = this.props.taskState.selected?.TarefaDevMerito ?? [];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }
}

/**
 * Validação do formulário
 */

/*
const validationVinculoSchema = yup.object().shape({
  descVinculo: yup
    .string()
    .max(100, "Máximo de 100 caracteres")
    .required("Campo obrigatório"),
  nomeAmigavel: yup
    .string()
    .max(100, "Máximo de 100 caracteres")
    .required("Campo obrigatório"),
  elegivelBonus: yup.bool().nullable(),
});
*/

/*
const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};
*/

/*
const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Devolutiva Mérito", isCurrentItem: true },
];
*/
const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...solicitarCartasDevolutiva,
      ...statusCartasDevolutiva,
      ...downloadCartaDevolutiva,
      ...realizarDevolutiva,
      ...cancelarDevolutiva,
      ...finishTask,
      ...setCurrentPage,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DevolutivaMerito)
);
