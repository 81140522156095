// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

//FluentUI
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  IconButton,
  ICommandBarStyles,
  
  PersonaSize,
  Icon,
} from "office-ui-fabric-react";
//Componentes
import PainelHeader from "~/components/layout/PainelHeader";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import NoItems from "~/components/layout/NoItems";
//Estilos
import { Wrapper,  ContainerContent } from "./styles";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Creators as loadTeam } from "~/store/ducks/teamEvaluation";
import { Creators as getTasksById } from "~/store/ducks/tasks";
import { Creators as finalizaTarefaColetiva } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoMeta } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoAtitude } from "~/store/ducks/tasks";
import { Creators as editAvaliacaoColetivaResultadoFinal } from "~/store/ducks/tasks";
import {
  DataTypes as DataTypesTasks,
  TaskAvaliacaoColetivaFuncionario,
} from "~/store/ducks/tasks/types";
import { RootState } from "~/store/ducks";
import { convertStatusTarefa } from "~/utils/tasks";
import Accordion from "~/components/Accordion";
import { Persona } from "@fluentui/react";
import { getAbbreviatedName } from "~/utils/abbreviatedName";
import Colors from "~/assets/js/colors";
import { getPresence } from "~/utils/statusFunc";
import DialogAvaliacaoFinal from "./dialogs/dialogAvaliacaoFinal";
import {
  PerformanceBoard,
  PerformanceBoardTotal,
} from "~/components/PerformanceBoard";
import DialogPerformance from "./dialogs/dialogPerformance";
import { renderAvaliacao } from "~/pages/SelfEvaluationCollective/selfEvaluationCollectiveRender";
import DialogAtitudes from "./dialogs/dialogAtitudes";
//#endregion
//#region Types e Interfaces

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

type Filter = {
  isOpen?: boolean;
  filteredBy?: string[];
  departamento?: any;
};

interface ICollectiveEvaluationsState {
  columns: IColumn[];
  items: TaskAvaliacaoColetivaFuncionario[];
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  filter: Filter;
  modalPerformance: {
    isOpen: boolean;
    idTarefa: number | null;
    funcionario: TaskAvaliacaoColetivaFuncionario | null;
  };
  modalAtitudes: {
    isOpen: boolean;
    idTarefa: number | null;
    funcionario: TaskAvaliacaoColetivaFuncionario | null;
  };
  modalAvaliacaoFinal: {
    isOpen: boolean;
    idTarefa: number | null;
    funcionario: TaskAvaliacaoColetivaFuncionario | null;
  };
}

interface ICollectiveEvaluationProps extends RouteComponentProps {
  setCurrentPage: (page: Page) => void;
  loadTeam: (idCiclo: number) => void;
  finalizaTarefaColetiva: (idTarefa: number) => void;
  history: RouteComponentProps["history"];
  getTasksById: (idTarefa: number) => void;
  editAvaliacaoColetivaResultadoMeta: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadometas: string,
    comentariosMetas: string
  ) => void;
  editAvaliacaoColetivaResultadoAtitude: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    resultadoatitudes: string,
    comentariosAtitudes: string
  ) => void;
  editAvaliacaoColetivaResultadoFinal: (
    idTarefa: number,
    idTarefaAvaliacao: number,
    idTabPerformance: number | null,
    potencial: string,
    sucessao: string | null,
    comentariosGerais: string
  ) => void;
  match: any;
  taskState: DataTypesTasks;
}
//#endregion

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Workflow", isCurrentItem: false, url: "/workflow" },
  { text: "Avaliação do Time", isCurrentItem: true },
];

const page: Page = {
  key: "timeavaliacao",
  pages: itemsBreadCrumb,
};

const baseURL = process.env.REACT_APP_BASE_URL;

class CollectiveEvaluations extends Component<
  ICollectiveEvaluationProps,
  ICollectiveEvaluationsState
> {
  private _selection: Selection;

  constructor(props: ICollectiveEvaluationProps) {
    super(props);
    //#region Colunas
    //#endregion

    this.state = {
      columns: [],
      items: [],
      selectionDetails: "",
      isFiltering: false,
      selectionCount: 0,
      filter: {
        isOpen: false,
        filteredBy: [],
        departamento: {},
      },
      modalPerformance: {
        idTarefa: null,
        funcionario: null,
        isOpen: false,
      },
      modalAtitudes: {
        idTarefa: null,
        funcionario: null,
        isOpen: false,
      },
      modalAvaliacaoFinal: {
        idTarefa: null,
        funcionario: null,
        isOpen: false,
      },
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  allColumns = (): IColumn[] => [
    {
      key: "avatar",
      name: "",
      ariaLabel: "Avatar",
      fieldName: "nomeCompleto",
      minWidth: 30,
      maxWidth: 30,
      isSortedDescending: false,
      styles: {
        root: {
          position: "sticky",
          left: 0,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => (
        <Persona
          imageUrl={`${baseURL}uploads/${item.avaliacao.funcionario.imagem}`}
          imageInitials={getAbbreviatedName(
            item.avaliacao.funcionario.nomeCompleto
          )}
          initialsColor={Colors.primary}
          size={PersonaSize.size32}
          presence={getPresence(item.avaliacao.funcionario.status)}
          styles={{
            root: {
              width: 32,
              marginRight: 10,
              selectors: {
                "& .ms-Persona-initials": {
                  filter:
                    item.avaliacao.funcionario.status === "D"
                      ? "grayscale(1)"
                      : "grayscale(0)",
                },
              },
            },
          }}
        />
      ),
    },
    {
      key: "idFuncionario",
      name: "ID",
      ariaLabel: "Id funcionário",
      fieldName: "avaliacao.idFuncionario",
      isRowHeader: true,
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      isSortedDescending: false,
      styles: {
        root: {
          position: "sticky",
          left: 50,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onColumnClick: this._onColumnClick,
      onRender: (item: TaskAvaliacaoColetivaFuncionario) =>
        item.avaliacao.idFuncionario,
    },
    {
      key: "nomeCompleto",
      name: "Nome",
      fieldName: "avaliacao.funcionario.nomeCompleto",
      minWidth: 210,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      styles: {
        root: {
          position: "sticky",
          left: 120,
          top: 50,
          backgroundColor: "#FFFFFF",
          zIndex: 9,
        },
      },
      onRender: (item: TaskAvaliacaoColetivaFuncionario) =>
        item.avaliacao.funcionario.nomeCompleto,
    },
    {
      key: "tempoEmpresa",
      name: "Tempo",
      fieldName: "tempoEmpresa",
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        if (item.tempoEmpresa < 12) {
          return `${item.tempoEmpresa} m`;
        } else {
          return `${(item.tempoEmpresa / 12).toFixed(0)} a`;
        }
      },
    },
    {
      key: "area",
      name: "Área",
      fieldName: "area.nomeLocal",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => (
        <Text style={{ whiteSpace: "break-spaces" }}>
          {item.area?.nomeLocal}
        </Text>
      ),
    },
    {
      key: "cargo",
      name: "Cargo",
      fieldName: "cargo.titulo",
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => (
        <Text style={{ whiteSpace: "break-spaces" }}>{item.cargo?.titulo}</Text>
      ),
    },
    {
      key: "performance",
      name: "Avaliação Performance",
      fieldName: "avaliacao.resultadometas",
      ariaLabel: "resultadometas",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        const readOnly = !this.props.taskState.selected?.flagEdicao;

        if (item.avaliacao.funcionario.status === "D") {
          return "";
        }

        return (
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            {renderAvaliacao(item.avaliacao?.resultadometas ?? "")}

            {!readOnly && (
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "Edit" }}
                style={{ marginLeft: 5 }}
                disabled={readOnly}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.setState({
                    modalPerformance: {
                      idTarefa: this.props.taskState.selected?.idTarefa ?? null,
                      isOpen: true,
                      funcionario: item,
                    },
                  })
                }
              />
            )}
          </Stack>
        );
      },
    },
    {
      key: "resultadoatitudes",
      name: "Atitudes Vencedoras",
      fieldName: "avaliacao.resultadoatitudes",
      ariaLabel: "Atitudes Vencedoras",
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        const readOnly = !this.props.taskState.selected?.flagEdicao;

        if (item.avaliacao.funcionario.status === "D") {
          return "";
        }

        return (
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            {renderAvaliacao(item.avaliacao?.resultadoatitudes ?? "")}

            {!readOnly && (
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "Edit" }}
                style={{ marginLeft: 5 }}
                disabled={readOnly}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.setState({
                    modalAtitudes: {
                      idTarefa: this.props.taskState.selected?.idTarefa ?? null,
                      isOpen: true,
                      funcionario: item,
                    },
                  })
                }
              />
            )}
          </Stack>
        );
      },
    },
    {
      key: "performancegestor",
      name: "Avaliação Final",
      fieldName: "avaliacao.idTabPerformance",
      ariaLabel: "performancegestor",
      isRowHeader: true,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        const readOnly = !this.props.taskState.selected?.flagEdicao;

        const performance = this.props.taskState.selected?.TarefaAvaliacaoColetiva?.resumo.performances.find(
          (per) => per.idTabPerformance === item.avaliacao?.idTabPerformance
        );

        return (
          <Stack horizontal verticalAlign="center">
            {performance?.cor && (
              <Icon
                iconName="CircleFill"
                styles={{ root: { color: performance.cor, marginRight: 8 } }}
              />
            )}
            <p style={{ whiteSpace: "break-spaces" }}>
              {performance?.descNivel}
            </p>

            {readOnly ? (
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "View" }}
                style={{ marginLeft: 5 }}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.props.history.push(
                    `/tarefas/${this.props.taskState.selected?.idTarefa}/avaliacao/${item.idCicloAvaliacao}`
                  )
                }
              />
            ) : (
              item.avaliacao.funcionario.status === "D" && (
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  style={{ marginLeft: 5 }}
                  disabled={readOnly}
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() =>
                    this.setState({
                      modalAvaliacaoFinal: {
                        isOpen: true,
                        funcionario: item,
                        idTarefa:
                          this.props.taskState.selected?.idTarefa ?? null,
                      },
                    })
                  }
                />
              )
            )}
          </Stack>
        );
      },
    },
    {
      key: "potencial",
      name: "Potencial",
      fieldName: "avaliacao.potencial",
      ariaLabel: "Potencial",
      isRowHeader: true,
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        const readOnly = !this.props.taskState.selected?.flagEdicao;

        if (item.avaliacao.funcionario.status === "D") {
          return "";
        }

        return (
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: "100%", whiteSpace: "break-spaces" } }}
          >
            <Text styles={{ root: { textAlign: "left", width: "100%" } }}>
              {item.avaliacao?.potencial}
            </Text>
            {!readOnly && (
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "Edit" }}
                style={{ marginLeft: 5 }}
                disabled={readOnly}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.setState({
                    modalAvaliacaoFinal: {
                      isOpen: true,
                      funcionario: item,
                      idTarefa: this.props.taskState.selected?.idTarefa ?? null,
                    },
                  })
                }
              />
            )}
          </Stack>
        );
      },
    },
    {
      key: "sucessao",
      name: "Sucessão",
      fieldName: "avaliacao.sucessao",
      ariaLabel: "Sucessão",
      isRowHeader: true,
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      data: "string",
      onRender: (item: TaskAvaliacaoColetivaFuncionario) => {
        if (item.avaliacao.funcionario.status === "D") {
          return "";
        }

        return (
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: "100%" } }}
          >
            <Text
              styles={{
                root: {
                  textAlign: "left",
                  width: "100%",
                  whiteSpace: "break-spaces",
                },
              }}
            >
              {item.avaliacao?.sucessao}
            </Text>
          </Stack>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.setCurrentPage(page);

    this.setState({ columns: this.allColumns() });
    // this.props.getTasksById(id)
  }

  componentDidUpdate(prevProps: ICollectiveEvaluationProps, _: any) {
    if (
      prevProps.taskState.selected?.flagEdicao !==
      this.props.taskState.selected?.flagEdicao
    ) {
      this.setState({ columns: this.allColumns() });
    }

    if (
      prevProps.taskState.successAction !== this.props.taskState.successAction
    ) {
      if (this.props.taskState.successAction) {
        this.setState({
          modalAvaliacaoFinal: {
            idTarefa: null,
            funcionario: null,
            isOpen: false,
          },
          modalPerformance: {
            idTarefa: null,
            funcionario: null,
            isOpen: false,
          },
          modalAtitudes: {
            idTarefa: null,
            funcionario: null,
            isOpen: false,
          },
        });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  //#endregion

  render() {
    const {
      isFiltering,
      items,
      columns,
      modalAvaliacaoFinal,
      modalPerformance,
      modalAtitudes,
    } = this.state;
    const { taskState, finalizaTarefaColetiva } = this.props;
    const { selected } = taskState;
    const tarefaColetiva = selected?.TarefaAvaliacaoColetiva;
    const idTarefa = selected?.idTarefa!;

    return (
      <>
        <Wrapper>
          <PainelHeader
            title={selected?.tituloTarefa ?? "AVALIAÇÃO COLETIVA"}
            icon={{ icon: "Equalizer" }}
            labelGroups={[
              // {
              //   label: "Ciclo",
              //   value: tarefaColetiva?.time.ciclo?.ano ? String(teams?.time.ciclo?.ano) : '',
              //   icon: "HomeGroup"
              // },
              {
                label: "Líder",
                value: selected?.funcionario?.nomeCompleto ?? "",
                icon: "Flag",
              },
              {
                label: "Status",
                value: convertStatusTarefa(selected?.statusTarefa ?? ""),
                icon: "Flag",
                size: "full",
              },
            ]}
          />
          {!selected?.flagFinalizada ? (
            <Stack horizontal styles={{ root: { padding: "10px 20px 0px" } }}>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Send" }}
                text="Finalizar Avaliação"
                onClick={() => finalizaTarefaColetiva(idTarefa)}
              />
            </Stack>
          ) : (
            <Stack horizontal styles={{ root: { padding: "10px 20px 0px" } }}>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "ChromeClose" }}
                text="Fechar"
                onClick={() => this.props.history.push("/workflow")}
              />
            </Stack>
          )}
          <ContainerContent>
            <Accordion title="Resumo">
              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { overflow: "auto" } }}
              >
                <PerformanceBoardTotal
                  color="red"
                  title="AVALIADOS"
                  value={tarefaColetiva?.resumo.avaliados ?? 0}
                  total={tarefaColetiva?.funcionarios.length ?? 0}
                />
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                  {tarefaColetiva?.resumo.performances
                    .filter((perf) => !perf.flagBloquearAvaliacao)
                    .map((performance) => (
                      <PerformanceBoard
                        key={performance.nivel}
                        color={performance.cor}
                        title={performance.descNivel}
                        value={performance.qtde}
                        percent={performance.perc}
                      />
                    ))}
                </Stack>
              </Stack>
            </Accordion>
            <Accordion title="Funcionários">
              {!taskState.loadingData &&
              tarefaColetiva?.funcionarios.length === 0 ? (
                <NoItems
                  error={taskState.error}
                  text="Não há funcionários para avaliar"
                  icon="Teamwork"
                />
              ) : (
                <ShimmeredDetailsList
                  items={
                    isFiltering ? items : tarefaColetiva?.funcionarios ?? []
                  }
                  enableShimmer={taskState.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  onRenderRow={this._onRenderRow}
                  className="teamTable"
                />
              )}
            </Accordion>
          </ContainerContent>
        </Wrapper>

        <DialogAvaliacaoFinal
          isOpen={modalAvaliacaoFinal.isOpen}
          loadingData={taskState.loadingAction}
          idTarefaAvaliacao={
            modalAvaliacaoFinal.funcionario?.idTarefaAvaliacao!
          }
          idTarefa={modalAvaliacaoFinal.idTarefa}
          performances={tarefaColetiva?.resumo.performances ?? []}
          onClose={() =>
            this.setState({
              modalAvaliacaoFinal: {
                isOpen: false,
                funcionario: null,
                idTarefa: null,
              },
            })
          }
          onSuccess={(values) => {
            this.props.editAvaliacaoColetivaResultadoFinal(
              idTarefa,
              modalAvaliacaoFinal.funcionario?.idTarefaAvaliacao!,
              modalAvaliacaoFinal.funcionario?.avaliacao.funcionario.status ===
                "D"
                ? values.idTabPerformance
                : null,
              values.potencial,
              values.sucessao,
              values.comentariosGerais
            );
          }}
          readonly={false}
        />

        <DialogPerformance
          isOpen={modalPerformance.isOpen}
          loadingData={taskState.loadingAction}
          success={taskState.successAction}
          idTarefaAvaliacao={modalPerformance.funcionario?.idTarefaAvaliacao!}
          idTarefa={modalPerformance.idTarefa}
          nomeCompleto={
            modalPerformance.funcionario?.avaliacao.funcionario.nomeCompleto ??
            ""
          }
          onClose={() =>
            this.setState({
              modalPerformance: {
                isOpen: false,
                idTarefa,
                funcionario: null,
              },
            })
          }
          onSuccess={(values) => {
            this.props.editAvaliacaoColetivaResultadoMeta(
              idTarefa,
              modalPerformance.funcionario?.idTarefaAvaliacao!,
              values.resultadometas,
              values.comentariosMetas
            );
          }}
          readonly={false}
        />

        <DialogAtitudes
          isOpen={modalAtitudes.isOpen}
          loadingData={taskState.loadingAction}
          success={taskState.successAction}
          idTarefaAvaliacao={modalAtitudes.funcionario?.idTarefaAvaliacao!}
          idTarefa={modalAtitudes.idTarefa}
          nomeCompleto={
            modalAtitudes.funcionario?.avaliacao.funcionario.nomeCompleto ?? ""
          }
          onClose={() =>
            this.setState({
              modalAtitudes: {
                isOpen: false,
                idTarefa,
                funcionario: null,
              },
            })
          }
          onSuccess={(values) => {
            this.props.editAvaliacaoColetivaResultadoAtitude(
              idTarefa,
              modalAtitudes.funcionario?.idTarefaAvaliacao!,
              values.resultadoatitudes,
              values.comentariosAtitudes
            );
          }}
          readonly={false}
        />
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: TaskAvaliacaoColetivaFuncionario[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items =
        this.props.taskState.selected?.TarefaAvaliacaoColetiva?.funcionarios ??
        [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) =>
      (
        isSortedDescending
          ? result(columnKey, a) < result(columnKey, b)
          : result(columnKey, a) > result(columnKey, b)
      )
        ? 1
        : -1
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.checkCell = {
          position: "sticky",
          left: 0,
          zIndex: 9,
          backgroundColor: detailsRowStyleProps.isSelected
            ? "#EDEBE9"
            : "#FFFFFF",
          marginTop: 50,
          display: "flex",
          alignItems: "center",
        };
        customStyles.root = {
          selectors: {
            // "& .ms-DetailsRow-fields": {
            //   opacity: props.item.funcionario.isVisible ? 1 : 0.5,
            // },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
          },
        };
        customStyles.cell = {
          display: "flex",
          alignItems: "center",
          selectors: {
            "&:nth-child(1)": {
              position: "sticky",
              zIndex: 9,
              left: 0,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(2)": {
              position: "sticky",
              zIndex: 9,
              left: 50,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(3)": {
              position: "sticky",
              zIndex: 9,
              left: 120,
              backgroundColor: detailsRowStyleProps.isSelected
                ? "#EDEBE9"
                : "#FFFFFF",
            },
            "&:nth-child(7) div, &:nth-child(8) div, &:nth-child(9) div, &:nth-child(10) div, &:nth-child(11) div, &:nth-child(12) div": {
              fontWeight: detailsRowStyleProps.isSelected
                ? "inherit"
                : "normal",
            },
          },
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };
}

const mapStateToProps = (state: RootState) => ({
  // tasks: state.tasksReducer
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...loadTeam,
      ...getTasksById,
      ...finalizaTarefaColetiva,
      ...editAvaliacaoColetivaResultadoMeta,
      ...editAvaliacaoColetivaResultadoAtitude,
      ...editAvaliacaoColetivaResultadoFinal,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CollectiveEvaluations)
);
