import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputDate, InputAutocomplete, InputNumber } from '~/components/Forms';

import { IUnit, DataTypes as DataTypesUnit } from "~/store/ducks/admin/units/types";
import { Creators as CreatorsUnit } from "~/store/ducks/admin/units";


import { PositionTypes, DataTypes as DataTypesPosition } from "~/store/ducks/admin/positions/types";
import { Creators as CreatorsPosition } from "~/store/ducks/admin/positions";

import { ChartType, DataTypes as DataTypesChart } from "~/store/ducks/admin/chart/types";
import { Creators as CreatorsChart } from "~/store/ducks/admin/chart";


import { RootState } from "~/store/ducks";
import { CCType, DataTypes as DataTypesCC } from '~/store/ducks/cc/types';
import { Creators as CreatorsCC } from '~/store/ducks/cc';
import { toNumber } from '~/utils/numbers';

import { WrapperAutoComplete } from '../../styles';
import { IButtonStyles, IconButton } from 'office-ui-fabric-react';
import CustomDialog from '~/components/CustomDialog';
import TreeView from '~/components/TreeView';
import SearchPosition from '~/pages/Admin/Positions/SearchPosition';

export type InfoAdmissionType = {
  matricula: string;
  dataAdmissao: Date | null;
  salario: number | null;
  unidade: Partial<IUnit> | null;
  cargo: Partial<PositionTypes> | null;
  area: Partial<ChartType> | null;
  cc: Partial<CCType> | null;
};

interface IFormInfoAdmissionProps {
  formRef: any;
  initialValues: InfoAdmissionType;
  editValues: (values: Partial<InfoAdmissionType>) => any;
  success: (values: InfoAdmissionType) => any;
};

const validationSchemaInfoAdmission = yup.object().shape({
  matricula:
    yup.string()
      .max(10, "Matrícula não pode ter mais do que 10 caracteres")
      .required("Campo obrigatório"),
  dataAdmissao:
    yup.date()
      .min(new Date("01/01/1900"), "Data de nascimento inválida")
      .max(new Date(), "Data de nascimento inválida")
      .nullable()
      .typeError('Data de nascimento inválida')
      .required("Campo obrigatório"),
  unidade: yup.object().nullable().required("Campo obrigatório"),
  cargo: yup.object().nullable().required("Campo obrigatório"),
  area: yup.object().nullable().required("Campo obrigatório"),
  cc: yup.object().nullable().required("Campo obrigatório"),
  salario: yup.number().nullable().required("Campo obrigatório")

});

const FormContentInfoAdmission: React.FC<IFormInfoAdmissionProps> = (props) => {
  const [valueUnit, setValueUnit] = useState<Partial<IUnit | null>>(props.initialValues.unidade);
  const [valuePosition, setValuePosition] = useState<Partial<PositionTypes> | null>(props.initialValues.cargo);
  const [valueChart, setValueChart] = useState<Partial<ChartType> | null>(props.initialValues.area);
  const [valueCC, setValueCC] = useState<Partial<CCType> | null>(props.initialValues.cc);

  const [isDialogSearchCargo, setIsDialogSearchCargo] = useState(false);
  const [isDialogTreeViewOpen, setIsDialogTreeViewOpen] = useState(false);
  const [valueChartTreeView, setValueChartTreeView] = useState<Partial<ChartType> | null>(null);

  const dispatch = useDispatch();
  let timeout: number = 0;

  const unitsState = useSelector<RootState, DataTypesUnit>(state => state.unitsReducer);
  const positionsState = useSelector<RootState, DataTypesPosition>(state => state.positionsReducer);
  const chartState = useSelector<RootState, DataTypesChart>(state => state.chartReducer);
  const ccState = useSelector<RootState, DataTypesCC>(state => state.ccReducer);
  
  // useEffect(() => {
  //     setValueUnit(props.initialValues.unidade);
  //     // setValuePosition(props.initialValues.cargo);
  //     // setValueChart(props.initialValues.local);
  //     // console.log(props.initialValues)
  // }, []);

  const search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        switch (type) {
          case 'unidade':
            dispatch(CreatorsUnit.getUnits(text, true));
            break;
          case 'cargo':
            dispatch(CreatorsPosition.getPositions(text, false, true));
            break;
          case 'area':
            dispatch(CreatorsChart.getChart(text, true, false));
            break;
          case 'cc':
            dispatch(CreatorsCC.getCC(text));
            break;
          case 'vaga':
            // dispatch(CreatorsCC.getCC(text));
            break;
        }
      }, 700);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={props.formRef}
        initialValues={props.initialValues}
        validationSchema={validationSchemaInfoAdmission}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(values: InfoAdmissionType) => {
          values.unidade = {
            idUnidade: valueUnit?.idUnidade,
            nomeUnidade: valueUnit?.nomeUnidade
          };
          values.cargo = {
            idCargo: valuePosition?.idCargo,
            titulo: valuePosition?.titulo
          };
          values.area = {
            idArea: valueChart?.idArea,
            nomeLocal: valueChart?.nomeLocal
          }
          values.cc = {
            idCC: valueCC?.idCC,
            nomeCC: valueCC?.nomeCC
          }

          props.success(values);
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 0, paddingRight: 8 }}>
                    <InputText
                      value={values.matricula}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError("matricula", "");
                      }}
                      onBlur={() => props.editValues(values)}
                      id="matricula"
                      error={errors.matricula ? true : false}
                      name="matricula"
                      type="text"
                      label="Matrícula"
                      helperText={errors.matricula}
                      className="mt-2"
                      inputProps={{
                        maxLength: 10
                      }}
                      autoFocus
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <InputDate
                      id="dataAdmissao"
                      error={errors.dataAdmissao ? true : false}
                      name="dataAdmissao"
                      label="Data de Admissão"
                      value={values.dataAdmissao}
                      onChange={value => {
                        setFieldError('dataAdmissao', '');
                        setFieldValue('dataAdmissao', value);
                      }}
                      onBlur={() => props.editValues(values)}
                      helperText={errors.dataAdmissao}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 0, paddingRight: 8 }}>
                    <InputNumber
                      label="Salário"
                      value={values.salario}
                      onChange={(e) => {
                        setFieldValue('salario', toNumber(e.target.value))
                        setFieldError('salario', '')
                      }}
                      onBlur={() => props.editValues(values)}
                      error={!!errors.salario}
                      helperText={errors.salario}
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <InputAutocomplete
                      value={(valueUnit as IUnit)}
                      onChange={(_, newValue) => {
                        setValueUnit(newValue!)
                        setFieldValue("unidade", { idUnidade: newValue?.idUnidade });
                        props.editValues({ ...values, unidade: newValue })
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("unidade", "");
                        search(newInputValue, "unidade");
                      }}
                      getOptionLabel={(unit: IUnit) => `${unit.idUnidade} - ${unit.nomeUnidade}`}
                      getOptionSelected={(option, value) => option.idUnidade === value.idUnidade}
                      options={unitsState.data}
                      input={{
                        idInput: "unidade",
                        labelInput: "Unidade",
                        helperTextInput: errors.unidade ?? '',
                        errorInput: !!errors.unidade
                      }}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <WrapperAutoComplete>
                      <InputAutocomplete
                        value={(valuePosition as PositionTypes)}
                        onChange={(_, newValue) => {
                          setValuePosition(newValue!)
                          setFieldValue("cargo", { idCargo: newValue?.idCargo! });
                          props.editValues({ ...values, cargo: newValue })
                        }}
                        onInputChange={(_, newInputValue) => {
                          setFieldError("cargo", "");
                          search(newInputValue, "cargo");
                        }}
                        getOptionLabel={(position: PositionTypes) => `${position.idCargo} - ${position.titulo}`}
                        getOptionSelected={(option, value) => option.idCargo === value.idCargo}
                        options={positionsState.data}
                        input={{
                          idInput: "cargo",
                          labelInput: "Cargo",
                          helperTextInput: errors.cargo ?? '',
                          errorInput: !!errors.cargo
                        }}
                      />
                      <IconButton
                        iconProps={{ iconName: "Search" }}
                        styles={IconButtonStyle}
                        onClick={() => {
                          setIsDialogSearchCargo(true)
                        }}
                      />
                    </WrapperAutoComplete>
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <WrapperAutoComplete>
                      <InputAutocomplete
                        value={(valueChart as ChartType)}
                        onChange={(_, newValue) => {
                          setValueChart(newValue!)
                          setFieldValue("area", { idArea: newValue?.idArea! });
                          props.editValues({ ...values, area: newValue })
                        }}
                        onInputChange={(_, newInputValue) => {
                          setFieldError("area", "");
                          search(newInputValue, "area");
                        }}
                        getOptionLabel={(position: ChartType) => `${position.idArea} - ${position.nomeLocal}`}
                        getOptionSelected={(option, value) => option.idArea === value.idArea}
                        options={chartState.data}
                        input={{
                          idInput: "area",
                          labelInput: "Área",
                          helperTextInput: errors.area,
                          errorInput: !!errors.area
                        }}
                      />
                      <IconButton
                        iconProps={{ iconName: "Org" }}
                        styles={IconButtonStyle}
                        onClick={() => {
                          dispatch(CreatorsChart.getChart('', true, true))
                          setIsDialogTreeViewOpen(true)
                        }}
                      />
                    </WrapperAutoComplete>
                  </div>
                  <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <InputAutocomplete
                      value={(valueCC as CCType)}
                      onChange={(_, newValue) => {
                        setValueCC(newValue!)
                        setFieldValue("cc", { idCC: newValue?.idCC! });
                        props.editValues({ ...values, cc: newValue })
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("cc", "");
                        search(newInputValue, "cc");
                      }}
                      getOptionLabel={(cc: CCType) => `${cc.idCC} - ${cc.nomeCC}`}
                      getOptionSelected={(option, value) => option.idCC === value.idCC}
                      options={ccState.data}
                      input={{
                        idInput: "cc",
                        labelInput: "Centro de Custo",
                        helperTextInput: errors.cc,
                        errorInput: !!errors.cc
                      }}
                    />
                  </div>
                  {/* <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                    <InputAutocomplete
                      value={(valueVaga as VacancyType)}
                      onChange={(_, newValue) => {
                        setValueVaga(newValue!)
                        setFieldValue("vaga", { idVaga: newValue?.idVaga });
                      }}
                      onInputChange={(_, newInputValue) => {
                        setFieldError("vaga", "");
                        search(newInputValue, "vaga");
                      }}
                      getOptionLabel={(vaga: VacancyType) => vaga.nomeVaga}
                      getOptionSelected={(option, value) => option.idVaga === value.idVaga}
                      options={vagaState.data}
                      input={{
                        idInput: "vaga",
                        labelInput: "Vaga",
                        // helperTextInput: errors.vaga,
                        // errorInput: !!errors.vaga
                      }}
                    />
                  </div> */}
                </div>
              </div>


              <CustomDialog
                open={isDialogTreeViewOpen}
                title="Escolher área"
                width={850}
                btnConfirmText="Definir"
                onClickCancel={() => setIsDialogTreeViewOpen(false)}
                onClickConfirm={() => {
                  setValueChart(valueChartTreeView)
                  setFieldValue("area", { idArea: valueChartTreeView?.idArea });
                  setFieldError("area", '')
                  props.editValues(values)
                  setIsDialogTreeViewOpen(false)
                }}
                disabledBtnConfirm={valueChartTreeView === null}
                height={500}
              >
                <TreeView
                  columns={[{
                    name: 'Área',
                    fieldName: 'nomeLocal'
                  }]}
                  selectionMode="single"
                  state={chartState}
                  fieldId="idArea"
                  changeItemsSelected={items => setValueChartTreeView(items[0])}
                />
              </CustomDialog>

              <SearchPosition
                isOpen={isDialogSearchCargo}
                onClose={() => setIsDialogSearchCargo(false)}
                onAdd={(value) => {
                  setValuePosition(value[0])
                  setFieldValue("cargo", { idCargo: value[0].idCargo });
                  setFieldError("cargo", '')
                  props.editValues(values)
                  setIsDialogSearchCargo(false)
                }}
              />

            </form>
          )
        }}
      </Formik>
    </>
  );
};

const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: 'absolute',
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8
  },
  icon: {
    fontSize: 12,
  }
};

export default FormContentInfoAdmission;
