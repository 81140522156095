import React from "react";
import filesize from "filesize";

import {
  CloudUploadIcon,
  Container,
  DeleteButton,
  Description,
  
  FileName,
  FileSize,
  HideInput,
  UploadArea,
  UploadFile as UploadFileContainer,
  WrapperInfoFile,
  Title,
} from "./styles";

import CloudUpload from "./icons/CloudUpload.svg";
import UploadFileIcon from "./icons/UploadFile.svg";

import { useUpload } from "./useUpload";
import { FontIcon } from "office-ui-fabric-react";

interface IUploadCardProps {
  title: string;
  descriptionUpload: string;
  managerDocs: [File[], React.Dispatch<React.SetStateAction<File[]>>];
  error?: boolean;
  details?: JSX.Element;
  multiple?: boolean;
}

export function UploadCard({
  title,
  descriptionUpload,
  managerDocs,
  error,
  details,
  multiple,
}: IUploadCardProps) {
  const { fileRef, handleClick, handleUpload, handleDelete, files } = useUpload(
    managerDocs
  );

  const shouldHideUploadArea = !multiple && files.length > 0;

  return (
    <Container>
      {!shouldHideUploadArea && (
        <UploadArea onClick={handleClick}>
          <CloudUploadIcon src={CloudUpload} />
          <Title>{title}</Title>
          <Description>{descriptionUpload}</Description>
          <HideInput
            ref={fileRef}
            type="file"
            onChange={handleUpload}
            multiple={multiple}
            accept="image/*,.pdf"
          />
        </UploadArea>
      )}
      {files.map(({ name, size }, i) => (
        <UploadFile
          key={i}
          name={name}
          size={size}
          error={error}
          onDelete={(e) => handleDelete(e, i)}
        />
      ))}
    </Container>
  );
}

interface UploadFileProps {
  name: string;
  size: number;
  error?: boolean;
  readOnly?: boolean;
  onDelete?: (e: any) => void;
  onDownload?: () => void;
}

export const UploadFile = ({
  name,
  size,
  readOnly,
  error,
  onDelete,
  onDownload,
}: UploadFileProps) => (
  <UploadFileContainer error={error} readOnly={readOnly}>
    <img src={UploadFileIcon} alt="Documentos anexados" />
    <WrapperInfoFile>
      <FileName>{name}</FileName>
      <FileSize>{filesize(size)}</FileSize>
    </WrapperInfoFile>
    {!readOnly && (
      <DeleteButton onClick={(e) => onDelete?.(e)}>
        <FontIcon iconName="Delete" />
      </DeleteButton>
    // : (
    //   <DeleteButton
    //     onClick={(e) => {
    //       e.preventDefault();
    //       onDownload?.();
    //     }}
    //   >
    //     <FontIcon iconName="Download" />
    //   </DeleteButton>
    )}
  </UploadFileContainer>
);
