import {
  Types,
  DataTypes,
  MeritActionTypes,
  MeritEmployee,
  IRequestMeritAtualizaPromocaoAction,
  Budget,
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: null,
  dataResumo: null,
  budgetHistory: null,
  cargosHistory: null,
  salariosHistory: null,
  avaliacoesHistory: null,
  ocorrenciasHistory: null,
  error: false,
  errorData: false,
  loadingData: false,
  loadingAction: false,
  loadingReprovar: false,
  loadingBudgetHistory: false,
  loadingGridsHistory: false,
  success: false,
  successFinalizar: false,
  successBudget: false,
  successAprovarReprovar: false,
  successReabrir: false,
};

/**
 * Actions
 */

export const Creators = {
  getMeritById: (idMerito: number): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_LOAD_BY_ID,
      idMerito,
    };
  },

  editMerit: (
    idMerito: number,
    merit: Partial<MeritEmployee>
  ): MeritActionTypes => {
    return {
      type: Types.REQUEST_MERIT_EDIT,
      payload: {
        idMerito,
        merit,
      },
    };
  },

  atualizaPercentual: (
    idMerito: number,
    idMeritoFuncionario: number,
    percEnquadramento: number,
    percMerito: number,
    percPromocao: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_ATUALIZA_PERCENTUAL,
    payload: {
      idMerito,
      idMeritoFuncionario,
      percEnquadramento,
      percMerito,
      percPromocao,
    },
  }),

  atualizaPromocao: (
    idMerito: number,
    merito: IRequestMeritAtualizaPromocaoAction["payload"]["merito"]
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_ATUALIZA_PROMOCAO,
    payload: {
      idMerito,
      merito,
    },
  }),

  atualizaSalario: (
    idMerito: number,
    idMeritoFuncionario: number,
    novoSalario: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_ATUALIZA_SALARIO,
    payload: {
      idMerito,
      idMeritoFuncionario,
      novoSalario,
    },
  }),

  atualizaComentarios: (
    idMerito: number,
    idMeritoFuncionario: number,
    comentarios: string
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_ATUALIZA_COMENTARIO,
    payload: {
      idMerito,
      idMeritoFuncionario,
      comentarios,
    },
  }),

  finalizar: (
    idMerito: number,
    idMeritoFuncionario: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_FINALIZAR,
    payload: {
      idMerito,
      idMeritoFuncionario,
    },
  }),

  reativar: (
    idMerito: number,
    idMeritoFuncionario: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_REATIVAR,
    payload: {
      idMerito,
      idMeritoFuncionario,
    },
  }),

  aplicar: (
    idMerito: number,
    idMeritoFuncionario: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_APLICAR,
    payload: {
      idMerito,
      idMeritoFuncionario,
    },
  }),

  ignorar: (
    idMerito: number,
    idMeritoFuncionario: number
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_IGNORAR,
    payload: {
      idMerito,
      idMeritoFuncionario,
    },
  }),

  getResumo: (idMerito: number, decisao: "G" | "RH"): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_RESUMO_LOAD,
    payload: {
      idMerito,
      decisao,
    },
  }),

  solicitarAprovacao: (
    idMerito: number,
    comentariosFinais: string
  ): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_SOLICITAR_APROVACAO,
    payload: {
      idMerito,
      comentariosFinais,
    },
  }),

  getBudgets: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_GET_BUDGETS,
    payload: {
      idMerito
    }
  }),

  editBudget: (idMerito: number, budget: Budget): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_EDIT_BUDGET,
    payload: {
      idMerito,
      budget
    }
  }),

  getSaldo: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_LOAD_SALDO,
    payload: {
      idMerito
    }
  }),

  aprovarMerito: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_APROVAR,
    payload: {
      idMerito
    }
  }),

  reprovarMerito: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_REPROVAR,
    payload: {
      idMerito
    }
  }),

  finalizarReuniao: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_FINALIZAR_REUNIAO,
    payload: {
      idMerito
    }
  }),

  reabrirReuniao: (idMerito: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_REABRIR,
    payload: {
      idMerito
    }
  }),

  listarCargos: (idMerito: number, idMeritoFuncionario: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_LISTAR_CARGOS,
    payload: {
      idMerito,
      idMeritoFuncionario
    }
  }),

  listarSalarios: (idMerito: number, idMeritoFuncionario: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_LISTAR_SALARIOS,
    payload: {
      idMerito,
      idMeritoFuncionario
    }
  }),

  listarAvaliacoes: (idMerito: number, idMeritoFuncionario: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_LISTAR_AVALIACOES,
    payload: {
      idMerito,
      idMeritoFuncionario
    }
  }),

  listarOcorrencias: (idMerito: number, idMeritoFuncionario: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_LISTAR_OCORRENCIAS,
    payload: {
      idMerito,
      idMeritoFuncionario
    }
  }),

  clearListaCargos: () => ({
    type: Types.CLEAR_MERIT_LISTAR_CARGOS,
  }),

  clearListaSalarios: () => ({
    type: Types.CLEAR_MERIT_LISTAR_SALARIOS,
  }),

  clearListaAvaliacoes: () => ({
    type: Types.CLEAR_MERIT_LISTAR_AVALIACOES,
  }),

  clearListaOcorrencias: () => ({
    type: Types.CLEAR_MERIT_LISTAR_OCORRENCIAS,
  }),

  downloadCarta: (idMerito: number, idMeritoFuncionario: number): MeritActionTypes => ({
    type: Types.REQUEST_MERIT_DOWNLOAD_CARTA,
    payload: {
      idMerito,
      idMeritoFuncionario
    }
  }),
};

export default function meritReducer(
  state = INITIAL_STATE,
  action: MeritActionTypes
): DataTypes {
  switch (action.type) {
    //LOAD
    case Types.REQUEST_MERIT_LOAD_BY_ID:
      return {
        ...state,
        data: null,
        loadingData: true,
        errorData: false,
        success: false,
        successFinalizar: false,
        budgetHistory: null
      };
    case Types.SUCCESS_MERIT_LOAD_BY_ID:
      return {
        ...state,
        data: {
          ...action.payload,
          funcionarios: action.payload!.funcionarios.map((item) => ({
            ...item,
            flagPercMeritoEdit: false,
            flagPercEnqEdit: false,
            flagPercPromocaoEdit: false,
          })),
        },
        loadingData: false,
        errorData: false,
        success: true,
      };
    case Types.FAILURE_MERIT_LOAD_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        data: null,
        loadingData: false,
        errorData: true,
        success: false,
        budgetHistory: null
      };

    case Types.REQUEST_MERIT_EDIT:
      return { ...state, loadingAction: true, success: false, error: false };
    case Types.SUCCESS_MERIT_EDIT:
      return {
        ...state,
        data: {
          ...state.data!,
          funcionarios: state.data!.funcionarios.map((item) => {
            if (
              item.idMeritoFuncionario !==
              action.payload.merit.idMeritoFuncionario
            ) {
              return item;
            } else {
              const novoItem: MeritEmployee = {
                ...item,
                ...action.payload.merit,
              };

              return novoItem;
            }
          }),
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_MERIT_EDIT:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    case Types.REQUEST_MERIT_ATUALIZA_PERCENTUAL:
    case Types.REQUEST_MERIT_ATUALIZA_PROMOCAO:
    case Types.REQUEST_MERIT_ATUALIZA_SALARIO:
    case Types.REQUEST_MERIT_ATUALIZA_COMENTARIO:
    case Types.REQUEST_MERIT_FINALIZAR:
    case Types.REQUEST_MERIT_REATIVAR:
    case Types.REQUEST_MERIT_APLICAR:
    case Types.REQUEST_MERIT_IGNORAR:
      return { ...state, loadingAction: true, success: false, error: false };

    case Types.SUCCESS_MERIT_ATUALIZA_PERCENTUAL:
    case Types.SUCCESS_MERIT_ATUALIZA_SALARIO:
    case Types.SUCCESS_MERIT_APLICAR:
    case Types.SUCCESS_MERIT_IGNORAR:
      return {
        ...state,
        data: {
          ...state.data!,
          ...action.payload,
          funcionarios: state.data!.funcionarios.map((item) => {
            if (
              item.idMeritoFuncionario ===
              action.payload.funcionario.idMeritoFuncionario
            ) {
              return {
                ...item,
                ...action.payload.funcionario,
              };
            } else {
              return item;
            }
          }),
          saldo: action.payload.saldo,
        },
        loadingAction: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_MERIT_ATUALIZA_PERCENTUAL:
    case Types.FAILURE_MERIT_ATUALIZA_PROMOCAO:
    case Types.FAILURE_MERIT_ATUALIZA_SALARIO:
    case Types.FAILURE_MERIT_ATUALIZA_COMENTARIO:
    case Types.FAILURE_MERIT_FINALIZAR:
    case Types.FAILURE_MERIT_REATIVAR:
    case Types.FAILURE_MERIT_APLICAR:
    case Types.FAILURE_MERIT_IGNORAR:
      customToast.error(action.message);
      return { ...state, loadingAction: false, success: false, error: true };

    case Types.SUCCESS_MERIT_FINALIZAR:
    case Types.SUCCESS_MERIT_REATIVAR:
    case Types.SUCCESS_MERIT_ATUALIZA_COMENTARIO:
    case Types.SUCCESS_MERIT_ATUALIZA_PROMOCAO:
      return {
        ...state,
        data: {
          ...state.data!,
          ...action.payload,
          funcionarios: state.data!.funcionarios.map((item) => {
            if (
              item.idMeritoFuncionario ===
              action.payload.funcionario.idMeritoFuncionario
            ) {
              return {
                ...item,
                ...action.payload.funcionario,
              };
            }

            return item;
          }),
        },
        loadingAction: false,
        success: true,
        error: false,
      };

    case Types.REQUEST_MERIT_RESUMO_LOAD:
      return {
        ...state,
        dataResumo: null,
        loadingData: true,
        success: false,
        error: false,
      };
    case Types.SUCCESS_MERIT_RESUMO_LOAD:
      return {
        ...state,
        dataResumo: action.payload,
        loadingData: false,
        success: true,
        error: false,
      };
    case Types.FAILURE_MERIT_RESUMO_LOAD:
      customToast.error(action.message);
      return {
        ...state,
        dataResumo: null,
        loadingData: false,
        success: false,
        error: true,
      };

    case Types.REQUEST_MERIT_SOLICITAR_APROVACAO:
      return {
        ...state,
        loadingAction: true,
        successFinalizar: false,
      };

    case Types.SUCCESS_MERIT_SOLICITAR_APROVACAO:
      return {
        ...state,
        data: {
          ...state.data!,
          merito: {
            ...state.data?.merito!,
            ...action.payload.data
          },
          controls: action.payload.controls
        },
        loadingAction: false,
        successFinalizar: true,
      };

    case Types.FAILURE_MERIT_SOLICITAR_APROVACAO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successFinalizar: false,
      };

    //REQUEST_MERIT_FINALIZAR_REUNIAO
    case Types.REQUEST_MERIT_FINALIZAR_REUNIAO:
      return {
        ...state,
        loadingAction: true,
        successFinalizar: false,
      };

    case Types.SUCCESS_MERIT_FINALIZAR_REUNIAO:
      return {
        ...state,
        data: {
          ...state.data!,
          merito: {
            ...state.data?.merito!,
            ...action.payload.data
          },
          controls: action.payload.controls
        },
        loadingAction: false,
        successFinalizar: true,
      };

    case Types.FAILURE_MERIT_FINALIZAR_REUNIAO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successFinalizar: false,
      };


    case Types.REQUEST_MERIT_GET_BUDGETS:
      return {
        ...state,
        loadingBudgetHistory: true,
      };

    case Types.SUCCESS_MERIT_GET_BUDGETS:
      return {
        ...state,
        loadingBudgetHistory: false,
        budgetHistory: action.payload,
      };

    case Types.FAILURE_MERIT_GET_BUDGETS:
      customToast.error(action.message);
      return {
        ...state,
        loadingBudgetHistory: false,
      };

    case Types.REQUEST_MERIT_EDIT_BUDGET:
      return {
        ...state,
        loadingAction: true,
        successBudget: false,
      };

    case Types.SUCCESS_MERIT_EDIT_BUDGET:
      return {
        ...state,
        loadingAction: false,
        successBudget: true,
        budgetHistory: state.budgetHistory ? [action.payload, ...state.budgetHistory] : [action.payload]
      };

    case Types.FAILURE_MERIT_EDIT_BUDGET:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successBudget: false,
      };

    //REQUEST_MERIT_LOAD_SALDO
    case Types.REQUEST_MERIT_LOAD_SALDO:
      return {
        ...state,
        loadingData: true,
      };

    case Types.SUCCESS_MERIT_LOAD_SALDO:
      return {
        ...state,
        data: {
          ...state.data!,
          saldo: action.payload
        },
        loadingData: false,
      };

    case Types.FAILURE_MERIT_LOAD_SALDO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
      };

    //REQUEST_MERIT_APROVAR
    case Types.REQUEST_MERIT_APROVAR:
      return {
        ...state,
        loadingAction: true,
        successAprovarReprovar: false,
      };

    case Types.SUCCESS_MERIT_APROVAR:
      customToast.success("Reunião foi aprovada com sucesso");
      return {
        ...state,
        loadingAction: false,
        successAprovarReprovar: true,
        data: {
          ...state.data!,
          merito: {
            ...state.data!.merito!,
            ...action.payload.data
          },
          controls: action.payload.controls
        }
      };

    case Types.FAILURE_MERIT_APROVAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        successAprovarReprovar: false,
      };

    //REQUEST_MERIT_REPROVAR
    case Types.REQUEST_MERIT_REPROVAR:
      return {
        ...state,
        loadingReprovar: true,
        successAprovarReprovar: false,
      };

    case Types.SUCCESS_MERIT_REPROVAR:
      customToast.success("Reunião foi reprovada com sucesso");
      return {
        ...state,
        loadingReprovar: false,
        successAprovarReprovar: true,
        data: {
          ...state.data!,
          merito: {
            ...state.data!.merito!,
            ...action.payload.data,
          },
          controls: action.payload.controls
        },
      };

    case Types.FAILURE_MERIT_REPROVAR:
      customToast.error(action.message);
      return {
        ...state,
        loadingReprovar: false,
        successAprovarReprovar: false,
      };

    //REQUEST_MERIT_REABRIR
    case Types.REQUEST_MERIT_REABRIR:
      return {
        ...state,
        loadingReprovar: true,
        successReabrir: false,
      };

    case Types.SUCCESS_MERIT_REABRIR:
      return {
        ...state,
        loadingReprovar: false,
        successReabrir: true,
        data: {
          ...state.data!,
          merito: {
            ...state.data!.merito!,
            ...action.payload.dados
          },
          controls: action.payload.controls
        }
      };

    case Types.FAILURE_MERIT_REABRIR:
      customToast.error(action.message);
      return {
        ...state,
        loadingReprovar: false,
        successReabrir: false,
      };

    //REQUEST_MERIT_LISTAR_CARGOS
    case Types.REQUEST_MERIT_LISTAR_CARGOS:
      return {
        ...state,
        cargosHistory: null,
        loadingGridsHistory: true
      };

    case Types.SUCCESS_MERIT_LISTAR_CARGOS:
      return {
        ...state,
        cargosHistory: action.payload,
        loadingGridsHistory: false
      };

    case Types.FAILURE_MERIT_LISTAR_CARGOS:
      customToast.error(action.message);
      return {
        ...state,
        cargosHistory: null,
        loadingGridsHistory: false
      };

    case Types.CLEAR_MERIT_LISTAR_CARGOS:
      return {
        ...state,
        cargosHistory: null,
        loadingGridsHistory: false
      };

    //REQUEST_MERIT_LISTAR_SALARIOS
    case Types.REQUEST_MERIT_LISTAR_SALARIOS:
      return {
        ...state,
        salariosHistory: null,
        loadingGridsHistory: true
      };

    case Types.SUCCESS_MERIT_LISTAR_SALARIOS:
      return {
        ...state,
        salariosHistory: action.payload,
        loadingGridsHistory: false
      };

    case Types.FAILURE_MERIT_LISTAR_SALARIOS:
      customToast.error(action.message);
      return {
        ...state,
        salariosHistory: null,
        loadingGridsHistory: false
      };

    case Types.CLEAR_MERIT_LISTAR_SALARIOS:
      return {
        ...state,
        salariosHistory: null,
        loadingGridsHistory: false
      };

    //REQUEST_MERIT_LISTAR_AVALIACOES
    case Types.REQUEST_MERIT_LISTAR_AVALIACOES:
      return {
        ...state,
        avaliacoesHistory: null,
        loadingGridsHistory: true
      };

    case Types.SUCCESS_MERIT_LISTAR_AVALIACOES:
      return {
        ...state,
        avaliacoesHistory: action.payload,
        loadingGridsHistory: false
      };

    case Types.FAILURE_MERIT_LISTAR_AVALIACOES:
      customToast.error(action.message);
      return {
        ...state,
        avaliacoesHistory: null,
        loadingGridsHistory: false
      };

    case Types.CLEAR_MERIT_LISTAR_AVALIACOES:
      return {
        ...state,
        avaliacoesHistory: null,
        loadingGridsHistory: false
      };

    //REQUEST_MERIT_LISTAR_OCORRENCIAS
    case Types.REQUEST_MERIT_LISTAR_OCORRENCIAS:
      return {
        ...state,
        ocorrenciasHistory: null,
        loadingGridsHistory: true
      };

    case Types.SUCCESS_MERIT_LISTAR_OCORRENCIAS:
      return {
        ...state,
        ocorrenciasHistory: action.payload,
        loadingGridsHistory: false
      };

    case Types.FAILURE_MERIT_LISTAR_OCORRENCIAS:
      customToast.error(action.message);
      return {
        ...state,
        ocorrenciasHistory: null,
        loadingGridsHistory: false
      };

    case Types.CLEAR_MERIT_LISTAR_OCORRENCIAS:
      return {
        ...state,
        ocorrenciasHistory: null,
        loadingGridsHistory: false
      };

    //REQUEST_MERIT_DOWNLOAD_CARTA
    case Types.REQUEST_MERIT_DOWNLOAD_CARTA:
      return {
        ...state,
      };

    case Types.SUCCESS_MERIT_DOWNLOAD_CARTA:
      return {
        ...state,
      };

    case Types.FAILURE_MERIT_DOWNLOAD_CARTA:
      customToast.error(action.message);
      return {
        ...state,
      };
    default:
      return state;
  }
}
