import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Nav,
  INavLink,
  INavStyles,
  INavLinkGroup,
} from "office-ui-fabric-react/lib/Nav";
import { RootState } from "~/store/ducks";
import { DataTypes } from "~/store/ducks/home/types";
import { DataTypes as DataTypesLogin } from "~/store/ducks/login/types";
import { Creators } from "~/store/ducks/home";
import { Creators as CreatorsWorkflow } from "~/store/ducks/workflow";
import { withRouter, useHistory, useLocation } from "react-router-dom";

const NavBar: React.FC = () => {
  const location = useLocation();
  const pageState = useSelector<RootState, DataTypes>(
    (state) => state.homeReducer
  );
  const loginState = useSelector<RootState, DataTypesLogin>(
    (state) => state.loginReducer
  );
  const [currentKeyPage, setCurrentKeyPage] = useState<string>("");
  const [isCadastroMenuOpen, setIsCadastroMenuOpen] = useState<boolean>(true);
  const [isDesempenhoMenuOpen, setIsDesempenhoMenuOpen] = useState<boolean>(
    true
  );
  const [isRelatoriosMenuOpen, setIsRelatoriosMenuOpen] = useState<boolean>(
    true
  );
  const [isCargoSalarioMenuOpen, setIsCargoSalarioMenuOpen] = useState<boolean>(
    true
  );
  const [isTabAuxMenuOpen, setIsTabAuxMenuOpen] = useState<boolean>(true);
  const [isRemuneracaoMenuOpen, setIsRemuneracaoMenuOpen] = useState<boolean>(
    true
  );
  const [isPdiMenuOpen, setIsPdiMenuOpen] = useState<boolean>(true);

  const [isJustaCausaMenuOpen, setIsJustaCausaMenuOpen] = useState<boolean>(
    true
  );
  const [isMedidasMenuOpen, setIsMedidasMenuOpen] = useState<boolean>(true);

  const [isAdminPage, setUsAdminPage] = useState(
    location.pathname.includes("admin")
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setUsAdminPage(location.pathname.includes("admin"));
  }, [location]);

  useEffect(() => {
    if (pageState.isMenuExpanded) {
      setCurrentKeyPage(pageState.page.key);
    } else {
      const statePage = pageState.page.key;

      switch (statePage) {
        case "home":
          setCurrentKeyPage("home");
          break;
        case "atitudes":
        case "pilares":
        case "objetivos":
        case "ciclos":
        case "reunioes":
          setCurrentKeyPage("desempenho");
          break;
        case "empresas":
        case "unidades":
        case "organograma":
        case "funcionarios":
        case "sancoes":
          setCurrentKeyPage("cadastros");
          break;
        case "relatorioCurvaPerformance":
        case "relatorioMatrizTalentos":
        case "relatorioSuccession":
        case "relatorioMerito":
          setCurrentKeyPage("relatorios");
          break;
        case "cargos":
        case "estruturasalarial":
        case "configEstruturaSalarial":
        case "configMerito":
          setCurrentKeyPage("cargoSalario");
          break;
        case "categoriasCargos":
        case "padroesCargos":
        case "tabelasPerformance":
        case "motivosDesligamentos":
        case "motivosFaltas":
        case "motivosAfastamentos":
        case "tiposMovimentacoes":
        case "vinculos":
          setCurrentKeyPage("tabAux");
          break;
        case "bonus":
        case "merito":
          setCurrentKeyPage("remuneracao");
          break;
        case "acoesDesenvolvimento":
          setCurrentKeyPage("pdi");
          break;
        case "reports":
          setCurrentKeyPage("reports");
          break;
        case "configJustaCausa":
          setCurrentKeyPage("justaCausa");
          break;
        default:
          setCurrentKeyPage(pageState.page.key);
      }
    }
  }, [pageState]);

  const resolveNavLink = (): INavLinkGroup[] => {
    let profile = "";

    if (loginState.data.flagAdministrador) {
      if (isAdminPage) {
        profile = "adminAdminstrador";
      } else {
        profile = "admin";
      }
    } else if (loginState.data.flagRH) {
      if (isAdminPage) {
        profile = "adminRh";
      } else {
        profile = "rh";
      }
    } else if (loginState.data.flagEfetividade) {
      profile = "efetividade";
    } else if (loginState.data.flagBP) {
      profile = "bp";
    } else {
      profile = "user";
    }

    let nav: INavLink[] = [];

    switch (profile) {
      case "adminAdminstrador":
        nav = [
          {
            name: "Página Inicial",
            url: "",
            key: "home",
            iconProps: { iconName: "Home", styles: iconStyles },
            automationId: "1",
            onClick: () => history.push("/"),
          },
          {
            name: "Cadastros",
            url: "",
            key: "cadastros",
            iconProps: { iconName: "Edit", styles: iconStyles },
            expandAriaLabel: "Expandir seção de cadastros",
            collapseAriaLabel: "Recolher seção de cadastros",
            automationId: "2",
            onClick: () =>
              handleMenuExpanded(setIsCadastroMenuOpen, isCadastroMenuOpen),
            links: [
              {
                name: "Empresas",
                url: "",
                key: "empresas",
                onClick: () => history.push("/admin/empresas"),
              },
              {
                name: "Unidades",
                url: "",
                key: "unidades",
                onClick: () => history.push("/admin/unidades"),
              },
              {
                name: "Organograma",
                url: "",
                key: "organograma",
                onClick: () => history.push("/admin/organograma"),
              },
            ],
            isExpanded: isCadastroMenuOpen,
          },
          {
            name: "Cargos & Salários",
            url: "",
            key: "cargoSalario",
            iconProps: { iconName: "Money", styles: iconStyles },
            expandAriaLabel: "Expandir seção de cargos & salários",
            collapseAriaLabel: "Recolher seção de cargos & salários",
            isExpanded: isCargoSalarioMenuOpen,
            automationId: "3",
            onClick: () =>
              handleMenuExpanded(
                setIsCargoSalarioMenuOpen,
                isCargoSalarioMenuOpen
              ),
            links: [
              {
                name: "Cargos",
                url: "",
                key: "cargos",
                onClick: () => history.push("/admin/cargos"),
              },
              {
                name: "Configurações",
                url: "",
                key: "configEstruturaSalarial",
                onClick: () => history.push("/admin/configuracao-es"),
              },
              {
                name: "Configurações do Mérito",
                url: "",
                key: "configMerito",
                onClick: () => history.push("/admin/configuracao-merito"),
              },
              {
                name: "Estruturas Salariais",
                url: "",
                key: "estruturasalarial",
                onClick: () => history.push("/admin/estruturas-salariais"),
              },
            ],
          },
          {
            name: "Tabelas Auxiliares",
            url: "",
            key: "tabAux",
            iconProps: { iconName: "Table", styles: iconStyles },
            expandAriaLabel: "Expandir seção de tabelas auxiliares",
            collapseAriaLabel: "Recolher seção de tabelas auxiliares",
            isExpanded: isTabAuxMenuOpen,
            automationId: "4",
            onClick: () =>
              handleMenuExpanded(setIsTabAuxMenuOpen, isTabAuxMenuOpen),
            links: [
              {
                name: "Categorias de Cargos",
                url: "",
                key: "categoriasCargos",
                onClick: () => history.push("/admin/categorias-cargos"),
              },
              {
                name: "Tabelas de Performance",
                url: "",
                key: "tabelasPerformance",
                onClick: () => history.push("/admin/tabelas-performance"),
              },
              {
                name: "Motivos de Afastamentos",
                url: "",
                key: "motivosAfastamentos",
                onClick: () => history.push("/admin/motivos-afastamentos"),
              },
              {
                name: "Motivos de Desligamentos",
                url: "",
                key: "motivosDesligamentos",
                onClick: () => history.push("/admin/motivos-desligamentos"),
              },
              {
                name: "Motivos de Faltas",
                url: "",
                key: "motivosFaltas",
                onClick: () => history.push("/admin/motivos-faltas"),
              },
              {
                name: "Padrões de Cargos",
                url: "",
                key: "padroesCargos",
                onClick: () => history.push("/admin/padroes-cargos"),
              },
              {
                name: "Tipos de Movimentações",
                url: "",
                key: "tiposMovimentacoes",
                onClick: () => history.push("/admin/tipos-movimentacoes"),
              },
              {
                name: "Vínculos Empregatícios",
                url: "",
                key: "vinculos",
                onClick: () => history.push("/admin/vinculos"),
              },
            ],
          },
          {
            name: "Medidas Educacionais",
            url: "",
            key: "medidas",
            iconProps: { iconName: "PeopleAlert", styles: iconStyles },
            expandAriaLabel: "Expandir seção de pdi",
            collapseAriaLabel: "Recolher seção de pdi",
            isExpanded: isMedidasMenuOpen,
            automationId: "5",
            onClick: () =>
              handleMenuExpanded(setIsMedidasMenuOpen, isMedidasMenuOpen),
            links: [
              {
                name: "Sanções",
                url: "",
                key: "sancoes",
                onClick: () => history.push("/admin/sancoes"),
              },
              {
                name: "Tipos de Condutas",
                url: "",
                key: "tipoConduta",
                onClick: () => history.push("/admin/tipoConduta"),
              },
              {
                name: "Condutas",
                url: "",
                key: "condutas",
                onClick: () => history.push("/admin/condutas"),
              },
            ],
          },
          {
            name: "PDI",
            url: "",
            key: "pdi",
            iconProps: { iconName: "BIDashboard", styles: iconStyles },
            expandAriaLabel: "Expandir seção de pdi",
            collapseAriaLabel: "Recolher seção de pdi",
            isExpanded: isPdiMenuOpen,
            automationId: "5",
            onClick: () => handleMenuExpanded(setIsPdiMenuOpen, isPdiMenuOpen),
            links: [
              {
                name: "Ações de Desenvolvimento",
                url: "",
                key: "acoesDesenvolvimento",
                onClick: () => history.push("/admin/acoes-desenvolvimento"),
              },
            ],
          },
          // {
          //   name: "Relatórios",
          //   url: "",
          //   key: "reports",
          //   iconProps: { iconName: "ReportDocument", styles: iconStyles },
          //   expandAriaLabel: "Expandir seção de relatórios",
          //   collapseAriaLabel: "Recolher seção de relatórios",
          //   isExpanded: isReportMenuOpen,
          //   automationId: "5",
          //   onClick: () =>
          //     handleMenuExpanded(setIsReportMenuOpen, isReportMenuOpen),
          //   links: [
          //     {
          //       name: "Cadastros",
          //       url: "",
          //       key: "reportCadastros",
          //       onClick: () => history.push("/admin/relatorios"),
          //     },
          //   ],
          // },
          {
            name: "Justa Causa",
            url: "",
            key: "justaCausa",
            iconProps: { iconName: "BlockContact", styles: iconStyles },
            expandAriaLabel: "Expandir seção de justa causa",
            collapseAriaLabel: "Recolher seção de justa causa",
            isExpanded: isJustaCausaMenuOpen,
            automationId: "4",
            onClick: () =>
              handleMenuExpanded(setIsJustaCausaMenuOpen, isJustaCausaMenuOpen),
            links: [
              {
                name: "Configurações",
                url: "",
                key: "configJustaCausa",
                onClick: () => history.push("/admin/configuracao-justa-causa"),
              },
            ],
          },
        ];
        break;
      case "adminRh":
        nav.push(
          {
            name: "Página Inicial",
            url: "",
            key: "home",
            iconProps: { iconName: "Home", styles: iconStyles },
            automationId: "1",
            onClick: () => history.push("/"),
          },
          {
            name: "Cadastros",
            url: "",
            key: "cadastros",
            iconProps: { iconName: "Edit", styles: iconStyles },
            expandAriaLabel: "Expandir seção de cadastros",
            collapseAriaLabel: "Recolher seção de cadastros",
            automationId: "2",
            onClick: () =>
              handleMenuExpanded(setIsCadastroMenuOpen, isCadastroMenuOpen),
            links: [
              {
                name: "Empresas",
                url: "",
                key: "empresas",
                onClick: () => history.push("/admin/empresas"),
              },
              {
                name: "Unidades",
                url: "",
                key: "unidades",
                onClick: () => history.push("/admin/unidades"),
              },
              {
                name: "Organograma",
                url: "",
                key: "organograma",
                onClick: () => history.push("/admin/organograma"),
              },
            ],
            isExpanded: isCadastroMenuOpen,
          },
          {
            name: "Cargos & Salários",
            url: "",
            key: "cargoSalario",
            iconProps: { iconName: "Money", styles: iconStyles },
            expandAriaLabel: "Expandir seção de cargos & salários",
            collapseAriaLabel: "Recolher seção de cargos & salários",
            isExpanded: isCargoSalarioMenuOpen,
            automationId: "3",
            onClick: () =>
              handleMenuExpanded(
                setIsCargoSalarioMenuOpen,
                isCargoSalarioMenuOpen
              ),
            links: [
              {
                name: "Cargos",
                url: "",
                key: "cargos",
                onClick: () => history.push("/admin/cargos"),
              },
            ],
          },
          {
            name: "Tabelas Auxiliares",
            url: "",
            key: "tabAux",
            iconProps: { iconName: "Table", styles: iconStyles },
            expandAriaLabel: "Expandir seção de tabelas auxiliares",
            collapseAriaLabel: "Recolher seção de tabelas auxiliares",
            isExpanded: isTabAuxMenuOpen,
            automationId: "3",
            onClick: () =>
              handleMenuExpanded(setIsTabAuxMenuOpen, isTabAuxMenuOpen),
            links: [
              {
                name: "Categorias de Cargos",
                url: "",
                key: "categoriasCargos",
                onClick: () => history.push("/admin/categorias-cargos"),
              },
              {
                name: "Motivos de Afastamentos",
                url: "",
                key: "motivosAfastamentos",
                onClick: () => history.push("/admin/motivos-afastamentos"),
              },
              {
                name: "Motivos de Desligamentos",
                url: "",
                key: "motivosDesligamentos",
                onClick: () => history.push("/admin/motivos-desligamentos"),
              },
              {
                name: "Motivos de Faltas",
                url: "",
                key: "motivosFaltas",
                onClick: () => history.push("/admin/motivos-faltas"),
              },
              {
                name: "Padrões de Cargos",
                url: "",
                key: "padroesCargos",
                onClick: () => history.push("/admin/padroes-cargos"),
              },
              {
                name: "Tipos de Movimentações",
                url: "",
                key: "tiposMovimentacoes",
                onClick: () => history.push("/admin/tipos-movimentacoes"),
              },
              {
                name: "Vínculos Empregatícios",
                url: "",
                key: "vinculos",
                onClick: () => history.push("/admin/vinculos"),
              },
            ],
          },
          // {
          //   name: "Medidas Educacionais",
          //   url: "",
          //   key: "medidas",
          //   iconProps: { iconName: "PeopleAlert", styles: iconStyles },
          //   expandAriaLabel: "Expandir seção de pdi",
          //   collapseAriaLabel: "Recolher seção de pdi",
          //   isExpanded: isMedidasMenuOpen,
          //   automationId: "5",
          //   onClick: () =>
          //     handleMenuExpanded(setIsMedidasMenuOpen, isMedidasMenuOpen),
          //   links: [
          //     {
          //       name: "Sanções",
          //       url: "",
          //       key: "sancoes",
          //       onClick: () => history.push("/admin/sancoes"),
          //     },
          //     {
          //       name: "Tipos de Condutas",
          //       url: "",
          //       key: "tipoConduta",
          //       onClick: () => history.push("/admin/tipoConduta"),
          //     },
          //     {
          //       name: "Condutas",
          //       url: "",
          //       key: "condutas",
          //       onClick: () => history.push("/admin/condutas"),
          //     },
          //   ],
          // }
        );

        break;

      case "admin":
        nav.push(
          {
            name: "Funcionários",
            url: "",
            key: "funcionarios",
            iconProps: { iconName: "IDBadge", styles: iconStyles },
            automationId: "3",
            onClick: () => history.push("/funcionarios"),
          },
          {
            name: "Desempenho",
            url: "",
            key: "desempenho",
            iconProps: { iconName: "BIDashboard", styles: iconStyles },
            expandAriaLabel: "Expandir seção de desempenho",
            collapseAriaLabel: "Recolher seção de desempenho",
            isExpanded: isDesempenhoMenuOpen,
            automationId: "5",
            onClick: () =>
              handleMenuExpanded(setIsDesempenhoMenuOpen, isDesempenhoMenuOpen),
            links: [
              {
                name: "Atitudes",
                url: "",
                key: "atitudes",
                automationId: "1",
                onClick: () => history.push("/atitudes"),
              },
              {
                name: "Objetivos",
                url: "",
                key: "objetivos",
                automationId: "3",
                onClick: () => history.push("/objetivos"),
              },
              {
                name: "Pilares",
                url: "",
                key: "pilares",
                automationId: "4",
                onClick: () => history.push("/pilares"),
              },
            ],
          },
          {
            name: "Relatórios",
            url: "",
            key: "relatorios",
            iconProps: { iconName: "ReportDocument", styles: iconStyles },
            expandAriaLabel: "Expandir seção de relatórios",
            collapseAriaLabel: "Recolher seção de relatórios",
            isExpanded: isRelatoriosMenuOpen,
            automationId: "7",
            onClick: () =>
              handleMenuExpanded(setIsRelatoriosMenuOpen, isRelatoriosMenuOpen),
            links: [
              {
                name: "Curva de Performance",
                url: "",
                key: "relatorioCurvaPerformance",
                onClick: () => history.push("/relatorios/curva-performance"),
              },
              {
                name: "Sucessão",
                url: "",
                key: "relatorioSuccession",
                onClick: () => history.push("/relatorios/sucessao"),
              },
              {
                name: "Matriz de Talentos",
                url: "",
                key: "relatorioMatrizTalentos",
                onClick: () => history.push("/relatorios/matriz-talentos"),
              },
              {
                name: "Mérito",
                url: "",
                key: "relatorioMerito",
                onClick: () => history.push("/relatorios/merito"),
              },
            ],
          }
        );

      case "efetividade":
      case "rh":
      case "bp":
        if (profile === "efetividade" || profile === "admin") {
          if (nav.length > 0) {
            nav.forEach((navLink) => {
              if (navLink.name === "Desempenho") {
                navLink.links?.push({
                  name: "Ciclos",
                  url: "",
                  key: "ciclos",
                  automationId: "2",
                  onClick: () => history.push("/ciclos"),
                });
              }
            });
          } else {
            nav.push({
              name: "Desempenho",
              url: "",
              key: "desempenho",
              iconProps: { iconName: "BIDashboard", styles: iconStyles },
              expandAriaLabel: "Expandir seção de desempenho",
              collapseAriaLabel: "Recolher seção de desempenho",
              isExpanded: isDesempenhoMenuOpen,
              automationId: "5",
              links: [
                {
                  name: "Ciclos",
                  url: "",
                  key: "ciclos",
                  automationId: "2",
                  onClick: () => history.push("/ciclos"),
                },
              ],
            });
          }
        }
        if (profile === "bp" || profile === "admin") {
          if (nav.length > 0) {
            nav.forEach((navLink) => {
              if (navLink.name === "Desempenho") {
                navLink.links?.push({
                  name: "Reuniões",
                  url: "",
                  key: "reunioes",
                  automationId: "5",
                  onClick: () => history.push("/reunioes"),
                });
              }
              navLink.links?.sort(
                (a, b) => Number(a?.automationId) - Number(b?.automationId)
              );
            });
          } else {
            nav.push({
              name: "Desempenho",
              url: "",
              key: "desempenho",
              iconProps: { iconName: "BIDashboard", styles: iconStyles },
              expandAriaLabel: "Expandir seção de desempenho",
              collapseAriaLabel: "Recolher seção de desempenho",
              isExpanded: isDesempenhoMenuOpen,
              automationId: "5",
              links: [
                {
                  name: "Reuniões",
                  url: "",
                  key: "reunioes",
                  automationId: "5",
                  onClick: () => history.push("/reunioes"),
                },
              ],
            });
          }
        }
        if (profile === "rh" || profile === "admin") {
          nav.push({
            name: "Recompensas",
            url: "",
            key: "remuneracao",
            iconProps: { iconName: "Money", styles: iconStyles },
            expandAriaLabel: "Expandir seção de Recompensas",
            collapseAriaLabel: "Recolher seção de Recompensas",
            isExpanded: isRemuneracaoMenuOpen,
            automationId: "8",
            onClick: () =>
              handleMenuExpanded(
                setIsRemuneracaoMenuOpen,
                isRemuneracaoMenuOpen
              ),
            links: [
              {
                name: "Bônus",
                url: "",
                key: "bonus",
                onClick: () => history.push("/bonus"),
              },
              {
                name: "Mérito",
                url: "",
                key: "merito",
                onClick: () => history.push("/merito"),
              },
            ],
          });
        }
      case "user":
        nav.push(
          {
            name: "Página Inicial",
            url: "",
            key: "home",
            iconProps: { iconName: "Home", styles: iconStyles },
            automationId: "1",
            onClick: () => history.push("/"),
          },
          {
            name: "Perfil",
            url: "",
            key: "perfil",
            iconProps: { iconName: "Contact", styles: iconStyles },
            automationId: "2",
            onClick: () => history.push("/perfil"),
          },
          {
            name: "Painel WorkFlow",
            url: "",
            key: "workflow",
            iconProps: { iconName: "TaskLogo", styles: iconStyles },
            automationId: "6",
            onClick: () => {
              dispatch(CreatorsWorkflow.setCurrentPage("0"));
              history.push("/workflow");
            },
          },
          {
            name: "Meu Time",
            url: "",
            onClick: () => history.push("/time"),
            key: "myTeam",
            iconProps: { iconName: "Teamwork", styles: iconStyles },
            automationId: "4",
          }
        );
    }

    return [
      {
        links: nav.sort(
          (a, b) => Number(a.automationId) - Number(b.automationId)
        ),
      },
    ];
  };

  const navStyles: Partial<INavStyles> = {
    root: {
      width: pageState.isMenuExpanded ? "250px" : "48px",
      transition: "width 0.3s",
      height: "calc(100vh - 50px)",
      backgroundColor: "#CFD3DA",
      boxSizing: "border-box",
      overflowX: "hidden",
      overflowY: "auto",
      selectors: {
        "[aria-current=page]": {
          backgroundColor: "#F5F6F7",
        },
        "li ul[role=list]": {
          backgroundColor: "#EBEDF0",
          display: pageState.isMenuExpanded ? "block" : "none",
          selectors: {
            "li div button": {
              height: 32,
            },
            "&hover ": {
              height: 32,
            },
          },
        },
      },
    },
    chevronIcon: {
      marginLeft: 8,
      marginRight: 11,
    },
    chevronButton: {
      width: 40,
      left: 210,
      selectors: {
        "&:after": {
          border: "none",
        },
      },
    },
    linkText: {
      color: "#323130 !important",
    },
  };

  const handleMenuExpanded = (
    handleMenuOpen: (value: React.SetStateAction<boolean>) => void,
    value: boolean
  ) => {
    if (pageState.isMenuExpanded) {
      handleMenuOpen(!value);
    } else {
      handleMenuOpen(true);
    }
    dispatch(Creators.setMenuExpanded(true));
  };

  return (
    <div style={{ zIndex: 10 }}>
      <Nav
        selectedKey={
          pageState.isMenuExpanded ? pageState.page.key : currentKeyPage
        }
        ariaLabel="Menu"
        styles={navStyles}
        groups={resolveNavLink()}
      />
    </div>
  );
};

const iconStyles = {
  root: {
    color: "#323130",
    marginLeft: 10,
    marginRight: 11,
  },
};

export default withRouter(NavBar);
