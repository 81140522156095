import { ChartType } from "../admin/chart/types";
import { MotivosFaltasLoadDTO } from "../admin/motivoFalta/types";
import { PositionTypes } from "../admin/positions/types";
import { IUnit } from "../admin/units/types";
import { VinculosLoadDTO } from "../admin/vinculo/types";
import { CCType } from "../cc/types";

/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_EMPLOYEES_LOAD = "employees/REQUEST_EMPLOYEES_LOAD",
  SUCCESS_EMPLOYEES_LOAD = "employees/SUCCESS_EMPLOYEES_LOAD",
  FAILURE_EMPLOYEES_LOAD = "employees/FAILURE_EMPLOYEES_LOAD",
  //GET BY ID
  REQUEST_EMPLOYEES_LOAD_ID = "employees/REQUEST_EMPLOYEES_LOAD_ID",
  SUCCESS_EMPLOYEES_LOAD_ID = "employees/SUCCESS_EMPLOYEES_LOAD_ID",
  FAILURE_EMPLOYEES_LOAD_ID = "employees/FAILURE_EMPLOYEES_LOAD_ID",
  //GET INFO
  REQUEST_EMPLOYEES_LOAD_INFO = "employees/REQUEST_EMPLOYEES_LOAD_INFO",
  SUCCESS_EMPLOYEES_LOAD_INFO = "employees/SUCCESS_EMPLOYEES_LOAD_INFO",
  FAILURE_EMPLOYEES_LOAD_INFO = "employees/FAILURE_EMPLOYEES_LOAD_INFO",
  //ADD
  REQUEST_EMPLOYEES_ADD = "employees/REQUEST_EMPLOYEES_EMAIL",
  SUCCESS_EMPLOYEES_ADD = "employees/SUCCESS_EMPLOYEES_ADD",
  FAILURE_EMPLOYEES_ADD = "employees/FAILURE_EMPLOYEES_ADD",
  //PUT
  REQUEST_EMPLOYEES_EDIT = "employees/REQUEST_EMPLOYEES_EDIT",
  SUCCESS_EMPLOYEES_EDIT = "employees/SUCCESS_EMPLOYEES_EDIT",
  FAILURE_EMPLOYEES_EDIT = "employees/FAILURE_EMPLOYEES_EDIT",
  //PUT DADOS CADASTRAIS
  REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS = "employees/REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS",
  SUCCESS_EMPLOYEES_EDIT_DADOS_CADASTRAIS = "employees/SUCCESS_EMPLOYEES_EDIT_DADOS_CADASTRAIS",
  FAILURE_EMPLOYEES_EDIT_DADOS_CADASTRAIS = "employees/FAILURE_EMPLOYEES_EDIT_DADOS_CADASTRAIS",
  //PUT PASSWORD
  REQUEST_EMPLOYEES_EDIT_ACCESS = "employees/REQUEST_EMPLOYEES_EDIT_ACCESS",
  SUCCESS_EMPLOYEES_EDIT_ACCESS = "employees/SUCCESS_EMPLOYEES_EDIT_ACCESS",
  FAILURE_EMPLOYEES_EDIT_ACCESS = "employees/FAILURE_EMPLOYEES_EDIT_ACCESS",
  //PUT ACCESS
  REQUEST_EMPLOYEES_EDIT_PASSWORD = "employees/REQUEST_EMPLOYEES_EDIT_PASSWORD",
  SUCCESS_EMPLOYEES_EDIT_PASSWORD = "employees/SUCCESS_EMPLOYEES_EDIT_PASSWORD",
  FAILURE_EMPLOYEES_EDIT_PASSWORD = "employees/FAILURE_EMPLOYEES_EDIT_PASSWORD",
  //DELETE
  REQUEST_EMPLOYEES_DELETE = "employees/REQUEST_EMPLOYEES_DELETE",
  SUCCESS_EMPLOYEES_DELETE = "employees/SUCCESS_EMPLOYEES_DELETE",
  FAILURE_EMPLOYEES_DELETE = "employees/FAILURE_EMPLOYEES_DELETE",
  // GET FUNC_AREAS
  REQUEST_EMPLOYEES_AREAS_LOAD = "employees/REQUEST_EMPLOYEES_AREAS_LOAD",
  SUCCESS_EMPLOYEES_AREAS_LOAD = "employees/SUCCESS_EMPLOYEES_AREAS_LOAD",
  FAILURE_EMPLOYEES_AREAS_LOAD = "employees/FAILURE_EMPLOYEES_AREAS_LOAD",
  // ADD FUNC_AREAS
  REQUEST_EMPLOYEES_AREA_ADD = "employees/REQUEST_EMPLOYEES_AREA_ADD",
  SUCCESS_EMPLOYEES_AREA_ADD = "employees/SUCCESS_EMPLOYEES_AREA_ADD",
  FAILURE_EMPLOYEES_AREA_ADD = "employees/FAILURE_EMPLOYEES_AREA_ADD",
  // DEL FUNC_AREAS
  REQUEST_EMPLOYEES_AREA_DEL = "employees/REQUEST_EMPLOYEES_AREA_DEL",
  SUCCESS_EMPLOYEES_AREA_DEL = "employees/SUCCESS_EMPLOYEES_AREA_DEL",
  FAILURE_EMPLOYEES_AREA_DEL = "employees/FAILURE_EMPLOYEES_AREA_DEL",
  // GET CC
  REQUEST_EMPLOYEES_CC_LOAD = "employees/REQUEST_EMPLOYEES_CC_LOAD",
  SUCCESS_EMPLOYEES_CC_LOAD = "employees/SUCCESS_EMPLOYEES_CC_LOAD",
  FAILURE_EMPLOYEES_CC_LOAD = "employees/FAILURE_EMPLOYEES_CC_LOAD",
  // ADD CC
  REQUEST_EMPLOYEES_CC_ADD = "employees/REQUEST_EMPLOYEES_CC_ADD",
  SUCCESS_EMPLOYEES_CC_ADD = "employees/SUCCESS_EMPLOYEES_CC_ADD",
  FAILURE_EMPLOYEES_CC_ADD = "employees/FAILURE_EMPLOYEES_CC_ADD",
  // DEL CC
  REQUEST_EMPLOYEES_CC_DEL = "employees/REQUEST_EMPLOYEES_CC_DEL",
  SUCCESS_EMPLOYEES_CC_DEL = "employees/SUCCESS_EMPLOYEES_CC_DEL",
  FAILURE_EMPLOYEES_CC_DEL = "employees/FAILURE_EMPLOYEES_CC_DEL",
  // GET UNIDADES
  REQUEST_EMPLOYEES_UNIDADES_LOAD = "employees/REQUEST_EMPLOYEES_UNIDADES_LOAD",
  SUCCESS_EMPLOYEES_UNIDADES_LOAD = "employees/SUCCESS_EMPLOYEES_UNIDADES_LOAD",
  FAILURE_EMPLOYEES_UNIDADES_LOAD = "employees/FAILURE_EMPLOYEES_UNIDADES_LOAD",
  // ADD UNIDADES
  REQUEST_EMPLOYEES_UNIDADES_ADD = "employees/REQUEST_EMPLOYEES_UNIDADES_ADD",
  SUCCESS_EMPLOYEES_UNIDADES_ADD = "employees/SUCCESS_EMPLOYEES_UNIDADES_ADD",
  FAILURE_EMPLOYEES_UNIDADES_ADD = "employees/FAILURE_EMPLOYEES_UNIDADES_ADD",
  // DEL UNIDADES
  REQUEST_EMPLOYEES_UNIDADES_DEL = "employees/REQUEST_EMPLOYEES_UNIDADES_DEL",
  SUCCESS_EMPLOYEES_UNIDADES_DEL = "employees/SUCCESS_EMPLOYEES_UNIDADES_DEL",
  FAILURE_EMPLOYEES_UNIDADES_DEL = "employees/FAILURE_EMPLOYEES_UNIDADES_DEL",
  // GET CARGOS
  REQUEST_EMPLOYEES_CARGOS_LOAD = "employees/REQUEST_EMPLOYEES_CARGOS_LOAD",
  SUCCESS_EMPLOYEES_CARGOS_LOAD = "employees/SUCCESS_EMPLOYEES_CARGOS_LOAD",
  FAILURE_EMPLOYEES_CARGOS_LOAD = "employees/FAILURE_EMPLOYEES_CARGOS_LOAD",
  // ADD CARGOS
  REQUEST_EMPLOYEES_CARGOS_ADD = "employees/REQUEST_EMPLOYEES_CARGOS_ADD",
  SUCCESS_EMPLOYEES_CARGOS_ADD = "employees/SUCCESS_EMPLOYEES_CARGOS_ADD",
  FAILURE_EMPLOYEES_CARGOS_ADD = "employees/FAILURE_EMPLOYEES_CARGOS_ADD",
  // EDIT CARGOS
  REQUEST_EMPLOYEES_CARGOS_EDIT = "employees/REQUEST_EMPLOYEES_CARGOS_EDIT",
  SUCCESS_EMPLOYEES_CARGOS_EDIT = "employees/SUCCESS_EMPLOYEES_CARGOS_EDIT",
  FAILURE_EMPLOYEES_CARGOS_EDIT = "employees/FAILURE_EMPLOYEES_CARGOS_EDIT",
  // DEL CARGOS
  REQUEST_EMPLOYEES_CARGOS_DEL = "employees/REQUEST_EMPLOYEES_CARGOS_DEL",
  SUCCESS_EMPLOYEES_CARGOS_DEL = "employees/SUCCESS_EMPLOYEES_CARGOS_DEL",
  FAILURE_EMPLOYEES_CARGOS_DEL = "employees/FAILURE_EMPLOYEES_CARGOS_DEL",
  // GET SALARIOS
  REQUEST_EMPLOYEES_SALARIOS_LOAD = "employees/REQUEST_EMPLOYEES_SALARIOS_LOAD",
  SUCCESS_EMPLOYEES_SALARIOS_LOAD = "employees/SUCCESS_EMPLOYEES_SALARIOS_LOAD",
  FAILURE_EMPLOYEES_SALARIOS_LOAD = "employees/FAILURE_EMPLOYEES_SALARIOS_LOAD",
  // ADD SALARIOS
  REQUEST_EMPLOYEES_SALARIOS_ADD = "employees/REQUEST_EMPLOYEES_SALARIOS_ADD",
  SUCCESS_EMPLOYEES_SALARIOS_ADD = "employees/SUCCESS_EMPLOYEES_SALARIOS_ADD",
  FAILURE_EMPLOYEES_SALARIOS_ADD = "employees/FAILURE_EMPLOYEES_SALARIOS_ADD",
  // EDIT SALARIOS
  REQUEST_EMPLOYEES_SALARIOS_EDIT = "employees/REQUEST_EMPLOYEES_SALARIOS_EDIT",
  SUCCESS_EMPLOYEES_SALARIOS_EDIT = "employees/SUCCESS_EMPLOYEES_SALARIOS_EDIT",
  FAILURE_EMPLOYEES_SALARIOS_EDIT = "employees/FAILURE_EMPLOYEES_SALARIOS_EDIT",
  // DEL SALARIOS
  REQUEST_EMPLOYEES_SALARIOS_DEL = "employees/REQUEST_EMPLOYEES_SALARIOS_DEL",
  SUCCESS_EMPLOYEES_SALARIOS_DEL = "employees/SUCCESS_EMPLOYEES_SALARIOS_DEL",
  FAILURE_EMPLOYEES_SALARIOS_DEL = "employees/FAILURE_EMPLOYEES_SALARIOS_DEL",
  // GET OCORRÊNCIAS
  REQUEST_EMPLOYEES_OCCURRENCES_LOAD = "employees/REQUEST_EMPLOYEES_OCCURRENCES_LOAD",
  SUCCESS_EMPLOYEES_OCCURRENCES_LOAD = "employees/SUCCESS_EMPLOYEES_OCCURRENCES_LOAD",
  FAILURE_EMPLOYEES_OCCURRENCES_LOAD = "employees/FAILURE_EMPLOYEES_OCCURRENCES_LOAD",
  // ADD OCORRÊNCIA
  REQUEST_EMPLOYEES_OCCURRENCES_ADD = "employees/REQUEST_EMPLOYEES_OCCURRENCES_ADD",
  SUCCESS_EMPLOYEES_OCCURRENCES_ADD = "employees/SUCCESS_EMPLOYEES_OCCURRENCES_ADD",
  FAILURE_EMPLOYEES_OCCURRENCES_ADD = "employees/FAILURE_EMPLOYEES_OCCURRENCES_ADD",
  // EDIT OCORRÊNCIA
  REQUEST_EMPLOYEES_OCCURRENCES_EDIT = "employees/REQUEST_EMPLOYEES_OCCURRENCES_EDIT",
  SUCCESS_EMPLOYEES_OCCURRENCES_EDIT = "employees/SUCCESS_EMPLOYEES_OCCURRENCES_EDIT",
  FAILURE_EMPLOYEES_OCCURRENCES_EDIT = "employees/FAILURE_EMPLOYEES_OCCURRENCES_EDIT",
  // DEL OCORRÊNCIA
  REQUEST_EMPLOYEES_OCCURRENCES_DEL = "employees/REQUEST_EMPLOYEES_OCCURRENCES_DEL",
  SUCCESS_EMPLOYEES_OCCURRENCES_DEL = "employees/SUCCESS_EMPLOYEES_OCCURRENCES_DEL",
  FAILURE_EMPLOYEES_OCCURRENCES_DEL = "employees/FAILURE_EMPLOYEES_OCCURRENCES_DEL",
  // GET FALTAS
  REQUEST_EMPLOYEES_FALTAS_LOAD = "employees/REQUEST_EMPLOYEES_FALTAS_LOAD",
  SUCCESS_EMPLOYEES_FALTAS_LOAD = "employees/SUCCESS_EMPLOYEES_FALTAS_LOAD",
  FAILURE_EMPLOYEES_FALTAS_LOAD = "employees/FAILURE_EMPLOYEES_FALTAS_LOAD",
  // ADD FALTA
  REQUEST_EMPLOYEES_FALTA_ADD = "employees/REQUEST_EMPLOYEES_FALTA_ADD",
  SUCCESS_EMPLOYEES_FALTA_ADD = "employees/SUCCESS_EMPLOYEES_FALTA_ADD",
  FAILURE_EMPLOYEES_FALTA_ADD = "employees/FAILURE_EMPLOYEES_FALTA_ADD",
  // EDIT FALTA
  REQUEST_EMPLOYEES_FALTA_EDIT = "employees/REQUEST_EMPLOYEES_FALTA_EDIT",
  SUCCESS_EMPLOYEES_FALTA_EDIT = "employees/SUCCESS_EMPLOYEES_FALTA_EDIT",
  FAILURE_EMPLOYEES_FALTA_EDIT = "employees/FAILURE_EMPLOYEES_FALTA_EDIT",
  // DEL FALTA
  REQUEST_EMPLOYEES_FALTA_DEL = "employees/REQUEST_EMPLOYEES_FALTA_DEL",
  SUCCESS_EMPLOYEES_FALTA_DEL = "employees/SUCCESS_EMPLOYEES_FALTA_DEL",
  FAILURE_EMPLOYEES_FALTA_DEL = "employees/FAILURE_EMPLOYEES_FALTA_DEL",
  //RESET
  RESET_EMPLOYEES = "employees/RESET_EMPLOYEES",
}
/**
 * Data types
 */

export type EmployeeSelected = {
  item: EmployeeType;
  areas: EmployeeAreaType[] | null;
  cc: EmployeeCCType[] | null;
  unidades: EmployeeUnidadeType[] | null;
  cargos: EmployeeCargoType[] | null;
  salarios: EmployeeSalaryType[] | null;
  ocorrencias: EmployeeOccurrenceType[] | null;
  faltas: EmployeeFaltaType[] | null;
  loading: boolean;
  loadingItems: boolean;
  loadingAction: boolean;
  success: boolean;
  error: boolean;
};

export type EmployeeType = {
  descAcesso: string;
  idFuncionario: number | null;
  matricula: string;
  nomeCompleto: string;
  dataNascimento?: Date | null;
  email: string;
  status: string;
  sexo: string;
  dataAdmissao: Date | null;
  dataDemissao?: Date | null;
  cpf: string;
  //unidade: Partial<IUnit> | null;
  unidades: UnidadesTypes[] | null;
  cargo: Partial<PositionTypes> | null;
  area: Partial<ChartType> | null;
  cc: Partial<CCType> | null;
  salario: number | null;
  flagAcessoRHR: boolean;
  flagAdministrador: boolean;
  flagRH: boolean;
  flagBP: boolean | null;
  imagem: string;
  password: string;
  idVinculo: number | null;
  vinculo: VinculosLoadDTO | null;
};

export type UnidadesTypes = {
  idUnidade: number | null;
  unidade: Partial<IUnit> | null;
};

export type EmployeeSalaryType = {
  idHistSalario?: number | null;
  dataHist: Date | null;
  sequencia: number | null;
  idTipoMov: number | null;
  perc: string | number;
  salario: string | number;
  tipomov: {
    idTipoMov: number | null;
    descTipoMov: string;
    nomeAmigavel?: string;
  };
};

export type EmployeeAreaType = {
  idHistArea?: number;
  dataHist: Date | null;
  idArea: number | null;
  area: {
    nomeLocal: string;
  };
};

export type EmployeeCCType = {
  idHistCC?: number;
  dataHist: Date | null;
  idCC: number | null;
  cc: {
    nomeCC: string;
  };
};

export type EmployeeUnidadeType = {
  idHistUnidade?: number;
  dataHist: Date | null;
  idUnidade: number | null;
  unidade: {
    nomeUnidade: string;
  };
};

export type EmployeeCargoType = {
  idHistCargo?: number;
  dataHist: Date | null;
  idCargo: number | null;
  cargo: {
    idCargo: number | null;
    titulo: string;
  };
  idTipoMov: number | null;
  tipomov: {
    idTipoMov: number | null;
    descTipoMov: string;
  } | null;
};

export type EmployeeOccurrenceType = {
  idFuncOcorrencia?: number;
  dataInicio: Date | null;
  dataRetorno: Date | null;
  cid: string;
  idMotivoAfastamento: number | null;
  motivoAfastamento: {
    idMotivoAfastamento: number | null;
    descMotivoAfastamento: string;
  } | null;
};

export type EmployeeFaltaType = {
  idHistFalta?: number;
  dataHist: Date | null;
  horario: Date | string | null;
  idMotivoFalta: number | null;
  motivo: MotivosFaltasLoadDTO | null;
};

export type EmployeeInfoType = {
  idFuncionario: number | null;
  matricula: number | null;
  nomeCompleto: string;
};

interface IEmployeesState {
  readonly data: EmployeeType[];
  readonly info: EmployeeInfoType;
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly itemSelected: EmployeeSelected;
  readonly error: boolean;
  readonly success: boolean;
  readonly successEdit: boolean;
}

export type DataTypes = IEmployeesState;

/**
 * Actions
 */

//GET
export interface IRequestEmployeesLoadAction {
  type: typeof Types.REQUEST_EMPLOYEES_LOAD;
  search: object;
}

export interface ISuccessEmployeesLoadAction {
  type: typeof Types.SUCCESS_EMPLOYEES_LOAD;
  payload: EmployeeType[];
}

interface IFailureEmployeesLoadAction {
  type: typeof Types.FAILURE_EMPLOYEES_LOAD;
  message: string;
}

type EmployeesLoadAction =
  | IRequestEmployeesLoadAction
  | ISuccessEmployeesLoadAction
  | IFailureEmployeesLoadAction;

//GET
export interface IRequestEmployeesLoadIDAction {
  type: typeof Types.REQUEST_EMPLOYEES_LOAD_ID;
  id: number;
}

interface ISuccessEmployeesLoadIDAction {
  type: typeof Types.SUCCESS_EMPLOYEES_LOAD_ID;
  payload: EmployeeType;
}

interface IFailureEmployeesLoadIDAction {
  type: typeof Types.FAILURE_EMPLOYEES_LOAD_ID;
  message: string;
}

type EmployeesLoadIDAction =
  | IRequestEmployeesLoadIDAction
  | ISuccessEmployeesLoadIDAction
  | IFailureEmployeesLoadIDAction;

//GET
export interface IRequestEmployeesLoadInfoAction {
  type: typeof Types.REQUEST_EMPLOYEES_LOAD_INFO;
  id: number;
}

interface ISuccessEmployeesLoadInfoAction {
  type: typeof Types.SUCCESS_EMPLOYEES_LOAD_INFO;
  payload: EmployeeInfoType;
}

interface IFailureEmployeesLoadInfoAction {
  type: typeof Types.FAILURE_EMPLOYEES_LOAD_INFO;
  message: string;
}

type EmployeesLoadInfoAction =
  | IRequestEmployeesLoadInfoAction
  | ISuccessEmployeesLoadInfoAction
  | IFailureEmployeesLoadInfoAction;

//ADD
export interface IRequestEmployeeAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_ADD;
  employee: EmployeeType;
}

interface ISuccessEmployeeAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_ADD;
  employee: EmployeeType;
}

interface IFailureEmployeeAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_ADD;
  message: string;
}

type EmployeeAddAction =
  | IRequestEmployeeAddAction
  | ISuccessEmployeeAddAction
  | IFailureEmployeeAddAction;

//PUT
export interface IRequestEmployeeEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT;
  employee: EmployeeType;
}

export interface ISuccessEmployeeEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT;
  employee: EmployeeType;
}

export interface IFailureEmployeeEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT;
  message: string;
}

type EmployeeEditAction =
  | IRequestEmployeeEditAction
  | ISuccessEmployeeEditAction
  | IFailureEmployeeEditAction;

//PUT
export interface IRequestEmployeeEditDadosCadastraisAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS;
  payload: {
    idFuncionario: number;
    nomeCompleto: string;
    cpf: string;
    dataNascimento: Date;
    email: string;
    sexo: string;
  };
}

export interface ISuccessEmployeeEditDadosCadastraisAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT_DADOS_CADASTRAIS;
  employee: Partial<EmployeeType>;
}

export interface IFailureEmployeeEditDadosCadastraisAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT_DADOS_CADASTRAIS;
  message: string;
}

type EmployeeEditDadosCadastraisAction =
  | IRequestEmployeeEditDadosCadastraisAction
  | ISuccessEmployeeEditDadosCadastraisAction
  | IFailureEmployeeEditDadosCadastraisAction;

//PUT
export interface IRequestEmployeeEditPasswordAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT_PASSWORD;
  payload: {
    id: number;
  };
}

interface ISuccessEmployeeEditPasswordAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD;
}

export interface IFailureEmployeeEditPasswordAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT_PASSWORD;
  message: string;
}

type EmployeeEditPasswordAction =
  | IRequestEmployeeEditPasswordAction
  | ISuccessEmployeeEditPasswordAction
  | IFailureEmployeeEditPasswordAction;

//PUT
export interface IRequestEmployeeEditAccessAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT_ACCESS;
  payload: {
    id: number;
    flagAcessoRHR: boolean;
    descAcesso: string;
  };
}

export interface ISuccessEmployeeEditAccessAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT_ACCESS;
  payload: {
    flagAcessoRHR: boolean;
    descAcesso: string;
  };
}

export interface IFailureEmployeeEditAccessAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT_ACCESS;
  message: string;
}

type EmployeeEditAccessAction =
  | IRequestEmployeeEditAccessAction
  | ISuccessEmployeeEditAccessAction
  | IFailureEmployeeEditAccessAction;

//DELETE
export interface IRequestEmployeeDeleteAction {
  type: typeof Types.REQUEST_EMPLOYEES_DELETE;
  id: number;
}

export interface ISuccessEmployeeDeleteAction {
  type: typeof Types.SUCCESS_EMPLOYEES_DELETE;
  id: number;
}

export interface IFailureEmployeeDeleteAction {
  type: typeof Types.FAILURE_EMPLOYEES_DELETE;
  message: string;
}

type EmployeeDeleteAction =
  | IRequestEmployeeDeleteAction
  | ISuccessEmployeeDeleteAction
  | IFailureEmployeeDeleteAction;

//EMPLOYEES AREAS
export interface IRequestEmployeeAreasAction {
  type: typeof Types.REQUEST_EMPLOYEES_AREAS_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeAreasAction {
  type: typeof Types.SUCCESS_EMPLOYEES_AREAS_LOAD;
  payload: EmployeeAreaType[];
}

export interface IFailureEmployeeAreasAction {
  type: typeof Types.FAILURE_EMPLOYEES_AREAS_LOAD;
  message: string;
}

type EmployeeAreasAction =
  | IRequestEmployeeAreasAction
  | ISuccessEmployeeAreasAction
  | IFailureEmployeeAreasAction;

//ADD EMPLOYEES AREAS
export interface IRequestEmployeeAreasAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_AREA_ADD;
  payload: {
    idFuncionario: number;
    area: EmployeeAreaType;
  };
}

export interface ISuccessEmployeeAreasAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_AREA_ADD;
  payload: EmployeeAreaType;
}

export interface IFailureEmployeeAreasAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_AREA_ADD;
  message: string;
}

type EmployeeAreasAddAction =
  | IRequestEmployeeAreasAddAction
  | ISuccessEmployeeAreasAddAction
  | IFailureEmployeeAreasAddAction;

//DEL EMPLOYEES AREAS
export interface IRequestEmployeeAreasDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_AREA_DEL;
  payload: {
    idFuncionario: number;
    idHistArea: number;
  };
}

export interface ISuccessEmployeeAreasDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_AREA_DEL;
  idHistArea: number;
}

export interface IFailureEmployeeAreasDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_AREA_DEL;
  message: string;
}

type EmployeeAreasDelAction =
  | IRequestEmployeeAreasDelAction
  | ISuccessEmployeeAreasDelAction
  | IFailureEmployeeAreasDelAction;

//LOAD EMPLOYEES CC
export interface IRequestEmployeeCCAction {
  type: typeof Types.REQUEST_EMPLOYEES_CC_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeCCAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CC_LOAD;
  payload: EmployeeCCType[];
}

export interface IFailureEmployeeCCAction {
  type: typeof Types.FAILURE_EMPLOYEES_CC_LOAD;
  message: string;
}

type EmployeeCCAction =
  | IRequestEmployeeCCAction
  | ISuccessEmployeeCCAction
  | IFailureEmployeeCCAction;

// ADD EMPLOYEES CC
export interface IRequestEmployeeCCAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_CC_ADD;
  payload: {
    idFuncionario: number;
    cc: EmployeeCCType;
  };
}

export interface ISuccessEmployeeCCAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CC_ADD;
  payload: EmployeeCCType;
}

export interface IFailureEmployeeCCAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_CC_ADD;
  message: string;
}

type EmployeeCCAddAction =
  | IRequestEmployeeCCAddAction
  | ISuccessEmployeeCCAddAction
  | IFailureEmployeeCCAddAction;

// DEL EMPLOYEES CC
export interface IRequestEmployeeCCDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_CC_DEL;
  payload: {
    idFuncionario: number;
    idHistCC: number;
  };
}

export interface ISuccessEmployeeCCDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CC_DEL;
  idHistCC: number;
}

export interface IFailureEmployeeCCDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_CC_DEL;
  message: string;
}

type EmployeeCCDelAction =
  | IRequestEmployeeCCDelAction
  | ISuccessEmployeeCCDelAction
  | IFailureEmployeeCCDelAction;

//EMPLOYEES UNIDADES
export interface IRequestEmployeeUnidadesAction {
  type: typeof Types.REQUEST_EMPLOYEES_UNIDADES_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeUnidadesAction {
  type: typeof Types.SUCCESS_EMPLOYEES_UNIDADES_LOAD;
  payload: EmployeeUnidadeType[];
}

export interface IFailureEmployeeUnidadesAction {
  type: typeof Types.FAILURE_EMPLOYEES_UNIDADES_LOAD;
  message: string;
}

type EmployeeUnidadesAction =
  | IRequestEmployeeUnidadesAction
  | ISuccessEmployeeUnidadesAction
  | IFailureEmployeeUnidadesAction;

//EMPLOYEES UNIDADES
export interface IRequestEmployeeUnidadesAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_UNIDADES_ADD;
  payload: {
    idFuncionario: number;
    unidade: EmployeeUnidadeType;
  };
}

export interface ISuccessEmployeeUnidadesAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_UNIDADES_ADD;
  payload: EmployeeUnidadeType;
}

export interface IFailureEmployeeUnidadesAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_UNIDADES_ADD;
  message: string;
}

type EmployeeUnidadesAddAction =
  | IRequestEmployeeUnidadesAddAction
  | ISuccessEmployeeUnidadesAddAction
  | IFailureEmployeeUnidadesAddAction;

//EMPLOYEES UNIDADES
export interface IRequestEmployeeUnidadesDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_UNIDADES_DEL;
  payload: {
    idFuncionario: number;
    idHistUnidade: number;
  };
}

export interface ISuccessEmployeeUnidadesDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_UNIDADES_DEL;
  idHistUnidade: number;
}

export interface IFailureEmployeeUnidadesDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_UNIDADES_DEL;
  message: string;
}

type EmployeeUnidadesDelAction =
  | IRequestEmployeeUnidadesDelAction
  | ISuccessEmployeeUnidadesDelAction
  | IFailureEmployeeUnidadesDelAction;

//EMPLOYEES CARGOS
export interface IRequestEmployeeCargosAction {
  type: typeof Types.REQUEST_EMPLOYEES_CARGOS_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeCargosAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CARGOS_LOAD;
  payload: EmployeeCargoType[];
}

export interface IFailureEmployeeCargosAction {
  type: typeof Types.FAILURE_EMPLOYEES_CARGOS_LOAD;
  message: string;
}

type EmployeeCargosAction =
  | IRequestEmployeeCargosAction
  | ISuccessEmployeeCargosAction
  | IFailureEmployeeCargosAction;

//ADD EMPLOYEES CARGOS
export interface IRequestEmployeeCargosAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_CARGOS_ADD;
  payload: {
    idFuncionario: number;
    cargo: EmployeeCargoType;
  };
}

export interface ISuccessEmployeeCargosAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CARGOS_ADD;
  payload: EmployeeCargoType;
}

export interface IFailureEmployeeCargosAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_CARGOS_ADD;
  message: string;
}

type EmployeeCargosAddAction =
  | IRequestEmployeeCargosAddAction
  | ISuccessEmployeeCargosAddAction
  | IFailureEmployeeCargosAddAction;

//EDIT EMPLOYEES CARGOS
export interface IRequestEmployeeCargosEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_CARGOS_EDIT;
  payload: {
    idFuncionario: number;
    cargo: EmployeeCargoType;
  };
}

export interface ISuccessEmployeeCargosEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CARGOS_EDIT;
  payload: EmployeeCargoType;
}

export interface IFailureEmployeeCargosEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_CARGOS_EDIT;
  message: string;
}

type EmployeeCargosEditction =
  | IRequestEmployeeCargosEditAction
  | ISuccessEmployeeCargosEditAction
  | IFailureEmployeeCargosEditAction;

//DEL EMPLOYEES CARGOS
export interface IRequestEmployeeCargosDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_CARGOS_DEL;
  payload: {
    idFuncionario: number;
    idHistCargo: number;
  };
}

export interface ISuccessEmployeeCargosDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_CARGOS_DEL;
  idHistCargo: number;
}

export interface IFailureEmployeeCargosDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_CARGOS_DEL;
  message: string;
}

type EmployeeCargosDelAction =
  | IRequestEmployeeCargosDelAction
  | ISuccessEmployeeCargosDelAction
  | IFailureEmployeeCargosDelAction;

//LOAD EMPLOYEES SALARIOS
export interface IRequestEmployeeSalariosAction {
  type: typeof Types.REQUEST_EMPLOYEES_SALARIOS_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeSalariosAction {
  type: typeof Types.SUCCESS_EMPLOYEES_SALARIOS_LOAD;
  payload: EmployeeSalaryType[];
}

export interface IFailureEmployeeSalariosAction {
  type: typeof Types.FAILURE_EMPLOYEES_SALARIOS_LOAD;
  message: string;
}

type EmployeeSalariosAction =
  | IRequestEmployeeSalariosAction
  | ISuccessEmployeeSalariosAction
  | IFailureEmployeeSalariosAction;

//ADD EMPLOYEES SALARIOS
export interface IRequestEmployeeSalariosAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_SALARIOS_ADD;
  payload: {
    idFuncionario: number;
    salario: EmployeeSalaryType;
  };
}

export interface ISuccessEmployeeSalariosAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_SALARIOS_ADD;
  payload: EmployeeSalaryType;
}

export interface IFailureEmployeeSalariosAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_SALARIOS_ADD;
  message: string;
}

type EmployeeSalariosAddAction =
  | IRequestEmployeeSalariosAddAction
  | ISuccessEmployeeSalariosAddAction
  | IFailureEmployeeSalariosAddAction;

//EDIT EMPLOYEES SALARIOS
export interface IRequestEmployeeSalariosEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_SALARIOS_EDIT;
  payload: {
    idFuncionario: number;
    salario: EmployeeSalaryType;
  };
}

export interface ISuccessEmployeeSalariosEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_SALARIOS_EDIT;
  payload: EmployeeSalaryType;
}

export interface IFailureEmployeeSalariosEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_SALARIOS_EDIT;
  message: string;
}

type EmployeeSalariosEditAction =
  | IRequestEmployeeSalariosEditAction
  | ISuccessEmployeeSalariosEditAction
  | IFailureEmployeeSalariosEditAction;

//DEL EMPLOYEES SALARIOS
export interface IRequestEmployeeSalariosDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_SALARIOS_DEL;
  payload: {
    idFuncionario: number;
    idHistSalario: number;
  };
}

export interface ISuccessEmployeeSalariosDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_SALARIOS_DEL;
  idHistSalario: number;
}

export interface IFailureEmployeeSalariosDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_SALARIOS_DEL;
  message: string;
}

type EmployeeSalariosDelAction =
  | IRequestEmployeeSalariosDelAction
  | ISuccessEmployeeSalariosDelAction
  | IFailureEmployeeSalariosDelAction;

//LOAD EMPLOYEES OCCORÊNCIAS
export interface IRequestEmployeeOccurrencesLoadAction {
  type: typeof Types.REQUEST_EMPLOYEES_OCCURRENCES_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeOccurrencesLoadAction {
  type: typeof Types.SUCCESS_EMPLOYEES_OCCURRENCES_LOAD;
  payload: EmployeeOccurrenceType[];
}

export interface IFailureEmployeeOccurrencesLoadAction {
  type: typeof Types.FAILURE_EMPLOYEES_OCCURRENCES_LOAD;
  message: string;
}

type EmployeeOccurrencesLoadAction =
  | IRequestEmployeeOccurrencesLoadAction
  | ISuccessEmployeeOccurrencesLoadAction
  | IFailureEmployeeOccurrencesLoadAction;

//ADD EMPLOYEES OCCORÊNCIAS
export interface IRequestEmployeeOccurrencesAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_OCCURRENCES_ADD;
  payload: {
    idFuncionario: number;
    ocorrencia: EmployeeOccurrenceType;
  };
}

export interface ISuccessEmployeeOccurrencesAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_OCCURRENCES_ADD;
  payload: EmployeeOccurrenceType;
}

export interface IFailureEmployeeOccurrencesAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_OCCURRENCES_ADD;
  message: string;
}

type EmployeeOccurrencesAddAction =
  | IRequestEmployeeOccurrencesAddAction
  | ISuccessEmployeeOccurrencesAddAction
  | IFailureEmployeeOccurrencesAddAction;

//EDIT EMPLOYEES OCCORÊNCIAS
export interface IRequestEmployeeOccurrencesEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_OCCURRENCES_EDIT;
  payload: {
    idFuncionario: number;
    ocorrencia: EmployeeOccurrenceType;
  };
}

export interface ISuccessEmployeeOccurrencesEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_OCCURRENCES_EDIT;
  payload: EmployeeOccurrenceType;
}

export interface IFailureEmployeeOccurrencesEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_OCCURRENCES_EDIT;
  message: string;
}

type EmployeeOccurrencesEditAction =
  | IRequestEmployeeOccurrencesEditAction
  | ISuccessEmployeeOccurrencesEditAction
  | IFailureEmployeeOccurrencesEditAction;

//DEL EMPLOYEES OCCORÊNCIAS
export interface IRequestEmployeeOccurrencesDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_OCCURRENCES_DEL;
  payload: {
    idFuncionario: number;
    idFuncOcorrencia: number;
  };
}

export interface ISuccessEmployeeOccurrencesDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_OCCURRENCES_DEL;
  idFuncOcorrencia: number;
}

export interface IFailureEmployeeOccurrencesDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_OCCURRENCES_DEL;
  message: string;
}

type EmployeeOccurrencesDelAction =
  | IRequestEmployeeOccurrencesDelAction
  | ISuccessEmployeeOccurrencesDelAction
  | IFailureEmployeeOccurrencesDelAction;

//EMPLOYEES FALTAS
export interface IRequestEmployeeFaltasLoadAction {
  type: typeof Types.REQUEST_EMPLOYEES_FALTAS_LOAD;
  idFuncionario: number;
}

export interface ISuccessEmployeeFaltasLoadAction {
  type: typeof Types.SUCCESS_EMPLOYEES_FALTAS_LOAD;
  payload: EmployeeFaltaType[];
}

export interface IFailureEmployeeFaltasLoadAction {
  type: typeof Types.FAILURE_EMPLOYEES_FALTAS_LOAD;
  message: string;
}

type EmployeeFaltasLoadAction =
  | IRequestEmployeeFaltasLoadAction
  | ISuccessEmployeeFaltasLoadAction
  | IFailureEmployeeFaltasLoadAction;

//ADD EMPLOYEES FALTAS
export interface IRequestEmployeeFaltaAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_FALTA_ADD;
  payload: {
    idFuncionario: number;
    falta: EmployeeFaltaType;
  };
}

export interface ISuccessEmployeeFaltaAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_FALTA_ADD;
  payload: EmployeeFaltaType;
}

export interface IFailureEmployeeFaltaAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_FALTA_ADD;
  message: string;
}

type EmployeeFaltaAddAction =
  | IRequestEmployeeFaltaAddAction
  | ISuccessEmployeeFaltaAddAction
  | IFailureEmployeeFaltaAddAction;

//EDIT EMPLOYEES FALTAS
export interface IRequestEmployeeFaltaEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_FALTA_EDIT;
  payload: {
    idFuncionario: number;
    falta: EmployeeFaltaType;
  };
}

export interface ISuccessEmployeeFaltaEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_FALTA_EDIT;
  payload: EmployeeFaltaType;
}

export interface IFailureEmployeeFaltaEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_FALTA_EDIT;
  message: string;
}

type EmployeeFaltaEditAction =
  | IRequestEmployeeFaltaEditAction
  | ISuccessEmployeeFaltaEditAction
  | IFailureEmployeeFaltaEditAction;

//DEL EMPLOYEES FALTAS
export interface IRequestEmployeeFaltaDelAction {
  type: typeof Types.REQUEST_EMPLOYEES_FALTA_DEL;
  payload: {
    idFuncionario: number;
    idHistFalta: number;
  };
}

export interface ISuccessEmployeeFaltaDelAction {
  type: typeof Types.SUCCESS_EMPLOYEES_FALTA_DEL;
  idHistFalta: number;
}

export interface IFailureEmployeeFaltaDelAction {
  type: typeof Types.FAILURE_EMPLOYEES_FALTA_DEL;
  message: string;
}

type EmployeeFaltaDelAction =
  | IRequestEmployeeFaltaDelAction
  | ISuccessEmployeeFaltaDelAction
  | IFailureEmployeeFaltaDelAction;

interface IResetEmployeesAction {
  type: typeof Types.RESET_EMPLOYEES;
}

export type EmployeesActionTypes =
  | EmployeesLoadAction
  | EmployeesLoadIDAction
  | EmployeesLoadInfoAction
  | EmployeeAddAction
  | EmployeeEditAction
  | EmployeeEditDadosCadastraisAction
  | EmployeeEditPasswordAction
  | EmployeeEditAccessAction
  | EmployeeDeleteAction
  | IResetEmployeesAction
  | EmployeeAreasAction
  | EmployeeAreasAddAction
  | EmployeeAreasDelAction
  | EmployeeCCAction
  | EmployeeCCAddAction
  | EmployeeCCDelAction
  | EmployeeCargosAction
  | EmployeeCargosAddAction
  | EmployeeCargosEditction
  | EmployeeCargosDelAction
  | EmployeeSalariosAction
  | EmployeeSalariosAddAction
  | EmployeeSalariosEditAction
  | EmployeeCargosDelAction
  | EmployeeSalariosAction
  | EmployeeSalariosAddAction
  | EmployeeSalariosDelAction
  | EmployeeAreasDelAction
  | EmployeeUnidadesAction
  | EmployeeUnidadesAddAction
  | EmployeeUnidadesDelAction
  | EmployeeOccurrencesLoadAction
  | EmployeeOccurrencesAddAction
  | EmployeeOccurrencesEditAction
  | EmployeeOccurrencesDelAction
  | EmployeeFaltasLoadAction
  | EmployeeFaltaAddAction
  | EmployeeFaltaEditAction
  | EmployeeFaltaDelAction;
