import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Formik } from "formik";
import * as yup from "yup";

import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  DefaultButton,
  
  ICommandBarStyles,
  IDialogContentProps,
} from "office-ui-fabric-react";
import { Stack } from "office-ui-fabric-react";

import Panel from "~/components/layout/Panel";
import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import {
  InputAutocomplete,
  InputDate,
  InputTimeDialog,
} from "~/components/Forms";
import NoItems from "~/components/layout/NoItems";
import CustomDialog from "~/components/CustomDialogFluentUI";

import { Creators as loadEmployeeFaltas } from "~/store/ducks/employees";
import { Creators as addEmployeeFalta } from "~/store/ducks/employees";
import { Creators as editEmployeeFalta } from "~/store/ducks/employees";
import { Creators as delEmployeeFalta } from "~/store/ducks/employees";
import {
  EmployeeSelected,
  EmployeeFaltaType,
} from "~/store/ducks/employees/types";
import {
  MotivosFaltasLoadDTO,
  DataTypes as DataTypesMotivo,
} from "~/store/ducks/admin/motivoFalta/types";
import { Creators as getMotivosFaltas } from "~/store/ducks/admin/motivoFalta";

import { RootState } from "~/store/ducks";
import { Text } from "@fluentui/react";

interface IEmployeeFaltasState {
  columns: IColumn[];
  items: EmployeeFaltaType[];
  selectionDetails: string;
  selectionCount: number;
  motivoFaltaSelected: Partial<MotivosFaltasLoadDTO> | null;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  initialValues: EmployeeFaltaType;
}

interface IEmployeeFaltasProps {
  employee: EmployeeSelected;
  idFuncionario: number;
  motivoFaltaState: DataTypesMotivo;
  loadEmployeeFaltas: (idFuncionario: number) => void;
  addEmployeeFalta: (idFuncionario: number, falta: EmployeeFaltaType) => void;
  editEmployeeFalta: (idFuncionario: number, falta: EmployeeFaltaType) => void;
  delEmployeeFalta: (idFuncionario: number, idHistFalta: number) => void;
  getMotivosFaltas: (search?: string) => void;
}

class EmployeeFaltas extends Component<
  IEmployeeFaltasProps,
  IEmployeeFaltasState
> {
  private _selection: Selection;
  private formRef: any;
  private timeout: number;

  constructor(props: IEmployeeFaltasProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        fieldName: "dataHist",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeFaltaType) =>
          moment(item.dataHist).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "ID",
        ariaLabel: "Código idMotivoFalta",
        fieldName: "idMotivoFalta",
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: "column3",
        name: "Motivo",
        fieldName: "motivo",
        minWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: EmployeeFaltaType) => (
          <Stack styles={{ root: { whiteSpace: "break-spaces" } }}>
            <Text>{item.motivo?.descMotivoFalta}</Text>
          </Stack>
        ),
      },
    ];

    this.state = {
      columns,
      items: [],
      selectionCount: 0,
      selectionDetails: "",
      motivoFaltaSelected: null,
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      initialValues: initialValuesFalta,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { loadEmployeeFaltas, idFuncionario, employee } = this.props;

    if (employee.faltas?.length === 0 || employee.faltas === null) {
      loadEmployeeFaltas(idFuncionario);
    }
  }

  componentDidUpdate(prevProps: IEmployeeFaltasProps) {
    if (prevProps.employee.success !== this.props.employee.success) {
      if (this.props.employee.success) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getMotivosFaltas(text);
      }, 700);
    }
  };

  _onItemInvoked = (): void => {
    this.openEdit();
  };

  openEdit = () => {
    const selected = this._selection.getSelection()[0] as EmployeeFaltaType;
   
    this.setState({
      isPanelOpen: true,
      initialValues: {...selected, horario: moment(`${new Date().getFullYear()}-1-1 ${selected.horario}`).toDate()},
      motivoFaltaSelected: selected.motivo,
    });
  };

  exclude = () => {
    const { idFuncionario } = this.props;
    const idHistFalta = (this._selection.getSelection()[0] as EmployeeFaltaType)
      .idHistFalta!;
    this.props.delEmployeeFalta(idFuncionario, idHistFalta);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };
  render() {
    const {
      columns,
      motivoFaltaSelected,
      isPanelOpen,
      isDialogExcludeOpen,
      initialValues,
    } = this.state;
    const {
      employee,
      loadEmployeeFaltas,
      idFuncionario,
      addEmployeeFalta,
      editEmployeeFalta,
      motivoFaltaState,
    } = this.props;
    const { faltas, loadingItems, loadingAction } = employee;
    return (
      <>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { width: "100%" } }}
        >
          <Stack horizontal verticalAlign="center">
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Add" }}
              text="Adicionar Falta"
              onClick={() =>
                this.setState({
                  isPanelOpen: true,
                  motivoFaltaSelected: null,
                  initialValues: initialValuesFalta,
                })
              }
            />
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Edit" }}
                text="Editar"
                onClick={this.openEdit}
              />
            )}
            {this._selection.getSelectedCount() > 0 && (
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Delete" }}
                text="Excluir"
                onClick={() => this.setState({ isDialogExcludeOpen: true })}
              />
            )}
          </Stack>
          <Stack>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: "Refresh" }}
              text="Atualizar"
              onClick={() => loadEmployeeFaltas(idFuncionario)}
            />
          </Stack>
        </Stack>
        {faltas?.length === 0 ? (
          <NoItems
            text="Não há histórico de faltas"
            img="/static/icons/supermarket.svg"
          />
        ) : (
          <ShimmeredDetailsList
            items={faltas ?? []}
            enableShimmer={loadingItems}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            onItemInvoked={this._onItemInvoked}
            isHeaderVisible={true}
            styles={{ root: { paddingTop: 0 } }}
          />
        )}

        <Panel
          title={!initialValues.idHistFalta ? "Nova Falta" : "Editar Falta"}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                onClick={this.handleSubmit}
                isLoading={loadingAction}
                text="Salvar"
              />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            onSubmit={(values) => {
              if (!!motivoFaltaSelected) {
                values.motivo = {
                  descMotivoFalta: motivoFaltaSelected.descMotivoFalta ?? "",
                  idMotivoFalta: motivoFaltaSelected.idMotivoFalta!,
                };
              }
              values.horario = moment(values.horario).format("HH:mm:ss");
              if (!values.idHistFalta) {
                addEmployeeFalta(idFuncionario, values);
              } else {
                editEmployeeFalta(idFuncionario, values);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data"
                  name="dataHist"
                  value={values.dataHist ? moment(values.dataHist) : null}
                  onChange={(value) => {
                    setFieldValue("dataHist", value);
                    setFieldError("dataHist", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataHist}
                  helperText={errors.dataHist}
                  autoFocus
                />
                <InputTimeDialog
                  id="horario"
                  error={errors.horario ? true : false}
                  name="horario"
                  label="Horário"
                  value={values.horario}
                  onChange={(value) => {
                    setFieldError("horario", "");
                    setFieldValue("horario", value);
                  }}
                  helperText={errors.horario}
                />
                <InputAutocomplete
                  value={motivoFaltaSelected as MotivosFaltasLoadDTO}
                  onChange={(_, newValue) => {
                    this.setState({ motivoFaltaSelected: newValue });
                    setFieldValue("idMotivoFalta", newValue?.idMotivoFalta);
                  }}
                  onInputChange={(_, newInputValue) => {
                    setFieldError("idMotivoFalta", "");
                    this.search(newInputValue);
                  }}
                  getOptionLabel={(motivo: MotivosFaltasLoadDTO) =>
                    `${motivo.idMotivoFalta} - ${motivo.descMotivoFalta}`
                  }
                  getOptionSelected={(option, value) =>
                    option.idMotivoFalta === value.idMotivoFalta
                  }
                  options={motivoFaltaState.data}
                  input={{
                    idInput: "idMotivoFalta",
                    labelInput: "Motivo",
                    helperTextInput: errors.idMotivoFalta,
                    errorInput: !!errors.idMotivoFalta,
                  }}
                />
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.exclude()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EmployeeFaltaType[] = this.props.employee.faltas ?? [];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

/*
const IconButtonStyle: Partial<IButtonStyles> = {
  root: {
    position: "absolute",
    top: 20,
    right: 8,
    width: 24,
    height: 24,
    padding: 8,
  },
  icon: {
    fontSize: 12,
  },
};
*/
const initialValuesFalta: EmployeeFaltaType = {
  dataHist: null,
  horario: null,
  idMotivoFalta: null,
  motivo: null,
};

const validationSchema = yup.object().shape({
  dataHist: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  horario: yup
    .date()
    .nullable()
    .typeError("Hora inválida")
    .required("Campo obrigatório"),
  idMotivoFalta: yup.number().nullable().required("Campo obrigatório"),
});

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
  motivoFaltaState: state.motivoFaltaReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeFaltas,
      ...addEmployeeFalta,
      ...editEmployeeFalta,
      ...delEmployeeFalta,
      ...getMotivosFaltas,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeFaltas);
