import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectionMode, IColumn } from "office-ui-fabric-react/lib/DetailsList";
import { DetailsHeader } from "office-ui-fabric-react";
import {
  ShimmeredDetailsList,
  IShimmeredDetailsListProps,
} from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { Creators as loadEmployeeSalarios } from "~/store/ducks/employees";
import {
  EmployeeSalaryType,
  EmployeeSelected,
} from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";
import {
  DetailsListLayoutMode,
  
  Sticky,
  Text,
} from "@fluentui/react";
import { toCurrency } from "~/utils/numbers";

interface IGridSalarioProps extends Omit<IShimmeredDetailsListProps, "items"> {
  employee: EmployeeSelected;
  idFuncionario: number | null;
  selectionMode?: SelectionMode;
  loadEmployeeSalarios: (idFuncionario: number) => void;
  _onItemInvoked?: (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ) => void;
}

interface IGridSalarioState {
  columns: IColumn[];
}

class GridSalario extends React.Component<
  IGridSalarioProps,
  IGridSalarioState
> {
  constructor(props: IGridSalarioProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        ariaLabel: "Data",
        fieldName: "dataHist",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 75,
        isResizable: true,
        onRender: (item: EmployeeSalaryType) =>
          moment(item.dataHist).format("DD/MM/YYYY"),
        // isSortedDescending: false,
        // onColumnClick: this._onColumnClick
      },
      {
        key: "column2",
        name: "Seq",
        fieldName: "sequencia",
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        styles: {
          cellTitle: {
            justifyContent: "flex-end",
            paddingRight: 28,
          },
        },
      },
      {
        key: "column3",
        name: "Motivo",
        fieldName: "tipomov.descTipoMov",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        data: "data",
        isPadded: true,
        onRender: (item: EmployeeSalaryType) => (
          <Text styles={{ root: { whiteSpace: "break-spaces" } }}>
            {item.tipomov.nomeAmigavel}
          </Text>
        ),
      },
      {
        key: "column4",
        name: "Perc",
        fieldName: "perc",
        minWidth: 55,
        maxWidth: 55,
        isRowHeader: true,
        isResizable: true,
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            justifyContent: "center",
          },
        },
        onRender: (item: EmployeeSalaryType) => item.perc + "%",
      },
      {
        key: "column5",
        name: "Salário (R$)",
        fieldName: "salario",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        styles: {
          cellTitle: {
            justifyContent: "center",
          },
        },
        // isSortedDescending: false,
        // sortAscendingAriaLabel: 'Sorted A to Z',
        // sortDescendingAriaLabel: 'Sorted Z to A',
        // onColumnClick: this._onColumnClick,
        onRender: (item: EmployeeSalaryType) => toCurrency(item.salario, true),
      },
    ];

    this.state = {
      columns,
    };
  }

  componentDidMount() {
    const { loadEmployeeSalarios, idFuncionario } = this.props;

    if (!!idFuncionario) {
      loadEmployeeSalarios(idFuncionario);
    }
  }

  render() {
    const { columns } = this.state;
    const { employee, ...rest } = this.props;
    return (
      <ShimmeredDetailsList
        {...rest}
        items={employee.salarios ?? []}
        enableShimmer={employee.loading}
        columns={columns}
        styles={{ root: { paddingTop: 0 } }}
        onRenderDetailsHeader={(detailsHeaderProps) => {
          return (
            <Sticky>
              <DetailsHeader
                {...detailsHeaderProps}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </Sticky>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeSalarios,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridSalario);
