import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  Types,
  EmployeeType,
  IRequestEmployeesLoadAction,
  IRequestEmployeesLoadIDAction,
  IRequestEmployeeAddAction,
  IRequestEmployeeEditAction,
  IRequestEmployeeEditDadosCadastraisAction,
  IRequestEmployeeEditPasswordAction,
  IRequestEmployeeEditAccessAction,
  IRequestEmployeeDeleteAction,
  IRequestEmployeeAreasAction,
  IRequestEmployeeAreasAddAction,
  IRequestEmployeeAreasDelAction,
  IRequestEmployeeOccurrencesLoadAction,
  IRequestEmployeeOccurrencesAddAction,
  IRequestEmployeeOccurrencesEditAction,
  IRequestEmployeeOccurrencesDelAction,
  IRequestEmployeeCCAction,
  IRequestEmployeeCCAddAction,
  IRequestEmployeeCCDelAction,
  IRequestEmployeeUnidadesAction,
  IRequestEmployeeUnidadesAddAction,
  IRequestEmployeeUnidadesDelAction,
  IRequestEmployeeCargosAction,
  IRequestEmployeeCargosAddAction,
  IRequestEmployeeCargosEditAction,
  IRequestEmployeeCargosDelAction,
  IRequestEmployeeSalariosAction,
  IRequestEmployeeSalariosAddAction,
  IRequestEmployeeSalariosEditAction,
  IRequestEmployeeSalariosDelAction,
  ISuccessEmployeeEditAccessAction,
  ISuccessEmployeeEditDadosCadastraisAction,
  ISuccessEmployeeAreasAction,
  ISuccessEmployeeAreasAddAction,
  ISuccessEmployeeAreasDelAction,
  ISuccessEmployeeCargosAction,
  ISuccessEmployeeCargosAddAction,
  ISuccessEmployeeCargosEditAction,
  ISuccessEmployeeCargosDelAction,
  ISuccessEmployeeCCAction,
  ISuccessEmployeeCCAddAction,
  ISuccessEmployeeCCDelAction,
  ISuccessEmployeeUnidadesAction,
  ISuccessEmployeeUnidadesAddAction,
  ISuccessEmployeeUnidadesDelAction,
  ISuccessEmployeeSalariosAction,
  ISuccessEmployeeSalariosAddAction,
  ISuccessEmployeeSalariosEditAction,
  ISuccessEmployeeSalariosDelAction,
  ISuccessEmployeeOccurrencesLoadAction,
  ISuccessEmployeeOccurrencesAddAction,
  ISuccessEmployeeOccurrencesEditAction,
  ISuccessEmployeeOccurrencesDelAction,
  EmployeeAreaType,
  EmployeeCCType,
  EmployeeUnidadeType,
  EmployeeCargoType,
  EmployeeSalaryType,
  EmployeeOccurrenceType,
  IRequestEmployeeFaltasLoadAction,
  EmployeeFaltaType,
  ISuccessEmployeeFaltasLoadAction,
  IRequestEmployeeFaltaAddAction,
  ISuccessEmployeeFaltaAddAction,
  IRequestEmployeeFaltaDelAction,
  ISuccessEmployeeFaltaDelAction,
  IRequestEmployeeFaltaEditAction,
  ISuccessEmployeeFaltaEditAction
} from "../ducks/employees/types";
import api from "~/services/api";

const apiCallGet = (search: any) => {
  // const searchFilter = search.searchText !== "" ? `SearchText=${search.searchText}` : "?";
  // const filter = search.status !== null ? `Status=${search.status}` : "";
  // const apenasAtivos = search.apenasAtivos !== null ? `ApenasAtivos=${search.apenasAtivos}` : "";

  return api
    .get(`funcionarios`, {
      params: {
        SearchText: search.searchText,
        Status: search.status,
        ApenasAtivos: search.apenasAtivos,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (id: number) => {
  return api
    .get(`funcionarios/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

/*
const apiCallGetByInfo = (id: number) => {
  return api
    .get(`funcionarios/${id}/info`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};
*/
const apiCallPost = (item: EmployeeType) => {
  return api
    .post("funcionarios", {
      ...item,
      idUnidade: item.unidades?.[0].unidade?.idUnidade,
      idCargo: item.cargo?.idCargo,
      idArea: item.area?.idArea,
      idCC: item.cc?.idCC,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: EmployeeType) => {
  return api
    .put(`funcionarios/${item.idFuncionario}`, {
      ...item,
      idUnidade: item.unidades?.[0].unidade?.idUnidade,
      idCargo: item.cargo?.idCargo,
      idArea: item.area?.idArea,
      idCC: item.cc?.idCC,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditDadosCadastrais = (
  payload: IRequestEmployeeEditDadosCadastraisAction["payload"]
) => {
  return api
    .put(`funcionarios/${payload.idFuncionario}`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPutPassword = (payload: any) => {
  return api
    .put(`funcionarios/${payload.id}/password`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPutAccess = (
  payload: IRequestEmployeeEditAccessAction["payload"]
) => {
  return api
    .put(`funcionarios/acesso/${payload.id}`, {
      ...payload,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return api
    .delete(`funcionarios/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeArea = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/areas`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeArea = (
  payload: IRequestEmployeeAreasAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/areas`, {
      ...payload.area,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeArea = (
  payload: IRequestEmployeeAreasDelAction["payload"]
) => {
  return api
    .delete(`funcionarios/${payload.idFuncionario}/areas/${payload.idHistArea}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeCC = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/cc`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeCC = (
  payload: IRequestEmployeeCCAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/cc`, {
      ...payload.cc,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeCC = (
  payload: IRequestEmployeeCCDelAction["payload"]
) => {
  return api
    .delete(`funcionarios/${payload.idFuncionario}/cc/${payload.idHistCC}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeUnidade = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/unidades`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeUnidade = (
  payload: IRequestEmployeeUnidadesAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/unidades`, {
      ...payload.unidade,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeUnidade = (
  payload: IRequestEmployeeUnidadesDelAction["payload"]
) => {
  return api
    .delete(
      `funcionarios/${payload.idFuncionario}/unidades/${payload.idHistUnidade}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeCargo = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/cargos`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeCargo = (
  payload: IRequestEmployeeCargosAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/cargos`, {
      ...payload.cargo,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditEmployeeCargo = (
  payload: IRequestEmployeeCargosEditAction["payload"]
) => {
  return api
    .put(
      `funcionarios/${payload.idFuncionario}/cargos/${payload.cargo.idHistCargo}`,
      {
        ...payload.cargo,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeCargo = (
  payload: IRequestEmployeeCargosDelAction["payload"]
) => {
  return api
    .delete(
      `funcionarios/${payload.idFuncionario}/cargos/${payload.idHistCargo}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeSalarios = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/salarios`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeSalarios = (
  payload: IRequestEmployeeSalariosAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/salarios`, {
      ...payload.salario,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditEmployeeSalario = (
  payload: IRequestEmployeeSalariosAddAction["payload"]
) => {
  return api
    .put(
      `funcionarios/${payload.idFuncionario}/salarios/${payload.salario.idHistSalario}`,
      payload.salario
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeSalarios = (
  payload: IRequestEmployeeSalariosDelAction["payload"]
) => {
  return api
    .delete(
      `funcionarios/${payload.idFuncionario}/salarios/${payload.idHistSalario}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeOcorrencias = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/ocorrencias`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeOcorrencia = (
  payload: IRequestEmployeeOccurrencesAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/ocorrencias`, {
      ...payload.ocorrencia,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditEmployeeOcorrencia = (
  payload: IRequestEmployeeOccurrencesEditAction["payload"]
) => {
  return api
    .put(
      `funcionarios/${payload.idFuncionario}/ocorrencias/${payload.ocorrencia.idFuncOcorrencia}`,
      {
        ...payload.ocorrencia,
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeOcorrencia = (
  payload: IRequestEmployeeOccurrencesDelAction["payload"]
) => {
  return api
    .delete(
      `funcionarios/${payload.idFuncionario}/ocorrencias/${payload.idFuncOcorrencia}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallLoadEmployeeFaltas = (idFuncionario: number) => {
  return api
    .get(`funcionarios/${idFuncionario}/faltas`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallAddEmployeeFalta = (
  payload: IRequestEmployeeFaltaAddAction["payload"]
) => {
  return api
    .post(`funcionarios/${payload.idFuncionario}/faltas`, {
      ...payload.falta,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallEditEmployeeFalta = (
  payload: IRequestEmployeeFaltaEditAction["payload"]
) => {
  return api
    .put(`funcionarios/${payload.idFuncionario}/faltas/${payload.falta.idHistFalta}`, {
      ...payload.falta,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDelEmployeeFalta = (
  payload: IRequestEmployeeFaltaDelAction["payload"]
) => {
  return api
    .delete(
      `funcionarios/${payload.idFuncionario}/faltas/${payload.idHistFalta}`
    )
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestEmployeesLoadAction) {
  try {
    let items: EmployeeType = yield call(apiCallGet, action.search);
    yield put({ type: Types.SUCCESS_EMPLOYEES_LOAD, payload: items });
  } catch (error) {
    const message =
      error !== undefined ? error.data.message : "Não foi possível carregar";
    yield put({
      type: Types.FAILURE_EMPLOYEES_LOAD,
      message,
    });
  }
}

function* getById(action: IRequestEmployeesLoadIDAction) {
  try {
    let item: EmployeeType = yield call(apiCallGetById, action.id);
    yield put({ type: Types.SUCCESS_EMPLOYEES_LOAD_ID, payload: item });
  } catch (error) {
    const message =
      error !== undefined ? error.data.message : "Não foi possível carregar";
    yield put({
      type: Types.FAILURE_EMPLOYEES_LOAD_ID,
      message,
    });
  }
}

/*
function* getByInfo(action: IRequestEmployeesLoadIDAction) {
  try {
    let item: EmployeeType = yield call(apiCallGetByInfo, action.id);
    yield put({ type: Types.SUCCESS_EMPLOYEES_LOAD_INFO, payload: item });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
    yield put({
      type: Types.FAILURE_EMPLOYEES_LOAD_INFO,
      message
    });
  }
}
*/
function* add(action: IRequestEmployeeAddAction) {
  try {
    const idFuncionario = yield call(apiCallPost, action.employee);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_ADD,
      employee: { ...action.employee, ...idFuncionario, flagAtiva: true },
    });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar funcionário";
    yield put({
      type: Types.FAILURE_EMPLOYEES_ADD,
      message,
    });
  }
}

function* edit(action: IRequestEmployeeEditAction) {
  try {
    yield call(apiCallPut, action.employee);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_EDIT,
      employee: action.employee,
    });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT, message });
  }
}
function* editDadosCadastrais(
  action: IRequestEmployeeEditDadosCadastraisAction
) {
  try {
    yield call(apiCallEditDadosCadastrais, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_EDIT_DADOS_CADASTRAIS,
      employee: { ...action.payload },
    } as ISuccessEmployeeEditDadosCadastraisAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar os dados cadastrais do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT_DADOS_CADASTRAIS, message });
  }
}

function* editPassword(action: IRequestEmployeeEditPasswordAction) {
  try {
    yield call(apiCallPutPassword, action.payload);
    yield put({ type: Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível alterar a senha do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT_PASSWORD, message });
  }
}

function* editAccess(action: IRequestEmployeeEditAccessAction) {
  try {
    yield call(apiCallPutAccess, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_EDIT_ACCESS,
      payload: {
        flagAcessoRHR: action.payload.flagAcessoRHR,
        descAcesso: action.payload.descAcesso,
      },
    } as ISuccessEmployeeEditAccessAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível alterar o acesso ao RHR";
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT_ACCESS, message });
  }
}

function* del(action: IRequestEmployeeDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield put({ type: Types.SUCCESS_EMPLOYEES_DELETE, id: action.id });
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_DELETE, message });
  }
}

function* loadEmployeeArea(action: IRequestEmployeeAreasAction) {
  try {
    const areas: EmployeeAreaType[] = yield call(
      apiCallLoadEmployeeArea,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_AREAS_LOAD,
      payload: areas,
    } as ISuccessEmployeeAreasAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as áreas do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_AREAS_LOAD, message });
  }
}

function* addEmployeeArea(action: IRequestEmployeeAreasAddAction) {
  try {
    const idDataHist: { idDataHist: number } = yield call(
      apiCallAddEmployeeArea,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_AREA_ADD,
      payload: {
        ...idDataHist,
        ...action.payload.area,
      },
    } as ISuccessEmployeeAreasAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adiciar a área do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_AREA_ADD, message });
  }
}

function* delEmployeeArea(action: IRequestEmployeeAreasDelAction) {
  try {
    yield call(apiCallDelEmployeeArea, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_AREA_DEL,
      idHistArea: action.payload.idHistArea,
    } as ISuccessEmployeeAreasDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír a área do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_AREA_DEL, message });
  }
}

function* loadEmployeeCC(action: IRequestEmployeeCCAction) {
  try {
    const cc: EmployeeCCType[] = yield call(
      apiCallLoadEmployeeCC,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CC_LOAD,
      payload: cc,
    } as ISuccessEmployeeCCAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os centros de custo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CC_LOAD, message });
  }
}

function* addEmployeeCC(action: IRequestEmployeeCCAddAction) {
  try {
    const idHistCC: { idHistCC: number } = yield call(
      apiCallAddEmployeeCC,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CC_ADD,
      payload: {
        ...idHistCC,
        ...action.payload.cc,
      },
    } as ISuccessEmployeeCCAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o centro de custo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CC_ADD, message });
  }
}

function* delEmployeeCC(action: IRequestEmployeeCCDelAction) {
  try {
    yield call(apiCallDelEmployeeCC, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CC_DEL,
      idHistCC: action.payload.idHistCC,
    } as ISuccessEmployeeCCDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o centro de custo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CC_DEL, message });
  }
}

function* loadEmployeeUnidade(action: IRequestEmployeeUnidadesAction) {
  try {
    const unidades: EmployeeUnidadeType[] = yield call(
      apiCallLoadEmployeeUnidade,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_UNIDADES_LOAD,
      payload: unidades,
    } as ISuccessEmployeeUnidadesAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as unidades do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_UNIDADES_LOAD, message });
  }
}

function* addEmployeeUnidade(action: IRequestEmployeeUnidadesAddAction) {
  try {
    const idHistUnidade: { idHistUnidade: number } = yield call(
      apiCallAddEmployeeUnidade,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_UNIDADES_ADD,
      payload: {
        ...idHistUnidade,
        ...action.payload.unidade,
      },
    } as ISuccessEmployeeUnidadesAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a unidade do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_UNIDADES_ADD, message });
  }
}

function* delEmployeeUnidade(action: IRequestEmployeeUnidadesDelAction) {
  try {
    yield call(apiCallDelEmployeeUnidade, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_UNIDADES_DEL,
      idHistUnidade: action.payload.idHistUnidade,
    } as ISuccessEmployeeUnidadesDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír a unidade do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_UNIDADES_DEL, message });
  }
}

function* loadEmployeeCargo(action: IRequestEmployeeCargosAction) {
  try {
    const cargos: EmployeeCargoType[] = yield call(
      apiCallLoadEmployeeCargo,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CARGOS_LOAD,
      payload: cargos,
    } as ISuccessEmployeeCargosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os cargos do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CARGOS_LOAD, message });
  }
}

function* addEmployeeCargo(action: IRequestEmployeeCargosAddAction) {
  try {
    const idHistCargo: { idHistCargo: number } = yield call(
      apiCallAddEmployeeCargo,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CARGOS_ADD,
      payload: {
        ...idHistCargo,
        ...action.payload.cargo,
      },
    } as ISuccessEmployeeCargosAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o cargo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CARGOS_ADD, message });
  }
}

function* editEmployeeCargo(action: IRequestEmployeeCargosEditAction) {
  try {
    yield call(apiCallEditEmployeeCargo, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CARGOS_EDIT,
      payload: action.payload.cargo,
    } as ISuccessEmployeeCargosEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o cargo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CARGOS_EDIT, message });
  }
}

function* delEmployeeCargo(action: IRequestEmployeeCargosDelAction) {
  try {
    yield call(apiCallDelEmployeeCargo, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_CARGOS_DEL,
      idHistCargo: action.payload.idHistCargo,
    } as ISuccessEmployeeCargosDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o cargo do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_CARGOS_DEL, message });
  }
}

function* loadEmployeeSalario(action: IRequestEmployeeSalariosAction) {
  try {
    const salarios: EmployeeSalaryType[] = yield call(
      apiCallLoadEmployeeSalarios,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_SALARIOS_LOAD,
      payload: salarios,
    } as ISuccessEmployeeSalariosAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar os salários do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_SALARIOS_LOAD, message });
  }
}

function* addEmployeeSalario(action: IRequestEmployeeSalariosAddAction) {
  try {
    const idHistSalario: { idHistSalario: number } = yield call(
      apiCallAddEmployeeSalarios,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_SALARIOS_ADD,
      payload: {
        ...idHistSalario,
        ...action.payload.salario,
      },
    } as ISuccessEmployeeSalariosAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar o salário do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_SALARIOS_ADD, message });
  }
}

function* editEmployeeSalario(action: IRequestEmployeeSalariosEditAction) {
  try {
    yield call(apiCallEditEmployeeSalario, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_SALARIOS_EDIT,
      payload: action.payload.salario,
    } as ISuccessEmployeeSalariosEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar o salário do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_SALARIOS_EDIT, message });
  }
}

function* delEmployeeSalario(action: IRequestEmployeeSalariosDelAction) {
  try {
    yield call(apiCallDelEmployeeSalarios, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_SALARIOS_DEL,
      idHistSalario: action.payload.idHistSalario,
    } as ISuccessEmployeeSalariosDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír o salário do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_SALARIOS_DEL, message });
  }
}

function* loadEmployeeOcorrencias(
  action: IRequestEmployeeOccurrencesLoadAction
) {
  try {
    const data: EmployeeOccurrenceType[] = yield call(
      apiCallLoadEmployeeOcorrencias,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_OCCURRENCES_LOAD,
      payload: data,
    } as ISuccessEmployeeOccurrencesLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as ocorrências do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_OCCURRENCES_LOAD, message });
  }
}

function* addEmployeeOcorrencias(action: IRequestEmployeeOccurrencesAddAction) {
  try {
    const idFuncOcorrencia: { idFuncOcorrencia: number } = yield call(
      apiCallAddEmployeeOcorrencia,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_OCCURRENCES_ADD,
      payload: {
        ...idFuncOcorrencia,
        ...action.payload.ocorrencia,
      },
    } as ISuccessEmployeeOccurrencesAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a ocorrência do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_OCCURRENCES_ADD, message });
  }
}

function* editEmployeeOcorrencias(
  action: IRequestEmployeeOccurrencesEditAction
) {
  try {
    yield call(apiCallEditEmployeeOcorrencia, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_OCCURRENCES_EDIT,
      payload: action.payload.ocorrencia,
    } as ISuccessEmployeeOccurrencesEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a ocorrência do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_OCCURRENCES_EDIT, message });
  }
}

function* delEmployeeOcorrencias(action: IRequestEmployeeOccurrencesDelAction) {
  try {
    yield call(apiCallDelEmployeeOcorrencia, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_OCCURRENCES_DEL,
      idFuncOcorrencia: action.payload.idFuncOcorrencia,
    } as ISuccessEmployeeOccurrencesDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível excluír as ocorrências do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_OCCURRENCES_DEL, message });
  }
}

function* loadEmployeeFaltas(action: IRequestEmployeeFaltasLoadAction) {
  try {
    const data: EmployeeFaltaType[] = yield call(
      apiCallLoadEmployeeFaltas,
      action.idFuncionario
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_FALTAS_LOAD,
      payload: data,
    } as ISuccessEmployeeFaltasLoadAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível carregar as faltas do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_FALTAS_LOAD, message });
  }
}

function* addEmployeeFalta(action: IRequestEmployeeFaltaAddAction) {
  try {
    const idFuncFalta: { idFuncFalta: number } = yield call(
      apiCallAddEmployeeFalta,
      action.payload
    );
    yield put({
      type: Types.SUCCESS_EMPLOYEES_FALTA_ADD,
      payload: {
        ...idFuncFalta,
        ...action.payload.falta,
      },
    } as ISuccessEmployeeFaltaAddAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a falta do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_FALTA_ADD, message });
  }
}

function* editEmployeeFalta(action: IRequestEmployeeFaltaEditAction) {
  try {
    yield call(apiCallEditEmployeeFalta, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_FALTA_EDIT,
      payload: action.payload.falta,
    } as ISuccessEmployeeFaltaEditAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível editar a falta do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_FALTA_EDIT, message });
  }
}

function* delEmployeeFalta(action: IRequestEmployeeFaltaDelAction) {
  try {
    yield call(apiCallDelEmployeeFalta, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEES_FALTA_DEL,
      idHistFalta: action.payload.idHistFalta,
    } as ISuccessEmployeeFaltaDelAction);
  } catch (error) {
    const message =
      error !== undefined
        ? error.data.message
        : "Não foi possível adicionar a falta do funcionário";
    yield put({ type: Types.FAILURE_EMPLOYEES_FALTA_ADD, message });
  }
}

export default function* employeesRoot() {
  yield all([
    yield takeEvery(Types.REQUEST_EMPLOYEES_LOAD, load),
    yield takeEvery(Types.REQUEST_EMPLOYEES_LOAD_ID, getById),
    yield takeEvery(Types.REQUEST_EMPLOYEES_ADD, add),
    yield takeEvery(Types.REQUEST_EMPLOYEES_EDIT, edit),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_EDIT_DADOS_CADASTRAIS,
      editDadosCadastrais
    ),
    yield takeEvery(Types.REQUEST_EMPLOYEES_EDIT_PASSWORD, editPassword),
    yield takeEvery(Types.REQUEST_EMPLOYEES_EDIT_ACCESS, editAccess),
    yield takeEvery(Types.REQUEST_EMPLOYEES_DELETE, del),
    yield takeEvery(Types.REQUEST_EMPLOYEES_AREAS_LOAD, loadEmployeeArea),
    yield takeEvery(Types.REQUEST_EMPLOYEES_AREA_ADD, addEmployeeArea),
    yield takeEvery(Types.REQUEST_EMPLOYEES_AREA_DEL, delEmployeeArea),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CC_LOAD, loadEmployeeCC),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CC_ADD, addEmployeeCC),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CC_DEL, delEmployeeCC),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_UNIDADES_LOAD,
      loadEmployeeUnidade
    ),
    yield takeEvery(Types.REQUEST_EMPLOYEES_UNIDADES_ADD, addEmployeeUnidade),
    yield takeEvery(Types.REQUEST_EMPLOYEES_UNIDADES_DEL, delEmployeeUnidade),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CARGOS_LOAD, loadEmployeeCargo),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CARGOS_ADD, addEmployeeCargo),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CARGOS_EDIT, editEmployeeCargo),
    yield takeEvery(Types.REQUEST_EMPLOYEES_CARGOS_DEL, delEmployeeCargo),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_SALARIOS_LOAD,
      loadEmployeeSalario
    ),
    yield takeEvery(Types.REQUEST_EMPLOYEES_SALARIOS_ADD, addEmployeeSalario),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_SALARIOS_EDIT,
      editEmployeeSalario
    ),
    yield takeEvery(Types.REQUEST_EMPLOYEES_SALARIOS_DEL, delEmployeeSalario),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_OCCURRENCES_LOAD,
      loadEmployeeOcorrencias
    ),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_OCCURRENCES_ADD,
      addEmployeeOcorrencias
    ),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_OCCURRENCES_EDIT,
      editEmployeeOcorrencias
    ),
    yield takeEvery(
      Types.REQUEST_EMPLOYEES_OCCURRENCES_DEL,
      delEmployeeOcorrencias
    ),
    yield takeEvery(Types.REQUEST_EMPLOYEES_FALTAS_LOAD, loadEmployeeFaltas),
    yield takeEvery(Types.REQUEST_EMPLOYEES_FALTA_ADD, addEmployeeFalta),
    yield takeEvery(Types.REQUEST_EMPLOYEES_FALTA_EDIT, editEmployeeFalta),
    yield takeEvery(Types.REQUEST_EMPLOYEES_FALTA_DEL, delEmployeeFalta),
  ]);
}
