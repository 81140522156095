import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

import { InputAdornment } from '@material-ui/core';
import { ShimmeredDetailsList, Stack, Selection, DetailsListLayoutMode, ICommandBarStyles, DefaultButton, IColumn, IDialogContentProps, FontIcon } from 'office-ui-fabric-react';
import { CommandBarButton, DeleteButton, PrimaryButton } from '~/components/Buttons';
import CustomDialog from "~/components/CustomDialogFluentUI";

import { RootState } from '~/store/ducks';
import { DataTypes as DataTypesTabPerformance, NivelType } from '~/store/ducks/admin/tabPerformances/types';
import { Creators as addNivel } from '~/store/ducks/admin/tabPerformances';
import { Creators as editNivel } from '~/store/ducks/admin/tabPerformances';
import { Creators as delNivel } from '~/store/ducks/admin/tabPerformances';

import { ListContainer } from './styles'
import { SelectionMode } from '@uifabric/utilities';
import { InputText } from '~/components/Forms';
import Panel from '~/components/layout/Panel';

interface INivelState {
  columns: IColumn[];
  selectionDetails: string;
  selectionCount: number;
  isPanelOpen: boolean;
  isDialogExcludeOpen: boolean;
  initialValues: NivelType;
}

interface INivelProps {
  idTabPerformance: number;
  tabPerformances: DataTypesTabPerformance;
  addNivel: (idTabPerformance: number, nivel: NivelType) => void;
  editNivel: (idTabPerformance: number, nivel: NivelType) => void;
  delNivel: (idTabPerformance: number, idTabPerformanceNivel: number) => void;
}

const initial: NivelType = {
  nivel: null,
  descNivel: '',
  descIngles: '',
  cor: ''
}

class Nivel extends Component<INivelProps, INivelState> {
  private _selection: Selection;
  private formRef: any;
  private formAddSalaryStructureRef: any;

  constructor(props: INivelProps) {
    super(props)
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Nível',
        ariaLabel: 'Nível performance',
        fieldName: 'nivel',
        isRowHeader: true,
        minWidth: 40,
        maxWidth: 40,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column2',
        name: 'Descrição',
        fieldName: 'descNivel',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Desc Inglês',
        fieldName: 'descIngles',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Cor',
        ariaLabel: 'cor performance',
        fieldName: 'cor',
        isRowHeader: true,
        minWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        onRender: (item: NivelType) => (
          <>
            <FontIcon
              iconName="CircleFill"
              style={{ color: item.cor, marginRight: 5 }}
            />
            {item.cor}
          </>)
      },
    ];
    //#endregion
    this.state = {
      columns,
      selectionDetails: '',
      selectionCount: 0,
      isPanelOpen: false,
      isDialogExcludeOpen: false,
      initialValues: initial
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.formAddSalaryStructureRef = React.createRef();
  }

  componentDidUpdate(prevProps: INivelProps, prevState: INivelState) {
    if (prevProps.tabPerformances.success !== this.props.tabPerformances.success) {
      if (this.props.tabPerformances.success) {
        this.setState({ isPanelOpen: false })
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  _onItemInvoked = (item: any): void => {
    this.openEdit();
  };

  openEdit = () => {
    const nivel = (this._selection.getSelection()[0] as NivelType);
    this.setState({ isPanelOpen: true, initialValues: { ...nivel, cor: nivel.cor.replace('#', '') } })
  }

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    }
  };

  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  excludeNivel = () => {
    this.props.delNivel(this.props.idTabPerformance, (this._selection.getSelection()[0] as NivelType).idTabPerformanceNivel!);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  }

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  render() {
    const { isPanelOpen, columns, isDialogExcludeOpen, initialValues } = this.state;
    const { tabPerformances, addNivel, editNivel, idTabPerformance } = this.props;
    const { itemSelected } = tabPerformances;
    const { niveis = [] } = itemSelected.item ?? {};

    return (
      <Stack>
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
          <Stack horizontal>
            <CommandBarButton
              styles={btnStyle}
              iconProps={{ iconName: 'Add' }}
              text="Adicionar Nível"
              onClick={() => this.setState({ isPanelOpen: true, initialValues: initial })}
            />
            {this.commandBarRender()}
          </Stack>
        </Stack>
        <ListContainer>
          <ShimmeredDetailsList
            items={niveis}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            getKey={this._getKey}
            selectionPreservedOnEmptyClick={true}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        </ListContainer>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeNivel()} text="Excluir" />
        </CustomDialog>


        <Panel
          title={initialValues.idTabPerformanceNivel ? initialValues.descNivel : 'Novo Nível'}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit} isLoading={tabPerformances.loadingAction} text="Salvar" />
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize
            validationSchema={validationNivelSchema}
            onSubmit={(values) => {
              if (!values.idTabPerformanceNivel) {
                addNivel(idTabPerformance, { ...values, cor: `#${values.cor}` });
              } else {
                editNivel(idTabPerformance, { ...values, cor: `#${values.cor}` });
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
              <form onSubmit={handleSubmit}>
                <Stack>
                  <InputText
                    value={values.descNivel}
                    onChange={(e) => { handleChange(e); setFieldError("descNivel", "") }}
                    id="descNivel"
                    error={!!errors.descNivel}
                    name="descNivel"
                    type="text"
                    label="Descrição"
                    helperText={errors.descNivel}
                    className="mt-2"
                    autoFocus
                  />
                  <InputText
                    value={values.descIngles}
                    onChange={(e) => { handleChange(e); setFieldError("descIngles", "") }}
                    id="descIngles"
                    error={!!errors.descIngles}
                    name="descIngles"
                    type="text"
                    label="Descrição em Inglês"
                    helperText={errors.descIngles}
                    className="mt-2"
                  />
                  <InputText
                    value={values.nivel}
                    onChange={(e) => { handleChange(e); setFieldError("nivel", "") }}
                    id="nivel"
                    error={!!errors.nivel}
                    name="nivel"
                    type="number"
                    label="Nível"
                    helperText={errors.nivel}
                    className="mt-2"
                  />
                  <InputText
                    value={values.cor}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("cor", "");
                    }}
                    id="cor"
                    error={!!errors.cor}
                    name="cor"
                    type="text"
                    label="Cor"
                    helperText={errors.cor}
                    className="mt-2"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontIcon
                            iconName="CircleShapeSolid"
                            style={{ color: `#${values.cor}` }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                </Stack>
              </form>
            )}
          </Formik>
        </Panel>
      </Stack >
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    //let items: NivelType[] = this.props.tabPerformances.itemSelected.item?.niveis ?? [];
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    //const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      //items: newItems,
    });
  };


  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const result = (prop: string, obj: T) => (prop.split('.').reduce((a: any, b: any) => a[b] ?? '', obj))
    return items.sort((a: T, b: T) => ((isSortedDescending ? result(columnKey, a) < result(columnKey, b) : result(columnKey, a) > result(columnKey, b)) ? 1 : -1));
  };
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

const dialogContentProps: IDialogContentProps = {
  title: 'Excluir?',
  closeButtonAriaLabel: 'Close',
  subText: 'Tem certeza de que deseja excluir este item?'
};

const validationNivelSchema = yup.object().shape({
  descNivel: yup.string().required("Campo obrigatório"),
  descIngles: yup.string().required("Campo obrigatório"),
  cor: yup.string().required("Campo obrigatório"),
  nivel: yup.number().nullable().required("Campo obrigatório"),
})

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...addNivel,
  ...editNivel,
  ...delNivel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Nivel);
