import {
  Types,
  DataTypes,
  MotivosDesligamentosActionTypes,
  MotivosDesligamentosAddOrEditDTO,
} from "./types";
import customToast from "~/components/Toast/index";
/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loadingData: false,
  loadingAction: false,
  success: false,
  error: false,
  itemSelected: {
    item: null,
    error: false,
    loading: false,
    success: false,
  },
};

/**
 * Actions
 */

export const Creators = {
  getMotivosDesligamentos: (): MotivosDesligamentosActionTypes => {
    return {
      type: Types.REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS,
    };
  },

  getMotivoDesligamentoById: (
    idMotivoDesligamento: number
  ): MotivosDesligamentosActionTypes => ({
    type: Types.REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID,
    idMotivoDesligamento,
  }),

  addMotivoDesligamento: (
    motivo: MotivosDesligamentosAddOrEditDTO
  ): MotivosDesligamentosActionTypes => ({
    type: Types.REQUEST_ADD_MOTIVO_DESLIGAMENTO,
    payload: motivo,
  }),

  editMotivoDesligamento: (
    motivo: MotivosDesligamentosAddOrEditDTO
  ): MotivosDesligamentosActionTypes => ({
    type: Types.REQUEST_EDIT_MOTIVO_DESLIGAMENTO,
    payload: motivo,
  }),

  delMotivoDesligamento: (
    idMotivoDesligamento: number
  ): MotivosDesligamentosActionTypes => ({
    type: Types.REQUEST_DEL_MOTIVO_DESLIGAMENTO,
    idMotivoDesligamento,
  }),
};

export default function motivoDesligamentoReducer(
  state = INITIAL_STATE,
  action: MotivosDesligamentosActionTypes
): DataTypes {
  switch (action.type) {
    // LOAD
    case Types.REQUEST_LOAD_MOTIVOS_DESLIGAMENTOS:
      return {
        ...state,
        loadingData: true,
        error: false,
        success: false,
        itemSelected: { ...state.itemSelected, item: null },
      };
    case Types.SUCCESS_LOAD_MOTIVOS_DESLIGAMENTOS:
      return {
        ...state,
        loadingData: false,
        error: false,
        success: true,
        data: action.payload,
      };
    case Types.FAILURE_LOAD_MOTIVOS_DESLIGAMENTOS:
      customToast.error(action.message);
      return {
        ...state,
        data: [],
        loadingData: false,
        success: false,
        error: true,
      };

    //LOAD BY ID
    case Types.REQUEST_LOAD_MOTIVO_DESLIGAMENTO_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: true,
          success: false,
          error: false,
        },
      };
    case Types.SUCCESS_LOAD_MOTIVO_DESLIGAMENTO_BY_ID:
      return {
        ...state,
        itemSelected: {
          item: action.payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    case Types.FAILURE_LOAD_MOTIVO_DESLIGAMENTO_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        itemSelected: {
          item: null,
          loading: false,
          success: false,
          error: true,
        },
      };

    // ADD
    case Types.REQUEST_ADD_MOTIVO_DESLIGAMENTO:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_ADD_MOTIVO_DESLIGAMENTO:
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: [...state.data, action.payload],
      };
    case Types.FAILURE_ADD_MOTIVO_DESLIGAMENTO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // EDIT
    case Types.REQUEST_EDIT_MOTIVO_DESLIGAMENTO:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_EDIT_MOTIVO_DESLIGAMENTO:
      return {
        ...state,
        loadingAction: false,
        error: false,
        success: true,
        data: state.data.map((item) => {
          if (
            item.idMotivoDesligamento === action.payload.idMotivoDesligamento
          ) {
            return action.payload;
          }
          return item;
        }),
      };
    case Types.FAILURE_EDIT_MOTIVO_DESLIGAMENTO:
      customToast.error(action.message);
      return {
        ...state,
        loadingAction: false,
        success: false,
        error: true,
      };

    // // DEL
    case Types.REQUEST_DEL_MOTIVO_DESLIGAMENTO:
      return { ...state, error: false, success: false };
    case Types.SUCCESS_DEL_MOTIVO_DESLIGAMENTO:
      return {
        ...state,
        error: false,
        success: true,
        data: state.data.filter(
          (item) => item.idMotivoDesligamento !== action.idMotivoDesligamento
        ),
      };
    case Types.FAILURE_DEL_MOTIVO_DESLIGAMENTO:
      customToast.error(action.message);
      return {
        ...state,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
