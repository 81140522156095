// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import moment from "moment";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  ConstrainMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  FontIcon,
  IconButton,
  Label,
  Persona,
  PersonaSize,
  Pivot,
  Text,
  HoverCard,
  HoverCardType,
  IHoverCard,
} from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { PivotItem } from "@fluentui/react";

import { Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";

import CustomDialog from "~/components/CustomDialog";
import {
  InputAutocomplete,
  InputCheckbox,
  InputNumber,
  InputText,
} from "~/components/Forms";
import NoItems from "~/components/layout/NoItems";
import PainelHeader from "~/components/layout/PainelHeader";
import Accordion from "~/components/Accordion";
import Dropdown, { MenuDropdown } from "~/components/layout/Dropdown";
import DialogComentario from "~/components/DialogComentario";
import { PrimaryButton } from "~/components/Buttons";
import TreeView from "~/components/TreeView";

//Estilos
import {
  Wrapper,
  ListContainer,
  ContainerContent,
  Comentario,
  WrapperPersonaInfo,
  WrapperAutoComplete,
  cellGreenDarkStyle,
  cellGreenStyle,
  hoverCardStyles,
  pivotStyles,
  IconButtonStyle,

  commandBarStyle,

} from "./styles";

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

import { getAbbreviatedName } from "~/utils/abbreviatedName";
import { toCurrency, toNumber } from "~/utils/numbers";
import { getPresence } from "~/utils/statusFunc";

import { Creators as getBonusById } from "~/store/ducks/bonus";
import { Creators as atualizaComentarios } from "~/store/ducks/bonus";
import {
  BonusFuncType,
  DataTypes as DataTypesBonus,
} from "~/store/ducks/bonus/types";

import { Creators as editBonusFunc } from "~/store/ducks/bonus";
import { Creators as finalizar } from "~/store/ducks/bonus";
import { Creators as reativar } from "~/store/ducks/bonus";
import { Creators as gerar } from "~/store/ducks/bonus";
import { Creators as publicar } from "~/store/ducks/bonus";
import { Creators as aplicar } from "~/store/ducks/bonus";
import { Creators as ignorar } from "~/store/ducks/bonus";
import { Creators as atualizaCalibracao } from "~/store/ducks/bonus";
import { Creators as atualizaResidual } from "~/store/ducks/bonus";
import { Creators as atualizaOutrasRemuneracoes } from "~/store/ducks/bonus";

import { Creators as getCC } from "~/store/ducks/cc";
import { CCType, DataTypes as DataTypesCC } from "~/store/ducks/cc/types";
import { Creators as getPositions } from "~/store/ducks/admin/positions";
import { DataTypes as DataTypesPosition } from "~/store/ducks/admin/positions/types";
import {
  ChartType,
  DataTypes as DataTypesChart,
} from "~/store/ducks/admin/chart/types";
import { Creators as getChart } from "~/store/ducks/admin/chart";

import { Creators as setCurrentPage } from "~/store/ducks/home";
import RegrasBonus from "./RegrasBonus";

import { Legenda } from "../Merit/styles";

const baseURL = process.env.REACT_APP_BASE_URL;
//#endregion

interface IBonusState {
  columns: IColumn[];
  items: BonusFuncType[];
  isFiltering: boolean;
  selectionDetails: string;
  comentarioEdit: {
    isOpen: boolean;
    comentario: string;
  };
  meritoFuncionarioSelected: BonusFuncType | null;
  isOpenFilter: boolean;
  filter: {
    gestor: string;
    cargo: string;
    area: Partial<ChartType> | null;
    tempo: string;
    idTabPerformanceNivel: string;
    search: string;
    cc: Partial<CCType> | null;
  };
  isDialogTreeViewOpen: boolean;
  areasTreeViewSelected: ChartType[];
}

interface IPropsBonus extends RouteComponentProps {
  areas: DataTypesChart;
  positions: DataTypesPosition;
  cc: DataTypesCC;
  bonusState: DataTypesBonus;
  getBonusById: (idBonus: number) => void;
  editBonusFunc: (bonusFunc: Partial<BonusFuncType>) => void;
  atualizaComentarios: (
    idBonus: number,
    idBonusFuncionario: string,
    comentarios: string
  ) => void;
  atualizaCalibracao: (
    idBonus: number,
    idBonusFuncionario: string,
    calibracaoAplicada: number
  ) => void;
  atualizaResidual: (
    idBonus: number,
    idBonusFuncionario: string,
    residualAnoAnterior: number
  ) => void;
  atualizaOutrasRemuneracoes: (
    idBonus: number,
    idBonusFuncionario: string,
    outrasRemuneracoes: number
  ) => void;
  finalizar: (idBonus: number, idBonusFuncionario: string) => void;
  reativar: (idBonus: number, idBonusFuncionario: string) => void;
  aplicar: (idBonus: number, idBonusFuncionario: string) => void;
  ignorar: (idBonus: number, idBonusFuncionario: string) => void;
  gerar: (idBonus: number) => void;
  publicar: (idBonus: number) => void;
  getChart: (
    search?: string,
    filter?: boolean | null,
    treeview?: boolean
  ) => void;
  getPositions: (
    search?: string,
    isSearch?: boolean,
    filter?: boolean | null,
    gs?: string | null
  ) => void;
  getCC: (SearchText?: string) => void;
  setCurrentPage: (page: Page) => void;

  match: any;
  history: RouteComponentProps["history"];
}

class Bonus extends Component<IPropsBonus, IBonusState> {
  private _selection: Selection;
  private hoverCard: React.RefObject<IHoverCard>;
  private timeout: number;

  constructor(props: IPropsBonus) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "avatar",
        name: "",
        fieldName: "",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          root: {
            position: "sticky",
            left: 0,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: BonusFuncType) => {
          const { nomeCompleto, imagem } = item.funcionario ?? {};
          // const { imagem, nomeCompleto, status, isVisible } = values.funcionario ?? {};
          // const imagePath = `${baseURL}uploads/${imagem}`;
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto ?? "");

          return (
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              styles={{ root: { whiteSpace: "break-spaces", width: "100%" } }}
            >
              <Stack horizontal verticalAlign="center">
                <Persona
                  imageUrl={imagePath}
                  imageInitials={initials}
                  initialsColor={Colors.primary}
                  size={PersonaSize.size32}
                  presence={getPresence(item.statusFuncionario)}
                  styles={{
                    root: {
                      width: 32,
                      marginRight: 10,
                      opacity: item.aplicarBonus ? 1 : 0.5,
                      selectors: {
                        "& .ms-Persona-initials": {
                          filter: "grayscale(0)",
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          );
        },
      },
      {
        key: "matricula",
        name: "Matrícula",
        fieldName: "funcionario.matricula",
        minWidth: 80,
        maxWidth: 80,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          root: {
            position: "sticky",
            left: 50,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: BonusFuncType) => (
          <Text>{item.funcionario.matricula}</Text>
        ),
      },
      {
        key: "nomeCompleto",
        name: "Nome",
        fieldName: "funcionario.nomeCompleto",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: {
          root: {
            position: "sticky",
            left: 150,
            top: 50,
            backgroundColor: "#FFFFFF",
            zIndex: 9,
          },
        },
        onRender: (item: BonusFuncType) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.funcionario.nomeCompleto}
          </Text>
        ),
      },
      {
        key: "nomeUnidade",
        name: "Unidade",
        fieldName: "unidade.nomeUnidade",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: BonusFuncType) => item.unidade?.nomeUnidade,
      },
      {
        key: "dataAdmissao",
        name: "Data Admissão",
        fieldName: "item.funcionario.dataAdmissao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: BonusFuncType) => {
          return moment(new Date(item.funcionario.dataAdmissao)).utc().format(
            "DD/MM/YYYY"
          );
        },
      },
      {
        key: "dataDemissao",
        name: "Data Demissão",
        fieldName: "dataDemissao",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: BonusFuncType) => {
          if (!item.dataDemissao) return "";
          return moment(new Date(item.dataDemissao)).utc().format("DD/MM/YYYY");
        },
      },
      {
        key: "motivoDesligamento",
        name: "Motivo Desligamento",
        fieldName: "item.motivoDesligamento?.descMotivo",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: BonusFuncType) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.motivoDesligamento?.descMotivo}
          </Text>
        ),
      },
      {
        key: "statusFuncionario",
        name: "Situação",
        fieldName: "statusFuncionario",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: BonusFuncType) => (
          <Text style={{ whiteSpace: "break-spaces" }}>
            {item.statusFuncionario === "A" && "ATIVO"}
            {item.statusFuncionario === "D" && "DESLIGADO"}
          </Text>
        ),
      },
      {
        key: "cargo",
        name: "Cargo",
        fieldName: "cargo.titulo",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: BonusFuncType) => item.cargo.titulo,
      },
      {
        key: "categoriaCargo",
        name: "Categoria",
        fieldName: "categoriaCargo",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: BonusFuncType) =>
          item.categoriaCargo?.descCategoriaCargo,
      },
      {
        key: "salarioRef",
        name: "Salário",
        fieldName: "salarioRef",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: BonusFuncType) => toCurrency(item.salarioRef),
      },
      {
        key: "area",
        name: "Área",
        fieldName: "area.nomeLocal",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        onRender: (item: BonusFuncType) => item.area?.nomeLocal,
      },
      {
        key: "afastamento",
        name: "Afastamento",
        fieldName: "afastamento",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: { cellTitle: { justifyContent: "center" } },
      },
      {
        key: "faltas",
        name: "Faltas",
        fieldName: "faltas",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: { cellTitle: { justifyContent: "center" } },
      },
      {
        key: "elegivel",
        name: "Elegível",
        fieldName: "flagElegivel",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: cellGreenStyle,
        onRender: (item: BonusFuncType) => (item.flagElegivel ? "SIM" : "NÃO"),
      },
      {
        key: "periodo",
        name: "Período",
        fieldName: "periodoElegibilidade",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        styles: cellGreenStyle,
      },
      {
        key: "target",
        name: "Target",
        fieldName: "targetSugerido",
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenStyle,
      },
      {
        key: "calibracaoSugerida",
        name: "Calibração",
        fieldName: "calibracaoSugerida",
        minWidth: 75,
        maxWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenStyle,
      },
      {
        key: "salariosMulitiplicadorSugerido",
        name: "Calib. Present.",
        fieldName: "salariosMulitiplicadorSugerido",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenStyle,
      },
      {
        key: "bonusSugerido",
        name: "Bônus",
        fieldName: "bonusSugerido",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#D3EADF",
            justifyContent: "flex-end",
            selectors: {
              "&:hover": {
                backgroundColor: "#CAE0D6",
              },
            },
          },
        },
        onRender: (item: BonusFuncType) => toCurrency(item.bonusSugerido),
      },
      {
        key: "aplicarBonus",
        name: "Aplicar",
        fieldName: "aplicarBonus",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenDarkStyle,
        onRender: (item: BonusFuncType) => {
          const disabled =
            item.flagFinalizado ||
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";
          return (
            <InputCheckbox
              checked={item.aplicarBonus}
              styles={{ margin: 0 }}
              onChange={(e) => this.handleAplicarBonus(e.target.checked, item)}
              disabled={disabled}
            />
          );
        },
      },
      {
        key: "calibracaoAplicada",
        name: "Calibração",
        fieldName: "calibracaoAplicada",
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenDarkStyle,
        onRender: (item: BonusFuncType) => {
          const disabled =
            item.flagFinalizado ||
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";

          if (!disabled) {
            return item.flagCalibEdit ? (
              <InputNumber
                defaultValue={Number(item.calibracaoAplicada)}
                decimalScale={1}
                onBlur={(e) => {
                  console.log(toNumber(e.target.value));
                  this.handleCalibracao(toNumber(e.target.value), item);
                  this.handleToggleCalib(item);
                }}
                height_container="100%"
              />
            ) : (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text
                  styles={{ root: { textAlign: "right", width: "100%" } }}
                >{`${Number(item.calibracaoAplicada).toFixed(2)}%`}</Text>
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  style={{ marginLeft: 5 }}
                  disabled={!item.aplicarBonus || item.flagFinalizado}
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    root: {
                      visibility: `hidden ${
                        !item.aplicarBonus || item.flagFinalizado
                          ? "!important"
                          : ""
                      }`,
                    },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() => this.handleToggleCalib(item)}
                />
              </Stack>
            );
          }
        },
      },
      {
        key: "bonusAplicado",
        name: "Bônus",
        fieldName: "bonusAplicado",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: {
          cellTitle: {
            backgroundColor: "#BEDBCA",
            justifyContent: "flex-end",
            selectors: {
              "&:hover": {
                backgroundColor: "#acccb9",
              },
            },
          },
        },
        onRender: (item: BonusFuncType) => toCurrency(item.bonusAplicado),
      },
      {
        key: "residualAnoAnterior",
        name: "Residual Anterior",
        fieldName: "residualAnoAnterior",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenDarkStyle,
        onRender: (item: BonusFuncType) => {
          const disabled =
            item.flagFinalizado ||
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";

          if (!disabled) {
            return item.flagResidualEdit ? (
              <InputNumber
                defaultValue={Number(item.residualAnoAnterior)}
                decimalScale={2}
                onBlur={(e) => {
                  this.handleResidual(toNumber(e.target.value), item);
                  this.handleToggleResidual(item);
                }}
                height_container="100%"
              />
            ) : (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text styles={{ root: { textAlign: "right", width: "100%" } }}>
                  {toCurrency(item.residualAnoAnterior)}
                </Text>
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  style={{ marginLeft: 5 }}
                  disabled={!item.aplicarBonus || item.flagFinalizado}
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    root: {
                      visibility: `hidden ${
                        !item.aplicarBonus || item.flagFinalizado
                          ? "!important"
                          : ""
                      }`,
                    },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() => this.handleToggleResidual(item)}
                />
              </Stack>
            );
          }
        },
      },

      {
        key: "outrasRemuneracoes",
        name: "Outras Remunerações",
        fieldName: "outrasRemuneracoes",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "number",
        styles: cellGreenDarkStyle,
        onRender: (item: BonusFuncType) => {
          const disabled =
            item.flagFinalizado ||
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";

          if (!disabled) {
            return item.flagOutrasRemEdit ? (
              <InputNumber
                defaultValue={Number(item.outrasRemuneracoes)}
                decimalScale={2}
                onBlur={(e) => {
                  this.handleOutrasRemuneracoes(toNumber(e.target.value), item);
                  this.handleToggleOutrasRem(item);
                }}
                height_container="100%"
              />
            ) : (
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={{ root: { width: "100%" } }}
              >
                <Text styles={{ root: { textAlign: "right", width: "100%" } }}>
                  {toCurrency(item.outrasRemuneracoes)}
                </Text>
                <IconButton
                  className="btnEditGrid"
                  iconProps={{ iconName: "Edit" }}
                  style={{ marginLeft: 5 }}
                  disabled={!item.aplicarBonus || item.flagFinalizado}
                  styles={{
                    rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                    root: {
                      visibility: `hidden ${
                        !item.aplicarBonus || item.flagFinalizado
                          ? "!important"
                          : ""
                      }`,
                    },
                    rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  onClick={() => this.handleToggleOutrasRem(item)}
                />
              </Stack>
            );
          }
        },
      },

      {
        key: "column37",
        name: "Finalizado",
        fieldName: "flagFinalizado",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: BonusFuncType) => {
          const readOnly =
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";

          return (
            <FormControlLabel
              style={{ margin: 0 }}
              disabled={readOnly}
              control={
                <Checkbox
                  checked={item.flagFinalizado}
                  icon={<FontIcon iconName="Unlock" style={{ fontSize: 20 }} />}
                  disabled={readOnly}
                  checkedIcon={
                    <FontIcon
                      iconName="LockSolid"
                      style={{ color: Colors.primary, fontSize: 20 }}
                    />
                  }
                  color="primary"
                  onChange={(e) => {
                    console.log("oi");

                    this.handleFuncFinalizado(e.target.checked, item);
                  }}
                />
              }
              label=""
            />
          );
        },
      },
      {
        key: "column38",
        name: "Política",
        fieldName: "statusPolitica",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            justifyContent: "center",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: BonusFuncType) => {
          const {
            funcionario,
            // cargoOrigem,
            statusPolitica,
            observacoesPolitica,
          } = item;
          const { nomeCompleto, imagem } = funcionario;
          const imagePath = `${baseURL}uploads/${imagem}`;
          const initials = getAbbreviatedName(nomeCompleto);

          const onRenderPlainCard = (): JSX.Element => {
            return (
              <WrapperPersonaInfo>
                <Stack styles={{ root: { height: "auto" } }}>
                  <Stack horizontal style={{ marginBottom: 25 }}>
                    <Persona
                      imageUrl={imagePath}
                      imageInitials={initials}
                      initialsColor={Colors.primary}
                      size={PersonaSize.size56}
                      styles={{ root: { width: 56, marginRight: 10 } }}
                    />
                    <Stack>
                      <Text
                        variant="medium"
                        styles={{ root: { fontWeight: "bold" } }}
                      >
                        {nomeCompleto}
                      </Text>
                      {/* <Text variant="smallPlus">{cargoOrigem.titulo}</Text> */}
                    </Stack>
                  </Stack>
                  {observacoesPolitica?.split("\n").map((obs, i) => (
                    <Stack
                      key={i}
                      horizontal
                      verticalAlign="center"
                      style={{ marginBottom: 10 }}
                    >
                      <FontIcon
                        iconName="AlertSolid"
                        style={{
                          color: Colors.error,
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      />
                      <Text styles={{ root: { marginBottom: 1 } }}>{obs}</Text>
                    </Stack>
                  ))}
                </Stack>
              </WrapperPersonaInfo>
            );
          };
          return statusPolitica ? (
            <FontIcon
              iconName="SkypeCircleCheck"
              style={{ color: Colors.primary, fontSize: 20 }}
            />
          ) : (
            <HoverCard
              type={HoverCardType.plain}
              plainCardProps={{ onRenderPlainCard }}
              componentRef={this.hoverCard}
              styles={hoverCardStyles}
            >
              <FontIcon
                iconName="AlertSolid"
                style={{ color: Colors.error, fontSize: 20 }}
              />
            </HoverCard>
          );
        },
      },
      {
        key: "column39",
        name: "Comentários",
        fieldName: "comentarios",
        minWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        styles: {
          cellTitle: {
            backgroundColor: "#F8E8E8",
            selectors: {
              "&:hover": {
                backgroundColor: "#FBE0E0",
              },
            },
          },
        },
        onRender: (item: BonusFuncType) => {
          const readOnly =
            this.props.bonusState.data?.bonus.statusbonus !== "Parametrização";
          return (
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              {item.comentarios && <Comentario>{item.comentarios}</Comentario>}
              <IconButton
                className="btnEditGrid"
                iconProps={{ iconName: "Edit" }}
                disabled={item.flagFinalizado || readOnly}
                style={{ marginLeft: 5 }}
                styles={{
                  rootHovered: { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                  root: {
                    visibility: `hidden ${
                      item.flagFinalizado ? "!important" : ""
                    }`,
                  },
                  rootPressed: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
                onClick={() =>
                  this.setState({
                    comentarioEdit: {
                      isOpen: true,
                      comentario: item.comentarios ?? "",
                    },
                    meritoFuncionarioSelected: item,
                  })
                }
              />
            </Stack>
          );
        },
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      selectionDetails: "",
      isFiltering: false,
      comentarioEdit: {
        isOpen: false,
        comentario: "",
      },
      meritoFuncionarioSelected: null,
      isOpenFilter: false,
      filter: {
        gestor: "",
        cargo: "",
        area: null,
        tempo: "",
        idTabPerformanceNivel: "",
        search: "",
        cc: null,
      },
      isDialogTreeViewOpen: false,
      areasTreeViewSelected: [],
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.hoverCard = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    this.props.setCurrentPage(page);
    this.loadBonus();
  }

  componentDidUpdate(prevProps: IPropsBonus, _: IBonusState) {
    const { errorData: oldError, redirect: oldRedirect } = prevProps.bonusState;
    const { errorData, redirect } = this.props.bonusState;

    if (oldError !== errorData) {
      if (errorData) {
        this.props.history.push("/bonus");
      }
    }

    if (oldRedirect !== redirect) {
      if (redirect) {
        this.props.history.push("/bonus");
      }
    }
  }

  handleAplicarBonus = (isChecked: boolean, meritoFunc: BonusFuncType) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = meritoFunc;
    if (isChecked) {
      this.props.aplicar(idBonus, idBonusFuncionario);
    } else {
      this.props.ignorar(idBonus, idBonusFuncionario);
    }
  };

  handleEditBonus = ({ ...bonusEmployee }: Partial<BonusFuncType>) => {
    this.props.editBonusFunc(bonusEmployee);
  };

  handleToggleCalib = (meritoFunc: BonusFuncType) => {
    const { idBonusFuncionario, flagCalibEdit } = meritoFunc;
    this.handleEditBonus({
      idBonusFuncionario,
      flagCalibEdit: !flagCalibEdit,
    });
  };

  handleToggleResidual = (meritoFunc: BonusFuncType) => {
    const { idBonusFuncionario, flagResidualEdit } = meritoFunc;
    this.handleEditBonus({
      idBonusFuncionario,
      flagResidualEdit: !flagResidualEdit,
    });
  };

  handleToggleOutrasRem = (meritoFunc: BonusFuncType) => {
    const { idBonusFuncionario, flagOutrasRemEdit } = meritoFunc;
    this.handleEditBonus({
      idBonusFuncionario,
      flagOutrasRemEdit: !flagOutrasRemEdit,
    });
  };

  loadBonus = () => {
    const { idBonus } = this.props.match.params;
    if (idBonus) {
      this.props.getBonusById(idBonus);
      this.clearFilter();
    }
  };

  _getSelectionDetails(): any {
    this._selection.getSelection();
  }

  clearFilter = () => {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
      filter: {
        gestor: "",
        cargo: "",
        area: null,
        tempo: "",
        idTabPerformanceNivel: "",
        search: "",
        cc: null,
      },
      isFiltering: false,
    });
  };

  handleSearch = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      this.handleFilter();
    }
  };

  handleCalibracao = (calibracaoAplicada: number, bonusFunc: BonusFuncType) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = bonusFunc;

    this.props.atualizaCalibracao(
      idBonus,
      idBonusFuncionario,
      calibracaoAplicada
    );
  };

  handleResidual = (residualAnoAnterior: number, bonusFunc: BonusFuncType) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = bonusFunc;

    this.props.atualizaResidual(
      idBonus,
      idBonusFuncionario,
      residualAnoAnterior
    );
  };

  handleOutrasRemuneracoes = (
    outrasRemuneracoes: number,
    bonusFunc: BonusFuncType
  ) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = bonusFunc;

    this.props.atualizaOutrasRemuneracoes(
      idBonus,
      idBonusFuncionario,
      outrasRemuneracoes
    );
  };

  handleFuncFinalizado = (
    flagFinalizado: boolean,
    bonusFunc: BonusFuncType
  ) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = bonusFunc;

    if (flagFinalizado) {
      this.props.finalizar(idBonus, idBonusFuncionario);
    } else {
      this.props.reativar(idBonus, idBonusFuncionario);
    }
  };

  handleEditComentario = (comentario: string) => {
    const { idBonus } = this.props.match.params;
    const { idBonusFuncionario } = this.state.meritoFuncionarioSelected!;

    this.props.atualizaComentarios(idBonus, idBonusFuncionario, comentario);

    this.setState({
      comentarioEdit: {
        isOpen: false,
        comentario: "",
      },
      meritoFuncionarioSelected: null,
    });
  };

  handleColorFaixa = (faixa: number): string => {
    const { steps } = this.props.bonusState.data ?? {};
    const piso = steps?.[0].percent;
    const teto = steps?.[steps.length - 1].percent;
    let cor = "#323130";

    if (piso && teto) {
      if (faixa < Number(piso) || faixa > Number(teto)) {
        cor = Colors.error;
      } else if (faixa > 100) {
        cor = Colors.greenDarker;
      }
    }

    return cor;
  };

  compareString = (item: string, itemCompared: string): boolean =>
    item.toLowerCase().indexOf(itemCompared.toLowerCase()) > -1;

  setFilter = (field: string, value: string | number | Object | null) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [field]: value,
      },
    });
  };

  handleFilter = () => {
    const { funcionarios } = this.props.bonusState.data?.bonus ?? {};
    const { areasTreeViewSelected } = this.state;
    const {
      gestor,
      
      area,
      
      
      search,
      
    } = this.state.filter;

    let result = funcionarios ?? [];

    // result = result?.filter((item) =>
    //   this.compareString(item.cargoOrigem.titulo, cargo)
    // );

    result = result?.filter((item) =>
      this.compareString(item.gestor?.nomeCompleto ?? "", gestor)
    );

    if (!!search) {
      if (Number(search)) {
        result = result?.filter((item) => item.funcionario.matricula === search);
      } else {
        result = result?.filter((item) =>
          this.compareString(item.funcionario.nomeCompleto, search)
        );
      }
    }

    // if (Number(tempo)) {
    //   result = result?.filter((item) => item.tempoCargo > Number(tempo));
    // }

    // if (idTabPerformanceNivel) {
    //   result = result?.filter(
    //     (item) =>
    //       Number(item.idTabPerformanceNivel) === Number(idTabPerformanceNivel)
    //   );
    // }

    if (area?.idArea !== null && area?.idArea !== undefined) {
      if (area?.idArea === 0) {
        result = result?.filter((item) => {
          return areasTreeViewSelected.some(
            (area) => item.idArea === area.idArea
          );
        });
      } else {
        result = result?.filter((item) => item.idArea === area?.idArea);
      }
    }

    // if (cc?.nomeCC) {
    //   result = result?.filter((item) => item.cc?.nomeCC === cc?.nomeCC);
    // }

    this.setState({ items: result, isFiltering: true });
  };

  search = (text: string, type: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        if (type === "area") {
          this.props.getChart(text, true, false);
        } else if (type === "cc") {
          this.props.getCC(text);
        } else {
          this.props.getPositions(text, false, true);
        }
      }, 700);
    }
  };

  handleGerarBonus = () => {
    const { idBonus } = this.props.match.params;
    this.props.gerar(idBonus);
  };

  handlePublicarBonus = () => {
    const { idBonus } = this.props.match.params;
    this.props.publicar(idBonus);
  };

  render() {
    const {
      isFiltering,
      items,
      filter,
      columns,
      isOpenFilter,
      comentarioEdit,
      isDialogTreeViewOpen,
      areasTreeViewSelected,
    } = this.state;
    const { areas,  bonusState } = this.props;
    const { bonus } = bonusState.data ?? {};
    const { performances } = bonus ?? {};
    const { funcionarios: funcData } = bonus ?? {};
    const { idBonus } = this.props.match.params;
    return (
      <>
        <Wrapper>
          <PainelHeader
            title={`Bônus ${bonus?.ano ?? ""}`}
            icon={{ icon: "Ribbon" }}
            labelGroups={[
              {
                icon: "DateTime",
                label: "Data de Referência",
                value: bonus?.dataReferencia
                  ? moment(bonus?.dataReferencia).format("DD/MM/YYYY")
                  : "",
              },
              {
                icon: "Flag",
                label: "Status",
                value: bonus?.statusbonus ?? "",
              },
            ]}
            button={
              bonus?.statusbonus === "Parametrização"
                ? {
                    text: "Gerar Bônus",
                    handleButton: this.handleGerarBonus,
                    isLoadingAction: bonusState.loadingAction,
                  }
                : bonus?.statusbonus === "Em conferência"
                ? {
                    text: "Publicar Bônus",
                    handleButton: this.handlePublicarBonus,
                    isLoadingAction: bonusState.loadingAction,
                  }
                : null
            }
          />
          {bonusState.loadingData ? (
            <Spinner
              size={SpinnerSize.medium}
              label="Carregando Reunião"
              styles={{ root: { height: "-webkit-fill-available" } }}
            />
          ) : bonusState.errorData ? (
            <NoItems text="" error={bonusState.errorData} />
          ) : (
            <Pivot aria-label="Funcionários" styles={pivotStyles}>
              <PivotItem headerText="Funcionários">
                <ContainerContent>
                  <Stack
                    horizontal
                    verticalAlign="center"
                    styles={{ root: { marginBottom: 20 } }}
                  >
                    <InputText
                      label="Entre com o id ou o nome para a busca"
                      value={filter.search}
                      onChange={(e) => this.setFilter("search", e.target.value)}
                      onKeyUp={(e: React.KeyboardEvent<Element>) =>
                        this.handleSearch(e)
                      }
                      variant="outlined"
                      height_container={40}
                      fullWidth
                      size="small"
                      smaller="small"
                    />
                    <CommandBarButton
                      styles={commandBarStyle}
                      iconProps={{ iconName: "Refresh" }}
                      text="Atualizar"
                      onClick={this.loadBonus}
                    />
                    <MenuDropdown close={isOpenFilter}>
                      <Stack>
                        <Stack horizontal horizontalAlign="space-between">
                          <Label styles={{ root: { fontSize: 16 } }}>
                            Filtros
                          </Label>
                          <IconButton
                            iconProps={{ iconName: "ClearFilter" }}
                            title="Limpar filtro"
                            ariaLabel="Limpar filtro"
                            onClick={this.clearFilter}
                          />
                        </Stack>
                        <InputText
                          label="Gestor"
                          type="text"
                          value={filter.gestor}
                          onChange={(e) =>
                            this.setFilter("gestor", e.target.value)
                          }
                          autoFocus
                        />
                        <InputText
                          label="Cargo de Origem"
                          type="text"
                          value={filter.cargo}
                          onChange={(e) =>
                            this.setFilter("cargo", e.target.value)
                          }
                        />
                        <WrapperAutoComplete>
                          <InputAutocomplete
                            value={filter.area as ChartType}
                            onChange={(_, newValue) => {
                              this.setFilter("area", newValue);
                            }}
                            onInputChange={(_, newInputValue) => {
                              this.search(newInputValue, "area");
                            }}
                            getOptionLabel={(area: ChartType) =>
                              area.nomeLocal ?? ""
                            }
                            getOptionSelected={(option, value) =>
                              option.idArea === value.idArea
                            }
                            options={areas.data}
                            input={{
                              idInput: "area",
                              labelInput: "Área",
                            }}
                          />
                          <IconButton
                            iconProps={{ iconName: "Org" }}
                            styles={IconButtonStyle}
                            onClick={() => {
                              this.props.getChart("", true, true);
                              this.setState({ isDialogTreeViewOpen: true });
                            }}
                          />
                        </WrapperAutoComplete>
                        <InputText
                          label="Tempo mínimo de empresa"
                          type="number"
                          value={filter.tempo ?? ""}
                          onChange={(e) =>
                            this.setFilter("tempo", e.target.value)
                          }
                          helperText="Meses"
                        />
                        <Dropdown
                          label="Performance calibrada"
                          name="perfomance"
                          values={filter.idTabPerformanceNivel}
                          handleChange={(e: any) =>
                            this.setFilter(
                              "idTabPerformanceNivel",
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value={""}>Nenhum</MenuItem>
                          {performances
                            ?.sort((a, b) => b.nivel.nivel - a.nivel.nivel)
                            .map((item) => (
                              <MenuItem
                                key={`${item.nivel.idTabPerformance}`}
                                value={`${item.nivel.idTabPerformance}`}
                              >
                                {item.nivel.descNivel}
                              </MenuItem>
                            ))}
                        </Dropdown>
                        <PrimaryButton
                          text="Aplicar Filtro"
                          styles={{
                            root: {
                              width: 125,
                              alignSelf: "flex-end",
                            },
                          }}
                          onClick={() => {
                            this.handleFilter();
                            this.setState({ isOpenFilter: !isOpenFilter });
                          }}
                        />
                      </Stack>
                    </MenuDropdown>
                  </Stack>
                  <Accordion
                    title="Funcionários"
                    styles={{ details: { marginBottom: 0 } }}
                  >
                    <Stack
                      horizontal
                      verticalAlign="center"
                      styles={{ root: { height: 40 } }}
                    >
                      <Stack
                        horizontal
                        verticalAlign="center"
                        styles={{ root: { marginRight: 16 } }}
                      >
                        <Legenda bgColor="#F6F6F6" />
                        <Text styles={{ root: { fontSize: 11 } }}>
                          Performance
                        </Text>
                      </Stack>
                      <Stack
                        horizontal
                        verticalAlign="center"
                        styles={{ root: { marginRight: 16 } }}
                      >
                        <Legenda bgColor="#d3eadf" />
                        <Text styles={{ root: { fontSize: 11 } }}>
                          Recomendado
                        </Text>
                      </Stack>
                      <Stack
                        horizontal
                        verticalAlign="center"
                        styles={{ root: { marginRight: 16 } }}
                      >
                        <Legenda bgColor="#bedbca" />
                        <Text styles={{ root: { fontSize: 11 } }}>
                          Proposta
                        </Text>
                      </Stack>
                      <Stack
                        horizontal
                        verticalAlign="center"
                        styles={{ root: { marginRight: 16 } }}
                      >
                        <Legenda bgColor="#f8e8e8" />
                        <Text styles={{ root: { fontSize: 11 } }}>
                          Política
                        </Text>
                      </Stack>
                    </Stack>
                    {!bonusState.loadingData && funcData?.length === 0 ? (
                      <NoItems
                        error={bonusState.error}
                        text="Não há bônus cadastrados"
                        icon="Teamwork"
                      />
                    ) : (
                      <ListContainer>
                        <ShimmeredDetailsList
                          items={isFiltering ? items : funcData ?? []}
                          className="meritTable"
                          enableShimmer={bonusState.loadingData}
                          columns={columns}
                          selectionMode={SelectionMode.none}
                          selection={this._selection}
                          getKey={this._getKey}
                          selectionPreservedOnEmptyClick={true}
                          setKey="single"
                          constrainMode={ConstrainMode.unconstrained}
                          layoutMode={DetailsListLayoutMode.fixedColumns}
                          isHeaderVisible={true}
                          onRenderRow={this._onRenderRow}
                        />
                      </ListContainer>
                    )}
                  </Accordion>
                </ContainerContent>
              </PivotItem>
              <PivotItem headerText="Regras">
                <RegrasBonus idBonus={idBonus} bonusState={bonusState} />
              </PivotItem>
            </Pivot>
          )}
        </Wrapper>

        <DialogComentario
          isOpen={comentarioEdit.isOpen}
          onClose={() =>
            this.setState({
              comentarioEdit: {
                isOpen: false,
                comentario: "",
              },
              meritoFuncionarioSelected: null,
            })
          }
          onConfirm={this.handleEditComentario}
          comentario={comentarioEdit.comentario}
        />

        <CustomDialog
          open={isDialogTreeViewOpen}
          title="Escolher área"
          width={850}
          btnConfirmText="Definir"
          onClickCancel={() => this.setState({ isDialogTreeViewOpen: false })}
          onClickConfirm={() => {
            const area: Partial<ChartType> =
              areasTreeViewSelected.length === 1
                ? areasTreeViewSelected[0]!
                : {
                    idArea: 0,
                    nomeLocal: "Multiplos áreas",
                  };

            this.setState({
              filter: {
                ...filter,
                area,
              },
            });

            this.setState({ isDialogTreeViewOpen: false });
          }}
          disabledBtnConfirm={areasTreeViewSelected.length === 0}
          height={500}
        >
          <TreeView
            columns={[
              {
                name: "Área",
                fieldName: "nomeLocal",
              },
            ]}
            selectionMode="multiple"
            state={areas}
            fieldId="idArea"
            changeItemsSelected={(items) =>
              this.setState({ areasTreeViewSelected: items })
            }
          />
        </CustomDialog>
      </>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.root = {
          selectors: {
            "& .ms-DetailsRow-fields": {
              opacity: props.item.flagElegivel ? 1 : 0.5,
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(15), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(16), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(17), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(18), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(19), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(20)": {
              backgroundColor: "#CAE0D6",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(21), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(22), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(23), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(24), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(25)": {
              backgroundColor: "#acccb9",
            },
            "&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(26), &:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(27),&:hover div.ms-DetailsRow-fields div.ms-DetailsRow-cell:nth-child(28)": {
              backgroundColor: "#FBE0E0",
            },
            "&:hover div.ms-DetailsRow-cell": {
              backgroundColor: "#F3F2F1",
            },
            "&:hover .btnEditGrid": {
              visibility: "visible",
            },
          },
        };
        if (props?.columns[0].key === "avatar") {
          customStyles.cell = {
            display: "flex",
            alignItems: "center",
            whiteSpace: "break-spaces",
            position: "sticky",
            top: 50,
            opacity: props.item.aplicarBonus ? 1 : 0.5,
            selectors: {
              "&:nth-child(1), &:nth-child(2), &:nth-child(3)": {
                position: "sticky",
                zIndex: 9,
                opacity: 1,
                span: {
                  opacity: props.item.aplicarBonus ? 1 : 0.5,
                },
                backgroundColor: detailsRowStyleProps.isSelected
                  ? "#EDEBE9"
                  : "#FFFFFF",
              },
              "&:nth-child(1)": {
                left: 0,
              },
              "&:nth-child(2)": {
                left: 50,
              },
              "&:nth-child(3)": {
                left: 150,
              },
              "&:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16), &:nth-child(18), &:nth-child(19), &:nth-child(21), &:nth-child(26), &:nth-child(27)": {
                justifyContent: "center",
              },
              "&:nth-child(11),&:nth-child(20), &:nth-child(23)": {
                justifyContent: "flex-end",
              },
              "&:nth-child(15), &:nth-child(16), &:nth-child(17), &:nth-child(18), &:nth-child(19), &:nth-child(20)": {
                backgroundColor: "#D3EADF",
              },
              "&:nth-child(21), &:nth-child(22), &:nth-child(23), &:nth-child(24), &:nth-child(25)": {
                backgroundColor: "#BEDBCA",
              },
              "&:nth-child(26), &:nth-child(27), &:nth-child(28)": {
                backgroundColor: "#F8E8E8",
                opacity: 1,
              },
            },
          };
        }
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: BonusFuncType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.bonusState.data?.bonus.funcionarios ?? [];
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending,
      currColumn.data
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    typeData?: string
  ): T[] {
    const result = (prop: string, obj: T) =>
      prop.split(".").reduce((a: any, b: any) => a[b] ?? "", obj);
    return items.sort((a: T, b: T) => {
      const comparedA =
        typeData === "number"
          ? Number(result(columnKey, a))
          : result(columnKey, a);
      const comparedB =
        typeData === "number"
          ? Number(result(columnKey, b))
          : result(columnKey, b);
      return (
        isSortedDescending ? comparedA < comparedB : comparedA > comparedB
      )
        ? 1
        : -1;
    });
  }
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  {
    text: "Home",
    isCurrentItem: false,
    icon: "HomeSolid",
    onlyIcon: true,
    url: "/",
  },
  { text: "Geração de bônus", url: "/bonus" },
  { text: "Bônus", isCurrentItem: true },
];

const page: Page = {
  key: "bonus",
  pages: itemsBreadCrumb,
};

const mapStateToProps = (state: RootState) => ({
  merits: state.meritReducer,
  areas: state.chartReducer,
  cc: state.ccReducer,
  positions: state.positionsReducer,
  bonusState: state.bonusReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getBonusById,
      ...editBonusFunc,
      ...atualizaComentarios,
      ...finalizar,
      ...reativar,
      ...gerar,
      ...publicar,
      ...atualizaCalibracao,
      ...atualizaResidual,
      ...atualizaOutrasRemuneracoes,
      ...aplicar,
      ...ignorar,
      ...getChart,
      ...getPositions,
      ...getCC,
      ...setCurrentPage,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bonus));
