import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Task from './Tasks';
import Approvals from './Approvals';
import Solicitations from './Solicitations';

import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/home";
import { RootState } from "~/store/ducks/index";
import { Creators as CreatorsWorkflow} from "~/store/ducks/workflow";
import { DataTypes as DataTypesWorkflow } from '~/store/ducks/workflow/types';

import { Container } from './styles';

const pivotStyles: Partial<IPivotStyles> = {
    root: {
        borderBottom: '1px solid #CFD3DA',
        backgroundColor: "#FFFFFF",
        width: '100%',
        zIndex: 999,
    },
    itemContainer: {
        height: 'calc(100vh - 181px);'
    },
    linkContent: {
        width: 130
    }
}

const WorkFlow: React.FC = () => {

    const dispatch = useDispatch();

    const { pivotSelected } = useSelector<RootState, DataTypesWorkflow>(state => state.workflowReducer);

    useEffect(() => {
        const items: BreadcrumbItems[] = [
            { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
            { text: 'Painel WorkFlow', url: "/workflow", isCurrentItem: true }
        ];

        const page: Page = {
            key: 'workflow',
            pages: items
        };

        dispatch(Creators.setCurrentPage(page));
    }, [dispatch])

    const handlePivot = (idPivot: string) => {
        dispatch(CreatorsWorkflow.setCurrentPage(idPivot))
    }


    return (
        <Container>
            <Pivot
                aria-label="Basic Pivot Example"
                styles={pivotStyles}
                selectedKey={pivotSelected}
                onLinkClick={(e) => handlePivot(e?.props.itemKey!)}
            >
                <PivotItem
                    headerText="Tarefas"
                    itemKey="0"
                    key="0"
                >
                    <Task />
                </PivotItem>
                <PivotItem
                    headerText="Aprovações"
                    itemKey="1"
                    key="1"
                >
                    <Approvals fielName="idFuncionario" />
                </PivotItem>
                <PivotItem
                    headerText="Solicitações"
                    itemKey="2"
                    key="2"
                >
                    <Solicitations />
                </PivotItem>
            </Pivot>
        </Container>
    );
}

export default WorkFlow;