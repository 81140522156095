// #region Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import {
  CommandBarButton,
  ICommandBarStyles,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { IDialogContentProps } from "office-ui-fabric-react/lib/Dialog";


//MaterialUI
import MenuItem from "@material-ui/core/MenuItem";
import Dropdown from "~/components/layout/Dropdown";
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";

import {
  

  InputAutocomplete,
} from "~/components/Forms";

import { DeleteButton, PrimaryButton } from "~/components/Buttons";
import NoItems from "~/components/layout/NoItems";
//Estilos
import {
  Wrapper,
  ListContainer,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { DataTypes as DataTypesUnit } from "~/store/ducks/admin/units/types";
import { Creators as loadUnitESById } from "~/store/ducks/admin/units";
import { Creators as addUnitES } from "~/store/ducks/admin/units";
import { Creators as editUnitES } from "~/store/ducks/admin/units";
import { Creators as delUnitES } from "~/store/ducks/admin/units";

import {
  SalaryStructureType,
  DataTypes as DataTypesSalary,
} from "~/store/ducks/admin/salaryStructure/types";
import { Creators as getSalaryStructure } from "~/store/ducks/admin/salaryStructure";

import { RootState } from "~/store/ducks";
import { UnitESType } from "~/store/ducks/admin/units/types";

import { DataTypes as DataTypesCarreira } from "~/store/ducks/admin/carreira/types";
import { Creators as getCarreiras } from "~/store/ducks/admin/carreira";
//#endregion

/**
 * Validação do formulário
 */

interface IFormInicialValues {
  idCicloAtitude: number | null;
  idAtitude: string;
  ordem: string | number;
  flagLideranca: boolean;
}

interface IUnitSalaryStructureState {
  columns: IColumn[];
  isPanelOpen: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  initialValues: UnitESType;
  esSelected: Partial<SalaryStructureType> | null;
}

interface IPropsUnitSalaryStructure {
  unitState: DataTypesUnit;
  salary: DataTypesSalary;
  carreiras: DataTypesCarreira;
  loadUnitESById: (idUnidade: number, idUnidadeES: number) => void;
  addUnitES: (idUnidade: number, es: UnitESType) => void;
  editUnitES: (idUnidade: number, es: UnitESType) => void;
  delUnitES: (idUnidade: number, idUnidadeES: number) => void;
  getSalaryStructure: (search?: string) => void;
  getCarreiras: () => void;
}

const inicialValues: UnitESType = {
  idUnidadeES: null,
  idES: null,
  idCarreira: null,
  es: {
    nomeES: "",
  },
  carreira: null,
};

class UnitSalaryStructure extends Component<
  IPropsUnitSalaryStructure,
  IUnitSalaryStructureState
> {
  private formRef: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: IPropsUnitSalaryStructure) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Cód.",
        fieldName: "idES",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Estrutura salarial",
        fieldName: "es.nomeEs",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (value: UnitESType) => value.es.nomeES,
      },
      {
        key: "column3",
        name: "Carreira",
        fieldName: "carreira.nomeCarreira",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (value: UnitESType) => value.carreira?.nomeCarreira,
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      isPanelOpen: false,
      selectionDetails: "",
      selectionCount: 0,
      isDialogExcludeOpen: false,
      initialValues: inicialValues,
      esSelected: null,
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    this.props.getCarreiras()
  }

  componentDidUpdate(prevProps: IPropsUnitSalaryStructure, _: any) {
    if (prevProps.unitState.success !== this.props.unitState.success) {
      if (this.props.unitState.success) {
        this.setState({ isPanelOpen: false });
      }
    }

    if (
      prevProps.unitState.itemEsSelected.success !==
      this.props.unitState.itemEsSelected.success
    ) {
      if (this.props.unitState.itemEsSelected.success) {
        this.setState({
          initialValues: this.props.unitState.itemEsSelected.item!,
        });
      }
    }

    if (
      prevProps.unitState.itemEsSelected.error !==
      this.props.unitState.itemEsSelected.error
    ) {
      if (this.props.unitState.itemEsSelected.error) {
        this.setState({
          isPanelOpen: false,
        });
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    }
  };

  _onItemInvoked = (): void => {
    this.handleEdit();
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  excludeUnitES = () => {
    const idUnidade = this.props.unitState.itemSelected.item!.idUnidade!;
    const idUnidadeES = (this._selection.getSelection()[0] as UnitESType)
      .idUnidadeES!;
    this.props.delUnitES(idUnidade, idUnidadeES);
    this.setState({ isDialogExcludeOpen: false });
    this._selection.setAllSelected(false);
  };

  handleEdit = () => {
    const idUnidade = this.props.unitState.itemSelected.item!.idUnidade!;
    const unidadeES = this._selection.getSelection()[0] as UnitESType;
    this.props.loadUnitESById(idUnidade, unidadeES.idUnidadeES!);
    this.setState({
      isPanelOpen: true,
      esSelected: {
        idES: unidadeES.idES,
        nomeES: unidadeES.es.nomeES,
      },
    });
  };

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <Stack horizontal verticalAlign="center">
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={this.handleEdit}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </Stack>
      );
    }
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false, esSelected: null });
  };

  search = (text: string) => {
    if (text.trim()) {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.props.getSalaryStructure(text);
      }, 700);
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      selectionCount,
      columns,
      initialValues,
      esSelected,
    } = this.state;
    const { unitState, addUnitES, editUnitES, salary, carreiras} = this.props;
    const { es: esData, idUnidade } = unitState.itemSelected.item ?? {};

    const dialogContentProps: IDialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    return (
      <>
        <Wrapper>
          <ContainerContent>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal verticalAlign="center">
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Add" }}
                  text="Adicionar ES"
                  onClick={() =>
                    this.setState({
                      isPanelOpen: true,
                      initialValues: initialValues,
                      esSelected: null,
                    })
                  }
                />
                {this.commandBarRender()}
              </Stack>
            </Stack>

            {!unitState.loading && esData?.length === 0 ? (
              <NoItems
                error={unitState.error}
                text="Não há estrutura salarial cadastrada"
                icon="Money"
              />
            ) : (
              <ListContainer>
                <ShimmeredDetailsList
                  items={esData ?? []}
                  enableShimmer={unitState.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            )}
          </ContainerContent>
        </Wrapper>

        <Panel
          title={
            !inicialValues.idUnidadeES
              ? "Nova Estrutura Salarial"
              : `${inicialValues.idES}`
          }
          open={isPanelOpen}
          loading={unitState.loading}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>
                Cancelar
              </DefaultButton>
              <PrimaryButton
                disabled={unitState.itemEsSelected.loading}
                onClick={this.handleSubmit}
                text="Salvar"
                isLoading={unitState.loading}
              />
            </Stack>
          }
        >
          {unitState.itemEsSelected.loading ? (
            <Spinner
              size={SpinnerSize.large}
              styles={{ root: { height: "calc(100% - 50px)" } }}
            />
          ) : (
            <Formik
              innerRef={this.formRef}
              initialValues={initialValues}
              validationSchema={validationUnitEsSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
              onSubmit={(values: UnitESType) => {
                const value: UnitESType = {
                  ...values,
                  es: {
                    nomeES: esSelected?.nomeES ?? "",
                  },
                  idCarreira: values.idCarreira ?? null,
                  carreira: carreiras.data.find(item => item.idCarreira === values.idCarreira) ?? null
                };
                if (!values.idUnidadeES) {
                  addUnitES(idUnidade!, value);
                } else {
                  editUnitES(idUnidade!, value);
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldError,
                setValues,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <InputText
                      value={values.idES ?? ""}
                      onChange={(e) => { handleChange(e); setFieldError("idES", "") }}
                      id="idES"
                      error={!!errors.idES}
                      name="idES"
                      type="text"
                      label="Estrutura Salarial"
                      helperText={errors.idES}
                      className="mt-2"
                    /> */}
                  <InputAutocomplete
                    value={esSelected as SalaryStructureType}
                    onChange={(_, newValue) => {
                      this.setState({ esSelected: newValue });
                      setFieldValue(
                        "idES",
                        newValue ? Number(newValue.idES) : null
                      );
                    }}
                    onInputChange={(_, newInputValue) => {
                      setFieldError("idES", "");
                      this.search(newInputValue);
                    }}
                    getOptionLabel={(salary: SalaryStructureType) =>
                      salary.nomeES
                    }
                    getOptionSelected={(option, value) =>
                      option.idES === value.idES
                    }
                    options={salary.data}
                    input={{
                      idInput: "idES",
                      labelInput: "Estrutura Salarial",
                      helperTextInput: errors.idES,
                      errorInput: !!errors.idES,
                    }}
                  />
                  <Dropdown
                    errors={errors.idCarreira}
                    label="Carreira"
                    name="idCarreira"
                    values={values.idCarreira ?? ""}
                    handleChange={(e: any) => {
                      handleChange(e);
                      setFieldError("idCarreira", "");
                    }}
                    errorText={errors.idCarreira}
                  >
                    <MenuItem value={""}>Nenhum</MenuItem>
                    {carreiras.data.map((car) => (
                      <MenuItem key={car.idCarreira} value={car.idCarreira}>
                        {car.nomeCarreira}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </form>
              )}
            </Formik>
          )}
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeUnitES()} text="Excluir" />
        </CustomDialog>
      </>
    );
  }

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }
}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const validationUnitEsSchema = yup.object().shape({
  idES: yup.number().nullable().required("Campo obrigatório"),
  idCarreira: yup.number().nullable().notRequired(),
});

const mapStateToProps = (state: RootState) => ({
  salary: state.salaryStructureReducer,
  carreiras: state.carreiraReducer
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadUnitESById,
      ...addUnitES,
      ...editUnitES,
      ...delUnitES,
      ...getSalaryStructure,
      ...getCarreiras,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitSalaryStructure);
