import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Stack,
  Spinner,
} from "office-ui-fabric-react";
import { Text } from "office-ui-fabric-react/lib/Text";
import {
  ICommandBarStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import {
  Dropdown as DropdownUI,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";


import Accordion from "~/components/Accordion";

import { CommandBarButton } from "~/components/Buttons";
import { InputText } from "~/components/Forms";
import PainelHeader from "~/components/layout/PainelHeader";

import {
  Container,
  Content,
  WrapperResults,
  Table,
  Tbody,
  TdResultAtitudes,
} from "./styles";

import { Creators as CreatorsHome } from "~/store/ducks/home";
import { Creators as CreatorsTask } from "~/store/ducks/tasks";
import { Creators as CreatorsProfile } from "~/store/ducks/profile";
import { DataTypes as DataTypesTask } from "~/store/ducks/tasks/types";
import { DataTypes as DataTypesProfile } from "~/store/ducks/profile/types";
import NoItems from "~/components/layout/NoItems";

import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { convertStatusTarefa } from "~/utils/tasks";
import Colors from "~/assets/js/colors";
import { RootState } from "~/store/ducks";

interface IAdapterFeedback {
  descricao: string;
  status: string;
  comentariosDeAcordo: string;
  dataHoraRecebimento: Date | null;
  dataOcorrencia: Date | null;
  deAcordo: "Não recebi feedback" | "Recebi e CONCORDO" | "Recebi e NÃO CONCORDO" | null;
  flagFinalizada?: boolean;
  flagEdicao?: boolean;
  funcionario?: {
    nomeCompleto?: string;
  }
  owner?: {
    nomeCompleto?: string;
  }
}

interface IFeedbackFuncionarioProps {
  taskState?: DataTypesTask;
}


interface Params {
  idTarefa?: string;
  idTarefaFeedback?: string;
  idFuncionario?: string;
  idFeedback?: string;
}

const FeedbackFuncionario: React.FC<IFeedbackFuncionarioProps> = (props) => {
  const { taskState } = props;
  const {
    selected: feedback,
    error,
    loadingData: loadingDataTask,
    loadingAction,
    redirectTo,
  } =
    taskState ??
    useSelector<RootState, DataTypesTask>((state) => state.tasksReducer);


  const [feedbackFunc, setFeedBackFunc] = useState<IAdapterFeedback | null>(null);
  const profileState = useSelector<RootState, DataTypesProfile>(state => state.profileReducer)


  const params = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorForm, setErrorForm] = useState(false);
  const isFeedback = params?.idFeedback!!;


  const itemsBreadCrumbTask: BreadcrumbItems[] = [
    {
      text: "Home",
      isCurrentItem: false,
      icon: "HomeSolid",
      onlyIcon: true,
      url: "/",
    },
    { text: "Workflow", isCurrentItem: false, url: "/workflow" },
    { text: "Feedback", isCurrentItem: true },
  ];

  const itemsBreadCrumbProfile: BreadcrumbItems[] = [
    {
      text: "Home",
      isCurrentItem: false,
      icon: "HomeSolid",
      onlyIcon: true,
      url: "/",
    },
    { text: "Perfil", isCurrentItem: false, url: `/perfil/${params.idFuncionario}` },
    { text: "Feedback", isCurrentItem: true },
  ];

  const page: Page = {
    key: "feedback",
    pages: isFeedback ? itemsBreadCrumbProfile : itemsBreadCrumbTask,
  };

  useEffect(() => {
    if (params.idFeedback && params.idFuncionario) {
      dispatch(CreatorsProfile.getNewFeedbackById(Number(params.idFuncionario), Number(params.idFeedback)));
    }
  }, [])

  useEffect(() => {
    const { dataFeedbackSelected } = profileState || {};
    setFeedBackFunc({
      comentariosDeAcordo: dataFeedbackSelected?.comentariosDeAcordo ?? "",
      dataHoraRecebimento: dataFeedbackSelected?.dataHoraRecebimento ?? null,
      dataOcorrencia: dataFeedbackSelected?.dataOcorrencia ?? null,
      deAcordo: dataFeedbackSelected?.deAcordo ?? null,
      descricao: dataFeedbackSelected?.descricao ?? "",
      status: dataFeedbackSelected?.status ?? "",
      owner: {
        nomeCompleto: dataFeedbackSelected?.owner.nomeCompleto
      },
      flagFinalizada: true,
      flagEdicao: false,
      funcionario: {
        nomeCompleto: dataFeedbackSelected?.funcionario.nomeCompleto
      }
    })
    resetForm({
      values: {
        deAcordo: dataFeedbackSelected?.deAcordo ?? null,
        comentariosDeAcordo: dataFeedbackSelected?.comentariosDeAcordo ?? ""
      }
    })
  }, [profileState?.dataFeedbackSelected])

  useEffect(() => {
    const { Feedback, flagFinalizada, flagEdicao, funcionario } = feedback || {};
    setFeedBackFunc({
      comentariosDeAcordo: Feedback?.comentariosDeAcordo ?? "",
      dataHoraRecebimento: Feedback?.dataHoraRecebimento ?? null,
      dataOcorrencia: Feedback?.dataOcorrencia ?? null,
      deAcordo: Feedback?.deAcordo ?? null,
      descricao: Feedback?.descricao ?? "",
      status: Feedback?.status ?? "",
      flagFinalizada,
      flagEdicao,
      funcionario
    })

    resetForm({
      values: {
        deAcordo: Feedback?.deAcordo ?? null,
        comentariosDeAcordo: Feedback?.comentariosDeAcordo ?? ""
      }
    })
  }, [feedback])


  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    errors,
    values,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      dispatch(
        CreatorsTask.finishTask(Number(params.idTarefa)!, {
          ...values,
        })
      );
    }
  });

  useEffect(() => {
    dispatch(CreatorsHome.setCurrentPage(page));
  }, []);

  // useEffect(() => {
  // console.log(feedbackFunc)
  //   resetForm({
  //     values: {
  //       deAcordo: feedbackFunc?.deAcordo ?? null,
  //       comentariosDeAcordo: feedbackFunc?.comentariosDeAcordo ?? ""
  //     },
  //   });
  // }, [dispatch, resetForm]);

  useEffect(() => {
    if (redirectTo) {
      history.push('/workflow');
    }
  }, [redirectTo]);

  const handleRedirect = () => {
    if (isFeedback) {
      dispatch(CreatorsProfile.setCurrentTab('5'));
      history.push(`/perfil/${params.idFuncionario}`);
    } else {
      history.push(`/workflow`);
    }
  }

  const onRenderTitlePotencial = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { width: "100%" } }}
      >
        <Stack horizontalAlign="center">
          <span
            style={{
              fontSize: 12,
              whiteSpace: "break-spaces",
              lineHeight: 1.5,
              textAlign: "left",
              ...option?.data?.styleOption,
            }}
          >
            {option.text}
          </span>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container>
      {
        <PainelHeader
          title={isFeedback ?
            feedbackFunc?.owner?.nomeCompleto ?
              `Feedback de ${feedbackFunc?.owner?.nomeCompleto}` : ""
            : feedback?.tituloTarefa ?? ""}
          icon={{ icon: "Comment" }}
          labelGroups={
            feedbackFunc
              ? [
                {
                  label: "Funcionário",
                  value: feedbackFunc?.funcionario?.nomeCompleto ?? "",
                  icon: "Contact",
                  size: "full",
                },
                {
                  label: "Status",
                  value: convertStatusTarefa(feedbackFunc?.status ?? ""),
                  icon: "Flag",
                  size: "full",
                },
                {
                  label: "Data de Recebimento",
                  value: feedbackFunc?.dataOcorrencia!! ? moment(feedbackFunc?.dataOcorrencia).format("DD/MM/YYYY") : "",
                  icon: "Calendar",
                  size: "full",
                },
              ]
              : []
          }
        />
      }
      <Content>
        {(loadingDataTask || profileState.loadingData) ? (
          <Spinner
            label={isFeedback ? "Carregando feedback" : "Carregando tarefa"}
            styles={{ root: { height: 500 } }}
          />
        ) : (
          <>
            {(error || profileState.error) ? (
              <NoItems text={isFeedback ?
                "Feedback não encontrado ou você não tem permissão para visualizar"
                : "Tarefa não encontrada"}
                error={true}
              />
            ) : (
              <>
                {!feedbackFunc?.flagFinalizada && !isFeedback &&
                  <CommandBarButton
                    iconProps={{ iconName: "Send" }}
                    isLoading={loadingAction}
                    onClick={() => handleSubmit()}
                    styles={commandButtonStyle}
                  >
                    Enviar
                  </CommandBarButton>
                }
                <CommandBarButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={handleRedirect}
                  styles={commandButtonStyle}
                >
                  Fechar
                </CommandBarButton>


                {errorForm && (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => {
                      setErrorForm(false);
                    }}
                  >
                    Todos os campos são obrigatórios!
                  </MessageBar>
                )}

                <WrapperResults>


                  <Accordion title="Feedback Recebido">
                    <div style={{ marginBottom: 16 }}>
                      <InputText
                        id="descricao"
                        name="descricao"
                        label="Descrição do Feedback"
                        multiline
                        rows={3}
                        disabled={true}
                        value={feedbackFunc?.descricao ?? ""}
                        aria-readonly="true"
                      />
                    </div>
                  </Accordion>

                  <Accordion title="De Acordo">
                    <Table style={{ width: "100%", height: "100%", minHeight: 206 }}>
                      <Tbody style={{ display: "block" }}>
                        <tr>
                          <td style={{ width: 530, minWidth: 530, textAlign: "end", paddingRight: 24 }}>
                            DE ACORDO
                          </td>
                          <Stack>
                            <TdResultAtitudes
                              style={{ width: 420, minWidth: 420 }}
                            >
                              <DropdownUI
                                options={optionsDeAcordo}
                                defaultSelectedKey={
                                  values.deAcordo ??
                                  "Selecione uma opção"
                                }
                                selectedKey={
                                  values.deAcordo ??
                                  "Selecione uma opção"
                                }
                                onRenderTitle={
                                  onRenderTitlePotencial as any
                                }
                                disabled={feedbackFunc?.flagFinalizada}
                                onChange={(e, item) => {
                                  setFieldValue("deAcordo", item?.key)
                                  setFieldError("deAcordo", undefined)
                                }}
                                styles={{
                                  title: {
                                    height: 60,
                                    border: "1px solid #CCCCCC",
                                    display: "flex",
                                    alignItems: "center",
                                    borderColor: "#CCCCCC"
                                  },
                                  caretDownWrapper: {
                                    top: 16,
                                  },
                                }}
                              />
                            </TdResultAtitudes>
                            <Text as="p" styles={{ root: { color: Colors.error, fontSize: 12, marginTop: "3px !important" } }}>{errors.deAcordo}</Text>                          </Stack>
                        </tr>
                        <tr style={{ display: "block" }}>
                          <InputText
                            id="comentariosDeAcordo"
                            name="comentariosDeAcordo"
                            label="Comentários"
                            multiline
                            rows={3}
                            value={values.comentariosDeAcordo}
                            onChange={(e) => {
                              setFieldValue("comentariosDeAcordo", e.target.value);
                              setFieldError("comentariosDeAcordo", "");
                            }}
                            disabled={!feedbackFunc?.flagEdicao}
                            error={errors.comentariosDeAcordo ? true : false}
                            aria-readonly="true"
                            helperText={errors.comentariosDeAcordo}
                          />
                        </tr>
                      </Tbody>
                    </Table>
                  </Accordion>
                </WrapperResults>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const optionsDeAcordo: IDropdownOption[] = [
  {
    key: "Selecione uma opção",
    text: "Selecione uma opção",
    disabled: true,
    hidden: true,
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Não recebi feedback",
    text: "NÃO RECEBI FEEDBACK",
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Recebi e CONCORDO",
    text: "RECEBI E CONCORDO",
    data: { styleOption: { fontSize: 14 } },
  },
  {
    key: "Recebi e NÃO CONCORDO",
    text: "RECEBI E NÃO CONCORDO",
    data: { styleOption: { fontSize: 14 } },
  },
]

const validation = yup.object({
  comentariosDeAcordo: yup.string().required("Campo obrigatório"),
  deAcordo: yup.string().nullable().required("Campo obrigatório")
});

const initialValues: {
  deAcordo: "Não recebi feedback" | "Recebi e CONCORDO" | "Recebi e NÃO CONCORDO" | null;
  comentariosDeAcordo: string
} = {
  deAcordo: null,
  comentariosDeAcordo: "",
};

const commandButtonStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

export default FeedbackFuncionario;
