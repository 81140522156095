import { takeEvery, put, call, all } from "redux-saga/effects";

import {
    Types,
    MeetingCriarType,
    IRequestMeetingsAddAction,
    IRequestMeetingsDelAction,
    IRequestMeetingsLoadCriarAction,
    ISuccessMeetingsLoadAction,
    ISuccessMeetingsAddAction,
    ISuccessMeetingsDelAction,
    ISuccessMeetingsLoadCriarAction,

} from "../ducks/meetings/types";
import api from "~/services/api";

const apiCallGet = () => {
    return api.get(`reunioes`).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const apiCallGetCriar = (idCiclo?: number) => {
    return api.get(`reunioes/criar${idCiclo ? `/${idCiclo}` : ''}`).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const apiCallAdd = (payload: IRequestMeetingsAddAction["payload"]) => {
    return api.post(`reunioes`, {
        idCicloGrupo: payload.idCicloGrupo
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        throw err.response;
    });
};

const apiCallDel = (payload: IRequestMeetingsDelAction["payload"]) => {
    return api.delete(`reunioes/${payload.idCicloReuniao}`)
        .then((response) => {
            return response.data;
        }).catch((err) => {
            throw err.response;
        });
};

function* load() {
    try {
        let items: any = yield call(apiCallGet);
        yield put({
            type: Types.SUCCESS_MEETINGS_LOAD,
            payload: items
        } as ISuccessMeetingsLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as reuniões'
        yield put({
            type: Types.FAILURE_MEETINGS_LOAD,
            message
        });
    }
}

function* loadCriar(action: IRequestMeetingsLoadCriarAction) {
    try {
        let item: MeetingCriarType = yield call(apiCallGetCriar, action.idCiclo);
        yield put({
            type: Types.SUCCESS_MEETINGS_LOAD_CRIAR,
            payload: item
        } as ISuccessMeetingsLoadCriarAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os grupos'
        yield put({
            type: Types.FAILURE_MEETINGS_LOAD_CRIAR,
            message
        });
    }
}

function* add(action: IRequestMeetingsAddAction) {
    try {
        let idCicloReuniao: any = yield call(apiCallAdd, action.payload);

        yield put({
            type: Types.SUCCESS_MEETINGS_ADD,
            ...idCicloReuniao
        } as ISuccessMeetingsAddAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível criar a reunião'
        yield put({
            type: Types.FAILURE_MEETINGS_ADD,
            message
        });
    }
}

function* del(action: IRequestMeetingsDelAction) {
    try {
        yield call(apiCallDel, action.payload);
        yield put({
            type: Types.SUCCESS_MEETINGS_DEL,
            payload: {
                ...action.payload
            }
        } as ISuccessMeetingsDelAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir a reunião'
        yield put({
            type: Types.FAILURE_MEETINGS_DEL,
            message
        });
    }
}

export default function* meetingsRoot() {
    yield all([
        yield takeEvery(Types.REQUEST_MEETINGS_LOAD, load),
        yield takeEvery(Types.REQUEST_MEETINGS_LOAD_CRIAR, loadCriar),
        yield takeEvery(Types.REQUEST_MEETINGS_ADD, add),
        yield takeEvery(Types.REQUEST_MEETINGS_DEL, del),
    ]);
}
