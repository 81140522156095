import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import {
  ShimmeredDetailsList,
  IShimmeredDetailsListProps,
} from "office-ui-fabric-react/lib/ShimmeredDetailsList";

import { Creators as loadEmployeeOcorrencias } from "~/store/ducks/employees";
import { RootState } from "~/store/ducks";
import { DetailsHeader, Text } from "office-ui-fabric-react";
import { Sticky } from "@fluentui/react";
import { BudgetType } from "~/store/ducks/merit/types";
import Colors from "~/assets/js/colors";
import { toCurrency } from "~/utils/numbers";

interface IGridBudgetProps
  extends Omit<IShimmeredDetailsListProps, "items"> {
  budgets: BudgetType[]
  isLoading: boolean;
  selectionMode?: SelectionMode;
  loadEmployeeOcorrencias: (idFuncionario: number) => void;
  _onItemInvoked?: (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ) => void;
}

interface IGridBudgetState {
  columns: IColumn[];
}

class GridBudget extends React.Component<
  IGridBudgetProps,
  IGridBudgetState
> {
  constructor(props: IGridBudgetProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: "column1",
        name: "Data",
        fieldName: "CreatedAt",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        data: "data",
        isPadded: true,
        onRender: (item: BudgetType) =>
          moment(item.CreatedAt).format("DD/MM/YYYY"),
      },
      {
        key: "column2",
        name: "Valor",
        fieldName: "valor",
        isRowHeader: true,
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: BudgetType) =>
          <Text styles={{ root: { color: item.valor >= 0 ? Colors.greenDarker : Colors.error } }}>{toCurrency(item.valor, false)}</Text>
      },
      {
        key: "column3",
        name: "Observação",
        fieldName: "comentarios",
        minWidth: 75,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
      },
    ];

    this.state = {
      columns,
    };
  }

  componentDidMount() {

  }

  render() {
    const { columns } = this.state;
    const { budgets, isLoading, ...rest } = this.props;
    return (
      <ShimmeredDetailsList
        {...rest}
        items={budgets ?? []}
        enableShimmer={isLoading}
        selectionMode={SelectionMode.none}
        shimmerLines={4}
        columns={columns}
        styles={{ root: { paddingTop: 0 } }}
        onRenderDetailsHeader={(detailsHeaderProps) => {
          return (
            <Sticky>
              <DetailsHeader
                {...detailsHeaderProps}
                layoutMode={DetailsListLayoutMode.justified}
              />
            </Sticky>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  employee: state.employeesReducer.itemSelected,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...loadEmployeeOcorrencias,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridBudget);
