import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik";

import { OutlinedButton } from '~/components/Buttons';
import Chip from '~/components/Chip';
import FormSearch from '../../../Employees/formSearch';

import {
  GroupItems,
} from '~/components/FormGroup';
import { Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';

import { Creators as CreatorsCycle } from '~/store/ducks/cycle';
import { DataTypes as DataTypesCycle, PesquisaFuncType } from '~/store/ducks/cycle/types';
import { Creators } from '~/store/ducks/worklevel'
import { DataTypes as DataTypesWorklevel, WorklevelType } from '~/store/ducks/worklevel/types'
import { RootState } from '~/store/ducks'

interface IFormParticipantesGroupProps {
  initialValues: WorklevelType[];
  idCiclo: number;
  formRef: any;
  success: (values: any) => any;
};

const FormParticipantes: React.FC<IFormParticipantesGroupProps> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [participants, setParticipants] = useState<PesquisaFuncType[]>(props.initialValues.flatMap(item => item.avaliados ?? []));
  const [error, setError] = useState(false);
  const filterParticipants = participants.filter((item, i, arr) => arr.map(part => part.idFuncionario).indexOf(item.idFuncionario) === i);
  const worklevelState = useSelector<RootState, DataTypesWorklevel>(state => state.worklevelReducer);
  const cycleState = useSelector<RootState, DataTypesCycle>(state => state.cycleReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (participants.length >= 0) {
      dispatch(Creators.addAvaliadosWorklevel(filterParticipants))
      setError(false)
    }
  }, [participants])

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={props.formRef}
        initialValues={participants}
        validateOnChange={false}
        validateOnBlur={true}
        validate={(values) => {
          if (participants.length === 0) {
            setError(true)
            return { partipants: 'Adicione os avaliados' }
          } else {
            return {};
          }
        }}
        onSubmit={() => {
          props.success(worklevelState.data);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 paddingZero">
                    <OutlinedButton
                      text="Adicionar Avaliados"
                      style={{ width: 190 }}
                      onClick={() => {
                        setIsDialogOpen(true);
                      }}
                    />
                  </div>
                  <Stack styles={{ root: { marginTop: 55, display: 'inline-flex', width: '100%' } }}>
                    {
                      error &&
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        styles={{ root: { width: '100%' } }}
                        onDismiss={() => setError(false)}
                        dismissButtonAriaLabel="Close"
                      >
                        Adicione os avaliados ao grupo!
                                                </MessageBar>
                    }

                    {

                      worklevelState.data.map((worklevel, i) => {
                        if (worklevel?.avaliados && worklevel.avaliados.length > 0) {
                          return (
                            <div className="ms-Grid-col ms-sm12 paddingZero" key={i}>
                              <GroupItems title={worklevel.descricao} styles={{ marginBottom: 40 }} hasMultiItems>
                                <GroupItems>
                                  {
                                    worklevel.avaliados?.map((item, i) =>
                                      <Chip
                                        key={i}
                                        nome={item.nomeCompleto}
                                        handleClick={() => setParticipants(participants.filter(part => part.idFuncionario !== item.idFuncionario))}
                                      />)
                                  }
                                </GroupItems>
                              </GroupItems>
                            </div>
                          )
                        }
                      })
                    }
                  </Stack>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>

      <FormSearch
        isOpen={isDialogOpen}
        items={cycleState.pesquisaFunc}
        loading={cycleState.loading}
        onClose={() => setIsDialogOpen(false)}
        onAdd={(values: PesquisaFuncType[]) => {
          setParticipants([...participants, ...values]);
          setIsDialogOpen(false);
        }}
        searchAPI={(nomeSearch?: string, idArea?: number | null, idWorkLevel?: number | null, idLider?: number | null) =>
          dispatch(CreatorsCycle.pesquisaFunc(props.idCiclo!, nomeSearch, idArea, idWorkLevel ? Number(idWorkLevel) : null, idLider ?? null))}
      />
    </>
  );
}

export default FormParticipantes;
