import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";

import { ListContainer, ContainerContent } from "./styles";

//FluentUI
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
//Componentes
import { RootState } from "~/store/ducks";
import { InputDate, InputNumber } from "~/components/Forms";
import Accordion from "~/components/Accordion";
import Dialog from "~/components/CustomDialog";
import CustomDialog from "~/components/CustomDialogFluentUI";

import { Creators as setCurrentTab } from "~/store/ducks/profile";
import { Creators as setCurrentPage } from "~/store/ducks/home";

import { Creators as editBonus } from "~/store/ducks/bonus";
import { Creators as editBonusPerformance } from "~/store/ducks/bonus";
import { Creators as addFalta } from "~/store/ducks/bonus";
import { Creators as editFalta } from "~/store/ducks/bonus";
import { Creators as delFalta } from "~/store/ducks/bonus";

import {
  BonusFaltaType,
  BonusPerformanceType,
  BonusType,
  DataTypes as DataTypesBonus,
} from "~/store/ducks/bonus/types";
import { GroupItems, TitleField } from "~/components/FormGroup";
import {
  ICommandBarStyles,
  IDialogContentProps,
  Stack,
} from "office-ui-fabric-react";
import { CommandBarButton, DeleteButton } from "~/components/Buttons";
import { DefaultButton } from "@fluentui/react";

type ListResultType = {
  descricao: string;
  valor: number | string;
};

interface IRegrasBonusState {
  columnsResult: IColumn[];
  columnsPerformance: IColumn[];
  columnsSalary: IColumn[];
  columnsFaltas: IColumn[];
  selectionDetails: string;
  selectionCount: number;
  isDialogEditParametrosOpen: boolean;
  isDialogEditResultOpen: boolean;
  isDialogEditPerformanceOpen: boolean;
  isDialogEditSalaryOpen: boolean;
  isDialogEditFaltasOpen: boolean;
  isDialogFaltaExcludeOpen: boolean;
  performanceSelected: BonusPerformanceType | null;
  initialValueFalta: BonusFaltaType;
}

interface IRegrasBonusProps {
  bonusState: DataTypesBonus;
  idBonus: number;
  setCurrentTab: (indexTab: string) => void;
  editBonus: (bonus: Partial<BonusType>) => void;
  editBonusPerformance: (
    idBonus: string,
    idBonusPerformance: string,
    multiplicador: string
  ) => void;
  addFalta: (idBonus: number, falta: BonusFaltaType) => void;
  editFalta: (idBonus: number, falta: BonusFaltaType) => void;
  delFalta: (idBonus: number, idBonusFalta: number) => void;
}

class RegrasBonus extends Component<IRegrasBonusProps, IRegrasBonusState> {
  private formRefParametro: any;
  private formRefResult: any;
  private formRefPerformance: any;
  private formRefFalta: any;
  private formRefSalary: any;
  private _selection: Selection;

  constructor(props: IRegrasBonusProps) {
    super(props);

    const columnsSalary: IColumn[] = [
      {
        key: "column1",
        name: "Descrição",
        fieldName: "descricao",
        minWidth: 200,
        isRowHeader: true,
      },
      {
        key: "column2",
        name: "Qtde",
        fieldName: "valor",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        styles: { cellTitle: { justifyContent: "flex-end" } },
      },
    ];

    const columnsResult: IColumn[] = [
      {
        key: "column1",
        name: "Descrição",
        fieldName: "descricao",
        minWidth: 200,
        isRowHeader: true,
      },
      {
        key: "column2",
        name: "Valor",
        fieldName: "valor",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: ListResultType) => `${Number(item.valor).toFixed(2)}`,
        // `${item.valor.toFixed(2)}${item.tipo === "P" ? "%" : ""}`,
      },
    ];

    const columnsPerformance: IColumn[] = [
      {
        key: "column1",
        name: "Descrição",
        fieldName: "nivel.descNivel",
        minWidth: 200,
        isRowHeader: true,
        onRender: (item: BonusPerformanceType) => item.nivel.descNivel,
      },
      // {
      //   key: "column2",
      //   name: "Nível",
      //   fieldName: "nivel",
      //   minWidth: 200,
      //   maxWidth: 250,
      //   isRowHeader: true,
      //   styles: { cellTitle: { justifyContent: "flex-end" } },
      // },
      {
        key: "column2",
        name: "Multiplicador",
        fieldName: "multiplicador",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: BonusPerformanceType) =>
          `${parseFloat(item.multiplicador)}%`,
      },
    ];

    const columnsFaltas: IColumn[] = [
      {
        key: "column1",
        name: "Quantidade Mínima",
        fieldName: "qtdeMinima",
        minWidth: 200,
        isRowHeader: true,
      },
      {
        key: "column2",
        name: "Desconto",
        fieldName: "desconto",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        styles: { cellTitle: { justifyContent: "flex-end" } },
        onRender: (item: BonusFaltaType) => `${item.desconto ?? 0}%`,
      },
    ];

    this.state = {
      columnsPerformance,
      columnsResult,
      columnsSalary,
      columnsFaltas,
      selectionDetails: "",
      selectionCount: 0,
      isDialogEditParametrosOpen: false,
      isDialogEditResultOpen: false,
      isDialogEditPerformanceOpen: false,
      isDialogEditSalaryOpen: false,
      isDialogEditFaltasOpen: false,
      isDialogFaltaExcludeOpen: false,
      performanceSelected: null,
      initialValueFalta: initialFalta,
    };

    this.formRefParametro = React.createRef();
    this.formRefResult = React.createRef();
    this.formRefPerformance = React.createRef();
    this.formRefSalary = React.createRef();
    this.formRefFalta = React.createRef();

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  }

  componentDidUpdate(prevProps: IRegrasBonusProps, _: IRegrasBonusState) {
    if (prevProps.bonusState.success !== this.props.bonusState.success) {
      if (this.props.bonusState.success) {
        this.setState({
          isDialogEditParametrosOpen: false,
          isDialogEditResultOpen: false,
          isDialogEditSalaryOpen: false,
          isDialogEditPerformanceOpen: false,
          isDialogEditFaltasOpen: false,
        });
      }
    }
  }

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  // Submit do formulário Formik
  handleSubmitParametro = () => {
    if (this.formRefParametro.current) {
      this.formRefParametro.current.handleSubmit();
    }
  };

  handleSubmitResult = () => {
    if (this.formRefResult.current) {
      this.formRefResult.current.handleSubmit();
    }
  };

  handleSubmitPerformance = () => {
    if (this.formRefPerformance.current) {
      this.formRefPerformance.current.handleSubmit();
    }
  };

  handleSubmitSalary = () => {
    if (this.formRefSalary.current) {
      this.formRefSalary.current.handleSubmit();
    }
  };

  handleSubmitFalta = () => {
    if (this.formRefFalta.current) {
      this.formRefFalta.current.handleSubmit();
    }
  };

  onResultItemParametroInvoked = () => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      this.setState({ isDialogEditParametrosOpen: true });
    }
  };

  onResultItemInvoked = () => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      this.setState({ isDialogEditResultOpen: true });
    }
  };

  onPerformanceItemInvoked = (item: BonusPerformanceType) => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      this.setState({
        isDialogEditPerformanceOpen: true,
        performanceSelected: item,
      });
    }
  };

  onSalarioItemInvoked = () => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      this.setState({ isDialogEditSalaryOpen: true });
    }
  };

  onFaltaItemInvoked = () => {
    this.editFalta();
  };

  editFalta = () => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      const selectedFalta = this._selection.getSelection()[0] as BonusFaltaType;
      this.setState({
        isDialogEditFaltasOpen: true,
        initialValueFalta: selectedFalta,
      });
    }
  };

  excludeFalta = () => {
    const { statusbonus } = this.props.bonusState.data?.bonus ?? {};

    if (statusbonus !== "Publicado") {
      const selectedFalta = this._selection.getSelection()[0] as BonusFaltaType;
      this.props.delFalta(this.props.idBonus, selectedFalta.idBonusFalta!);
      this.setState({
        isDialogFaltaExcludeOpen: false,
      });
    }
  };

  render() {
    const {
      
      columnsResult,
      
      columnsFaltas,
      isDialogEditParametrosOpen,
      isDialogEditResultOpen,
      isDialogEditPerformanceOpen,
      isDialogEditSalaryOpen,
      isDialogEditFaltasOpen,
      isDialogFaltaExcludeOpen,
      performanceSelected,
      selectionCount,
      initialValueFalta,
    } = this.state;
    const {
      idBonus,
      bonusState,
      editBonus,
      editBonusPerformance,
      addFalta,
      editFalta,
    } = this.props;

    const { bonus } = this.props.bonusState.data ?? {};
    const {
      acelerador,

      resultadoPercentual,

      statusbonus,
      dataReferencia,
      mesesTrabalhados,
    } = bonus ?? {};

    const readOnly = statusbonus === "Publicado";

    const itemsParametros: ListResultType[] = [
      {
        descricao: "Data Referência",
        valor: dataReferencia
          ? moment(dataReferencia).format("DD/MM/YYYY")
          : "",
      },
      {
        descricao: "Qtde. mínima de meses trabalhada",
        valor: mesesTrabalhados ?? "",
      },
    ];

    const itemsResult: ListResultType[] = [
      {
        descricao: "Percentual (%) ",
        valor: resultadoPercentual ?? 0,
      },
      {
        descricao: "Acelerador (%)",
        valor: acelerador ?? 0,
      },
    ];

    return (
      <ContainerContent>
        <Accordion title="Pârametros Gerais">
          <ListContainer>
            <ShimmeredDetailsList
              items={itemsParametros}
              enableShimmer={false}
              columns={columnsResult}
              selectionMode={SelectionMode.none}
              onItemInvoked={this.onResultItemParametroInvoked}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={this._onRenderRowResult}
            />
          </ListContainer>
        </Accordion>
        <Accordion title="Resultado da Companhia">
          <ListContainer>
            <ShimmeredDetailsList
              items={itemsResult}
              enableShimmer={false}
              columns={columnsResult}
              selectionMode={SelectionMode.none}
              onItemInvoked={this.onResultItemInvoked}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={this._onRenderRowResult}
            />
          </ListContainer>
        </Accordion>

        <Accordion title="Faltas">
          {this.props.bonusState.data?.bonus.statusbonus !== "Publicado" && (
            <Stack horizontal>
              <CommandBarButton
                styles={btnStyle}
                iconProps={{ iconName: "Add" }}
                text="Adicionar Falta"
                onClick={() =>
                  this.setState({
                    isDialogEditFaltasOpen: true,
                    initialValueFalta: initialFalta,
                  })
                }
              />
              {selectionCount === 1 && (
                <>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Edit" }}
                    text="Editar"
                    onClick={() => this.editFalta()}
                  />
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Delete" }}
                    text="Excluir"
                    onClick={() =>
                      this.setState({ isDialogFaltaExcludeOpen: true })
                    }
                  />
                </>
              )}
            </Stack>
          )}
          <ListContainer>
            <ShimmeredDetailsList
              items={bonus?.faltas ?? []}
              enableShimmer={false}
              columns={columnsFaltas}
              selectionMode={
                statusbonus !== "Publicado"
                  ? SelectionMode.single
                  : SelectionMode.none
              }
              selection={this._selection}
              onItemInvoked={this.onFaltaItemInvoked}
              selectionPreservedOnEmptyClick={true}
              setKey="single"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={this._onRenderRowResult}
            />
          </ListContainer>
        </Accordion>

        <Dialog
          title="Parâmetros Gerais"
          open={isDialogEditParametrosOpen}
          onClickCancel={() =>
            this.setState({ isDialogEditParametrosOpen: false })
          }
          onClickConfirm={this.handleSubmitParametro}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonusState.loadingAction || readOnly}
          isLoadingConfirm={bonusState.loadingAction}
          btnConfirmText="Salvar"
        >
          <Formik
            innerRef={this.formRefParametro}
            initialValues={{
              mesesTrabalhados,
              dataReferencia,
            }}
            validationSchema={validationParametrosSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              values.dataReferencia = moment(values.dataReferencia).format(
                "YYYY-MM-DD"
              );
              editBonus({
                ...values,
                idBonus: String(idBonus),
              });
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputDate
                  label="Data de Referência"
                  name="dataReferencia"
                  value={
                    values.dataReferencia ? moment(values.dataReferencia) : null
                  }
                  onChange={(value) => {
                    setFieldValue("dataReferencia", value);
                    setFieldError("dataReferencia", "");
                  }}
                  className="mt-2"
                  error={!!errors.dataReferencia}
                  helperText={errors.dataReferencia}
                />
                <InputNumber
                  value={
                    !!values.mesesTrabalhados
                      ? Number(values.mesesTrabalhados)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("mesesTrabalhados", value.floatValue);
                    setFieldError("mesesTrabalhados", "");
                  }}
                  id="mesesTrabalhados"
                  name="mesesTrabalhados"
                  label="Meses trabalhados"
                  decimalScale={0}
                  helperText={errors.mesesTrabalhados}
                  error={!!errors.mesesTrabalhados}
                />
              </form>
            )}
          </Formik>
        </Dialog>
        <Dialog
          title="Resultado da Companhia"
          open={isDialogEditResultOpen}
          onClickCancel={() => this.setState({ isDialogEditResultOpen: false })}
          onClickConfirm={this.handleSubmitResult}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonusState.loadingAction || readOnly}
          isLoadingConfirm={bonusState.loadingAction}
          btnConfirmText="Salvar"
        >
          <Formik
            innerRef={this.formRefResult}
            initialValues={bonus!}
            validationSchema={validationResultSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              const {
                idBonus,
                resultadoPercentual,
              } = values;

              editBonus({
                idBonus,
                resultadoPercentual,
              });
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputNumber
                  value={
                    !!values.resultadoPercentual
                      ? Number(values.resultadoPercentual)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("resultadoPercentual", value.floatValue);
                    setFieldError("resultadoPercentual", "");
                  }}
                  id="resultadoPercentual"
                  name="resultadoPercentual"
                  label="Percentual"
                  helperText={errors.resultadoPercentual}
                  error={!!errors.resultadoPercentual}
                />
              </form>
            )}
          </Formik>
        </Dialog>
        <Dialog
          title="Performance"
          open={isDialogEditPerformanceOpen}
          onClickCancel={() =>
            this.setState({ isDialogEditPerformanceOpen: false })
          }
          onClickConfirm={this.handleSubmitPerformance}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonusState.loadingAction || readOnly}
          isLoadingConfirm={bonusState.loadingAction}
          btnConfirmText="Salvar"
        >
          <Formik
            innerRef={this.formRefPerformance}
            initialValues={performanceSelected!}
            validationSchema={validationPerformanceSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              editBonusPerformance(
                String(idBonus),
                values.idBonusPerformance,
                values.multiplicador
              );
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <GroupItems title="Nível" titleRegular>
                  <TitleField text={values?.nivel?.descNivel ?? ""} bold />
                </GroupItems>
                <InputNumber
                  value={
                    !!values.multiplicador ? Number(values.multiplicador) : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("multiplicador", value.floatValue);
                    setFieldError("multiplicador", "");
                  }}
                  id="multiplicador"
                  name="multiplicador"
                  label="Multiplicador"
                  helperText={errors.multiplicador}
                  error={!!errors.multiplicador}
                />
              </form>
            )}
          </Formik>
        </Dialog>
        <Dialog
          title="Salário de Bônus"
          open={isDialogEditSalaryOpen}
          onClickCancel={() => this.setState({ isDialogEditSalaryOpen: false })}
          onClickConfirm={this.handleSubmitSalary}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonusState.loadingAction || readOnly}
          isLoadingConfirm={bonusState.loadingAction}
          btnConfirmText="Salvar"
        >
          <Formik
            innerRef={this.formRefSalary}
            initialValues={bonus!}
            validationSchema={validationSalarySchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              const {
                idBonus,
                salariosDiretor,
                salariosCoordenador,
                salariosGerente,
                salariosSupervisor,
              } = values;

              editBonus({
                idBonus,
                salariosDiretor,
                salariosCoordenador,
                salariosGerente,
                salariosSupervisor,
              });
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputNumber
                  value={
                    !!values.salariosDiretor
                      ? Number(values.salariosDiretor)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("salariosDiretor", value.floatValue);
                    setFieldError("salariosDiretor", "");
                  }}
                  id="salariosDiretor"
                  name="salariosDiretor"
                  label="Diretor"
                  helperText={errors.salariosDiretor}
                  error={!!errors.salariosDiretor}
                />
                <InputNumber
                  value={
                    !!values.salariosGerente
                      ? Number(values.salariosGerente)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("salariosGerente", value.floatValue);
                    setFieldError("salariosGerente", "");
                  }}
                  id="salariosGerente"
                  name="salariosGerente"
                  label="Gerente"
                  helperText={errors.salariosGerente}
                  error={!!errors.salariosGerente}
                />
                <InputNumber
                  value={
                    !!values.salariosCoordenador
                      ? Number(values.salariosCoordenador)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("salariosCoordenador", value.floatValue);
                    setFieldError("salariosCoordenador", "");
                  }}
                  id="salariosCoordenador"
                  name="salariosCoordenador"
                  label="Coordenador"
                  helperText={errors.salariosCoordenador}
                  error={!!errors.salariosCoordenador}
                />
                <InputNumber
                  value={
                    !!values.salariosSupervisor
                      ? Number(values.salariosSupervisor)
                      : null
                  }
                  onValueChange={(value) => {
                    setFieldValue("salariosSupervisor", value.floatValue);
                    setFieldError("salariosSupervisor", "");
                  }}
                  id="salariosSupervisor"
                  name="salariosSupervisor"
                  label="Supervisor / Analista"
                  helperText={errors.salariosSupervisor}
                  error={!!errors.salariosSupervisor}
                />
              </form>
            )}
          </Formik>
        </Dialog>
        <Dialog
          title="Faltas"
          open={isDialogEditFaltasOpen}
          onClickCancel={() => this.setState({ isDialogEditFaltasOpen: false })}
          onClickConfirm={this.handleSubmitFalta}
          btnCancelText="Fechar"
          disabledBtnConfirm={bonusState.loadingAction || readOnly}
          isLoadingConfirm={bonusState.loadingAction}
          btnConfirmText="Salvar"
        >
          <Formik
            innerRef={this.formRefFalta}
            initialValues={initialValueFalta}
            validationSchema={validationFaltaSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => {
              if (!values.idBonusFalta) {
                addFalta(idBonus, values);
              } else {
                editFalta(idBonus, values);
              }
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              setFieldError,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <InputNumber
                  value={!!values.qtdeMinima ? Number(values.qtdeMinima) : null}
                  onValueChange={(value) => {
                    setFieldValue("qtdeMinima", value.floatValue);
                    setFieldError("qtdeMinima", "");
                  }}
                  id="qtdeMinima"
                  name="qtdeMinima"
                  label="Quantidade mínima"
                  helperText={errors.qtdeMinima}
                  error={!!errors.qtdeMinima}
                  decimalScale={0}
                />
                <InputNumber
                  value={!!values.desconto ? Number(values.desconto) : null}
                  onValueChange={(value) => {
                    setFieldValue("desconto", value.floatValue);
                    setFieldError("desconto", "");
                  }}
                  id="desconto"
                  name="desconto"
                  label="Desconto"
                  helperText={errors.desconto}
                  error={!!errors.desconto}
                />
              </form>
            )}
          </Formik>
        </Dialog>
        <CustomDialog
          hidden={!isDialogFaltaExcludeOpen}
          onDismiss={() => this.setState({ isDialogFaltaExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogFaltaExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton onClick={() => this.excludeFalta()} text="Excluir" />
        </CustomDialog>
      </ContainerContent>
    );
  }

  private _onRenderRowResult: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const stylesForRow = (detailsRowStyleProps: any) => {
        customStyles.cell = {
          selectors: {
            "&:nth-child(2), &:nth-child(3)": {
              justifyContent: "flex-end",
              display: "flex",
            },
          },
        };
        return customStyles;
      };
      return (
        <DetailsRow
          {...props}
          styles={stylesForRow}
          itemIndex={props.itemIndex}
        />
      );
    }
    return null;
  };
}

const initialFalta: BonusFaltaType = {};

const dialogContentProps: IDialogContentProps = {
  title: "Excluir?",
  closeButtonAriaLabel: "Close",
  subText: "Tem certeza de que deseja excluir este item?",
};

const validationParametrosSchema = yup.object().shape({
  dataReferencia: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .required("Campo obrigatório"),
  mesesTrabalhados: yup
    .number()
    .min(0, "Qtde. mínima meses trabalhados inválida")
    .max(12, "Qtde. mínima meses trabalhados maior que 12")
    .nullable()
    .required("Campo obrigatório"),
});

const validationResultSchema = yup.object().shape({
  resultadoPercentual: yup.number().nullable().required("Campo obrigatório"),
});

const validationSalarySchema = yup.object().shape({
  salariosDiretor: yup.number().nullable().required("Campo obrigatório"),
  salariosCoordenador: yup.number().nullable().required("Campo obrigatório"),
  salariosGerente: yup.number().nullable().required("Campo obrigatório"),
  salariosSupervisor: yup.number().nullable().required("Campo obrigatório"),
});

const validationPerformanceSchema = yup.object().shape({
  multiplicador: yup.number().nullable().required("Campo obrigatório"),
});

const validationFaltaSchema = yup.object().shape({
  qtdeMinima: yup.number().nullable().required("Campo obrigatório"),
  desconto: yup.number().nullable().required("Campo obrigatório"),
});

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
  },
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...setCurrentPage,
      ...setCurrentTab,
      ...editBonus,
      ...editBonusPerformance,
      ...addFalta,
      ...editFalta,
      ...delFalta,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegrasBonus);
