import styled from 'styled-components';

import { Container as MaterialContainer } from "@material-ui/core";


export const ContainerDropdownInput = styled.div`
    width: auto;
    display: flex;
    align-items: center;

`

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 992px){
      flex-direction: column;
      align-items: flex-start;

     ${ContainerDropdownInput}:last-child{
       margin-top: 10px;
     }
    }
`;

export const InputContainer = styled(MaterialContainer)`
  margin-left: 0px !important;
  padding: 0px !important;
  width: 115px !important;
  margin-right: 0px !important;
`;

export const WrapperLabelGroup = styled.div`
    padding: 0px 16px 16px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between;
    flex-wrap: wrap; */

    /* div.lg{
        /* width: 330px;
        min-width: 330px; 
        padding-right: 10px;
        box-sizing: border-box;
        margin-right: 0px;
        margin-top: 20px;

        @media screen and (max-width: 767px){
          min-width: auto;
        }
    } */
`

export const WrapperBtnSolicita = styled.div`
  width: 320px;
  min-width: 320px;
  display: flex;
  align-items: center;
`
