import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as yup from 'yup';

import { useId } from '@uifabric/react-hooks';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FontIcon, Spinner, Stack, Text, TooltipHost } from 'office-ui-fabric-react';
import NoItems from '~/components/layout/NoItems';
import { Dropdown as DropdownUI, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

import { Table, Gap, Tbody, TdAvaliacao, textStyles, TdResultAtitudes } from '../allStyles';
import { ThAvaliacao, ThComentarios, TrAtitude, TdAtitude, TdDesc, TextAtitude, TrComentario, TextComentario } from './styles';
import Colors from '~/assets/js/colors';

import { AtitudesType } from '~/store/ducks/tasks/types';
import { Creators as CreatorsTasks } from "~/store/ducks/tasks";
import { Creators as CreatorsAvaliation } from "~/store/ducks/avaliation";
import { AbaixoExpectativa, AcimaExpectativa, DentroExpectativa, onRenderTitle, Accordion, AccordionSummary } from '../resultsCompontent';

interface IAttitudesProps {
    isLoading: boolean;
    atitudes: AtitudesType[];
    habilitaAvaliacaoGestor: boolean;
    flagAutoAvaliacao: boolean;
    tipoAvaliacao: string;
    onSave?: (values: IAtitudes[]) => void;
    validating?: boolean;
    isValid?: boolean;
    setIsValid?: (value: boolean) => void;
}

const validation = yup.object({
    atitudes: yup.array().of(
        yup.object().shape({
            resultado: yup.string().required('Campo obrigatório'),
            comentarios: yup.string().required('Campo obrigatório')
        })
    ),
});

const Attitudes: React.FC<IAttitudesProps> = (props) => {
    const { isLoading, atitudes, habilitaAvaliacaoGestor, flagAutoAvaliacao, tipoAvaliacao,  validating, setIsValid } = props;
    const initialValues: IInitialValues = {
        atitudes: []
    };

    const tooltipId = useId('tooltip');
    const dispatch = useDispatch();


    const {
        handleSubmit,
        setFieldValue,
        setFieldError,
        errors,
        values,
        isValid,
        
        isSubmitting,
        resetForm
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        validateOnBlur: true,
        validateOnChange: false,
        isInitialValid: false,
        onSubmit(values) {
            // onSave(values.atitudes);

        }
    });

    useEffect(() => {
        if (atitudes.length > 0) {
            const atitudesMap = atitudes.map(item => {
                return {
                    resultado: item.resultado,
                    comentarios: item.comentarios,
                    idCicloAvaliacaoAtitude: item.idCicloAvaliacaoAtitude
                }
            })
            resetForm({
                values: {
                    atitudes: atitudesMap
                }
            })
        }
    }, [atitudes])

    useEffect(() => {
        handleSubmit();
    }, [validating])

    useEffect(() => {
        if (isSubmitting) {
            setIsValid?.(isValid)
        }
    }, [isSubmitting, isValid])

    return (
        <form onSubmit={handleSubmit}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Text variant="mediumPlus" styles={textStyles}>Atitudes Vencedoras</Text>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        isLoading ?
                            <Spinner label="Carregando atitudes" styles={{ root: { height: 200 } }} />
                            :
                            (atitudes.length ?? []) <= 0 ?
                                <NoItems
                                    text="Não há atitudes"
                                    error={false}
                                    icon="Feedback"
                                />
                                :
                                <Table>
                                    <thead>
                                        <tr>
                                            <th colSpan={3}></th>
                                            <ThAvaliacao>AVALIAÇÃO</ThAvaliacao>
                                            <Gap />
                                            <ThComentarios>COMENTÁRIOS</ThComentarios>
                                        </tr>
                                    </thead>
                                    <Tbody>
                                        {
                                            atitudes.map((item, i) => {
                                                const { atitude, idCicloAvaliacaoAtitude } = item;
                                                const { nomeAtitude, descAtitude } = atitude;
                                                const errorResultado = errors?.atitudes ? (errors?.atitudes[i] as any)?.resultado : "";
                                                const errorComentario = errors?.atitudes ? (errors?.atitudes[i] as any)?.comentarios : "";

                                                if (values.atitudes && values?.atitudes.length > 0) {
                                                    return (
                                                        <TrAtitude key={i}>
                                                            <TdAtitude>
                                                                <p>
                                                                    {nomeAtitude}
                                                                </p>
                                                            </TdAtitude>
                                                            <TdDesc>
                                                                <TooltipHost
                                                                    content={descAtitude}
                                                                    id={tooltipId}
                                                                    styles={{ root: { position: 'relative' } }}
                                                                >
                                                                    <TextAtitude>
                                                                        {descAtitude}
                                                                    </TextAtitude>
                                                                </TooltipHost>
                                                            </TdDesc>
                                                            <Gap />
                                                            <TdAvaliacao>
                                                                <DropdownUI
                                                                    placeholder="Selecione uma opção"
                                                                    selectedKey={values?.atitudes[i]?.resultado ?? "Selecione uma opção"}
                                                                    options={options ?? []}
                                                                    onRenderTitle={onRenderTitle as any}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(`atitudes.${i}.resultado`, item?.key)
                                                                        setFieldError(`atitudes.${i}.resultado`, '')
                                                                        if (tipoAvaliacao !== 'Avaliação gestor') {
                                                                            dispatch(CreatorsTasks.saveAtitudeAvaliacao(idCicloAvaliacaoAtitude, String(item?.key) ?? ''))
                                                                        } else {
                                                                            dispatch(CreatorsAvaliation.saveAtitudeAvaliacao(idCicloAvaliacaoAtitude, String(item?.key) ?? ''))
                                                                        }
                                                                    }}
                                                                    disabled={tipoAvaliacao === 'Avaliação gestor' ? !habilitaAvaliacaoGestor : !flagAutoAvaliacao}
                                                                    styles={{ title: { height: 60, display: 'flex', alignItems: 'center', borderColor: errorResultado ? Colors.error : '#CCCCCC', paddingLeft: 10 }, caretDownWrapper: { top: 16 } }}
                                                                />
                                                            </TdAvaliacao>
                                                            <Gap />
                                                            <TrComentario>
                                                                <TextComentario
                                                                    rows={2}
                                                                    value={values?.atitudes[i]?.comentarios ?? ''}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`atitudes.${i}.comentarios`, e.target.value)
                                                                        setFieldError(`atitudes.${i}.comentarios`, '')
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (tipoAvaliacao !== 'Avaliação gestor') {
                                                                            dispatch(CreatorsTasks.saveAtitudeComentario(idCicloAvaliacaoAtitude, e.target.value))
                                                                        } else {
                                                                            dispatch(CreatorsAvaliation.saveAtitudeComentario(idCicloAvaliacaoAtitude, e.target.value))
                                                                        }
                                                                    }}
                                                                    style={{ borderColor: errorComentario ? Colors.error : '#CCCCCC' }}
                                                                    disabled={tipoAvaliacao === 'Avaliação gestor' ? !habilitaAvaliacaoGestor : !flagAutoAvaliacao}
                                                                />
                                                            </TrComentario>
                                                        </TrAtitude>
                                                    )
                                                }
                                            })}
                                        <tr>
                                            <td colSpan={3} />
                                            <TdResultAtitudes colSpan={3}>
                                                <Stack horizontal verticalAlign="center" horizontalAlign="center">
                                                    {
                                                        renderAvaliacao(convertAvaliacao(values.atitudes))
                                                    }
                                                </Stack>
                                            </TdResultAtitudes>
                                        </tr>
                                    </Tbody>
                                </Table>
                    }
                </AccordionDetails>
            </Accordion>
        </form>
    );
}


const renderAvaliacao = (result: string) => {
    switch (result) {
        case 'Abaixo da Expectativa':
            return AbaixoExpectativa();
        case 'Dentro da Expectativa':
            return DentroExpectativa();
        case 'Acima da Expectativa':
            return AcimaExpectativa();
        default:
            return result;
    }
}


const convertAvaliacao = (result: any[]) => {
    const total = result?.map(item => item.resultado).reduce((total, item) => convertAtitudesVal(item) + total, 0);
    const length = result?.length;
    const hasChanged = result?.some(item => item.resultado === null);
    const valorAcima = Math.round(length * 1.3333);

    if (hasChanged) {
        return '';
    } else if (total >= valorAcima)
        return 'Acima da Expectativa';
    else
        if (total >= length)
            return 'Dentro da Expectativa'
        else
            return 'Abaixo da Expectativa'
}

const convertAtitudesVal = (result: string) => {
    switch (result) {
        case 'Selecione uma opção':
            return 0;
        case 'Acima da Expectativa':
            return 2;
        case 'Dentro da Expectativa':
            return 1;
        case 'Abaixo da Expectativa':
            return 0;
        default:
            return 0;
    }
}

const options: IDropdownOption[] = [
    { key: "Selecione uma opção", text: 'Selecione uma opção', disabled: true, hidden: true, data: { styleOption: { fontSize: 14 } } },
    { key: "Acima da Expectativa", text: 'ACIMA DA EXPECTATIVA', data: { icon: 'SkypeCircleArrow', style: { color: Colors.blue, transform: 'rotate(90deg)' } } },
    { key: "Dentro da Expectativa", text: 'DENTRO DA EXPECTATIVA', data: { icon: 'SkypeCircleCheck', style: { color: Colors.primary } } },
    { key: "Abaixo da Expectativa", text: 'ABAIXO DA EXPECTATIVA', data: { icon: 'SkypeCircleArrow', style: { color: Colors.error, transform: 'rotate(-90deg)' } } }
];

interface IAtitudes {
    idCicloAvaliacaoAtitude: number;
    resultado: string | null;
    comentarios: string | null;
}

interface IInitialValues {
    atitudes: IAtitudes[]
}

export default Attitudes;
